import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col } from 'reactstrap';
import './ArrangementSlider.scss';
import ImageBuilding from 'app/modules/administration/arrangement/Components/image-building/image-building';

const ArrangementSlider: FC<any> = ({ tabs, isShow }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const backgroundLayerRef = useRef(null);

  const onExiting = useCallback(() => {
    setAnimating(true);
  }, []);

  const onExited = useCallback(() => {
    setAnimating(false);
  }, []);

  const slides = useMemo(
    () => [
      <CarouselItem onExiting={onExiting} onExited={onExited} key={0}>
        <Col xs={12}>
          <ImageBuilding
            isFixed={false}
            isRenderSeason={true}
            isShow={activeIndex === 0}
            isInterior={false}
            backgroundWidth={backgroundLayerRef?.current?.clientWidth}
          />
        </Col>
      </CarouselItem>,
      ...(tabs?.length === 3
        ? [
            <CarouselItem onExiting={onExiting} onExited={onExited} key={1}>
              <Col xs={12}>
                <ImageBuilding
                  isFixed={false}
                  isRenderSeason={true}
                  isShow={activeIndex === 1}
                  isInterior={true}
                  backgroundWidth={backgroundLayerRef?.current?.clientWidth}
                />
              </Col>
            </CarouselItem>,
          ]
        : []),
    ],
    [tabs?.length, onExiting, onExited, activeIndex, backgroundLayerRef?.current, isShow]
  );

  const next = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }, [animating, activeIndex]);

  const previous = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }, [activeIndex, animating]);

  const goToIndex = useCallback(
    newIndex => {
      if (animating) return;
      setActiveIndex(newIndex);
    },
    [animating]
  );

  return (
    <div
      ref={backgroundLayerRef}
      style={{
        width: '100%',
        ...(isShow
          ? {}
          : {
              position: 'absolute',
              overflow: 'hidden',
              left: '-10000px',
              top: '0',
              visible: 'hidden',
            }),
      }}
    >
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        className={'arrangement-slider'}
        autoPlay={false}
        interval={false}
        fade={true}
      >
        {slides}
        {slides?.length > 1 ? (
          <>
            <CarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={goToIndex} />
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </>
        ) : null}
      </Carousel>
    </div>
  );
};

export default ArrangementSlider;
