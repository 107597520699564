import React, { FC, useMemo } from "react";
import BasicFormDialog from "app/shared/layout/Dialog/BasicFormDialog";
import Translate from "app/shared/layout/Translation/translate";
import PartForm from "app/shared/layout/FormGenerator/Components/PartForm";
import { Button, Row } from "reactstrap";
import ButtonLoading from "app/shared/layout/ButtonLoading/ButtonLoading";
import { REQUIRED } from "app/shared/util/validation";
import { useAppSelector } from "app/config/store";
import { Storage } from "react-jhipster";

const getOfferOptions = (list, langKey) => {
  return (list || []).map(otherEntity => ({
    ...otherEntity,
    key: otherEntity.id,
    value: otherEntity.id,
    id: otherEntity.id,
    label: langKey === 'pl' ? otherEntity.name : otherEntity.nameEn,
  }));
};

interface ICreateModal {
  toggleClose: () => void;
  onSubmit: (event, values) => void;
  isOpen?: boolean;
  fieldsState: any;
  onChangeSelect: (objSelected: any, meta: any) => void;

}

export const CreateModal: FC<ICreateModal> = (
  {
    toggleClose,
    onSubmit,
    isOpen,
    onChangeSelect,
    fieldsState
  }: ICreateModal) => {
  const langKey = Storage.session.get('locale', 'pl');
  const offers = useAppSelector(state => state.offer.entities);
  const offerOptions = useMemo(() => getOfferOptions(offers, langKey), [offers, langKey]);
  const updating = useAppSelector(state => state.arrangement.updating);

  const fields = [
    {
      autoComplete: 'off',
      readOnly: false,
      name: 'offerArrangements',
      id: 'offerArrangements',
      type: 'select',
      onChange: onChangeSelect,
      value: fieldsState.offerArrangements,
      options: offerOptions,
      validate: { ...REQUIRED() },
      label: <Translate contentKey="proEcoApp.arrangement.offerArrangements"/>,
      col: { lg: '12', md: '12' },
    },
  ];

  return (
    <BasicFormDialog
      isOpen={isOpen}
      toggleClose={toggleClose}
      onValidSubmit={onSubmit}
      childrenHeader={<Translate contentKey={`proEcoApp.arrangement.addOfferHeader`} className={'text-center'}/>}
    >
      <PartForm fields={fields}/>
      <Row className={'justify-content-center d-flex flex-gap-1'}>
        <Button onClick={toggleClose} color='second-type' classNames="btn-default-size">
          <Translate contentKey="entity.action.cancel"/>
        </Button>
        <ButtonLoading
          disabled={updating}
          updating={updating}
          text={'entity.action.save'} classNames="btn-default-size"/>
      </Row>
    </BasicFormDialog>
  )
}
