import {useEffect} from "react";
import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import pluginConfig from "./CookieConsentConfig";
import "./CookieConsent.scss"
import {Storage} from "react-jhipster";
import {useLocation} from "react-router-dom";


export const CookieConsent = () => {
    const langKey = Storage.session.get('locale', 'pl');
    const {pathname} = useLocation();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieConsentApi = window.initCookieConsent();
    useEffect(() => {

        if (!document.getElementById('cc--main')) {
            if (pathname === '/cookies-policy' || pathname === '/privacy-policy') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.CookieConsentApi.hide();
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.CookieConsentApi.run({...pluginConfig, current_lang: langKey});
            }

        }

    }, [langKey, pathname]);
    return null;
}

export default CookieConsent;
