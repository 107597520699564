import { Button } from 'reactstrap';
import Translate from 'app/shared/layout/Translation/translate';
import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';

interface IUnauthorizedMenu {
  toggleClose: () => void;
}

const UnauthorizedMenu: FC<IUnauthorizedMenu> = ({ toggleClose }: IUnauthorizedMenu) => {
  const history = useHistory();

  const handleOnClickSignIn = () => {
    history.push('/login');
    toggleClose();
  };

  const handleOnClickRegister = () => {
    history.push('/account/register');
    toggleClose();
  };

  return (
    <>
      <div className="authorized-menu mb-3">
        <Translate className="menu-section" contentKey="global.menu.admin.content" />
        <Link className="home-menu-item" to="/" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.homepage" />
        </Link>
        <Link className="home-menu-item" to="/offer" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.products" />
        </Link>
        <Link to="/our-services" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.services" />
        </Link>
        <Link to="/how-it-works">
          <Translate contentKey="global.menu.homePage.howItWorks" />
        </Link>
        <Link to="/faq" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.faq" />
        </Link>
        <Link to="/blog" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.blog" />
        </Link>
        <Link to="/contact" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.contact" />
        </Link>
        <Link to="/about-us" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.aboutUs" />
        </Link>
        <Link to="/configurator" onClick={toggleClose}>
          <Translate contentKey={'global.menu.configurator'} />
        </Link>
      </div>
      <Button className="sign-in-button mr-ml" onClick={handleOnClickSignIn} color="first-type">
        <Translate contentKey="login.form.button" />
      </Button>
      <Translate className="text-inner-line mr-ml" contentKey="global.menu.topNav.noAccounts" />
      <Button className="register-button mt-5 mr-ml" onClick={handleOnClickRegister} color="only-text">
        <Translate contentKey="global.menu.topNav.createAnAccount" />
      </Button>
    </>
  );
};

export default UnauthorizedMenu;
