import { IOrder } from 'app/shared/model/order.model';
import { ICartItemStatus } from 'app/shared/model/cart-item-status.model';
import { IUser } from 'app/shared/model/user.model';
import { IOffer } from 'app/shared/model/offer.model';
import {IArrangement} from "app/modules/administration/arrangement/arrangement.reducer";

export interface ICartItem {
  id?: number;
  quantity?: number;
  order?: IOrder | null;
  cartItemStatus?: ICartItemStatus;
  client?: IUser;
  offer?: IOffer;
  arrangement?: IArrangement;
  cartItemValue?: number;
  cartNumber?: number;
}

export const defaultValue: Readonly<ICartItem> = {};
