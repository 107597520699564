import React, {FC, ReactNode} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Translate from 'app/shared/layout/Translation/translate';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {Link} from 'react-router-dom';

type IAddRowWrapper = {
  showAdd?: boolean;
  handleAdd?: () => void;
  children?: ReactNode;
};
export const AddRowWrapper: FC<IAddRowWrapper> = ({showAdd, handleAdd, children}) => {
  return (
    <>
      {children}
      {showAdd && (
        <Link className={'add-new'} to={'/'} onClick={(e) => {
          e.preventDefault();
          handleAdd();
        }}>
          <Translate contentKey="proEcoApp.project.addNew"/> <FontAwesomeIcon icon={faPlus}/>
        </Link>
      )}
    </>
  );
};

export default AddRowWrapper;
