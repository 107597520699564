import React, { FC } from "react";
import "./CartItem.scss";
import {returnCurrencyFormat, roundPrice} from "app/shared/util/entity-utils";

interface ICartItem {
  id: number;
  price: number;
  currency: string;
  arrangementName: string;
  quantity: number;
  handleRemove: () => void;
}

const trashIcon = "content/images/trash-icon.svg";

export const CartItem: FC<ICartItem> = ({ price, quantity, arrangementName, currency, handleRemove }: ICartItem) => {
  return (
    <div className="cart-item">
      <div>{quantity}sz. {arrangementName}</div>
      <div className="price-wrapper">
        {`${returnCurrencyFormat(roundPrice(price))} ${currency}`}
        <img onClick={handleRemove} src={trashIcon}/>
      </div>
    </div>
  )
}
