import React, {useEffect, useMemo} from 'react';
import DictionaryItemUpdate from 'app/modules/dictionaries/Components/dictionaryItemUpdate';

import Translate from 'app/shared/layout/Translation/translate';
import {TRANSLATED_DICTS_KEYS} from 'app/entities/translate-dict-item/constants';
import {
  MIN,
  REQUIRED
} from 'app/shared/util/validation';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {omit} from 'lodash';

import {Row} from 'reactstrap';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import {useOptions} from 'app/shared/hooks/useOptions';
import {convertEnumKey} from 'app/shared/util/entity-utils';
import {useRole} from 'app/shared/hooks/useRole';
import {ICategory} from 'app/shared/model/category.model';
import useCustomForm from 'app/shared/hooks/useCustomForm';
import {DICT_CONFIGURATION_LIST, DICT_CONFIGURATION_OTHER_LIST} from 'app/modules/dictionaries/constants';
import {defaultPagination} from 'app/shared/util/pagination.constants';
import {HAS_MATERIALS} from 'app/config/constants';
import {useMaterialOptions} from 'app/modules/administration/home-object-v2/hooks/useMaterialOptions';
import {getEntities as getMaterialTypes} from 'app/entities/material-type/material-type.reducer';
import {useEntityInformation} from 'app/shared/layout/DictionariesView/hooks/useEntityInformation';

interface IOptionTypeUpdateWrapper {
  isReadOnly?: boolean;
  dictTypeEntity?: ICategory;
  createEntity?: any;
  updateEntity?: any;
  getEntity?: any;
  reset?: any;
  pathArray?: any[];
  isCategoryDetail?: boolean;
  hasMaterial?: boolean;
}

type PropertyPrice = {
  netPurchasingPrice: number;
  priceTypeId: null;
};

interface IFieldsState {
  materialType: number;
  propertyPrice: PropertyPrice;
  name?: string;
  enumKey?: string;
}

const initFieldsState: IFieldsState = {
  materialType: null,
  propertyPrice: {
    netPurchasingPrice: null,
    priceTypeId: null,
  },
};

const OptionUpdateWrapper = ({
                               isReadOnly,
                               updateEntity,
                               createEntity,
                               getEntity,
                               reset,
                               pathArray,
                               hasMaterial = false,
                               isCategoryDetail,
                             }: IOptionTypeUpdateWrapper) => {
  const dispatch = useDispatch();
  const {id} = useParams<{ id: string }>();
  const {fieldsState, setFieldsState, onChangeSelectById} = useCustomForm({initFields: initFieldsState});
  const {priceTypesOptions} = useOptions(TRANSLATED_DICTS_KEYS.PRICE_TYPES);

  const {isSale, isPurchasing} = useRole();
  const canEdit = !isSale && !isPurchasing;

  const {pathname} = useLocation();
  const searchPath = useMemo(()=> pathname.split('/')[3],[pathname]);

  const dictListItem = useMemo(()=> [...DICT_CONFIGURATION_LIST, ...DICT_CONFIGURATION_OTHER_LIST]?.find(elem => elem.path === searchPath), [DICT_CONFIGURATION_LIST, DICT_CONFIGURATION_OTHER_LIST, searchPath]);

  const materialTypeOptions = useMaterialOptions({
    isDetail: isReadOnly,
    componentTypeId: dictListItem?.dictMaterial,
    hasMaterial
  });

  const {dictTypeEntity} = useEntityInformation(dictListItem?.translate, isCategoryDetail);
  useEffect(() => {
    if (HAS_MATERIALS?.includes(dictListItem?.dictMaterial) || hasMaterial) {
      dispatch(getMaterialTypes(defaultPagination));
    }
  }, [dictListItem?.dictMaterial]);

  useEffect(() => {
    if (id && dictTypeEntity?.id && id === dictTypeEntity.id.toString()) {
      setFieldsState({...dictTypeEntity});
    }
  }, [dictTypeEntity, id]);

  useEffect(() => {
    if (fieldsState.name && !id) {
      setFieldsState(prev => ({...prev, enumKey: convertEnumKey(fieldsState?.name)}));
    }
  }, [fieldsState.name]);

  const customSave = values => {
    const entity = {
      ...dictTypeEntity,
      ...omit(values, ['imagePath', 'image', 'file', 'required']),
      isActive: id ? dictTypeEntity?.isActive : true,
    };

    if (id) {
      const item = isCategoryDetail
        ? {
          entity: {...entity, id},
          category: dictListItem?.categoryDetailName,
        }
        : {...entity, id};
      dispatch(updateEntity(item));
    } else {
      const item = isCategoryDetail
        ? {
          entity,
          category: dictListItem?.categoryDetailName,
        }
        : entity;
      dispatch(createEntity(item));
    }
  };

  const additionalFields = materialTypeOptions?.length
    ? [
      {
        readOnly: isReadOnly || !canEdit,
        name: 'materialTypeId',
        id: 'materialTypeId',
        type: 'select',
        onChange: onChangeSelectById,
        value: materialTypeOptions?.find(materialTypeOption => materialTypeOption?.id === fieldsState?.materialTypeId),
        options: materialTypeOptions,
        label: dictListItem?.isGroup ? <Translate contentKey="proEcoApp.category.materialTypeGroup"/>: <Translate contentKey="proEcoApp.category.materialType"/>,
      },
    ]
    : [];

  const pricesFields = [
    {
      readOnly: isReadOnly,
      name: 'propertyPrice.priceTypeId',
      id: 'propertyPrice.priceTypeId',
      validate: {
        ...REQUIRED(),
      },
      type: 'select',
      options: priceTypesOptions,
      value: priceTypesOptions?.find(priceTypesOption => priceTypesOption.id === fieldsState?.propertyPrice?.priceTypeId),
      onChange: onChangeSelectById,
      label: <Translate contentKey="proEcoApp.category.priceTypeId"/>,
      col: {sm: 6},
    },

    {
      readOnly: isReadOnly,
      name: 'propertyPrice.netPurchasingPrice',
      id: 'propertyPrice.netPurchasingPrice',
      validate: {
        ...MIN(0),
        ...REQUIRED(),
      },
      type: 'decimalNumber',
      value: fieldsState?.propertyPrice?.netPurchasingPrice,
      label: <Translate contentKey="proEcoApp.category.netPurchasingPrice"/>,
      col: {sm: 6},
    },
  ];

  return (
    <DictionaryItemUpdate
      updateEntity={updateEntity}
      createEntity={createEntity}
      reset={reset}
      getEntity={getEntity}
      additionalFields={additionalFields}
      customSave={customSave}
      titleFields="proEcoApp.category.data"
      number={1}
      pathArray={pathArray}
      isCategory={true}
      isCategoryDetail={isCategoryDetail}
    >
      <Row>
        <PartForm fields={pricesFields} number={2} title="proEcoApp.category.prices" titleClass="mt-4"/>
      </Row>
    </DictionaryItemUpdate>
  );
};
export default OptionUpdateWrapper;
