import React, {FC, useMemo} from 'react';
import TabsCustom from 'app/modules/administration/arrangement/Components/TabsCustom';
import { useRoomTypeOptions } from 'app/modules/administration/home-object-v2/hooks/useRoomTypeOptions';
import { ArrangementWallRoom } from 'app/modules/administration/arrangement/Components/arrangement-section/arrangement-wall-room';

interface IArrangementGroupedMaterial {
  wallRoomComponents: any[];
  isEdit: boolean;
  isNew: boolean;
  isConfiguration: boolean;
}

export const ArrangementWallGroupedRoom: FC<IArrangementGroupedMaterial> = ({
  wallRoomComponents,
  isEdit,
  isNew,
  isConfiguration,
}: IArrangementGroupedMaterial) => {
  const roomTypeOptions = useRoomTypeOptions();

  const groupedComponentPropertyOptions = useMemo(()=> {
    return wallRoomComponents?.map((wallRoomComponent, wallRoomComponentIndex) => {
      const roomTypeOption = roomTypeOptions?.find(option => option?.id === wallRoomComponent?.roomTypeId);

      return {
        label: roomTypeOption?.label,
        isActive: 0,
        component: wallRoomComponent?.wallRooms?.map((wallRoom, wallRoomIndex) => (
          <ArrangementWallRoom
            isConfiguration={isConfiguration}
            isEdit={isEdit}
            isNew={isNew}
            wallRoom={wallRoom}
            key={wallRoom?.id}
            wallRoomIndex={wallRoomIndex}
            pathRoom={`interiorSection.wallRoomComponents[${wallRoomComponentIndex}].wallRooms[${wallRoomIndex}]`}
            isRequiredSection={false}
          />
        )),
      };
    })
  }, [wallRoomComponents, roomTypeOptions, isEdit, isNew, isConfiguration]);

  return (
    <div className={'arrangement-grouped-material'}>
      <TabsCustom tabs={groupedComponentPropertyOptions} activeTab={0} />
    </div>
  );
};
