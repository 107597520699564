import { useAppSelector } from 'app/config/store';
import { useMemo } from 'react';

export const useConfigurationLoading = () => {
  const categoryDetailLoading = useAppSelector(state => state.categoryDetail.loading);
  const componentTypeLoading = useAppSelector(state => state.componentType.loading);
  const roomTypeLoading = useAppSelector(state => state.roomType.loading);
  const projectLoading = useAppSelector(state => state.project.loading);
  const arrangementLoading = useAppSelector(state => state.arrangement.loading);

  return useMemo(
    () => categoryDetailLoading || componentTypeLoading || roomTypeLoading || projectLoading || arrangementLoading,
    [categoryDetailLoading, componentTypeLoading, roomTypeLoading, projectLoading, arrangementLoading]
  );
};
