import React, { memo, useContext } from 'react';
import WallRoomItem from 'app/modules/administration/home-object-v2/wall-room-item';
import DeleteRowWrapper from 'app/modules/administration/home-object-v2/delete-row-wrapper';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import AddRowWrapper from 'app/modules/administration/home-object-v2/add-row-wrapper';
import Translate from 'app/shared/layout/Translation/translate';
import { uniqueId } from 'lodash';
import BadgeInPublic from 'app/shared/layout/BadgeInPublic/BadgeInPublic';

export const WallRooms = ({ wallRooms, path, isDetail = false }) => {
  const { onRemoveRow, onAddRow } = useContext(HomeObjectContext);
  return (
    <>
      <div className={'home-object-title'}>
        <span className={'component-header'}>
          <AddRowWrapper
            handleAdd={() =>
              onAddRow(path, {
                key: uniqueId(),
                wallRoomColors: [{}],
              })
            }
            showAdd={!isDetail}
          >
            <BadgeInPublic>
              <Translate contentKey="proEcoApp.project.wall" />
            </BadgeInPublic>
          </AddRowWrapper>
        </span>
      </div>
      {wallRooms?.map((wallRoom, wallRoomIndex) => {
        return (
          <DeleteRowWrapper
            key={wallRoom?.id ?? wallRooms?.key}
            showDelete={!isDetail}
            handleDelete={() => onRemoveRow(path, wallRoomIndex)}
          >
            <WallRoomItem
              isDetail={isDetail}
              wallRoom={wallRoom}
              wallRoomIndex={wallRoomIndex}
              path={path}
              key={wallRoom?.id ?? wallRooms?.key}
            />
          </DeleteRowWrapper>
        );
      })}
    </>
  );
};

export default memo(WallRooms);
