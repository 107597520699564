import { IOrder } from 'app/shared/model/order.model';
import { IDeliveryType } from 'app/shared/model/delivery-type.model';

export interface IOrderDeliveryInformation {
  id?: number;
  shippingCost?: number | string | null;
  buyerName?: string;
  buyerLastName?: string;
  buyerEmail?: string;
  phoneNumber?: string;
  street?: string;
  buildingNumber?: string;
  flatNumber?: string;
  zipcode?: string;
  city?: string;
  order?: IOrder;
  deliveryType?: IDeliveryType;
  deliveryTypeId?: number | string| null;
}

export const defaultValue: Readonly<IOrderDeliveryInformation> = {};
