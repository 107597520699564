import React from 'react';
import { deactivateEntity, restoreEntity, getEntities, reset } from 'app/entities/equipment-type/equipment-type.reducer';
import { Switch, useRouteMatch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { DictionaryItemList } from 'app/modules/dictionaries/Components/dictionaryItemList';
import OptionUpdateWrapper from 'app/shared/layout/OptionDictionary/option-update-wrapper';
import { createEntity, getEntity, updateEntity } from 'app/entities/equipment-type/equipment-type.reducer';
import { useRole } from 'app/shared/hooks/useRole';

const Routes = () => {
  const { url } = useRouteMatch();
  const { isAdmin, isSystem, isDesign, isMarketing } = useRole();

  return (
    <Switch>
      <ErrorBoundaryRoute
        exact
        path={`${url}/new`}
        component={OptionUpdateWrapper}
        componentProps={{
          createEntity,
          getEntities,
          reset,
          canAction: isAdmin || isSystem || isDesign,
          canEdit: !isMarketing,
          hasMaterial: true
        }}
      />
      <ErrorBoundaryRoute
        exact
        path={`${url}/:id/edit`}
        component={OptionUpdateWrapper}
        componentProps={{
          updateEntity,
          getEntity,
          getEntities,
          reset,
          canAction: isAdmin || isSystem || isDesign,
          canEdit: !isMarketing,
          hasMaterial: true
        }}
      />
      <ErrorBoundaryRoute
        exact path={`${url}/:id`}
        component={OptionUpdateWrapper}
        componentProps={{
          isReadOnly: true,
          getEntity,
          hasMaterial: true
      }}
      />
      <ErrorBoundaryRoute
        path={url}
        component={DictionaryItemList}
        componentProps={{
          deleteEntity: deactivateEntity,
          restoreEntity,
          getEntities,
          getEntity,
          reset,
          canAction: isAdmin || isSystem || isDesign,
          canEdit: !isMarketing,
          additionalTableColumns: ['materialTypeId', 'propertyPrice'],
          hasMaterial: true
        }}
      />
    </Switch>
  );
};

export default Routes;
