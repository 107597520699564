import React, {useEffect, useRef, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState} from 'draft-js';
import './editor-draft.scss';
import {translate} from 'app/shared/layout/Translation/translate';
import {AvField} from 'availity-reactstrap-validation';
import RequiredAsterisk from 'app/shared/layout/RequiredAsterisk';
import {FontSizeOptions} from 'app/shared/layout/FormGenerator/Components/FontSizeOptions';
import {ColorPickerOption} from './ColorPickerOption';
import {stateToHTML} from "draft-js-export-html";
import {convertFromHTML} from "draft-convert";

interface IEditor {
  value?: string;
  onChange?: any;
  validate?: any;
  label?: any;
  name?: any;
  readOnly?: any;
}

const customStyleMap = {
  'fontsize-heading-60': {
    lineHeight: '54px',
    fontSize: '60px',
    fontWeight: '400',
    fontFamily: 'var(--dm-serif-display)',
  },
  'fontsize-heading-40': {
    lineHeight: '40px',
    fontSize: '40px',
    fontWeight: '400',
    fontFamily: 'var(--dm-serif-display)',
  },
  'fontsize-heading-30': {
    lineHeight: '30px',
    fontSize: '30px',
    fontWeight: '500',
    fontFamily: 'var(--dm-sans)',
  },
  'fontsize-heading-28': {
    lineHeight: '28px',
    fontSize: '28px',
    fontWeight: '400',
    fontFamily: 'var(--dm-serif-display)',
  },
  'fontsize-heading-12': {
    lineHeight: '16.8px',
    fontSize: '12px',
    fontWeight: '700',
    fontFamily: 'var(--dm-sans)',
    letterSpacing: '0.08em'
  },
  'fontsize-heading-18': {
    lineHeight: '23px',
    fontSize: '18px',
    fontWeight: '400',
    fontFamily: 'var(--dm-sans)',
  },
  'fontsize-heading-16': {
    lineHeight: '25.6px',
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: 'var(--dm-sans)',
  },
  'fontsize-heading-14': {
    lineHeight: '30.8px',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'var(--dm-sans)',
  },
};

const EditorComponent = ({value, onChange, validate, label, name, readOnly}: IEditor) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const changeEditorStatusRef = useRef(false);

  const onEditorStateChange = editorStateData => {
    changeEditorStatusRef.current = true;
    onChangeEditor(editorStateData);
    setEditorState(editorStateData);
  };
  useEffect(() => {
    if (value !== null && value !== undefined && changeEditorStatusRef.current === false) {
      const contentState = convertFromHTML({
        htmlToStyle(nodeName, node, currentStyle) {
          let style = currentStyle;
          if (node.style.color) {
            style = style.add(`color-${node.style.color}`);
          }
          if (node.style.fontSize) {
            const styleName = node.style.fontSize.replace('px', '')
            style = style.add(`fontsize-heading-${styleName}`);
          }

          return style
        },
      })(value);
      const editorCurrentState = EditorState.createWithContent(contentState);
      setEditorState(editorCurrentState);
    }
  }, [value]);

  const options: any = {
    inlineStyles: {
      SUPERSCRIPT: {
        element: 'sup'
      },
    },
    blockStyleFn(block) {
      if (block.getData().get('text-align')) {
        return {
          style: {
            textAlign: block.getData().get('text-align'),
          },
        }
      }
    },
    inlineStyleFn(styles) {
      let style = {};

      styles.toJS().map((styleName: string) => {
        if (customStyleMap[styleName]) {
          style = {...style, ...customStyleMap[styleName]};
        } else if (styleName?.includes('color-')) {
          style = {
            ...style,
            color: styleName?.replace('color-', '')
          };
        } else if (styleName?.includes('fontsize-')) {
          style = {
            ...style,
            fontSize: styleName?.replace('fontsize-', '')
          };
        } else if (styleName?.includes('fontweight-')) {
          style = {
            ...style,
            fontWeight: styleName?.replace('fontweight-', '')
          };
        } else if (styleName?.includes('fontfamily-')) {
          style = {
            ...style,
            fontFamily: styleName?.replace('fontfamily-', '')
          };
        } else if (styleName?.includes('lineHeight-')) {
          style = {
            ...style,
            lineHeight: styleName?.replace('lineHeight-', '')
          };
        }
      });

      return {
        element: 'span',
        style,
      };
    },
  };

  const onChangeEditor = e => {
    e?.getCurrentContent() && onChange && onChange({name, value: stateToHTML(e?.getCurrentContent(), options)});
  };


  return (
    <div className="editor-component">
      <div className={'editor-label'}>
        {!!label && label}
        {!readOnly && validate && validate.required && <RequiredAsterisk/>}
      </div>
      <Editor
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={onEditorStateChange}
        editorState={editorState}
        toolbar={{
          options: ['textAlign', 'list', 'inline', 'colorPicker'],
          blockType: {
            inDropdown: true,
          },
          fontSize: {
            options: [10, 11, 12, 14, 16, 18, 24, 30, 36],
          },
          textAlign: {
            inDropdown: true,
          },
          list: {
            inDropdown: true,
            options: ['unordered', 'ordered'],
          },
          inline: {
            options: ['superscript'],
          },
          colorPicker: {
            component: ColorPickerOption,
            colors: [
              '#1B1C1E',
              '#F5F3EE',
              '#E97A28',
              '#D56614',
              '#111827',
              '#1F2937',
              '#374151',
              '#4B5563',
              '#6B7280',
              '#9CA3AF',
              '#D1D5DB',
              '#E5E7EB',
              '#F3F4F6',
              '#F9FAFB',
            ],
          },
        }}
        customStyleMap={customStyleMap}
        toolbarCustomButtons={[<FontSizeOptions key={'FontSizeOptions'} onChange={setEditorState}
                                                editorState={editorState}/>]}
        readOnly={readOnly}
      />

      {validate && (
        <AvField
          errorMessage={translate('global.messages.validate.invalidField') || 'This field is invalid'}
          type="hidden"
          name={name}
          value={value ? value : ''}
          validate={validate}
        />
      )}
    </div>
  );
};

export default EditorComponent;
