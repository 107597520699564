import axios from 'axios';
import { IProviderTransaction, defaultValue } from 'app/shared/model/provider-transaction.model';
import { EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { createAsyncThunk, createSlice, isPending } from '@reduxjs/toolkit';
import { translate } from 'react-jhipster';

interface EntityProviderTransactionState extends EntityState<IProviderTransaction> {
  providerTransactionPaidUrl?: any;
  isUpdateProvTransPaidUrl?: boolean;
}

const initialState: EntityProviderTransactionState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IProviderTransaction>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  providerTransactionPaidUrl: { redirectUri: null },
  isUpdateProvTransPaidUrl: false,
};

const apiUrl = 'api/provider-transactions';

export type ProviderTransactionState = Readonly<typeof initialState>;

// Actions
export const getEntities = createAsyncThunk('providerTransaction/fetch_entity_list', async (params: any) => {
  return axios.get<IProviderTransaction[]>(apiUrl, { params });
});

export const getEntity = createAsyncThunk(
  'providerTransaction/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IProviderTransaction>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const updateProviderTransactionPaidUrl = createAsyncThunk(
  'providerTransaction/paid_entity',
  async (id: number, thunkAPI) => {
    try {
      const result = await axios.put<IProviderTransaction>(`${apiUrl}/paid-url/${id}`);
      thunkAPI.dispatch(getEntities({}));
      return result;
    } catch (err) {
      if (err.response.status.toString().startsWith('5')) {
        err.response.data.message = translate('proEcoApp.offer.error');
        err.response.status = 503;
      }
      throw err;
    }
  },
  { serializeError: serializeAxiosError }
);

// slice
export const ProviderTransactionSlice = createSlice({
  name: 'providerTransaction',
  initialState: initialState as ProviderTransactionState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = true;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(updateProviderTransactionPaidUrl.fulfilled, (state, action) => {
        state.updateSuccess = true;
        state.updating = true;
        state.isUpdateProvTransPaidUrl = false;
        state.providerTransactionPaidUrl = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = ProviderTransactionSlice.actions;

// Reducer
export default ProviderTransactionSlice.reducer;
