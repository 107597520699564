import React, { FC, useContext, useEffect, useState } from 'react';
import ButtonLoading from 'app/shared/layout/ButtonLoading/ButtonLoading';
import { reset } from 'app/entities/project/project.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useHistory } from 'react-router';
import { useRole } from 'app/shared/hooks/useRole';
import { updateArrangement } from 'app/modules/administration/arrangement/arrangement.reducer';
import { omit } from 'lodash';
import './arrangement-action.scss';
import { ConfigurationLayoutContext } from 'app/app';

interface IButtonsWrapper {
  isShare: boolean;
  isConfiguration: boolean;
  isNew: boolean;
  isEdit: boolean;
  offerId: number;
  withSummary?: boolean;
}

export const ButtonsWrapper: FC<IButtonsWrapper> = ({ isEdit, isConfiguration, isNew, isShare, withSummary }: IButtonsWrapper) => {
  const { currentTab, setCurrentTab, tabs } = useContext(ConfigurationLayoutContext);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const arrangementData = useAppSelector(state => state.arrangement.entity);
  const updateSuccess = useAppSelector(state => state.arrangement.updateSuccess);
  const [isSubmit] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isAdministrator } = useRole();
  const updating = useAppSelector(state => state.arrangement.updating);

  useEffect(() => {
    if (isSubmit && updateSuccess) {
      dispatch(reset());
      history.push(isAdministrator ? '/admin/arrangements' : '/arrangements');
    }
  }, [updateSuccess, isSubmit]);

  const handleSave = () => {
    dispatch(
      updateArrangement({
        ...omit(arrangementData, ['wallRoomColors']),
      })
    );
  };

  const openEdit = event => {
    const id = arrangementData?.arrangement?.id;
    event?.preventDefault();
    history.push(isAdministrator ? `/admin/arrangements/${id}/edit` : `/arrangements/${id}/edit`);
  };

  return (
    <div className="arrangement-action mt-3">
      {currentTab !== 0 && (
        <ButtonLoading
          type="button"
          color="second-type"
          updating={updating}
          disabled={updating}
          onClick={() => setCurrentTab(prev => prev - 1)}
          text="entity.action.back"
          classNames={'btn-default-size'}
          id="back"
        />
      )}
      {!isShare && !isConfiguration && !isNew && isEdit && isAuthenticated && (
        <ButtonLoading updating={updating} disabled={updating} text="entity.action.save" onClick={handleSave} id="save" />
      )}
      {!isShare && !isNew && !isEdit && !isConfiguration && (
        <ButtonLoading
          updating={updating}
          color={'third-only-border'}
          disabled={updating}
          onClick={openEdit}
          text="entity.action.edit"
          classNames={'btn-default-size'}
          id="edit"
        />
      )}

      {currentTab < tabs.length - 1 && (
        <ButtonLoading updating={updating} disabled={updating} text="entity.action.next" id="next" classNames={'btn-default-size'} />
      )}
      {!isShare && isEdit && withSummary && (
        <ButtonLoading
          updating={updating}
          disabled={updating}
          text="proEcoApp.arrangement.summary"
          id="summary"
          type="button"
          onClick={() => setCurrentTab(tabs?.length - 1)}
          classNames={'btn-default-size'}
        />
      )}
    </div>
  );
};
