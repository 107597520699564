import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {createEntitySlice, EntityState, IQueryParams, serializeAxiosError} from 'app/shared/reducers/reducer.utils';
import {defaultValue, IWallColorType} from 'app/shared/model/wall-color-type.model';
import {unset} from "lodash";
import {IEquipmentPropertyOptionType} from "app/shared/model/equipment-property-option-type.model";

const initialState: EntityState<IWallColorType> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/wall-color-types';

// Actions

export const getEntities = createAsyncThunk('wallColorType/fetch_entity_list', async ({
                                                                                        page,
                                                                                        size,
                                                                                        sort
                                                                                      }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IWallColorType[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'wallColorType/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IWallColorType>(requestUrl);
  },
  {serializeError: serializeAxiosError}
);

export const createEntity = createAsyncThunk(
  'wallColorType/create_entity',
  async (data: any, thunkAPI) => {
    const result = await axios.post<IWallColorType>(apiUrl, data, {
      headers: {['Content-Type']: 'multipart/form-data'},
    });
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  {serializeError: serializeAxiosError}
);

export const updateEntity = createAsyncThunk(
  'wallColorType/update_entity',
  async (data: any, thunkAPI) => {
    const result = axios.put<IWallColorType>(`${apiUrl}/${data.id}`, data.entity, {
      headers: {['Content-Type']: 'multipart/form-data'},
    })
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  {serializeError: serializeAxiosError}
);

export const partialUpdateEntity = createAsyncThunk(
  'wallColorType/partial_update_entity',
  async (entity: IWallColorType, thunkAPI) => {
    const result = await axios.patch<IWallColorType>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  {serializeError: serializeAxiosError}
);

export const deleteEntity = createAsyncThunk(
  'wallColorType/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IWallColorType>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  {serializeError: serializeAxiosError}
);

export const deactivateEntity = createAsyncThunk(
  'wallColorType/deactivate_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}/deactivate`;
    const result = await axios.put<IWallColorType>(requestUrl);
    if (result.status === 204) {
      unset(result, "headers['x-proecoapp-alert']");
      thunkAPI.dispatch(getEntities({}));

      return result;
    }
    return result;
  },
  {serializeError: serializeAxiosError}
);

export const restoreEntity = createAsyncThunk(
  'wallColorType/restore_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}/restore`;
    const result = await axios.put<IWallColorType>(requestUrl);

    if (result.status === 204) {
      unset(result, "headers['x-proecoapp-alert']");
      return result;
    }
    return result;
  },
  {serializeError: serializeAxiosError}
);


// slice

export const WallColorTypeSlice = createEntitySlice({
  name: 'wallColorType',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },

  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, restoreEntity, deactivateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, restoreEntity, deactivateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const {reset} = WallColorTypeSlice.actions;

// Reducer
export default WallColorTypeSlice.reducer;
