import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IQuestion } from 'app/shared/model/question.model';
import qs from "query-string";


const initialState: EntityState<IQuestion> = {
  loading: false,
  loadingPopular: false,
  errorMessage: null,
  entities: [],
  popularEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/questions';

// Actions

export const getEntities = createAsyncThunk('question/fetch_entity_list', async (params: any) => {
  return axios.get<IQuestion[]>(apiUrl, {
    params,
    paramsSerializer : (p) => qs.stringify(p, { arrayFormat: 'none' })
  });
});

export const getPopularEntities = createAsyncThunk('question/fetch_popular_entity_list', async () => {
  const params = { page: 0, size: 25, sort: 'displayOrder,asc', isPopular: true, isActive: true };
  return axios.get<IQuestion[]>(apiUrl, { params });
});

export const getEntity = createAsyncThunk(
  'question/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IQuestion>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'question/create_entity',
  async (entity: IQuestion, thunkAPI) => {
    return await axios.post<IQuestion>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'question/update_entity',
  async (entity: IQuestion, thunkAPI) => {
    return await axios.put<IQuestion>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'question/partial_update_entity',
  async (entity: IQuestion, thunkAPI) => {
    return await axios.patch<IQuestion>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'question/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.delete<IQuestion>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const deactivateQuestion = createAsyncThunk(
  'question/deactivate_entity',
  async (questionId: number, thunkAPI) => {
    return axios.put(`${apiUrl}/${questionId}/deactivate`);
  },
  { serializeError: serializeAxiosError }
);

export const restoreQuestion = createAsyncThunk(
  'question/restore_entity',
  async (questionId: number, thunkAPI) => {
    return await axios.put(`${apiUrl}/${questionId}/restore`);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const QuestionSlice = createEntitySlice({
  name: 'question',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getPopularEntities), (state, action) => {
        return {
          ...state,
          loadingPopular: false,
          popularEntities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deactivateQuestion, restoreQuestion), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getPopularEntities), state => {
        return {
          ...state,
          loadingPopular: true,
          errorMessage: null,
          updateSuccess: false,
        };
      })
      .addMatcher(isPending(getEntities, getEntity, getPopularEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, deactivateQuestion, restoreQuestion), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = QuestionSlice.actions;

// Reducer
export default QuestionSlice.reducer;
