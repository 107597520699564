import ConfigurationStep from 'app/shared/layout/TopNavMenu/Components/ConfigurationStep/ConfigurationStep';
import React from 'react';
import './configuration-step-mobile.scss';

const ConfigurationStepMobile = () => {
  return (
    <div className="d-xl-none configuration-step-mobile">
      <ConfigurationStep />
    </div>
  );
};

export default ConfigurationStepMobile;
