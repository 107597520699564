import React, { FC, useContext } from 'react';
import { Button, Col, UncontrolledTooltip } from 'reactstrap';
import ButtonLoading from 'app/shared/layout/ButtonLoading/ButtonLoading';
import Translate from 'app/shared/layout/Translation/translate';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { reset } from 'app/modules/administration/arrangement/arrangement.reducer';
import { useHistory } from 'react-router';
import { useRole } from 'app/shared/hooks/useRole';
import { ConfigurationLayoutContext } from 'app/app';
import { stringifySerializer } from 'app/shared/util/entity-utils';

interface IButtonsWrapper {
  isSubmit: boolean;
  isEdit: boolean;
  isShare: boolean;
  isDetail: boolean;
  isConfiguration: boolean;
  openShare: (event) => void;
  isNew: boolean;
  offerId: number;
  addToOffer: (item) => void;
  openEdit: (item) => void;
}

export const ButtonsWrapper: FC<IButtonsWrapper> = ({
  isEdit,
  isSubmit,
  isShare,
  isDetail,
  isConfiguration,
  openShare,
  isNew,
  offerId,
  addToOffer,
  openEdit,
}: IButtonsWrapper) => {
  const { isAdministrator, isClient } = useRole();
  const updating = useAppSelector(state => state.arrangement.updating);
  const updateSuccess = useAppSelector(state => state.arrangement.updateSubmitSuccess);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const arrangementData = useAppSelector(state => state.arrangement.entity);
  const isViewShareButton = !isShare && (isClient || (isNew && isAdministrator));
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isViewSaveButton =
    (!isSubmit || (isSubmit && !updateSuccess)) && !isShare && (isEdit || isClient || (isNew && isAdministrator) || !isAuthenticated);
  const { setCurrentTab } = useContext(ConfigurationLayoutContext);

  const onClickCancel = () => {
    dispatch(reset());
    if (isAdministrator && !isConfiguration) {
      history.push('/admin/arrangements');
      return;
    }
    if (isConfiguration) {
      history.push({
        pathname: `/offer/project/${arrangementData.projectId}/arrangement`,
        search: stringifySerializer({ projectId: arrangementData.projectId, offerId }),
      });
      return;
    }

    if (isClient) {
      history.push('/arrangements');
      return;
    }
  };

  const renderShareButton = () => (
    <>
      <div id={'openShare'} className="btn-fixed-width">
        <ButtonLoading
          updating={updating}
          color={'third-only-border'}
          disabled={(!isSubmit || (isSubmit && !updateSuccess)) && !isDetail}
          onClick={openShare}
          text="entity.action.share"
          classNames={'btn-fixed-width'}
          id="share"
        />
      </div>
      {!isSubmit && !isDetail && (
        <UncontrolledTooltip placement="top" target="openShare">
          <Translate contentKey="proEcoApp.arrangement.home.orderRequiredMessage" />
        </UncontrolledTooltip>
      )}
    </>
  );

  return (
    <Col xs={12} md={4}>
      <div className={'arrangement-section-summary'}>
        {isViewSaveButton && (
          <div id={'save-tooltip'} className="btn-fixed-width">
            <ButtonLoading
              updating={updating}
              color="first-type"
              disabled={updating}
              text="proEcoApp.arrangement.save"
              classNames={'btn-fixed-width'}
              id="save"
            />
            {isClient && (
              <UncontrolledTooltip placement="top" target="save-tooltip">
                <Translate contentKey="proEcoApp.arrangement.saveTooltip" />
              </UncontrolledTooltip>
            )}
          </div>
        )}
        {isViewShareButton && renderShareButton()}

        <ButtonLoading
          type="button"
          color="second-type"
          updating={updating}
          disabled={updating}
          onClick={() => setCurrentTab(prev => prev - 1)}
          text="entity.action.back"
          classNames={'btn-fixed-width'}
          id="back"
        />

        {!isConfiguration && isNew && !isShare && (
          <div className="btn-fixed-width">
            <ButtonLoading
              type="button"
              color="third-only-border"
              updating={updating}
              disabled={updating}
              onClick={addToOffer}
              text="proEcoApp.arrangement.addToOffer"
              classNames={'btn-fixed-width'}
              id="addToOffer"
            />
          </div>
        )}
        {!isNew && !isEdit && !isShare && (
          <ButtonLoading
            updating={updating}
            color={'third-only-border'}
            disabled={updating}
            onClick={openEdit}
            text="entity.action.edit"
            classNames={'btn-fixed-width'}
            id="edit"
          />
        )}
        <div className="btn-fixed-width">
          <Button type="button" color="third-only-border" onClick={onClickCancel} className={'btn-fixed-width'}>
            <Translate contentKey="entity.action.cancel" />
          </Button>
        </div>
      </div>
    </Col>
  );
};
