import React, {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";

interface IHeaderCard {
  label: any;
  isOpen?: boolean;
  isHeader?: boolean;
}

export const HeaderCard: FC<IHeaderCard> = ({isOpen, label, isHeader = true}: IHeaderCard) =>
  <div className={`component-header ${isHeader ? '' : 'small'} ${isOpen ? 'open' : ''}`}>
    {label}
    <div className={'component-header-icon-bound'}>
      {isOpen ? <FontAwesomeIcon icon={faArrowUp}/> :
        <FontAwesomeIcon icon={faArrowDown}/>}
    </div>
  </div>
