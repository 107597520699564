import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity, updateOrCreate } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IContent } from 'app/shared/model/content.model';
import { unset } from 'lodash';
import { defaultPagination } from 'app/shared/util/pagination.constants';

const initialState: EntityState<IContent> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  homepage: [],
  steps: [],
  currentStep: 0,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/contents';
const apiUploadUrl = 'api/files/upload';

// Actions

export const getEntities = createAsyncThunk('content/fetch_entity_list', async (params: any) => {
  return axios.get<IContent[]>(apiUrl, { params: { sort: 'id,asc', ...params, ...defaultPagination } });
});

export const getEntity = createAsyncThunk(
  'content/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IContent>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'content/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<IContent>(apiUrl, entity);
    if (result.status === 201) {
      unset(result, "headers['x-proecoapp-alert']");
    }
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'content/update_entity',
  async (entity: IContent, thunkAPI) => {
    return await axios.put<IContent>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'content/partial_update_entity',
  async (entity: IContent, thunkAPI) => {
    return await axios.patch<IContent>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const uploadImage = createAsyncThunk(
  'content/upload_image',
  async (data: { formData: any; contentObj?: any }, thunkAPI) => {
    return axios.post<any>(`${apiUploadUrl}`, data?.formData, { headers: { ['Content-Type']: 'multipart/form-data' } });
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'content/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.delete<IContent>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const resetHomePage = createAsyncThunk('content/reset_homepage', () => {}, { serializeError: serializeAxiosError });
// slice

export const ContentSlice = createEntitySlice({
  name: 'content',
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
    changeCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    updateCms(state, action) {
      if (action?.payload?.length) {
        action.payload?.map(data => {
          updateOrCreate(state.homepage, data, 'contentKey');
        });
      } else {
        updateOrCreate(state.homepage, action.payload, 'contentKey');
      }
    },
    updateSteps(state, action) {
      state.steps = action.payload;
    },
    deleteImage(state, action) {
      state.loading = false;
      updateOrCreate(
        state.homepage,
        {
          ...action.payload.contentObj,
          imageUrl: null,
          imagePath: null,
        },
        'contentKey'
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetHomePage.fulfilled, state => {
        state.homepage = initialState.homepage;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(uploadImage), (state, action) => {
        state.loading = false;
        updateOrCreate(
          state.homepage,
          {
            ...action.meta.arg.contentObj,
            imageUrl: action.payload.data.url,
            imagePath: action.payload.data.url,
          },
          'contentKey'
        );
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.homepage = [];
      })
      .addMatcher(isPending(uploadImage), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, updateCms, deleteImage, updateSteps, changeCurrentStep } = ContentSlice.actions;

// Reducer
export default ContentSlice.reducer;
