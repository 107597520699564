import { IComponents } from 'app/shared/model/component.model';
import { IEquipmentType } from 'app/shared/model/equipment-type.model';
import { IComponentProperty } from 'app/shared/model/component-property.model';

export interface IEquipment {
  id?: number;
  imagePath?: string;
  imageLayerName?: string;
  imageUrl?: string;
  positionTop?: number;
  positionLeft?: number;
  imageWidth?: number;
  imageHeight?: number;
  imageOrder?: number;
  equipmentTypeId?: any;
  isEquipmentSet?: boolean;
  component?: IComponents;
  equipmentType?: IEquipmentType;
  equipmentProperties?: any;
}

export interface IEquipmentMaterialGroup {
  id?: number;
  materialTypeId?: number;
  materialGroups: IEquipment[];
}

export const defaultValue: Readonly<IEquipment> = {};
