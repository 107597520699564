import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { UpdatePage } from 'app/shared/layout/Pages/UpdatePage';
import { get } from 'lodash';
import { DICT_CONFIGURATION_LIST, DICT_CONFIGURATION_OTHER_LIST, DICT_LIST, IDictListItem } from 'app/modules/dictionaries/constants';
import DictItemForm from './dictItemForm';
import { useEntityInformation } from 'app/shared/layout/DictionariesView/hooks/useEntityInformation';

interface IDictItemUpdate {
  getEntity?: any;
  reset?: any;
  pathArray?: any;
  createEntity?: any;
  updateEntity?: any;
  additionalFields?: any;
  children?: any;
  dictList?: IDictListItem[];
  customSave?: (values) => void;
  titleFields?: string;
  number?: number;
  isCategory?: boolean;
  isCategoryDetail?: boolean;
}

const DictItemUpdate: React.FC<IDictItemUpdate> = ({
  getEntity,
  reset,
  createEntity,
  updateEntity,
  additionalFields,
  children,
  customSave,
  dictList = [...DICT_LIST, ...DICT_CONFIGURATION_LIST, ...DICT_CONFIGURATION_OTHER_LIST],
  titleFields,
  pathArray,
  number,
  isCategory,
  isCategoryDetail,
}: IDictItemUpdate) => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const searchPath = pathname.split('/')[3];
  const dictListItem = dictList.find(elem => elem.path === searchPath);
  const { dictTypeEntity, loading } = useEntityInformation(dictListItem?.translate, isCategoryDetail);
  const isNew = useMemo(() => !id, [id]);
  const searchEditPath = pathname.split('/').slice(-1)[0];
  const isEdit = useMemo(() => isNew || searchEditPath === 'edit', [isNew, searchEditPath]);
  const isEmptyProjectFormTypeEntity = useMemo(() => !get(dictTypeEntity, 'id', false), [dictTypeEntity]);
  const isLoading = useMemo(() => loading || (!isNew && isEmptyProjectFormTypeEntity), [loading, isEmptyProjectFormTypeEntity, isNew]);

  return (
    <UpdatePage
      withAppPrefix
      pathName={dictListItem.path}
      i18nGroupPrefix={dictListItem.translate}
      isLoading={isLoading}
      getEntity={getEntity}
      reset={reset}
      isReadOnly={!isEdit}
      pathArray={pathArray}
      isCategoryDetail={isCategoryDetail}
      categoryDetailName={dictListItem?.categoryDetailName}
    >
      <DictItemForm
        isNew={isNew}
        isSave={isEdit}
        isReadOnly={!isEdit}
        createEntity={createEntity}
        updateEntity={updateEntity}
        dictListItem={dictListItem}
        additionalFields={additionalFields}
        customSave={customSave}
        titleFields={titleFields}
        number={number}
        dictPath={isCategory ? searchPath : undefined}
        isCategoryDetail={isCategoryDetail}
      >
        {children}
      </DictItemForm>
    </UpdatePage>
  );
};

export default DictItemUpdate;
