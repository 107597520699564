import React, { memo, useContext } from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';

import AddRowWrapper from 'app/modules/administration/home-object-v2/add-row-wrapper';

import ComponentPropertyConstructionItem from 'app/modules/administration/home-object-v2/component-property-construction-item';

export const ComponentPropertyConstructions = ({
  componentTypeId,
  componentTypeLabel,
  componentPropertyConstructions = [],
  path,
  isDetail = false,
}) => {
  const { onAddRow } = useContext(HomeObjectContext);

  return (
    <>
      <div className={'home-object-title'}>
        <span className={'component-header'}>
          <AddRowWrapper handleAdd={() => onAddRow(path)} showAdd={!isDetail}>
            <Translate contentKey="proEcoApp.project.componentPropertyConstructionTitle" interpolate={{ type: componentTypeLabel }} />
          </AddRowWrapper>
        </span>
      </div>
      {componentPropertyConstructions?.map((componentPropertyConstruction, componentPropertyConstructionIndex) => {
        return (
          <ComponentPropertyConstructionItem
            key={componentPropertyConstruction?.id ?? componentPropertyConstruction?.key}
            componentTypeId={componentTypeId}
            componentPropertyConstruction={componentPropertyConstruction}
            componentPropertyConstructionIndex={componentPropertyConstructionIndex}
            path={path}
            isDetail={isDetail}
            showDelete={!isDetail}
          />
        );
      })}
    </>
  );
};

export default memo(ComponentPropertyConstructions);
