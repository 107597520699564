import React, { FC } from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import { Button, Spinner } from 'reactstrap';

export interface IButtonLoadingProps {
  updating: boolean;
  disabled: boolean;
  text?: string;
  type?: any;
  color?: string;
  id?: string;
  isSuccess?: boolean;
  classNames?: string;
  onClick?: any;
}

const ButtonLoading: FC<IButtonLoadingProps> = ({
  updating,
  text = 'entity.action.save',
  type = 'submit',
  color = 'first-type',
  id = 'save-entity',
  classNames = '',
  disabled = updating,
  onClick,
}: IButtonLoadingProps) => {
  return (
    <Button
      color={color}
      id={id}
      type={type}
      className={`${disabled ? 'disabled' : ''} ${classNames}`}
      disabled={disabled}
      onClick={onClick}
    >
      {updating ? <Spinner style={{ width: '25px', height: '25px' }} className={"mr-2"}/> : null}
      <Translate className="label" contentKey={text}>
        Save
      </Translate>
    </Button>
  );
};

export default ButtonLoading;
