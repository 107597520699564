import React, { memo, useMemo } from 'react';
import ComponentProperty from 'app/modules/administration/home-object-v2/component-property';
import { useComponentType } from 'app/modules/administration/arrangement/hooks/useComponentType';
import {OPTIONAL_COMPONENTS} from "app/config/constants";
type IComponentItem = {
  component: any;
  path: string;
  isDetail: boolean;
  componentIndex: number;
  isInterior?: boolean;
}
export const ComponentItem = memo<IComponentItem>(({ component, path, isDetail = false, componentIndex, isInterior = false }) => {
  const componentTypesOptions = useComponentType(isDetail);
  const componentTypeObj = useMemo(
    () => componentTypesOptions.find(componentTypesOption => componentTypesOption.id === component?.componentTypeId),
    [componentTypesOptions, component?.componentTypeId]
  );
  return (
    <div key={componentIndex}>
      <ComponentProperty
        componentProperties={component?.componentProperties}
        componentIndex={componentIndex}
        componentTypeLabel={componentTypeObj?.label}
        componentTypeId={componentTypeObj?.id}
        path={`${path}[${componentIndex}].componentProperties`}
        isDetail={isDetail}
        isInterior={isInterior}
        showDelete={!isDetail && (OPTIONAL_COMPONENTS?.includes(component?.componentTypeId) || component?.componentProperties?.length > 1)}
      />
    </div>
  );
});

export default ComponentItem;
