import React, { memo, useContext } from 'react';
import EquipmentItem from 'app/modules/administration/home-object-v2/equipment-item';
import Translate from 'app/shared/layout/Translation/translate';
import AddRowWrapper from 'app/modules/administration/home-object-v2/add-row-wrapper';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import BadgeInPublic from 'app/shared/layout/BadgeInPublic/BadgeInPublic';

export const Equipments = ({ equipments = [], path, isDetail = false, isSet = false }) => {
  const { onAddRow } = useContext(HomeObjectContext);

  return (
    <>
      <div className={'home-object-title'}>
        <span className={'component-header'}>
          <AddRowWrapper handleAdd={() => onAddRow(path)} showAdd={!isDetail}>
            <BadgeInPublic>
              {isSet ? <Translate contentKey="proEcoApp.project.sets" /> : <Translate contentKey="proEcoApp.project.equipment" />}
            </BadgeInPublic>
          </AddRowWrapper>
        </span>
      </div>
      {equipments?.map((equipment, equipmentIndex) => {
        return (
          <EquipmentItem
            isSet={isSet}
            key={equipment?.id ?? equipmentIndex}
            equipment={equipment}
            equipmentIndex={equipmentIndex}
            path={path}
            isDetail={isDetail}
            showDelete={!isDetail}
          />
        );
      })}
    </>
  );
};

export default Equipments;
