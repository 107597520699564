import React, {FC, useCallback, useContext, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {ConfigurationLayoutContext} from 'app/app';
import SelectPropertiesOptions
  from 'app/modules/administration/arrangement/Components/select-properties-options/select-properties-options';
import SelectPropertyName
  from 'app/modules/administration/arrangement/Components/select-properties-options/select-property-name';
import Translate from 'app/shared/layout/Translation/translate';
import {updateSelectedOption} from 'app/modules/administration/arrangement/arrangement.reducer';
import {IsSubmitForShareContext} from 'app/modules/administration/arrangement/arrangement-update';
import {
  useOptionDescriptions,
  useOptionNames
} from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';
import {BUILDING_TYPE_ID} from 'app/modules/administration/home-object-v2/hooks/useBuildingTypeOptions';
import {useMaterialOptions} from "app/modules/administration/home-object-v2/hooks/useMaterialOptions";

interface IArrangementGroupedMaterialItem {
  materialGroup: any;
  materialGroupIndex: number;
  componentTypeId?: number;
  isEdit: boolean;
  isNew: boolean;
  isConfiguration: boolean;
  isRequiredSection?: boolean;
  isMultiChoice?: boolean;
  isInterior?: boolean;
  selected?: any;
  path: string;
}

export const ArrangementGroupedMaterialItem: FC<IArrangementGroupedMaterialItem> = ({
                                                                                      materialGroup,
                                                                                      materialGroupIndex,
                                                                                      path,
                                                                                      isEdit,
                                                                                      isNew,
                                                                                      isConfiguration,
                                                                                      isRequiredSection,
                                                                                      isMultiChoice,
                                                                                      selected,
                                                                                    }: IArrangementGroupedMaterialItem) => {
  const dispatch = useAppDispatch();
  const {setErrorMessage} = useContext(ConfigurationLayoutContext);
  const buildingTypeId = useAppSelector(state => state.arrangement.entity?.arrangement?.buildingTypeId || BUILDING_TYPE_ID.SUMMER_TYPE);

  const componentProperties = useMemo(() => materialGroup?.componentProperties, [materialGroup?.componentProperties]);
  const isDetail = useMemo(() => !isEdit && !isNew, [isEdit, isNew]);
  const isSwitchAble = useAppSelector(state => state.arrangement.entity?.isSwitchAble);
  const materialTypeOptions = useMaterialOptions({isDetail, hasMaterial: true});


  const materialTypeLabel = useMemo(
    () => materialTypeOptions?.find(option => option?.id === materialGroup?.materialTypeId)?.label,
    [materialTypeOptions, materialGroup?.materialTypeId]
  );

  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const {setIsSubmitForShare} = useContext(IsSubmitForShareContext);

  const componentPropertyOptions = useMemo(() => componentProperties
    ?.map((property, propertyIndex) => ({
      ...property,
      label: nameOptions?.find(nameOption => nameOption?.id === property?.cpNameId)?.label ?? (
        <Translate
          contentKey="proEcoApp.project.componentPropertyOption"
          interpolate={{component: materialTypeLabel, index: propertyIndex + 1}}
        />
      ),
      path: `${path}.materialGroups[${materialGroupIndex}].componentProperties[${propertyIndex}]`,

      children: (
        <>
          <div className="properties-header-description">
            {descriptionOptions?.find(descriptionOption => descriptionOption?.id === property?.cpDescriptionId)?.label}
          </div>
        </>
      ),
    })), [componentProperties, materialTypeLabel, descriptionOptions, nameOptions, buildingTypeId]);

  const options = useMemo(() => {

    if (!isSwitchAble) {
      return componentPropertyOptions;
    }
    return componentPropertyOptions?.filter(
      componentProperty =>
        componentProperty?.buildingTypeId === BUILDING_TYPE_ID?.ALL ||
        componentProperty?.buildingTypeId === buildingTypeId ||
        !componentProperty?.buildingTypeId
    );
  }, [componentPropertyOptions, buildingTypeId, isSwitchAble]);

  const handleSelected = pathGroup => (name, value, pathName) => {
    dispatch(
      updateSelectedOption({
        name,
        value,
        path: pathName,
        ...!isMultiChoice ? {
          pathGroup,
          pathGroupKey: "componentProperties",
        } : {},
      })
    );
    setErrorMessage('');
    setIsSubmitForShare(false);
  };

  return (
    <div key={materialGroupIndex} className={'component-properties'}
         id={`option-grouped-material-${materialGroupIndex}`}>
      <div id={`component-${materialGroupIndex}`}>
        <SelectPropertiesOptions
          options={options}
          name={'componentPropertyOptionId'}
          hasImage={true}
          onSelected={handleSelected(`${path}.materialGroups`)}
          path={`${path}.materialGroups[${materialGroupIndex}].componentProperties`}
          disabled={!isNew && !isEdit && !isConfiguration}
          isRequiredSection={isRequiredSection}
          value={selected}
        />
      </div>
    </div>
  );
};
