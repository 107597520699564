import { IProjectSeason } from 'app/shared/model/project-season.model';
import { IProjectType } from 'app/shared/model/project-type.model';
import { IArrangementDetails } from 'app/shared/model/arrangement.model';

export interface IProject {
  id?: number;
  hasArrangement?: boolean;
  name?: string;
  parentId?: number;
  sourceName?: string;
  privateName?: string;
  description?: string | null;
  storey?: number;
  totalSquare?: number;
  roofWindowNumber?: number;
  roofSquare?: number;
  windowNumber?: number;
  roomNumber?: number;
  numberOfExteriorDoor?: number;
  numberOfInteriorDoor?: number;
  bathroomNumber?: number;
  totalBasePrice?: number;
  facadeSquare?: number;
  internalWall?: number;
  outerWall?: number;
  floorThickness?: number;
  projectType?: IProjectType;
  projectTypeId?: number;
  projectSeason?: IProjectSeason;
  sections?: any;
  psdImageUrl?: string;
  isActive?: boolean;
  shellAndCores?: any[];
  isSwitchAble?: number;
  interiorSection?: any;
  previewLayerUrl?: any;
  previewLayerPath?: any;
  psdImagePath?: string;
  psdImageWidth?: number;
  psdImageHeight?: number;
  baseLayerPath?: string;
  baseLayerUrl?: string;
  baseLayerWidth?: number;
  baseLayerHeight?: number;
  positionLeft?: any;
  positionTop?: any;

  interiorPsdImageUrl?: string;
  interiorPreviewLayerUrl?: any;
  interiorPreviewLayerPath?: any;
  interiorPsdImagePath?: string;
  interiorPsdImageWidth?: number;
  interiorPsdImageHeight?: number;
  interiorBaseLayerPath?: string;
  interiorBaseLayerUrl?: string;
  interiorBaseLayerWidth?: number;
  interiorBaseLayerHeight?: number;
  interiorPositionLeft?: any;
  interiorPositionTop?: any;

  price?: number;
  project?: any;
  arrangementName?: string;
  arrangementType?: any;
  arrangementDetails?: IArrangementDetails[];
  minPrice?: number;
  objectConstructionTypeId?: any;
  roomProjects?: any;
  components?: any;
  sectionSeasons?: any;
  interiorSectionSeasons?: any;
  numberOfCar?: any;
  craneHour?: any;
}

export const defaultValue: Readonly<IProject> = {
  arrangementDetails: [],
  isActive: true,
  components: [
    {
      componentTypeId: 1,
      componentProperties: [
        {

          componentPropertyTypeId: 1,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 2,
      componentProperties: [
        {

          componentPropertyTypeId: 2,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 3,
      componentProperties: [
        {

          componentPropertyTypeId: 3,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      // name: 'Roof option',
      componentTypeId: 4,
      componentProperties: [
        {

          componentPropertyTypeId: 4,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      // name: 'External doors option',
      componentTypeId: 6,
      componentProperties: [
        {

          componentPropertyTypeId: 6,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 7,
      componentProperties: [
        {

          componentPropertyTypeId: 7,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 8,
      componentProperties: [
        {


          componentPropertyTypeId: 8,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 10,
      componentProperties: [
        {


          componentPropertyTypeId: 10,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 19,
      componentProperties: [
        {

          componentPropertyTypeId: 19,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 9,
      componentProperties: [
        {

          componentPropertyTypeId: 9,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 15,
      componentProperties: [
        {

          componentPropertyTypeId: 15,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 14,
      componentProperties: [
        {

          componentPropertyTypeId: 14,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 16,
      componentProperties: [
        {

          componentPropertyTypeId: 16,
          imagePath: null,
          imageUrl: null,
          imageLayerName: null,
          positionTop: null,
          positionLeft: null,
          imageWidth: null,
          imageHeight: null,
          imageOrder: null,
          quantity: null,
          labour: null,
          totalPrice: null,
          subComponentProperties: [],
          componentPropertyAdditionalMaterials: [],
          componentPropertyConstructions: [],
        },
      ],
    },
    {
      componentTypeId: 17,
      componentProperties: [],
    },
  ],
  roomProjects: [],

  numberOfCar: null,
  craneHour: null,
  sectionSeasons: [
    {
      projectSeasonId: 2,
    },
  ],
  interiorSectionSeasons: [
    {
      projectSeasonId: 2,
    },
  ],
};
