import React, { FC } from 'react';

import ItemCollapse from 'app/modules/administration/arrangement/Components/ItemCollapse';
import Translate from 'app/shared/layout/Translation/translate';
import ComponentCollapse from 'app/modules/administration/arrangement/Components/ComponentCollapse';

import { useRoomTypeOptions } from 'app/modules/administration/home-object-v2/hooks/useRoomTypeOptions';

import { FurnishingEquipmentItem } from 'app/modules/administration/arrangement/Components/arrangement-section/furnishing-equipment-item';

interface IFurnishingEquipments {
  roomEquipments: any;
  counter: number;
  isEdit: boolean;
  isNew: boolean;
  isConfiguration: boolean;
}

export const FurnishingEquipments: FC<IFurnishingEquipments> = ({ roomEquipments, isNew, isEdit, counter, isConfiguration }) => {
  const roomTypeOptions = useRoomTypeOptions();

  if (roomEquipments?.length < 1) {
    return <></>;
  }
  return (
    <>
      <ItemCollapse
        header={isOpen => (
          <div className={`subsection-header w-100 ${isOpen ? 'open' : ''}`}>
            {counter}. <Translate contentKey="proEcoApp.arrangement.furnishings" />
          </div>
        )}
        id={`subsection`}
        defaultOpen={true}
      >
        {roomEquipments?.map((room, index) => (
          <ComponentCollapse
            key={`walls-${index}`}
            id={`walls-${index}`}
            header={isOpen => (
              <div className={`component-header w-100 ${isOpen ? 'open' : ''}`}>
                {roomTypeOptions?.find(option => option?.id === room?.roomTypeId)?.translateLabel}
              </div>
            )}
            defaultOpen={true}
          >
            <FurnishingEquipmentItem
              equipments={room?.equipmentSets}
              isNew={isNew}
              isEdit={isEdit}
              isConfiguration={isConfiguration}
              isSet={true}
              path={`interiorSection.furnishingSubsection.roomEquipments[${index}]`}
            />

            <FurnishingEquipmentItem
              equipments={room?.equipments}
              isNew={isNew}
              isEdit={isEdit}
              isConfiguration={isConfiguration}
              path={`interiorSection.furnishingSubsection.roomEquipments[${index}]`}
            />
          </ComponentCollapse>
        ))}
      </ItemCollapse>
    </>
  );
};
