import React, { FC, useMemo, useState } from 'react';

export interface IImageLayerProps {
  item?: any;
  scale?: number;
  alt?: string;
  onLoad?: any;
  imageOrder?: number;
  isInterior?: boolean;
  isShow?: boolean;
}

const ImageLayer: FC<IImageLayerProps> = ({ item, scale, alt = '', onLoad, isInterior, isShow }) => {
  const positionTop = useMemo(() => (item?.positionTop ?? 0) * scale, [item?.positionTop, scale]);
  const positionLeft = useMemo(() => (item?.positionLeft ?? 0) * scale, [item?.positionLeft, scale]);

  return (
    <img
      style={{
        width: item?.imageWidth * scale,
        height: item?.imageHeight * scale,
        top: positionTop,
        left: positionLeft,
        position: 'absolute',
        opacity: isShow && item.isSelected && isInterior === item?.isInterior ? 1 : 0,
      }}
      alt={alt}
      src={item?.imageUrl}
      onLoad={onLoad}
    />
  );
};

export default ImageLayer;
