import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import ButtonLoading from 'app/shared/layout/ButtonLoading/ButtonLoading';
import SelectPropertiesOptions from '../select-properties-options/select-properties-options';
import { useOptions } from 'app/shared/hooks/useOptions';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';
import { updateArrangementData } from 'app/modules/administration/arrangement/arrangement.reducer';
import { AvForm } from 'availity-reactstrap-validation';

interface IArrangementSectionSummary {
  isShare: boolean;
  isEdit: boolean;
  isConfiguration: boolean;
  isNew: boolean;
  vatRate: any;
}

const ArrangementSummaryRight: FC<IArrangementSectionSummary> = ({
  isShare,
  isEdit,
  isConfiguration,
  isNew,
}: IArrangementSectionSummary) => {
  const dispatch = useAppDispatch();
  const updating = useAppSelector(state => state.arrangement.updating);
  const arrangementName = useAppSelector(state => state.arrangement?.entity?.arrangement?.arrangementName);
  const sectionSeasons = useAppSelector(state => state.arrangement.entity.sectionSeasons);
  const { projectSeasonsOptions } = useOptions(TRANSLATED_DICTS_KEYS.PROJECT_SEASONS);
  const projectSeasonId = useAppSelector(state => state.arrangement.entity.arrangement?.projectSeasonId);
  const contactWithExpert = event => {
    event.preventDefault();
    window.open('/contact', '_blank');
  };

  const handleSelected = (key, value) => {
    dispatch(updateArrangementData({ [key]: projectSeasonId === value?.projectSeasonId ? null : value?.projectSeasonId }));
  };

  return (
    <AvForm>
      <div className={'mt-1'}>
        <span className={'arrangement-name'}>{arrangementName}</span>
      </div>
      <div className={'mt-4'}>
        {!isShare && (
          <ButtonLoading
            type="button"
            color="first-type"
            updating={updating}
            disabled={updating}
            onClick={contactWithExpert}
            text="proEcoApp.arrangement.contactWithExpert"
            classNames={'btn-default-size w-100 mb-2'}
            id="contactWithExpert"
          />
        )}
      </div>
      {!isShare && (
        <SelectPropertiesOptions
          hasImage={false}
          onlyLabel={true}
          options={sectionSeasons?.map(sectionSeason => ({
            ...sectionSeason,
            isSelected: sectionSeason?.projectSeasonId === projectSeasonId,
            path: `projectSeasonId`,
            label: projectSeasonsOptions?.find(projectSeasonsOption => projectSeasonsOption.id === sectionSeason?.projectSeasonId)
              ?.translateLabel,
          }))}
          onSelected={handleSelected}
          disabled={!isEdit && !isConfiguration && !isNew}
          className="mt-3"
        />
      )}
    </AvForm>
  );
};

export default ArrangementSummaryRight;
