import React, { memo, useMemo } from 'react';
import SubComponentPropertyItem from 'app/modules/administration/home-object-v2/sub-component-property-item';
import { defaultWrapper } from 'app/shared/util/entity-utils';

type ISubComponentProperty = {
  componentTypeId: number;
  subComponentPropertyTypeOptions: any[];
  subComponentProperties: any[];
  path: string;
  isDetail: boolean;
};

export const SubComponentProperty = memo<ISubComponentProperty>(
  ({ componentTypeId, subComponentPropertyTypeOptions, subComponentProperties = [], path, isDetail = false }) => {
    const subComponentPropertyTypes = useMemo(
      () => subComponentProperties?.map(subComponentProperty => subComponentProperty?.subComponentPropertyTypeId),
      [subComponentProperties]
    );

    const options = useMemo(
      () =>
        subComponentPropertyTypeOptions?.filter(
          subComponentPropertyTypeOption => !subComponentPropertyTypes?.includes(subComponentPropertyTypeOption?.id)
        ),
      [subComponentPropertyTypeOptions, subComponentPropertyTypes]
    );

    return (
      <>
        {defaultWrapper(subComponentProperties)?.map((subComponentProperty, subComponentPropertyIndex) => {
          return (
            <SubComponentPropertyItem
              key={`sub-${path}[${subComponentPropertyIndex}]`}
              componentTypeId={componentTypeId}
              subComponentPropertyTypeOptions={subComponentPropertyTypeOptions}
              subComponentProperty={subComponentProperty}
              subComponentPropertyIndex={subComponentPropertyIndex}
              showDelete={!isDetail && subComponentProperties?.length > 1}
              path={`${path}.subComponentProperties`}
              options={options}
              isDetail={isDetail}
            />
          );
        })}
      </>
    );
  }
);

export default SubComponentProperty;
