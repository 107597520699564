export interface ICategory {
  id?: number;
  name?: string;
  description?: string | null;
  enumKey?: string;
  materialType?: number;
  propertyPrice?: {
    netPurchasingPrice: number;
    priceTypeId: null;
  };
  isActive?: boolean;
}

export const defaultValue: Readonly<ICategory> = {
  isActive: false,
};
