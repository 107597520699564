import { useAppSelector } from 'app/config/store';
import { useMemo } from 'react';
import { getDictOptions } from 'app/shared/util/select-utils';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';

export const useEquipmentTypes = (isDetail = true) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const equipmentTypesDict = useAppSelector(state => state.equipmentType.entities);

  return useMemo(
    () => getDictOptions(equipmentTypesDict, TRANSLATED_DICTS.EQUIPMENT_TYPES, null, isDetail),
    [equipmentTypesDict, currentLocale, isDetail]
  );
};
