import React, { FC } from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import { Button, Modal, ModalBody } from 'reactstrap';
import ButtonLoading from 'app/shared/layout/ButtonLoading/ButtonLoading';
import { updateBuildingType } from 'app/modules/administration/arrangement/arrangement.reducer';
import { useAppDispatch } from 'app/config/store';

interface IModalSwitchBuildingType {
  handleClose: () => void;
  isOpen: boolean;
  buildingTypeId?: number;
}

export const ModalSwitchBuildingType: FC<IModalSwitchBuildingType> = ({
  isOpen,
  buildingTypeId,
  handleClose,
}: IModalSwitchBuildingType) => {
  const dispatch = useAppDispatch();
  const handleChangeBuilding = () => {
    dispatch(updateBuildingType({ buildingTypeId }));
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} style={{ maxWidth: '800px', width: '100%' }} centered>
      <div className="modal-header">
        <Translate contentKey={`configuration.switchBuildingType.modal.title`} />
      </div>
      <ModalBody className="p-0">
        <div className={'pt-1 pb-3'}>
          <Translate contentKey={`configuration.switchBuildingType.modal.subtitle`} />
        </div>
        <div className={'pt-1 pb-3 font-weight-bolder'}>
          <Translate contentKey={`configuration.switchBuildingType.modal.question`} />
        </div>
        <div className={'d-flex'}>
          <Button type="button" color="only-border" onClick={handleClose} className={'btn-default-size mt-3 mr-3'}>
            <Translate contentKey={`entity.action.cancel`} />
          </Button>
          <ButtonLoading
            type={'button'}
            color="first-type"
            disabled={false}
            updating={false}
            onClick={handleChangeBuilding}
            text={'configuration.switchBuildingType.modal.switch'}
            classNames="btn-default-size mt-3"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};
