import React from 'react';
import ComponentProperty from 'app/modules/administration/home-object-v2/component-property';
import { useAppSelector } from 'app/config/store';
import { getDictOptions } from 'app/shared/util/select-utils';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';

export const InteriorComponent = ({ componentProperties, componentTypeId, componentIndex, isDetail, path }) => {
  const componentTypeList = useAppSelector(state => state.componentType.entities);
  const componentTypesOptions = getDictOptions(componentTypeList, TRANSLATED_DICTS.COMPONENT_TYPES, null, isDetail);
  const componentTypeObj = componentTypesOptions.find(componentTypesOption => componentTypesOption.id === componentTypeId);
  return (
    <>
      <ComponentProperty
        {...{
          componentProperties,
          componentIndex,
          componentTypeLabel: componentTypeObj?.label,
          componentTypeId: componentTypeObj?.id,
          path,
          isDetail,
          isInterior: true,
          showDelete: !isDetail,
        }}
      />
    </>
  );
};

export default InteriorComponent;
