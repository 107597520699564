import React, {useCallback, useRef, useState, MouseEvent, MutableRefObject} from 'react';
import { cloneDeep, compact, get, remove, set, unset } from 'lodash';

interface ICustomForm {
  search?: (params) => void;
  initFields?: any;
}

export interface ICustomFormResult {
  search?: (params) => void;
  formRef?: MutableRefObject<any>;
  onClear?: any;
  onChangeSelect?: (objSelected: any, meta: any) => void;
  onChangeSelectById?: (objSelected: any, meta: any) => void;
  onChangeDate?: (type: string) => (date: Date) => void;
  onChangeInput?: (ev: MouseEvent<HTMLDivElement>) => void;
  setFieldsState?: (params) => void;
  fieldsState?: any;
}

const useCustomForm = ({ search, initFields }: ICustomForm): ICustomFormResult => {
  const [fieldsState, setFieldsState] = useState<any>(initFields);
  const formRef = useRef(null);

  const onChangeSelect = useCallback((objSelected, meta) => {
    setFieldsState(prev => {
      const newState = cloneDeep(prev);
      set(newState, `${meta.name}`, objSelected);
      return newState;
    });
  }, [setFieldsState]);

 const onChangeSelectById = useCallback((objSelected, meta) => {
    setFieldsState(prev => {
      const newState = cloneDeep(prev);
      set(newState, `${meta.name}`, objSelected?.id);
      return newState;
    });
  }, [setFieldsState]);

  const onChangeDate = useCallback(
    type => date => {
      setFieldsState(prev => ({ ...prev, [type]: date }));
    },
    [setFieldsState]
  );

  const onChangeInput = useCallback(ev => {
      setFieldsState(prev => ({ ...prev, [ev.target.name]: ev.target.value }))
  }
    , [setFieldsState]);

  const onClear = useCallback(() => {
    setFieldsState(initFields);
    formRef?.current?.reset();
    search && search({});
  }, [search, formRef, setFieldsState]);

  return {
    fieldsState,
    setFieldsState,
    onClear,
    formRef,
    onChangeDate,
    onChangeSelect,
    onChangeSelectById,
    onChangeInput,
  };
};

export const useCustomFormArr = ({ search, initFields }: ICustomForm) => {
  const [fieldsState, setFieldsState] = useState<any>(initFields);
  const formRef = useRef(null);

  const onChangeInput = ev =>
    setFieldsState(prev => {
      const newState = cloneDeep(prev);
      set(newState, `${ev.target.name}`, ev.target.value);
      return newState;
    });

  const onChangeSelect = (objSelected, meta) => {
    setFieldsState(prev => {
      const newState = cloneDeep(prev);
      set(newState, `${meta.name}`, objSelected);
      return newState;
    });
  };

  const onAddRow = (key, defaultValue = {}) => {
    setFieldsState(prev => {
      const newState = cloneDeep(prev);
      if (key) {
        const length = get(prev, key)?.length ?? 0;
        set(newState, `${key}[${length}]`, defaultValue);
        return newState;
      }

      const length = prev?.length;
      set(newState, `[${length}]`, defaultValue);
      return newState;
    });
  };

  const onRemoveRow = (key, index) => {
    setFieldsState(prev => {
      const newState = cloneDeep(prev);
      if (key) {
        unset(newState, `${key}.[${index}]`);
        set(newState, key, compact(get(newState, key)));
        return newState;
      }
      remove(newState, function (item, i) {
        return i === index;
      });
      return newState;
    });
  };

  const onClear = () => {
    setFieldsState(initFields);
    formRef?.current?.reset();
    search && search({});
  };

  return {
    fieldsState,
    setFieldsState,
    onClear,
    formRef,
    onChangeInput,
    onChangeSelect,
    onAddRow,
    onRemoveRow,
  };
};

export default useCustomForm;
