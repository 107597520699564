export interface IComponentPropertyDescription {
  id?: number;
  descriptionPl?: string;
  descriptionEn?: string;
  isActive?: boolean;
}

export const defaultValue: Readonly<IComponentPropertyDescription> = {
  isActive: false,
};
