import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import ShellCoreForm from 'app/modules/administration/home-object-v2/Components/home-object-sections/shell-core-form';

export const ShellCoreStep = ({ isDetail = false }) => {

  return (
    <Row className="justify-content-center">
      <Col xs={12}>
        <Card>
            <ShellCoreForm isDetail={isDetail} />
        </Card>
      </Col>
    </Row>
  );
};
