import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import PageWithTableWrapper from 'app/shared/layout/PageWrapper/PageWithTableWrapper';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import CollapseWithOverflowText from 'app/shared/layout/Collapse/CollapseWithOverflowText';
import { renderDeactivateButton, renderEditButton } from 'app/shared/layout/DynamicColumnTable/Components/Buttons';
import Translate from 'app/shared/layout/Translation/translate';
import { useHistory } from 'react-router';
import { deleteEntity, getEntities } from './component-property-name.reducer';
import { getDictEnumsIfNeeded } from 'app/entities/translate-dict-item/translate-dict-item.reducer';
import { IComponentPropertyName } from 'app/shared/model/component-property-name.model';
import { ConfirmDeactivateDialog } from 'app/shared/layout/Dialog/Dialogs';
import ComponentPropertyNameFilter from "app/entities/component-property-name/component-property-name-filter";

const TABLE_COLUMN_VISIBILITY_STORAGE_KEY = 'componentPropertyName/TABLE_COLUMN_VISIBILITY_STORAGE_KEY';

const permanentColumns = ['namePl', 'nameEn'];
const tableColumns = [];

export const ComponentPropertyNameList = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const componentPropertyNameList = useAppSelector(state => state.componentPropertyName.entities);
  const loading = useAppSelector(state => state.componentPropertyName.loading);
  const totalItems = useAppSelector(state => state.componentPropertyName.totalItems);
  const updateSuccess = useAppSelector(state => state.componentPropertyName.updateSuccess);
  const [searchCriteria, setSearchCriteria] = useState({});
  const [deletingEntityData, setDeletingEntityData] = useState(null);

  useEffect(() => {
    dispatch(getDictEnumsIfNeeded(TRANSLATED_DICTS.SYSTEM_SERVICES));
  }, []);

  const getComponentPropertyNameList = params => {
    dispatch(getEntities({ ...params }));
  };

  const renderTableCell = (data: any, fieldId: string) => {
    const value = data[fieldId];
    switch (fieldId) {
      case 'isActive':
        return (
          <td key={fieldId}>
            <Translate contentKey={`status.${value?.toString()}`} />
          </td>
        );
      default:
        return (
          <CollapseWithOverflowText key={fieldId} id={fieldId} text={value}>
            {value}
          </CollapseWithOverflowText>
        );
    }
  };

  const openDeleteEntityPopup = entity => event => {
    event.stopPropagation();
    setDeletingEntityData(entity);
  };
  const onCloseDeleteEntityPopup = () => {
    setDeletingEntityData(null);
  };

  const renderActionCell = (entity: IComponentPropertyName) => {
    const editPath = `/admin/dict/component-property-name/${entity.id}/edit`;
    const editId = `dictionaries-table-edit-dictionary-${entity.id}`;
    const deleteId = `dictionaries-table-delete-dictionary-${entity.id}`;
    return (
      <div key="entity-actions" className="d-flex">
        {renderEditButton(editPath, editId)}
        {renderDeactivateButton(openDeleteEntityPopup(entity), deleteId)}
      </div>
    );
  };

  const composeOnClickTableRow = entity => () => {
    history.push(`/admin/dict/component-property-name/${entity.id}`);
  };

  const removeEntity = () => {
    dispatch(deleteEntity(deletingEntityData.id));
    onCloseDeleteEntityPopup();
  };

  const search = params => {
    setSearchCriteria(params);
  };

  return (
    <>
      <PageWithTableWrapper
        composeOnClickTableRow={composeOnClickTableRow}
        titleContentKey="proEcoApp.componentPropertyName.home.title"
        subTitleContentKey="proEcoApp.componentPropertyName.home.subtitle"
        totalItems={totalItems}
        renderActionCell={renderActionCell}
        persistenceKey={TABLE_COLUMN_VISIBILITY_STORAGE_KEY}
        items={[...componentPropertyNameList]}
        permanentColumns={permanentColumns}
        tableColumns={tableColumns}
        renderTableCell={renderTableCell}
        loading={loading}
        i18nGroupName="proEcoApp.componentPropertyName"
        defaultSort={'createdAt'}
        defaultOrder={'desc'}
        buttonCreateNew={{
          isLink: true,
          link: '/admin/dict/component-property-name/new',
          translateCreateNew: 'proEcoApp.componentPropertyName.home.createLabel',
        }}
        updateSuccess={updateSuccess}
        getEntities={getComponentPropertyNameList}
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        canAdvancedSearch
        childrenSearchForm={<ComponentPropertyNameFilter search={search} />}
        pathArray={[
          { path: 'admin/dashboard', translation: 'global.menu.admin.mainPage' },
          { path: 'admin/dict', translation: 'global.menu.admin.dictionaries' },
          {
            path: 'admin/dict/component-property-name',
            translation: 'global.menu.entities.componentPropertyName',
          },
        ]}
      />
      <ConfirmDeactivateDialog
        translateDeleteQuestion={'proEcoApp.componentPropertyName.delete.question'}
        toggleClose={onCloseDeleteEntityPopup}
        onValidSubmit={removeEntity}
        isOpen={!!deletingEntityData}
        isRemove
      />
    </>
  );
};

export default ComponentPropertyNameList;
