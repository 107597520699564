import {useLocation} from "react-router-dom";
import React, {useContext, useEffect} from 'react';
import "./CartNavigate.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {ConfigurationLayoutContext} from "app/app";
import {Tab, TabList, Tabs, TabPanel} from 'react-tabs';
import Translate from 'app/shared/layout/Translation/translate';
import {TranslatorContext} from "react-jhipster";

export const DELIVERY_STEP = 'delivery-data';
export const DELIVERY_AND_PAYMENT_STEP = 'delivery-and-payment';
export const SUMMARY_STEP = 'summary';
export const FINISH_STEP = 'finish';

export const CART_PATH_LIST = {
  [DELIVERY_STEP]: {
    value: '/cart/delivery-data',
    key: 0
  },
  [DELIVERY_AND_PAYMENT_STEP]: {
    value: '/cart/delivery-and-payment',
    key: 1
  },
  [SUMMARY_STEP]: {
    value: '/cart/summary',
    key: 2
  },
  [FINISH_STEP]: {
    value: '/cart/finish',
    key: 3
  },
};

export const CartNavigate = () => {
  const {tabs, currentTab, setCurrentTab, setTabs, setChangeCurrentTab} = useContext(ConfigurationLayoutContext);
  const {pathname} = useLocation();
  const isCartProcess = pathname === '/cart/delivery-data' || pathname === '/cart/delivery-and-payment' || pathname === '/cart/summary' || pathname === '/cart/finish';

  useEffect(() => {
    setTabs([
      {key: 'delivery-data', label: <Translate contentKey={`proEcoApp.cart.myDetails`}/>},
      {key: 'delivery-and-payment', label: <Translate contentKey={`proEcoApp.cart.deliveryPayment`}/>},
      {key: 'summary', label: <Translate contentKey={`proEcoApp.cart.summaryText`}/>},
    ])
  }, [TranslatorContext.context.locale]);

  useEffect(() => {
    if (isCartProcess) {
      const cartStep = Object.keys(CART_PATH_LIST).find(cartPathKey => CART_PATH_LIST[cartPathKey]?.value === pathname);
      setCurrentTab(cartStep);
      setChangeCurrentTab(undefined);
    }
  }, [pathname]);

  return (
    <Tabs selectedIndex={CART_PATH_LIST?.[currentTab]?.key || 0} className={'arrangement-section-header'}
          onSelect={(value) => {
            if (value < CART_PATH_LIST[currentTab]?.key + 2) {
              setChangeCurrentTab(Object.keys(CART_PATH_LIST).find(key => CART_PATH_LIST[key]?.key === value));
            }
          }}>
      <div className="tab-list-wrapper">
        <TabList>
          {tabs.map((e, i) => (
            <Tab key={e.key}
                 className={`react-tabs__tab ${CART_PATH_LIST[currentTab]?.key >= CART_PATH_LIST[e.key]?.key ? 'stepper' : 'stepper-next'} `}>
              <div>
                {i > 0 && <FontAwesomeIcon className={'stepper-icon'} icon={faArrowRight}/>} {e?.label}
              </div>
            </Tab>
          ))}
        </TabList>

      </div>
      {tabs.map((e, i) => (
        <TabPanel key={i}></TabPanel>
      ))}
    </Tabs>
  )
}
