import React, { useContext, useEffect, useRef } from 'react';
import { useAppSelector } from 'app/config/store';
import { AvForm } from 'availity-reactstrap-validation';

import { ButtonsWrapper } from 'app/modules/administration/arrangement/Components/arrangement-section/buttons-wrapper';
import { ConfigurationLayoutContext } from 'app/app';
import Translate from 'app/shared/layout/Translation/translate';
import { NavbarLayoutContext } from 'app/shared/layout/DefaultLayout/default-layout';
import ObligatoryInfo from 'app/shared/layout/ObligatoryInfo/ObligatoryInfo';
import ShallAndCoreItem from 'app/modules/administration/arrangement/Components/arrangement-section/shall-and-core-item';
import { useOptions } from 'app/shared/hooks/useOptions';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';
import { SwitchBuildingType } from 'app/shared/layout/CustomInput/SwitchBuildingType/SwitchBuildingType';

const iconImageUrl = 'content/images/logo-icon.svg';

const ShallAndCore = ({ isNew, isEdit, isConfiguration, isShare, offerId }) => {
  const { setCurrentTab, setErrorMessage, errorMessage, currentTab, changeCurrentTab, setChangeCurrentTab } =
    useContext(ConfigurationLayoutContext);
  const shellAndCores = useAppSelector(state => state.arrangement.entity.shellAndCores);
  const arrangementName = useAppSelector(state => state.arrangement?.entity?.arrangement?.arrangementName);
  const formRef = useRef(null);
  const arrangementHeaderRef = useRef<HTMLDivElement>(null);
  const { navbarTop } = useContext(NavbarLayoutContext);
  const arrangementHeaderBounce = arrangementHeaderRef.current?.getBoundingClientRect();
  const { subsectionTypesOptions } = useOptions(TRANSLATED_DICTS_KEYS.SUBSECTION_TYPES, true);

  useEffect(() => {
    if (changeCurrentTab !== undefined && changeCurrentTab !== currentTab) {
      formRef.current.click();
    }
  }, [changeCurrentTab]);

  const handleInvalidSubmit = () => {
    setChangeCurrentTab(undefined);
    setErrorMessage('proEcoApp.arrangement.validateError');
  };

  const handleSubmit = event => {
    event.preventDefault();
    setErrorMessage('');
    if (changeCurrentTab !== undefined) {
      setCurrentTab(changeCurrentTab);
    } else {
      setCurrentTab(prev => prev + 1);
    }
    setChangeCurrentTab(undefined);
  };

  return (
    <AvForm onValidSubmit={handleSubmit} className="position-relative" onInvalidSubmit={handleInvalidSubmit}>
      <input type="submit" style={{ display: 'none' }} ref={formRef} />
      <div>
        <div
          className={'arrangement-header'}
          style={{
            position: 'sticky',
            top: navbarTop - 1 + 'px',
            left: arrangementHeaderBounce?.x + 'px',
          }}
          ref={arrangementHeaderRef}
        >
          <img src={iconImageUrl} alt="icon" />
          {arrangementName}
        </div>
        <SwitchBuildingType />
        {shellAndCores?.map((shellAndCore, shellAndCoreIndex) => (
          <ShallAndCoreItem
            key={shellAndCoreIndex}
            shallAndCoreIndex={shellAndCoreIndex}
            isConfiguration={isConfiguration}
            isEdit={isEdit}
            isNew={isNew}
            subsectionType={subsectionTypesOptions?.find(subsectionTypesOption => subsectionTypesOption?.id === shellAndCore?.subsectionId)}
            components={shellAndCore?.components}
          />
        ))}
      </div>
      <ObligatoryInfo />
      <div>{errorMessage ? <Translate className={'invalid-feedback'} contentKey={errorMessage} /> : null}</div>
      <ButtonsWrapper isShare={isShare} isNew={isNew} isEdit={isEdit} isConfiguration={isConfiguration} offerId={offerId} />
    </AvForm>
  );
};

export default ShallAndCore;
