import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { defaultValue, IOrder } from 'app/shared/model/order.model';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { get } from 'lodash';

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IOrder>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  shippingCost: 0,
};

const apiUrl = 'api/orders';

export const getOrders = createAsyncThunk('orders/fetch_orders', async (params: any) => {
  return axios.get<IOrder[]>(apiUrl, { params });
});

export const getShippingCost = createAsyncThunk('orders/get_shipping_cost', async (params: any) => {
  return axios.post<IOrder[]>(`${apiUrl}/shipping-cost`, params);
});

export const setShippingCost = createAsyncThunk('orders/set_shipping_cost', (price: number) => {
  return price;
});

export const resetShippingCost = createAsyncThunk('orders/reset_shipping_cost', async () => {});

export const getOrder = createAsyncThunk(
  'orders/fetch_order_detail',
  async (id: number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IOrder>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const placeOrder = createAsyncThunk(
  'order/place_order',
  async (order: IOrder) => {
    const requestUrl = `${apiUrl}/place`;
    return axios.post<IOrder>(requestUrl, cleanEntity(order));
  },
  { serializeError: serializeAxiosError }
);

export const createOrder = createAsyncThunk(
  'orders/create_order',
  async (order: IOrder) => {
    return await axios.post<IOrder>(apiUrl, order);
  },
  { serializeError: serializeAxiosError }
);

export const updateOrder = createAsyncThunk(
  'orders/update_order',
  async (order: IOrder) => {
    const requestUrl = `${apiUrl}/${order.id}`;
    return axios.put<IOrder>(requestUrl, order);
  },
  { serializeError: serializeAxiosError }
);

export const maskPaid = createAsyncThunk(
  'orders/mask_in_paid',
  async (order: IOrder) => {
    const requestUrl = `${apiUrl}/${order.id}/mark-as-paid`;
    return axios.put<IOrder>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const cancelOrder = createAsyncThunk(
  'orders/cancel_order',
  async (order: IOrder) => {
    const requestUrl = `${apiUrl}/${order.id}/cancel`;
    return axios.put<IOrder>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const acceptOrder = createAsyncThunk(
  'orders/accept_order',
  async (order: IOrder) => {
    const requestUrl = `${apiUrl}/${order.id}/accept`;
    const result = await axios.put<IOrder>(requestUrl);
    if (result.status === 200) {
      result.headers['x-proecoapp-alert'] = 'proEcoApp.order.acceptSuccess';
    }
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const completeOrder = createAsyncThunk(
  'orders/complete_order',
  async (order: IOrder) => {
    const requestUrl = `${apiUrl}/${order.id}/finalize`;
    return axios.put<IOrder>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const deleteOrder = createAsyncThunk(
  'orders/delete_order',
  async (id: number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<IOrder>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const deactivateOrder = createAsyncThunk(
  'orders/deactivate_entity',
  async (order: IOrder, thunkAPI) => {
    await axios.put(`${apiUrl}/${order?.id}/deactivate`, order);
    thunkAPI.dispatch(getOrders({}));
    return;
  },
  { serializeError: serializeAxiosError }
);

export const restoreOrder = createAsyncThunk(
  'orders/restore_entity',
  async (order: IOrder, thunkAPI) => {
    const response = await axios.put(`${apiUrl}/${order?.id}/restore`, order);
    thunkAPI.dispatch(getOrders({}));
    return response;
  },
  { serializeError: serializeAxiosError }
);

export type orderManagementState = Readonly<typeof initialState>;

export const OrderManagementSlice = createSlice({
  name: 'order',
  initialState: initialState as orderManagementState,
  reducers: {
    reset() {
      return initialState;
    },
    updateOrderEntity(state, action) {
      return {
        ...state,
        entity: {
          ...state?.entity,
          ...action.payload,
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteOrder.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = defaultValue;
      })
      .addMatcher(isFulfilled(getOrders), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(restoreOrder, deactivateOrder), state => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(createOrder, updateOrder, cancelOrder, completeOrder, acceptOrder, maskPaid), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(placeOrder), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getShippingCost), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.shippingCost = Number(action.payload.data || 0).toFixed(2);
      })
      .addMatcher(isFulfilled(setShippingCost), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.shippingCost = action.payload;
      })
      .addMatcher(isFulfilled(resetShippingCost), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.shippingCost = 0;
      })
      .addMatcher(isPending(getOrders, getOrder), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createOrder,
          updateOrder,
          deleteOrder,
          restoreOrder,
          deactivateOrder,
          placeOrder,
          cancelOrder,
          completeOrder,
          acceptOrder,
          maskPaid
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      )
      .addMatcher(
        isRejected(
          getOrders,
          getOrder,
          createOrder,
          updateOrder,
          deleteOrder,
          restoreOrder,
          deactivateOrder,
          cancelOrder,
          completeOrder,
          acceptOrder,
          maskPaid,
          placeOrder
        ),
        (state, action) => {
          state.loading = false;
          state.updating = false;
          state.updateSuccess = false;
          state.errorMessage = get(action, 'error.response.data.message');
        }
      );
  },
});

export const { reset, updateOrderEntity } = OrderManagementSlice.actions;

// Reducer
export default OrderManagementSlice.reducer;
