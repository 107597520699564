import React, { useState } from 'react';
import DictionariesView from 'app/shared/layout/DictionariesView/DictionariesView';
import { useAppSelector } from 'app/config/store';
import { useRole } from 'app/shared/hooks/useRole';
import { useLocation } from 'react-router-dom';
import { DICT_CONFIGURATION_LIST, DICT_CONFIGURATION_OTHER_LIST, DICT_LIST, IDictListItem } from 'app/modules/dictionaries/constants';
import { isArray } from 'lodash';
import { useEntitiesInformation } from 'app/shared/layout/DictionariesView/hooks/useEntitiesInformation';

const permanentColumns = ['enumKey'];
const tableColumns = ['name', 'itemTranslation', 'translateDescription'];

interface IDictionaryItemList {
  getEntities?: any;
  reset?: () => void;
  deleteEntity: any;
  restoreEntity?: any;
  additionalTableColumns?: string[];
  dictList?: IDictListItem[];
  searchPathText?: string;
  pathArray?: any[];
  canAction?: boolean;
  canEdit?: boolean;
  isCategoryDetail?: boolean;
}

export const DictionaryItemList: React.FC<IDictionaryItemList> = ({
  getEntities,
  reset,
  pathArray,
  deleteEntity,
  additionalTableColumns = [],
  restoreEntity,
  canAction,
  canEdit,
  isCategoryDetail,
  dictList = [...DICT_LIST, ...DICT_CONFIGURATION_LIST, ...DICT_CONFIGURATION_OTHER_LIST],
  searchPathText = '/admin/dict/',
}: IDictionaryItemList) => {
  const { isAdministrator } = useRole();
  const { pathname } = useLocation();
  const [searchCriteria, setSearchCriteria] = useState({});
  const searchPath = pathname.replace(searchPathText, '');
  const dictListItem = dictList.find(elem => elem.path === searchPath);
  const { loading, entitiesList, totalItems, updateSuccess } = useEntitiesInformation(
    dictList,
    searchPathText,
    isCategoryDetail,
    dictListItem.categoryDetailName
  );
  const i18nGroupName = `proEcoApp.${dictListItem.translate}`;
  const translateTitle = `${i18nGroupName}.home.title`;
  const translateCreateNew = `${i18nGroupName}.home.createLabel`;
  const deletingQuestionTranslate = `${i18nGroupName}.delete.question`;
  const TABLE_COLUMN_VISIBILITY_STORAGE_KEY = `${dictListItem.translate}/TABLE_COLUMN_VISIBILITY_STORAGE_KEY`;
  return (
    <DictionariesView
      titleId={`${dictListItem.translate}-heading`}
      translateTitle={translateTitle}
      getEntities={getEntities}
      translateCreateNew={translateCreateNew}
      itemList={[...(isArray(entitiesList) ? entitiesList : [])]}
      i18nGroupName={i18nGroupName}
      defaultVisibleProperties={[...tableColumns, ...additionalTableColumns, 'isActive']}
      itemPropertyNames={[...tableColumns, ...additionalTableColumns, 'isActive']}
      totalItems={totalItems || 0}
      editTranslationPathPrefix={dictListItem.editTranslationPathPrefix}
      categoryDetailName={dictListItem.categoryDetailName}
      dynamicDictPrefix={dictListItem.dict}
      persistenceKey={TABLE_COLUMN_VISIBILITY_STORAGE_KEY}
      permanentColumns={permanentColumns}
      loadingItems={loading}
      deletingQuestionTranslate={deletingQuestionTranslate}
      paramNameDeletingQuestion="enumKey"
      deleteEntityFunction={deleteEntity}
      restoreEntityFunction={restoreEntity}
      resetEntity={reset}
      updateSuccess={updateSuccess}
      canCreateNew={canAction !== undefined ? canAction : isAdministrator}
      canEdit={canEdit !== undefined ? canEdit : isAdministrator}
      canBeDeleted={canAction !== undefined ? canAction : isAdministrator}
      pathArray={pathArray}
      searchCriteria={searchCriteria}
      setSearchCriteria={setSearchCriteria}
      isCategoryDetail={isCategoryDetail}
    />
  );
};
