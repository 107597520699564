import React, {memo, useMemo} from 'react';

import ComponentItem from 'app/modules/administration/home-object-v2/component-item';
import {useAppSelector} from 'app/config/store';
import {COMPONENT_IN_INTERIOR} from 'app/config/constants';

type IComponents = {
  isDetail?: boolean;
  isInterior?: boolean;
}
const Components = memo<IComponents>(({isDetail = false}) => {
    const components = useAppSelector(state => state.project.entity.components);
    const componentBases = useMemo(
      () => components?.filter(component => !COMPONENT_IN_INTERIOR?.includes(component.componentTypeId)),
      [components]
    );
    return (
      <>
        {componentBases?.map((component, componentIndex) => {
          return (
            <ComponentItem
              key={`components[${componentIndex}]`}
              component={component}
              componentIndex={componentIndex}
              path={'components'}
              isDetail={isDetail}
            />
          );
        })}
      </>
    );
  })
;

export default Components;
