import React, {FC, useCallback, useContext, useMemo} from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import SelectPropertiesOptions
  from 'app/modules/administration/arrangement/Components/select-properties-options/select-properties-options';

import {useAppDispatch} from 'app/config/store';
import {ConfigurationLayoutContext} from 'app/app';
import {updateSelectSingleOption} from 'app/modules/administration/arrangement/arrangement.reducer';
import {useWallColorTypeOptions} from 'app/modules/administration/home-object-v2/hooks/useWallColorTypeOptions';
import {IsSubmitForShareContext} from 'app/modules/administration/arrangement/arrangement-update';
import {
  useOptionDescriptions,
  useOptionNames
} from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';

interface IArrangementWallRoom {
  wallRoom: any;
  wallRoomIndex: number;
  isEdit: boolean;
  isNew: boolean;
  pathRoom: string;
  isConfiguration: boolean;
  isRequiredSection?: boolean;
}

export const ArrangementWallRoom: FC<IArrangementWallRoom> = ({
                                                                wallRoom,
                                                                wallRoomIndex,
                                                                isEdit,
                                                                isNew,
                                                                isConfiguration,
                                                                isRequiredSection,
                                                                pathRoom,
                                                              }) => {
  const dispatch = useAppDispatch();
  const {setIsSubmitForShare} = useContext(IsSubmitForShareContext);
  const wallColorTypesOptions = useWallColorTypeOptions();
  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const wallRoomColors = useMemo(() => {
    return wallRoom?.wallRoomColors?.map((wallRoomColor, wallRoomColorIndex) => {
      return {
        ...wallRoomColor,
        path: `${pathRoom}.wallRoomColors[${wallRoomColorIndex}]`,
        label:
          nameOptions?.find(nameOption => nameOption?.id === wallRoomColor?.cpNameId)?.label ??
          wallColorTypesOptions?.find(option => option?.id === wallRoomColor?.wallColorTypeId)?.label,
        children: (
          <>
            <div className="properties-header-description">
              {descriptionOptions?.find(descriptionOption => descriptionOption?.id === wallRoomColor?.cpDescriptionId)?.label}
            </div>
          </>
        ),
      };
    })
  }, [wallRoom?.wallRoomColors, pathRoom, nameOptions, wallColorTypesOptions, descriptionOptions]);

  const {setErrorMessage} = useContext(ConfigurationLayoutContext);

  const handleSelected = path => (name, value) => {
    dispatch(updateSelectSingleOption({name, value, path}));
    setErrorMessage('');
    setIsSubmitForShare(false);
  };

  const renderHeader = useCallback(() =>
    wallRoomColors?.length > 1 ? (
      <div className={`subsection-header open`} id={`wall-room-${wallRoomIndex}`}>
        <Translate contentKey="proEcoApp.project.wall"/> {wallRoomIndex + 1}
      </div>
    ) : null
  , [wallRoomColors?.length, wallRoomIndex]);

  return (
    <div>
      {renderHeader()}
      <div className={'component-properties'} id={`option-room-${wallRoom?.id}`}>
        <SelectPropertiesOptions
          options={wallRoomColors}
          onSelected={handleSelected(`${pathRoom}.wallRoomColors`)}
          disabled={!isNew && !isEdit && !isConfiguration}
          isRequiredSection={isRequiredSection}
        />
      </div>
    </div>
  );
};
