import React, { FC, useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';

export interface ICollapseComponentProps {
  header: any;
  extraButton?: React.ReactNode;
  btnClassName?: string;
  id: string;
  defaultOpen?: boolean;
  alwaysOpen?: boolean;
  children?: React.ReactNode;
}

const ItemCollapse: FC<ICollapseComponentProps> = ({
  extraButton,
  header,
  btnClassName,
  id,
  defaultOpen,
  alwaysOpen = false,
  children,
}: ICollapseComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultOpen) setIsOpen(true);
  }, [defaultOpen]);

  const handleSetIsOpen = () => {
    if (alwaysOpen) {
      return;
    }
    setIsOpen(prev => !prev);
  };
  return (
    <>
      <div
        id={id}
        className={`${btnClassName || 'btn-collapse'}`}
        onClick={handleSetIsOpen}
        style={{
          padding: 0,
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {header?.(isOpen)}
      </div>
      {extraButton}
      <Collapse isOpen={isOpen} className={'w-100'}>
        {children}
      </Collapse>
    </>
  );
};

export default ItemCollapse;
