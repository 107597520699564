import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';

import getStore from './config/store';
import { registerLocale } from './config/translation';
import setupAxiosInterceptors from './config/axios-interceptor';
import { clearAuthentication } from './shared/reducers/authentication';
import ErrorBoundary from './shared/error/error-boundary';
import AppComponent from './app';
import { loadIcons } from './config/icon-loader';
import { HelmetProvider } from 'react-helmet-async';

const store = getStore();
registerLocale(store);

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

loadIcons();

const rootEl = document.getElementById('root');
const helmetContext = {};
const render = Component =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <HelmetProvider context={helmetContext}>
      <ErrorBoundary>
        <Provider store={store}>
          <div>
            <Component/>
          </div>
        </Provider>
      </ErrorBoundary>
    </HelmetProvider>,
    rootEl
  );

render(AppComponent);
