import React, { useContext, useEffect } from 'react';
import './configuration-step-header.scss';
import { ConfigurationLayoutContext } from 'app/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
import { useArrangementParam } from 'app/modules/administration/arrangement/arrangement-update';
import ArrangementPrice from 'app/shared/layout/TopNavMenu/Components/ConfigurationStep/ArrangementPrice';
import Translate from 'app/shared/layout/Translation/translate';
import { useAppSelector } from 'app/config/store';

const ConfigurationStep = ({className= ''}) => {
  const { vatRate } = useArrangementParam();
  const { setTabs, tabs, currentTab, setCurrentTab, setChangeCurrentTab } = useContext(ConfigurationLayoutContext);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const roomNumber = useAppSelector(state => state.project.entity.roomNumber);

  useEffect(() => {
    setCurrentTab(0);
  }, []);

  useEffect(() => {
    const ARRANGEMENT_SECTIONS = [
      {
        key: `shellAndCore`,
        label: <Translate contentKey="proEcoApp.arrangement.shellAndCore" />,
      },
      ...(roomNumber === 0
        ? []
        : [
            {
              key: `furnishings`,
              label: <Translate contentKey="proEcoApp.arrangement.furnishings" />,
            },
          ]),
      {
        key: 'section-last',
        label: <Translate contentKey="proEcoApp.arrangement.summary" />,
      },
    ];
    setTabs(ARRANGEMENT_SECTIONS);
  }, [currentLocale]);

  return (
    <Tabs
      selectedIndex={currentTab}
      className={`arrangement-section-header ${className}`}
      onSelect={value => {
        setChangeCurrentTab(value);
      }}
    >
      <div className="tab-list-wrapper">
        <TabList>
          {tabs.map((e, i) => (
            <Tab key={i} className={`react-tabs__tab ${currentTab >= i ? 'stepper' : 'stepper-next'} `}>
              <div>
                {i > 0 && <FontAwesomeIcon className={'stepper-icon'} icon={faArrowRight} />} {e?.label}
              </div>
            </Tab>
          ))}
        </TabList>
        <ArrangementPrice vatRate={vatRate} />
      </div>
      {tabs.map((e, i) => (
        <TabPanel key={i}></TabPanel>
      ))}
    </Tabs>
  );
};
export default ConfigurationStep;
