import React, { useContext } from 'react';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import InteriorComponent from 'app/modules/administration/home-object-v2/interior-component';
import { SUB_COMPONENT_PROPERTY_TYPES } from 'app/config/constants';

const LightingInteriorComponent = ({ lightingCPs, isDetail = false, path }) => {
  return (
    <>
      <InteriorComponent
        componentIndex={0}
        path={path}
        componentProperties={lightingCPs}
        componentTypeId={SUB_COMPONENT_PROPERTY_TYPES.STEP2_LIGHTING}
        isDetail={isDetail}
      />
    </>
  );
};

export default LightingInteriorComponent;
