import { useEffect, useMemo, useState } from 'react';
import { useLayers } from 'app/modules/administration/arrangement/hooks/useLayers';

export const useLoadImage = (arrangement, isInterior) => {
  const layerOptions = useLayers(arrangement, isInterior);
  const [loading, setLoading] = useState([]);

  const layerLoadedOptions = useMemo(
    () =>
      layerOptions
        ?.filter(layerOption => layerOption?.imageUrl)
        ?.sort((a, b) => {
          return b.imageOrder - a.imageOrder;
        }),
    [layerOptions, isInterior]
  );
  const imageNumber = useMemo(() => layerLoadedOptions?.length, [layerLoadedOptions?.length]);

  useEffect(() => {
    setLoading(new Array(imageNumber)?.fill(true));
  }, [imageNumber]);
  const onLoadImage = index => {
    const newImagesLoading = [...loading];
    newImagesLoading[index] = false;
    setLoading(newImagesLoading);
  };

  return { onLoadImage, loaded: loading?.every(item => !item), layerLoadedOptions, imageNumber };
};
