import { useAppSelector } from 'app/config/store';
import { useMemo } from 'react';
import { getDictOptions } from 'app/shared/util/select-utils';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import { CATEGORY_TYPE, SUB_COMPONENT_PROPERTY_TYPES } from 'app/config/constants';

export const useSubcomponentPropertyTypes = (componentPropertyTypeId?: number, isDetail = true) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const floorTypesDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.FLOOR_TYPE]);
  const roofTypesDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.ROOF_TYPE]);
  const internalWallTypesDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.INTERNAL_WALL_TYPE]);
  const facadeTypesDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.FACADE_TYPE]);
  const exteriorDoorTypesDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.EXTERIOR_DOOR_TYPES]);
  const windowTypesDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.WINDOW_TYPES]);
  const electricianTypesDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.ELECTRICIAN_TYPES]);
  const hydraulicsTypesDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.HYDRAULICS_TYPES]);
  const ventilationTypeDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.VENTILATION_TYPES]);
  const plumbingHeatTypeDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.PLUMBING_HEAT_TYPES]);
  const airConditioningTypeDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.AIR_CONDITIONING_TYPES]);
  const electricianAddonTypeDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.ELECTRICIAN_ADDON_TYPES]);
  const electricianIntelligentHomeTypeDict = useAppSelector(
    state => state.categoryDetail.categories[CATEGORY_TYPE.ELECTRICIAN_TYPE_INTELLIGENT_HOME_TYPES]
  );
  const step2InteriorFloorTypeDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.STEP2_INTERIOR_FLOOR_TYPES]);

  const step2InteriorLightingTypeDict = useAppSelector(
    state => state.categoryDetail.categories[CATEGORY_TYPE.STEP2_INTERIOR_LIGHTING_TYPES]
  );

  const electricianAddonTypeOptions = useMemo(
    () => getDictOptions(electricianAddonTypeDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [electricianAddonTypeDict, isDetail, currentLocale]
  );

  const electricianIntelligentHomeTypeOptions = useMemo(
    () => getDictOptions(electricianIntelligentHomeTypeDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [electricianIntelligentHomeTypeDict, isDetail, currentLocale]
  );

  const ventilationTypeOptions = useMemo(
    () => getDictOptions(ventilationTypeDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [ventilationTypeDict, isDetail, currentLocale]
  );

  const airConditioningTypeOptions = useMemo(
    () => getDictOptions(airConditioningTypeDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [airConditioningTypeDict, isDetail, currentLocale]
  );

  const plumbingHeatTypeOptions = useMemo(
    () => getDictOptions(plumbingHeatTypeDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [plumbingHeatTypeDict, isDetail, currentLocale]
  );

  const floorTypeOptions = useMemo(
    () => getDictOptions(floorTypesDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [floorTypesDict, isDetail, currentLocale]
  );

  const internalWallTypeOptions = useMemo(
    () => getDictOptions(internalWallTypesDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [internalWallTypesDict, isDetail, currentLocale]
  );

  const facadeTypeOptions = useMemo(
    () => getDictOptions(facadeTypesDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [facadeTypesDict, isDetail, currentLocale]
  );

  const exteriorDoorTypeOptions = useMemo(
    () => getDictOptions(exteriorDoorTypesDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [exteriorDoorTypesDict, isDetail, currentLocale]
  );

  const windowTypeOptions = useMemo(
    () => getDictOptions(windowTypesDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [windowTypesDict, isDetail, currentLocale]
  );

  const electricianTypeOptions = useMemo(
    () => getDictOptions(electricianTypesDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [electricianTypesDict, isDetail, currentLocale]
  );

  const hydraulicsTypeOptions = useMemo(
    () => getDictOptions(hydraulicsTypesDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [hydraulicsTypesDict, isDetail, currentLocale]
  );

  const interiorFloorTypeOptions = useMemo(
    () => getDictOptions(step2InteriorFloorTypeDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [step2InteriorFloorTypeDict, isDetail, currentLocale]
  );
  const interiorLightingTypeOptions = useMemo(
    () => getDictOptions(step2InteriorLightingTypeDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [step2InteriorLightingTypeDict, isDetail, currentLocale]
  );

  const roofTypeOptions = useMemo(
    () => getDictOptions(roofTypesDict, TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES, null, isDetail),
    [roofTypesDict, isDetail, currentLocale]
  );

  const subComponentPropertyTypeOptions = useMemo(() => {
    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.FLOOR_TYPE) {
      return floorTypeOptions;
    }

    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.INTERNAL_WALL_TYPE) {
      return internalWallTypeOptions;
    }

    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.FACADE_TYPE) {
      return facadeTypeOptions;
    }

    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.ROOF_TYPE) {
      return roofTypeOptions;
    }

    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.EXTERIOR_DOOR_TYPES) {
      return exteriorDoorTypeOptions;
    }

    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.WINDOW_TYPES) {
      return windowTypeOptions;
    }
    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_TYPES) {
      return electricianTypeOptions;
    }
    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.HYDRAULICS_TYPES) {
      return hydraulicsTypeOptions;
    }

    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.STEP2_INTERIOR_FLOOR) {
      return interiorFloorTypeOptions;
    }
    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.STEP2_LIGHTING) {
      return interiorLightingTypeOptions;
    }

    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.VENTILATION_TYPES) {
      return ventilationTypeOptions;
    }

    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.PLUMBING_HEAT_TYPE) {
      return plumbingHeatTypeOptions;
    }
    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_ADDONS) {
      return electricianAddonTypeOptions;
    }
    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_INTELLIGENT_HOME) {
      return electricianIntelligentHomeTypeOptions;
    }
    if (componentPropertyTypeId === SUB_COMPONENT_PROPERTY_TYPES.AIR_CONDITIONING_TYPES) {
      return airConditioningTypeOptions;
    }
  }, [
    componentPropertyTypeId,
    floorTypesDict,
    internalWallTypesDict,
    facadeTypesDict,
    roofTypesDict,
    exteriorDoorTypesDict,
    windowTypesDict,
    electricianTypesDict,
    hydraulicsTypesDict,
    airConditioningTypeDict,
    ventilationTypeDict,
    plumbingHeatTypeDict,
    electricianAddonTypeDict,
    electricianIntelligentHomeTypeDict,
    interiorFloorTypeOptions,
    interiorLightingTypeOptions,
    currentLocale,
  ]);

  return {
    floorTypeOptions,
    internalWallTypeOptions,
    facadeTypeOptions,
    exteriorDoorTypeOptions,
    windowTypeOptions,
    electricianTypeOptions,
    hydraulicsTypeOptions,
    roofTypeOptions,
    subComponentPropertyTypeOptions,
    electricianAddonTypeOptions,
    electricianIntelligentHomeTypeOptions,
    airConditioningTypeOptions,
    plumbingHeatTypeOptions,
    ventilationTypeOptions,
  };
};
