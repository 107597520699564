import React, { FC } from 'react';
import ItemCollapse from 'app/modules/administration/arrangement/Components/ItemCollapse';
import Translate from 'app/shared/layout/Translation/translate';
import { ArrangementWallGroupedRoom } from 'app/modules/administration/arrangement/Components/arrangement-section/arrangement-wall-grouped-material';

interface IFurnishingWallRoomColor {
  wallRoomComponents: any;
  counter: number;
  isEdit: boolean;
  isNew: boolean;
  isConfiguration: boolean;
}

export const FurnishingWallRoomColor: FC<IFurnishingWallRoomColor> = ({ wallRoomComponents, isNew, isEdit, counter, isConfiguration }) => {
  if (wallRoomComponents?.length < 1) {
    return <></>;
  }
  return (
    <>
      <ItemCollapse
        header={isOpen => (
          <div className={`subsection-header w-100 ${isOpen ? 'open' : ''}`}>
            <span>{counter}.</span> <Translate contentKey="proEcoApp.arrangement.walls" />
          </div>
        )}
        id={`subsection`}
        defaultOpen={true}
      >
        <ArrangementWallGroupedRoom
          wallRoomComponents={wallRoomComponents}
          isConfiguration={isConfiguration}
          isEdit={isEdit}
          isNew={isNew}
        />
      </ItemCollapse>
    </>
  );
};
