import React, { memo, useContext } from 'react';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import Translate from 'app/shared/layout/Translation/translate';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import { useOptions } from 'app/shared/hooks/useOptions';
import DeleteRowWrapper from 'app/modules/administration/home-object-v2/delete-row-wrapper';
import { Row } from 'reactstrap';
import { useConstructionTypes } from 'app/modules/administration/home-object-v2/hooks/useConstructionTypes';
import { removeOptionHomeObject, updateHomeObject } from 'app/entities/project/project.reducer';
import { useAppDispatch } from 'app/config/store';
import { onChangePositiveInput } from 'app/shared/util/validation';
import { debounce } from 'lodash';
import { getNumber } from 'app/shared/util/entity-utils';

export const ComponentPropertyConstructionItem = ({
  componentTypeId,
  componentPropertyConstruction,
  componentPropertyConstructionIndex,
  path,
  showDelete = false,
  isDetail = false,
}) => {
  const dispatch = useAppDispatch();
  const { onChangeSelect, onChangeInputNumber, onRemoveRow, calcTotalPrice } = useContext(HomeObjectContext);
  const { priceTypesOptions } = useOptions(TRANSLATED_DICTS_KEYS.PRICE_TYPES, true);
  const constructionTypesOptions = useConstructionTypes(componentTypeId, isDetail);

  const constructionType = constructionTypesOptions?.find(
    constructionTypesOption => constructionTypesOption.id === componentPropertyConstruction?.componentPropertyConstructionTypeId
  );

  const updateTotalPrice = debounce(ev => {
    const totalPrice = calcTotalPrice(getNumber(ev.target.value, 0), getNumber(constructionType?.propertyPrice?.netPurchasingPrice, 0));
    dispatch(updateHomeObject({ name: `${path}[${componentPropertyConstructionIndex}].totalPrice`, value: totalPrice }));
  }, 300);

  const handleChangeInput = ev => {
    onChangeInputNumber(ev);
    updateTotalPrice(ev);
  };

  const handleChangeType = (objSelected, meta) => {
    if (meta.action === 'clear' && objSelected === null) {
      dispatch(
        removeOptionHomeObject({
          keys: [meta?.name],
        })
      );
    } else {
      onChangeSelect()(objSelected, meta);
    }
    const totalPrice = calcTotalPrice(
      getNumber(componentPropertyConstruction?.quantity, 0),
      getNumber(objSelected?.propertyPrice?.netPurchasingPrice, 0)
    );
    dispatch(updateHomeObject({ name: `${path}[${componentPropertyConstructionIndex}].totalPrice`, value: totalPrice }));
  };

  const fieldComponent = [
    {
      readOnly: isDetail,
      name: `${path}[${componentPropertyConstructionIndex}].componentPropertyConstructionTypeId`,
      id: `${path}[${componentPropertyConstructionIndex}].componentPropertyConstructionTypeId`,
      type: 'select',
      onChange: handleChangeType,
      value: constructionType,
      options: constructionTypesOptions,
      isClearable: true,
      label: (
        <>
          <Translate contentKey="proEcoApp.project.subComponentPropertyTypeId" />
        </>
      ),
      col: { lg: '2', md: '2' },
    },

    {
      readOnly: true,
      name: `${path}[${componentPropertyConstructionIndex}].priceTypeId`,
      id: `${path}[${componentPropertyConstructionIndex}].priceTypeId`,
      type: 'select',
      value: priceTypesOptions.find(priceTypesOption => priceTypesOption.id === constructionType?.propertyPrice?.priceTypeId),
      options: priceTypesOptions,
      isClearable: true,
      label: <Translate contentKey="proEcoApp.project.priceTypeId" />,
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      type: 'number',
      name: `${path}[${componentPropertyConstructionIndex}].quantity`,
      id: `${path}[${componentPropertyConstructionIndex}].quantity`,
      onChange: handleChangeInput,
      onWheel: event => event.currentTarget.blur(),
      onKeyPress: onChangePositiveInput,
      min: 0,
      value: componentPropertyConstruction.quantity,
      label: <Translate contentKey="proEcoApp.project.quantity" />,
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: true,
      name: `${path}[${componentPropertyConstructionIndex}].netPurchasingPrice`,
      id: `${path}[${componentPropertyConstructionIndex}].netPurchasingPrice`,
      value: constructionType?.propertyPrice?.netPurchasingPrice,
      label: <Translate contentKey="proEcoApp.project.pricePerUnit" />,
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN" />
        </span>
      ),
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: true,
      name: `${path}[${componentPropertyConstructionIndex}].price`,
      id: `${path}[${componentPropertyConstructionIndex}].price`,
      value: componentPropertyConstruction.totalPrice,
      label: <Translate contentKey="proEcoApp.project.totalNetPrice" />,
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN" />
        </span>
      ),
      col: { lg: '2', md: '2' },
    },
  ];

  return (
    <div key={componentPropertyConstruction?.id ?? componentPropertyConstruction?.key}>
      <DeleteRowWrapper showDelete={showDelete} handleDelete={() => onRemoveRow(path, componentPropertyConstructionIndex)}>
        <Row>
          <PartForm fields={fieldComponent} />
        </Row>
      </DeleteRowWrapper>
    </div>
  );
};

export default memo(ComponentPropertyConstructionItem);
