import { ISubsection } from 'app/shared/model/subsection.model';
import { IComponentType } from 'app/shared/model/component-type.model';
import { IEquipment } from 'app/shared/model/equipment.model';
import { IComponentProperty } from 'app/shared/model/component-property.model';

export interface IComponents {
  id?: number;
  componentTypeId?: number;
  componentIndex?: number;
  materialGroups?: any[];
}

export interface IMaterialGroup {
  id?: number;
  materialTypeId?: number;
  componentProperties: IComponentProperty[];
}

export const defaultValue: Readonly<IComponents> = {};
