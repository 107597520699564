import React, {FC} from 'react';
import {convertCurrency, roundPrice} from 'app/shared/util/entity-utils';
import {
  IPropertiesOption
} from 'app/modules/administration/arrangement/Components/select-properties-options/select-properties-options';
import './select-properties-option.scss';
import {isNumber} from "lodash";

interface ISelectPropertiesOption {
  option: IPropertiesOption;
  onSelected?: (name, option, path?) => void;
  value?: any;
  path?: string;
  disabled?: boolean;
  onlyLabel?: boolean;
  name?: string;
  vatRate?: any;
}

export const PROJECT_SEASON_ID = 'projectSeasonId';

export const SelectPropertiesOption: FC<ISelectPropertiesOption> = ({
                                                                      option,
                                                                      path,
                                                                      disabled,
                                                                      onSelected,
                                                                      onlyLabel,
                                                                      vatRate,
                                                                    }: ISelectPropertiesOption) => {
  const activeClassName = option?.isSelected ? 'active' : '';
  const disabledClassName = disabled ? 'disabled' : '';
  const imageClassName = '';

  const onClick = () => {
    if (!disabled) {
      if (path === PROJECT_SEASON_ID) {
        onSelected?.(path, option);
      } else {
        onSelected?.(`${path}.isSelected`, !option?.isSelected, path);
      }
    }
  };

  return (
    <div
      className={`select-properties-option ${activeClassName} ${disabledClassName} ${imageClassName} ${option?.uploadedImageUrl ? 'has-image' : ''}`}
      onClick={onClick}>
      {!!option?.uploadedImageUrl && (
        <div className="properties-image">
          <img src={`${option?.uploadedImageUrl}`} alt={option?.uploadedImageUrl} width={88} height={88}/>
        </div>
      )}
      <div className="properties-header">
        <div className="title-wrapper">{option?.label}</div>
        {option?.children}
        {!onlyLabel && option.description && (
          <div className="properties-header-description">
            <span>{option.description}</span>
          </div>
        )}
        <div
          className="properties-price">{convertCurrency(isNumber(option?.totalPrice) ? option?.totalPrice : option?.price, vatRate)}</div>
      </div>
    </div>
  );
};
