import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';

import { EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, ICategory } from 'app/shared/model/category.model';
import { groupBy } from 'lodash';
import { cleanEntity, stringifySerializer } from 'app/shared/util/entity-utils';

export interface ICategoryDetailEntity extends EntityState<ICategory> {
  categories: { [key: string]: ICategory[] };
}

const initialState: ICategoryDetailEntity = {
  loading: false,
  errorMessage: null,
  entities: [],
  categories: {},
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/categories/detail';

// Actions

export const getEntities = createAsyncThunk('categoryDetail/fetch_entity_list', async (params: { category: string[] }) => {
  return axios.get<ICategory[]>(apiUrl, {
    params,
    paramsSerializer: stringifySerializer,
  });
});

export const createEntity = createAsyncThunk(
  'categoryDetail/create_entity',
  async (entity: { entity: ICategory; category: string }, thunkAPI) => {
    return await axios.post<ICategory>(`${apiUrl}?category=${entity.category}`, cleanEntity(entity.entity));
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'categoryDetail/fetch_entity',
  async (item: { id: string | number; category: string }) => {
    const requestUrl = `${apiUrl}/${item.id}?category=${item.category}`;
    return axios.get<ICategory>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const deactivateEntity = createAsyncThunk(
  'categoryDetail/deactivate_entity',
  async (item: { id: string | number; category: string }, thunkAPI) => {
    const requestUrl = `${apiUrl}/${item.id}/deactivate?category=${item.category}`;
    return await axios.put<ICategory>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const restoreEntity = createAsyncThunk(
  'categoryDetail/restore_entity',
  (item: { id: string | number; category: string }, thunkAPI) => {
    return axios.put(`${apiUrl}/${item.id}/restore?category=${item.category}`);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'categoryDetail/update_entity',
  async (entity: { entity: ICategory; category: string }, thunkAPI) => {
    return await axios.put<ICategory>(`${apiUrl}/${entity.entity.id}?category=${entity.category}`, cleanEntity(entity.entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'categoryDetail/delete_entity',
  async (item: { id: string | number; category: string }, thunkAPI) => {
    const requestUrl = `${apiUrl}/${item.id}?category=${item.category}`;
    return await axios.delete<ICategory>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const CategorySlice = createSlice({
  name: 'categoryDetail',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    updateErrorMessage(state, action) {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const entities = groupBy(data, 'category');
        return {
          ...state,
          loading: false,
          categories: entities,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deactivateEntity, restoreEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, deleteEntity, deactivateEntity, restoreEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, updateErrorMessage } = CategorySlice.actions;

// Reducer
export default CategorySlice.reducer;
