export const TRANSLATED_DICTS_KEYS = {
  COUNTRIES: 'COUNTRIES',
  LANGUAGES: 'LANGUAGES',
  CURRENCIES: 'CURRENCIES',
  ROLES: 'ROLES',
  SYSTEM_SERVICES: 'SYSTEM_SERVICES',
  OFFER_PRIORITIES: 'OFFER_PRIORITIES',
  OFFER_TYPES: 'OFFER_TYPES',
  PAGE_TYPES: 'PAGE_TYPES',
  CONTENT_TYPES: 'CONTENT_TYPES',
  PREFERRED_CONTACT_TYPES: 'PREFERRED_CONTACT_TYPES',
  CONTACT_FORM_STATUSES: 'CONTACT_FORM_STATUSES',
  MATERIAL_TYPES: 'MATERIAL_TYPES',
  PROJECT_TYPES: 'PROJECT_TYPES',
  COMPONENT_TYPES: 'COMPONENT_TYPES',
  SECTION_TYPES: 'SECTION_TYPES',
  SUBSECTION_TYPES: 'SUBSECTION_TYPES',
  COMPONENT_PROPERTY_TYPES: 'COMPONENT_PROPERTY_TYPES',
  COMPONENT_PROPERTY_OPTION_TYPES: 'COMPONENT_PROPERTY_OPTION_TYPES',
  EQUIPMENT_TYPES: 'EQUIPMENT_TYPES',
  EQUIPMENT_PROPERTY_TYPES: 'EQUIPMENT_PROPERTY_TYPES',
  EQUIPMENT_PROPERTY_OPTION_TYPES: 'EQUIPMENT_PROPERTY_OPTION_TYPES',
  ARRANGEMENT_TYPES: 'ARRANGEMENT_TYPES',
  VAT_TYPES: 'VAT_TYPES',
  PROJECT_SEASONS: 'PROJECT_SEASONS',
  PRICE_TYPES: 'PRICE_TYPES',
  ORDER_STATUS_TYPES: 'ORDER_STATUS_TYPES',
  OBJECT_CONSTRUCTION_TYPES: 'OBJECT_CONSTRUCTION_TYPES',
  BLOG_TAGS: 'BLOG_TAGS',
  QUESTION_TAGS: 'QUESTION_TAGS',
  DELIVERY_TYPES: 'DELIVERY_TYPES',
  PAYMENT_METHODS: 'PAYMENT_METHODS',
  ADVANCED_PAYMENT_TYPES: 'ADVANCED_PAYMENT_TYPES',
  DISCOUNT_CODE_STATUSES: 'DISCOUNT_CODE_STATUSES',
  DISCOUNT_CODE_TYPES: 'DISCOUNT_CODE_TYPES',
  ROOM_TYPES: 'ROOM_TYPES',
  WALL_COLOR_TYPES: 'WALL_COLOR_TYPES',
  SUB_COMPONENT_PROPERTY_TYPES: 'SUB_COMPONENT_PROPERTY_TYPES',
  CATEGORY_MATERIAL_TYPES: 'CATEGORY_MATERIAL_TYPES',
  CP_ADDITIONAL_MATERIAL_TYPES: 'CP_ADDITIONAL_MATERIAL_TYPES',
  CP_CONSTRUCTION_TYPES: 'CP_CONSTRUCTION_TYPES',
};

export const TRANSLATED_DICTS = {
  COUNTRIES: 'dict_countries',
  LANGUAGES: 'dict_languages',
  CURRENCIES: 'dict_currencies',
  ROLES: 'dict_roles',
  SYSTEM_SERVICES: 'dict_system_services',
  OFFER_PRIORITIES: 'dict_offer_priorities',
  OFFER_TYPES: 'dict_offer_types',
  PAGE_TYPES: 'dict_page_types',
  CONTENT_TYPES: 'dict_content_types',
  PREFERRED_CONTACT_TYPES: 'dict_preferred_contact_types',
  CONTACT_FORM_STATUSES: 'dict_contact_form_statuses',
  MATERIAL_TYPES: 'dict_material_types',
  PROJECT_TYPES: 'dict_project_types',
  COMPONENT_TYPES: 'dict_component_types',
  COMPONENT_PROPERTY_TYPES: 'dict_component_property_types',
  COMPONENT_PROPERTY_OPTION_TYPES: 'dict_component_property_option_types',
  SECTION_TYPES: 'dict_section_types',
  SUBSECTION_TYPES: 'dict_subsection_types',
  EQUIPMENT_TYPES: 'dict_equipment_types',
  EQUIPMENT_PROPERTY_TYPES: 'dict_equipment_property_types',
  EQUIPMENT_PROPERTY_OPTION_TYPES: 'dict_equipment_property_option_types',
  ARRANGEMENT_TYPES: 'dict_arrangement_types',
  PROJECT_SEASONS: 'dict_project_seasons',
  VAT_TYPES: 'dict_vat_types',
  PRICE_TYPES: 'dict_price_types',
  ORDER_STATUS_TYPES: 'dict_order_status_types',
  OBJECT_CONSTRUCTION_TYPES: 'dict_object_construction_types',
  BLOG_TAGS: 'dict_blog_tags',
  QUESTION_TAGS: 'dict_question_tags',
  DELIVERY_TYPES: 'dict_delivery_types',
  PAYMENT_METHODS: 'dict_payment_methods',
  ADVANCED_PAYMENT_TYPES: 'dict_advanced_payment_types',
  DISCOUNT_CODE_STATUSES: 'dict_discount_code_statuses',
  DISCOUNT_CODE_TYPES: 'dict_discount_code_types',
  ROOM_TYPES: 'dict_room_types',
  WALL_COLOR_TYPES: 'dict_wall_color_types',
  WALL_COLOR_ADDITIONAL_MATERIAL_TYPES: 'dict_wc_additional_material_types',
  SUB_COMPONENT_PROPERTY_TYPES: 'dict_sub_component_property_types',
  CATEGORY_MATERIAL_TYPES: 'dict_material_types',
  CP_ADDITIONAL_MATERIAL_TYPES: 'dict_cp_additional_material_types',
  CP_CONSTRUCTION_TYPES: 'dict_cp_construction_types',
  CATEGORIES: 'dict_categories',
};

export const CURRENCY = {
  id: 1,
  currAbbreviation: 'PLN',
  currName: 'POLISH ZŁOTY',
  enumKey: 'PLN',
};
