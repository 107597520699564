export interface IComponentPropertyName {
  id?: number;
  namePl?: string;
  nameEn?: string;
  isActive?: boolean;
}

export const defaultValue: Readonly<IComponentPropertyName> = {
  isActive: false,
};
