import React from 'react';
import {Route, RouteProps} from 'react-router-dom';
import ErrorBoundary from 'app/shared/error/error-boundary';
import DefaultLayoutComponent from "app/shared/layout/DefaultLayout/default-layout";
import {useAppSelector} from "app/config/store";

interface IErrorBoundaryRoute extends RouteProps {
  componentProps?: any;
  defaultLayout?: any;
  isRenderLayout?: any;
}

export const ErrorBoundaryRoute = ({
                                     component: Component,
                                     isRenderLayout = true,
                                     defaultLayout: DefaultLayout = DefaultLayoutComponent,
                                     componentProps,
                                     ...rest
                                   }: IErrorBoundaryRoute) => {

  const renderWithLayout = props => (
    <ErrorBoundary>
      <DefaultLayout>
        <Component {...props} {...componentProps}/>
      </DefaultLayout>
    </ErrorBoundary>
  );

  const renderWithoutLayout = props => (
    <ErrorBoundary>
      <Component {...props} {...componentProps}/>
    </ErrorBoundary>
  );

  if (!Component) throw new Error(`A component needs to be specified for path ${(rest as any).path}`);

  return <Route {...rest} render={isRenderLayout ? renderWithLayout : renderWithoutLayout}/>;

};

export default ErrorBoundaryRoute;
