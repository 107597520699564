import React, { FC, useMemo } from 'react';

import ArrangementEquipments from 'app/modules/administration/arrangement/Components/arrangement-section/arrangement-equipments';
import TabsCustom from 'app/modules/administration/arrangement/Components/TabsCustom';
import { useMaterialOptions } from 'app/modules/administration/home-object-v2/hooks/useMaterialOptions';
import { IEquipmentMaterialGroup } from 'app/shared/model/equipment.model';
import Translate from 'app/shared/layout/Translation/translate';

interface IFurnishingEquipmentItem {
  equipments: IEquipmentMaterialGroup[];
  isEdit: boolean;
  isNew: boolean;
  isSet?: boolean;
  isConfiguration: boolean;
  path: string;
}

export const FurnishingEquipmentItem: FC<IFurnishingEquipmentItem> = ({
  equipments,
  path,
  isNew,
  isSet = false,
  isEdit,
  isConfiguration,
}) => {
  const isDetail = !isEdit && !isNew;
  const materialTypesOptions = useMaterialOptions({ isDetail, hasMaterial: true });
  const pathEquipment = useMemo(() => (isSet ? `${path}.equipmentSets` : `${path}.equipments`), [isSet]);

  const groupedComponentPropertyOptions = equipments?.map((equipment, equipmentIndex) => {
    const materialTypeId = materialTypesOptions?.find(materialTypesOption => materialTypesOption.id === equipment?.materialTypeId);
    return {
      materialTypeId,
      label: materialTypeId?.label,
      isActive: 0,
      component: (
        <ArrangementEquipments
          materialGroups={equipment?.materialGroups}
          equipmentIndex={equipmentIndex}
          isConfiguration={isConfiguration}
          isEdit={isEdit}
          isNew={isNew}
          path={pathEquipment}
          isSet={isSet}
        />
      ),
    };
  });

  return (
    <div>
      <div className={'ml-4'}>
        {equipments?.length >= 1 && <Translate contentKey={`proEcoApp.arrangement.${isSet ? 'equipmentSet' : 'equipments'}`} />}
      </div>
      <div className={'arrangement-grouped-material'}>
        <TabsCustom tabs={groupedComponentPropertyOptions} activeTab={0} />
      </div>
    </div>
  );
};
