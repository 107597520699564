import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import {
  FURNISHING_STEP,
  HomeObjectContext,
  LayerContext,
  StepContext,
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import Translate from 'app/shared/layout/Translation/translate';
import Button from 'reactstrap/es/Button';
import ButtonLoading from 'app/shared/layout/ButtonLoading/ButtonLoading';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { checkValidate, uploadPsd } from 'app/entities/project/project.reducer';
import { FileLabel } from 'app/shared/layout/CustomInput/Components/FileLabel';
import BasicInformation from 'app/modules/administration/home-object-v2/Components/home-object-update/basic-information';
import Components from 'app/modules/administration/home-object-v2/components';
import { AvForm } from 'availity-reactstrap-validation';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import ObligatoryInfo from 'app/shared/layout/ObligatoryInfo/ObligatoryInfo';
import BadgeInPublicInfo from 'app/shared/layout/BadgeInPublic/BadgeInPublicInfo';
import {REQUIRED, scrollToError} from "app/shared/util/validation";
import Seasons from "app/modules/administration/home-object-v2/seasons";

const ShellCoreForm = ({ isDetail = false }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isNew = !id;
  const { onClickCancel, parseFilePsd } = useContext(HomeObjectContext);
  const updating = useAppSelector(state => state.project.updating);
  const sectionSeasons = useAppSelector(state => state.project.entity.sectionSeasons);
  const previewLayerUrl = useAppSelector(state => state.project.entity.previewLayerUrl);
  const validateSuccess = useAppSelector(state => state.project.validateSuccess);
  const [isLoading, setIsLoading] = useState(false);
  const { setLayers, layers } = useContext(LayerContext);
  const { setStep } = useContext(StepContext);

  useEffect(() => {
    if (validateSuccess) {
      setStep(FURNISHING_STEP);
    }
  }, [validateSuccess]);

  const upload = event => {
    if (event.target.files && event.target.files[0]) {
      const resultFile = parseFilePsd(event.target.files[0], setIsLoading, setLayers, false);
      if (resultFile) {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        dispatch(
          uploadPsd({
            formData: data,
          })
        );
      }
    }
  };
  const fields = [
    {
      avGroupClassName: 'file-wrapper flex-column',
      id: `baseLayerPath`,
      name: `baseLayerPath`,
      type: 'image',
      accept: '.psd',
      readOnly: isDetail,
      disabled: isDetail,
      src: previewLayerUrl,
      label: <FileLabel acceptFile=".psd" name={''} isLoading={isLoading} maxResolution={'1920 x 1080px'} />,
      value: !!layers?.length,
      style: {
        width: '100%',
        padding: '5px 0',
      },
      validate: {
        ...REQUIRED(),
      },
      onChange: upload,
      col: { sm: 6 },
    },
  ];

  const handleSubmit = (event, error) => {
    if (error?.length) {
      scrollToError(error);
    } else dispatch(checkValidate());
  };

  return (
    <AvForm onSubmit={handleSubmit}>
      <BasicInformation isDetail={isDetail} isNew={isNew} />

      <div className={'section-block'}>
        <div className={'step-header'}>
          <Translate contentKey="proEcoApp.project.shellAndCore" />
        </div>
        <div className={'step-description'}>
          <Translate contentKey="proEcoApp.project.shellAndCoreDescription" />
        </div>
        <Row>
          <PartForm fields={fields} />
        </Row>

        <Components isDetail={isDetail}/>
        <Seasons seasons={sectionSeasons} path={'sectionSeasons'} isDetail={isDetail}/>

        <ObligatoryInfo />
        <BadgeInPublicInfo />
      </div>
      <div className={'w-100 home-object-button'}>
          <Button type="button" color="only-border" onClick={onClickCancel} className={'btn-default-size'}>
            <Translate contentKey="entity.action.cancel" />
          </Button>
          <ButtonLoading
            updating={updating}
            disabled={updating}
            text="entity.action.next"
            classNames={'btn-default-size'}
            id="arrangement"
          />
          {isDetail ? (
            <ButtonLoading
              updating={updating}
              disabled={updating}
              onClick={e => {
                e.preventDefault();
                history.push(`/admin/home-object/${id}/edit`);
              }}
              text="entity.action.edit"
              classNames={'btn-default-size'}
              id="home-object"
            />
          ) : null}
      </div>
    </AvForm>
  );
};

export default React.memo(ShellCoreForm);
