export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const GOOGLE_MAP_API_KEY = 'AIzaSyCqgfmrulB9SzwvsqrVzBU_c1m2PC69_R8';
export const FACEBOOK_PAGE_ID = '110906321050881';

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_DATEPICKER_DATE_FORMAT = 'dd/MM/yyyy';

export const AUTHORITIES = {
  SYSTEM_PRO_ECO_MODULE: {
    id: 1,
    enumKey: 'SYSTEM_PRO_ECO_MODULE',
  },
  ADMINISTRATOR: {
    id: 2,
    enumKey: 'ADMINISTRATOR',
  },
  SALE: {
    id: 3,
    enumKey: 'SALE',
  },
  PURCHASING: {
    id: 4,
    enumKey: 'PURCHASING',
  },
  DESIGN: {
    id: 5,
    enumKey: 'DESIGN',
  },
  CLIENT: {
    id: 6,
    enumKey: 'CLIENT',
  },
  MARKETING: {
    id: 7,
    enumKey: 'MARKETING',
  },
};

export const ManageAdminDashboard = [
  AUTHORITIES.SYSTEM_PRO_ECO_MODULE.id,
  AUTHORITIES.ADMINISTRATOR.id,
  AUTHORITIES.SALE.id,
  AUTHORITIES.MARKETING.id,
  AUTHORITIES.PURCHASING.id,
  AUTHORITIES.DESIGN.id,
];
export const CONTACT_STATUS = {
  SENT: 1,
  SOLVED: 2,
  REJECTED: 3,
  CLOSED: 4,
};

export const ORDER_STATUS = {
  CANCELED: 1,
  REGISTERED: 2,
  PAID: 3,
  COMPLETED: 4,
  PAID_IN_ADVANCE: 5,
  PENDING: 6,
};

export const DISCOUNT_CODE_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
  IN_USE: 3,
  SENT: 4,
};

export const DISCOUNT_CODE_TYPE = {
  GENERAL_DISCOUNT_CODE: 1,
  INDIVIDUAL_DISCOUNT_CODES: 2,
  FOR_PRINT_DISCOUNT_CODE: 3,
};

export const HOME_PAGE_SECTION = {
  MARKETING: 'MARKETING',
  HOW_IT_WORKS: 'HOW_IT_WORKS',
  CONFIGURATOR: 'CONFIGURATOR',
  PRODUCTS: 'PRODUCTS',
  SERVICES: 'SERVICES',
  MAINPART: 'MAINPART',
  FOOTER: 'FOOTER',
};

export const SERVICES_SECTION = {
  ARCHITECTURAL_DESIGN: 'ARCHITECTURAL_DESIGN',
};

export const HOW_IT_WORKS_SECTION = {
  STEPS: 'STEPS',
};

export const BLOGS_SECTION = {
  SECTION1: 'SECTION1',
};

export const ABOUTUS_SECTION = {
  INFORMATION: 'INFORMATION',
};

export const LANGUAGE_IDS = {
  en: 1,
  pl: 2,
};

export const PAGE_TYPE_KEYS = {
  HOME_PAGE: 'HOME_PAGE',
  SERVICE: 'SERVICE',
  ABOUT_US: 'ABOUT_US',
  BLOG: 'BLOG',
  OTHER_PAGE: 'OTHER_PAGE',
  FAQ: 'FAQ',
  HOW_IT_WORKS: 'HOW_IT_WORKS',
};

export const CONTENT_TYPE = {
  TEXT: {
    label: 'TEXT',
    name: 'textarea',
    id: 1,
  },
  IMAGE: {
    label: 'IMAGE',
    name: 'image',
    id: 2,
  },
};

export const ARRANGEMENT_TYPE = {
  ARCHITECT_ARRANGEMENT: 1,
  END_CLIENT_ARRANGEMENT: 2,
};

export const OFFER_TYPE = {
  INDIVIDUAL: 1,
};

export const PAYMENT_METHODS = {
  REGULAR_BANK_TRANSFER: 1,
  ONLINE_PAYMENT: 2,
};
export const KEYCODER = {
  ENTER: 13,
};

export const BLOG_TYPES = {
  BLOG: 1,
  OTHER_PAGE: 2,
};

export const onlyAdmin = [AUTHORITIES.SYSTEM_PRO_ECO_MODULE.id, AUTHORITIES.ADMINISTRATOR.id];
export const canAccessCms = [...onlyAdmin, AUTHORITIES.MARKETING.id];
export const canAccessSpecificOffer = [...onlyAdmin, AUTHORITIES.PURCHASING.id];
export const canAccessConfigurationAndHomeObject = [...onlyAdmin, AUTHORITIES.DESIGN.id];
export const canAccessClientRequest = [...onlyAdmin, AUTHORITIES.SALE.id];
export const canAccessDiscountCode = [...onlyAdmin, AUTHORITIES.SALE.id, AUTHORITIES.MARKETING.id];

export const MENU = {
  ADMIN: {
    HOME_OBJECT: '/admin/home-object',
    IMAGE_OPTION: '/admin/image-options',
    IMAGE_OPTION_NEW: '/admin/image-options/new',
    IMAGE_OPTION_EDIT: '/admin/image-options/edit',
  },
};

export const CATEGORY_TYPE = {
  FLOOR_TYPE: 'FLOOR_TYPE',
  FLOOR_MATERIAL_TYPES: 'FLOOR_MATERIAL_TYPE',
  FLOOR_CONSTRUCTIONS: 'FLOOR_CONSTRUCTION_TYPE',
  INTERNAL_WALL_TYPE: 'INTERNAL_WALL_TYPE',
  INTERNAL_WALL_ADDITIONAL_MATERIAL_TYPES: 'INTERNAL_WALL_ADDITIONAL_MATERIAL_TYPE',
  INTERNAL_WALL_CONSTRUCTIONS: 'INTERNAL_WALL_CONSTRUCTION_TYPE',
  FACADE_TYPE: 'FACADE_TYPE',
  FACADE_ADDITIONAL_MATERIAL_TYPE: 'FACADE_ADDITIONAL_MATERIAL_TYPE',
  FACADE_CONSTRUCTION_TYPE: 'FACADE_CONSTRUCTION_TYPE',
  FACADE_MATERIAL_TYPE: 'FACADE_MATERIAL_TYPE',
  ROOF_TYPE: 'ROOF_TYPE',
  ROOF_ADDITIONAL_MATERIAL_TYPES: 'ROOF_ADDITIONAL_MATERIAL_TYPE',
  ROOF_CONSTRUCTION_TYPE: 'ROOF_CONSTRUCTION_TYPE',
  EXTERIOR_DOOR_TYPES: 'EXTERIOR_DOOR_TYPE',
  INTERIOR_DOOR_TYPES: 'INTERIOR_DOOR_TYPE',
  WINDOW_TYPES: 'WINDOW_TYPE',
  EXTERIOR_DOOR_MATERIAL_TYPE: ' EXTERIOR_DOOR_MATERIAL_TYPE',
  INTERIOR_DOOR_MATERIAL_TYPE: ' INTERIOR_DOOR_MATERIAL_TYPE',
  WINDOW_MATERIAL_TYPE: 'WINDOW_MATERIAL_TYPE',
  ELECTRICIAN_TYPES: 'ELECTRICIAN_TYPE',
  HYDRAULICS_TYPES: 'HYDRAULICS_TYPE',
  ELECTRICIAN_TYPE_INTELLIGENT_HOME_TYPES: 'ELECTRICIAN_TYPE_INTELLIGENT_HOME_TYPE',
  VENTILATION_TYPES: 'VENTILATION_TYPE',
  AIR_CONDITIONING_TYPES: 'AIR_CONDITIONING_TYPE',
  PLUMBING_HEAT_TYPES: 'PLUMBING_HEAT_TYPE',
  ELECTRICIAN_ADDON_TYPES: 'ELECTRICIAN_ADDON_TYPE',
  STEP2_INTERIOR_FLOOR_TYPES: 'STEP2_INTERIOR_FLOOR_TYPE',
  STEP2_INTERIOR_LIGHTING_TYPES: 'STEP2_INTERIOR_LIGHTING_TYPE',
};
export const SUB_COMPONENT_PROPERTY_TYPES = {
  FLOOR_TYPE: 1,
  INTERNAL_WALL_TYPE: 2,
  FACADE_TYPE: 3,
  ROOF_TYPE: 4,
  INTERIOR_DOOR_TYPES: 5,
  EXTERIOR_DOOR_TYPES: 6,
  WINDOW_TYPES: 7,
  ELECTRICIAN_TYPES: 8,
  HYDRAULICS_TYPES: 9,
  VENTILATION_TYPES: 14,
  AIR_CONDITIONING_TYPES: 16,
  PLUMBING_HEAT_TYPE: 15,
  ELECTRICIAN_INTELLIGENT_HOME: 10,
  ELECTRICIAN_ADDONS: 19,
  STEP2_INTERIOR_FLOOR: 17,
  STEP2_LIGHTING: 18,
};
export const OPTIONAL_COMPONENTS = [
  SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_INTELLIGENT_HOME,
  SUB_COMPONENT_PROPERTY_TYPES.PLUMBING_HEAT_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.VENTILATION_TYPES,
  SUB_COMPONENT_PROPERTY_TYPES.AIR_CONDITIONING_TYPES,
  SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_ADDONS,
];
export const GROUP_TYPE = [
  SUB_COMPONENT_PROPERTY_TYPES.FLOOR_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.FACADE_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.WINDOW_TYPES,
  SUB_COMPONENT_PROPERTY_TYPES.EXTERIOR_DOOR_TYPES,
];
export const HAS_GROUP_COMPONENT = [
  SUB_COMPONENT_PROPERTY_TYPES?.ELECTRICIAN_TYPES,
  SUB_COMPONENT_PROPERTY_TYPES?.ELECTRICIAN_INTELLIGENT_HOME,
  SUB_COMPONENT_PROPERTY_TYPES?.ELECTRICIAN_ADDONS,
];
export const GROUP_COMPONENT_TYPE = {
  ELECTRICIAN: 'ELECTRICIAN',
  DEFAULT: 'DEFAULT',
};

export const HAS_MATERIALS = [
  SUB_COMPONENT_PROPERTY_TYPES.FLOOR_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.INTERNAL_WALL_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.FACADE_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.WINDOW_TYPES,
  SUB_COMPONENT_PROPERTY_TYPES.EXTERIOR_DOOR_TYPES,
  SUB_COMPONENT_PROPERTY_TYPES.STEP2_INTERIOR_FLOOR,
];

export const NONE_BUILDING_TYPE = [
  SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_TYPES,
  SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_INTELLIGENT_HOME,
  SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_ADDONS,
  SUB_COMPONENT_PROPERTY_TYPES.HYDRAULICS_TYPES,
  SUB_COMPONENT_PROPERTY_TYPES.AIR_CONDITIONING_TYPES,
  SUB_COMPONENT_PROPERTY_TYPES.PLUMBING_HEAT_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.VENTILATION_TYPES,
];

export const COMPONENT_IN_INTERIOR = [SUB_COMPONENT_PROPERTY_TYPES?.STEP2_INTERIOR_FLOOR, SUB_COMPONENT_PROPERTY_TYPES.STEP2_LIGHTING];
export const ADDITIONAL_MATERIAL_TYPES = {
  INTERNAL_WALL_ADDITIONAL_MATERIAL_TYPES: 2,
  FACADE_ADDITIONAL_MATERIAL_TYPE: 3,
  ROOF_ADDITIONAL_MATERIAL_TYPE: 4,
};

export const CONSTRUCTION_TYPES = {
  FLOOR_CONSTRUCTION_TYPE: 1,
  INTERNAL_WALL_CONSTRUCTION_TYPE: 2,
  FACADE_CONSTRUCTION_TYPE: 3,
  ROOF_CONSTRUCTION_TYPES: 4,
};

export const LANGUAGE = {
  PL: 'pl',
  EN: 'en',
};
export const CATEGORIES = {
  FLOOR_TYPE: 21,
  INTERNAL_WALL_TYPE: 22,
  FACADE_TYPE: 23,
  ROOF_TYPE: 24,
  EXTERIOR_DOOR_TYPES: 25,
  INTERIOR_DOOR_TYPES: 26,
  WINDOW_TYPES: 27,
  ELECTRICIAN_TYPES: 28,
  ELECTRICIAN_TYPE_INTELLIGENT_HOME_TYPE: 34,
  VENTILATION_TYPE: 38,
  PLUMBING_HEAT_TYPE: 39,
  AIR_CONDITIONING_TYPE: 40,
  ELECTRICIAN_ADDON_TYPE: 52,
  HYDRAULICS_TYPES: 29,
  EQUIPMENT_TYPES: 30,
  SETS_TYPES: 31,
};
export const DESKTOP_QUERY = {
  query: '(min-width: 1024px)',
};

export const SUMMER_SEASON_ID = 2;
