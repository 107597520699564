import React, { FC, useContext, useMemo } from 'react';
import ItemCollapse from 'app/modules/administration/arrangement/Components/ItemCollapse';
import SelectPropertiesOptions from 'app/modules/administration/arrangement/Components/select-properties-options/select-properties-options';
import { useRoomTypeOptions } from 'app/modules/administration/home-object-v2/hooks/useRoomTypeOptions';
import { ConfigurationLayoutContext } from 'app/app';
import { updateSelectSingleOption } from 'app/modules/administration/arrangement/arrangement.reducer';
import { useAppDispatch } from 'app/config/store';
import { IsSubmitForShareContext } from 'app/modules/administration/arrangement/arrangement-update';
import { HeaderCard } from 'app/modules/administration/arrangement/Components/arrangement-section/header-card';
import { useOptionDescriptions, useOptionNames } from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';
import SelectPropertyName from 'app/modules/administration/arrangement/Components/select-properties-options/select-property-name';

interface IFurnishingLightingItem {
  lighting: any;
  isEdit: boolean;
  isNew: boolean;
  isConfiguration: boolean;
  path: string;
}

export const FurnishingLightingItem: FC<IFurnishingLightingItem> = ({ lighting, path, isNew, isEdit, isConfiguration }) => {
  const isDetail = !isNew && !isEdit && !isConfiguration;
  const dispatch = useAppDispatch();
  const { setIsSubmitForShare } = useContext(IsSubmitForShareContext);

  const roomTypeOptions = useRoomTypeOptions(isDetail);
  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const { setErrorMessage } = useContext(ConfigurationLayoutContext);

  const handleSelected = pathName => (name, value) => {
    dispatch(updateSelectSingleOption({ name, value, path: pathName }));
    setErrorMessage('');
    setIsSubmitForShare(false);
  };

  const selected = useMemo(() => {
    const temp = [];
    lighting?.componentProperties?.map(componentProperty => {
      if (componentProperty?.isSelected) {
        temp.push(componentProperty?.id);
      }
    });
    return temp;
  }, [lighting?.componentProperties]);

  const options = useMemo(() => {
    return lighting?.componentProperties?.map((property, propertyIndex) => ({
      ...property,
      label: nameOptions?.find(nameOption => nameOption?.id === property?.cpNameId)?.label,
      path: `${path}.componentProperties[${propertyIndex}]`,
      children: (
        <div className="properties-header-description">
          {descriptionOptions?.find(descriptionOption => descriptionOption?.id === property?.cpDescriptionId)?.label}
        </div>
      ),
    }));
  }, [lighting?.componentProperties, nameOptions, descriptionOptions]);

  return (
    <>
      <ItemCollapse
        header={isOpen => (
          <HeaderCard
            label={roomTypeOptions.find(roomTypeOption => roomTypeOption.id === lighting?.roomTypeId)?.translateLabel}
            isOpen={isOpen}
          />
        )}
        defaultOpen={true}
        id={'lighting'}
      >
        <div className={'component-properties'} id={`option-lighting-${lighting?.id}`}>
          <SelectPropertiesOptions
            options={options}
            onSelected={handleSelected(`${path}.componentProperties`)}
            disabled={!isNew && !isEdit && !isConfiguration}
            isRequiredSection={false}
            value={selected}
          />
        </div>
      </ItemCollapse>
    </>
  );
};
