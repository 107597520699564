import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import '../../home-object.scss';
import PageHeader from 'app/shared/layout/PageWrapper/PageHeader';
import { getEntity as getProject, reset } from 'app/entities/project/project.reducer';

import { useLayerOptions, useTransformSection } from 'app/modules/administration/home-object-v2/hooks/useTransformSection';
import { defaultPagination } from 'app/shared/util/pagination.constants';
import { getEntities as getComponentType } from 'app/entities/component-type/component-type.reducer';
import { getEntities as getRoomType } from 'app/entities/room-type/room-type.reducer';
import { ShellCoreStep } from 'app/modules/administration/home-object-v2/Components/home-object-update/shell-core-step';
import { FurnishingStep } from 'app/modules/administration/home-object-v2/Components/home-object-update/furnishing-step';
import { useHomeObjectLoading } from 'app/modules/administration/home-object-v2/hooks/useHomeObjectLoading';
import { getDictEnumsIfNeeded } from 'app/entities/translate-dict-item/translate-dict-item.reducer';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import { PageLoader } from 'app/shared/layout/Loader/Loader';

import { getEntities as getUploadedImages } from 'app/entities/image-option/image-option.reducer';
import { getEntities as getMaterialTypes } from 'app/entities/material-type/material-type.reducer';
import { useFetchList } from 'app/modules/administration/arrangement/hooks/useFetchList';

const pathArray = [
  { path: 'admin/dashboard', translation: 'global.menu.admin.mainPage' },
  { path: 'admin/home-object', translation: 'global.menu.admin.homeObjectsDirectory' },
  { path: 'admin/home-object/new', translation: 'proEcoApp.project.home.createLabel' },
];

interface ILayerContext {
  layers?: any;
  setLoading?: any;
  loading?: boolean;
  setLayers?: any;
  setLayerInteriors?: any;
  layerInteriors?: any;
}

export const LayerContext = createContext<ILayerContext>({ layers: [] });

interface IHomeObjectContext {
  sections?: any[];
  setSections?: any;
  onChangeSelect?: any;
  onChangeCheckbox?: any;
  onChangeInput?: any;
  onChangeInputNumber?: any;
  onAddRow?: any;
  onRemoveRow?: any;
  button?: any;
  setButton?: any;
  onClickCancel?: any;
  selectPropertyOption?: any;
  parseFilePsd?: any;
  calcTotalPrice?: any;
  handleChangeQuantity?: any;
}

interface IStepContext {
  step?: number;
  setStep?: (step: number) => void;
}

export const HomeObjectContext = createContext<IHomeObjectContext>({ sections: [] });
export const SHELL_AND_CORE_STEP = 0;
export const FURNISHING_STEP = 1;
export const StepContext = createContext<IStepContext>({ step: SHELL_AND_CORE_STEP });

export const HomeObjectUpdate = ({ isDetail = false }) => {
  const { id } = useParams<{ id: string }>();
  const psdImageUrl = useAppSelector(state => state.project.entity.psdImageUrl);
  const interiorPsdImageUrl = useAppSelector(state => state.project.entity.interiorPsdImageUrl);
  const dispatch = useAppDispatch();
  const dataSections = useTransformSection();
  const { layers, setLayers } = useLayerOptions(psdImageUrl);
  const { layers: layerInteriors, setLayers: setLayerInteriors } = useLayerOptions(interiorPsdImageUrl);
  const [step, setStep] = useState(FURNISHING_STEP);
  useFetchList();

  useEffect(() => {
    dispatch(getComponentType(defaultPagination));
    dispatch(getRoomType(defaultPagination));
    dispatch(getUploadedImages(defaultPagination));
    dispatch(getMaterialTypes(defaultPagination));
    dispatch(getDictEnumsIfNeeded(TRANSLATED_DICTS.OBJECT_CONSTRUCTION_TYPES));
    dispatch(getDictEnumsIfNeeded(TRANSLATED_DICTS.PROJECT_TYPES));
    dispatch(getDictEnumsIfNeeded(TRANSLATED_DICTS.PROJECT_SEASONS));
    return () => {
      dispatch(reset());
    };
  }, []);

  const loading = useHomeObjectLoading(id);

  useEffect(() => {
    if (id) {
      dispatch(getProject(id));
    }
  }, [id]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <StepContext.Provider value={{ step, setStep }}>
      <LayerContext.Provider value={{ layers, setLayers, layerInteriors, setLayerInteriors }}>
        <HomeObjectContext.Provider value={dataSections}>
          <div className="app-page-size page-with-table-wrapper">
            <PageHeader
              titleContentKey="proEcoApp.project.home.createLabelTitle"
              subTitleContentKey="proEcoApp.project.home.createLabelDescription"
              pathArray={pathArray}
            />

            {step === SHELL_AND_CORE_STEP && <ShellCoreStep isDetail={isDetail} />}
            {step === FURNISHING_STEP && <FurnishingStep isDetail={isDetail} />}
          </div>
        </HomeObjectContext.Provider>
      </LayerContext.Provider>
    </StepContext.Provider>
  );
};

export default HomeObjectUpdate;
