import React, { FC, useRef, useState } from 'react';
import { RichUtils } from 'draft-js';
import Translate from 'app/shared/layout/Translation/translate';
import { useOnClickOutside } from 'app/shared/hooks/useClickOutSide';

interface FontSizeOptions {
  onChange;
  editorState;
}

export const FONTSIZE_OPTIONS = [
  { label: 'global.form.HEADING_60', value: 'fontsize-heading-60' },
  { label: 'global.form.HEADING_40', value: 'fontsize-heading-40' },
  { label: 'global.form.HEADING_30', value: 'fontsize-heading-30' },
  { label: 'global.form.HEADING_28', value: 'fontsize-heading-28' },
  { label: 'global.form.HEADING_12', value: 'fontsize-heading-12' },
  { label: 'global.form.HEADING_18', value: 'fontsize-heading-18' },
  { label: 'global.form.HEADING_16', value: 'fontsize-heading-16' },
  { label: 'global.form.HEADING_14', value: 'fontsize-heading-14' },
];

export const FontSizeOptions: FC<FontSizeOptions> = ({ onChange, editorState }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [over, setOver] = useState<string | null>(null);
  const ref = useRef(null);

  const [fontValue, setFontValue] = useState<any>(null);
  useOnClickOutside(ref, () => setOpen(false));
  const toggleFontSize = style => {
    setFontValue(style);
    const currentStyle = editorState?.getCurrentInlineStyle()?.toJS();
    let newEditorStat1 = editorState;
    currentStyle?.map(styleInline => {
      if (styleInline?.includes('fontsize-heading') && style?.value?.includes('fontsize-heading')) {
        newEditorStat1 = RichUtils.toggleInlineStyle(newEditorStat1, styleInline);
      }
    });

    newEditorStat1 = RichUtils.toggleInlineStyle(newEditorStat1, style?.value);
    onChange(newEditorStat1);
  };

  const handleOver = itemValue => {
    setOver(itemValue);
  };

  return (
    <div
      ref={ref}
      onMouseDown={() => setOpen(!open)}
      className="rdw-block-wrapper font-select"
      aria-label="rdw-block-control"
      role="button"
      tabIndex={0}
    >
      <div className="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown" style={{ minWidth: '150px' }}>
        <div className="rdw-dropdown-selectedtext">
          {fontValue ? <Translate contentKey={fontValue?.label} /> : <Translate contentKey={'global.form.fontPlaceHolder'} />}{' '}
          <div className={`rdw-dropdown-caretto${open ? 'close' : 'open'}`} />
        </div>
        {open ? (
          <ul className={`rdw-dropdown-optionwrapper ${open ? '' : 'placeholder-ul'}`}>
            {FONTSIZE_OPTIONS.map(item => (
              <li
                onMouseDown={() => toggleFontSize(item)}
                key={item.value}
                onMouseOver={() => handleOver(item.value)}
                className={`rdw-dropdownoption-default ${item.value === over ? 'rdw-dropdownoption-highlighted' : ''}`}
              >
                <Translate contentKey={item?.label} />
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};
