export interface IApproval {
  id?: number;
  title?: string;
  content?: string;
  validFrom?: string;
  validTo?: string | null;
  availableFrom?: string;
  availableTo?: string | null;
  isObligatory?: boolean;
  version?: number | null;
  group?: number | null;
}

export const defaultValue: Readonly<IApproval> = {
  isObligatory: false,
};
