import React, {FC, useMemo} from 'react';
import ComponentCollapse from 'app/modules/administration/arrangement/Components/ComponentCollapse';

import {HeaderCard} from 'app/modules/administration/arrangement/Components/arrangement-section/header-card';
import PropertiesOptions
  from 'app/modules/administration/arrangement/Components/arrangement-section-summary/select-properties-options/properties-options';

import {
  FURNISHING_STEP
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import {useComponentType} from 'app/modules/administration/arrangement/hooks/useComponentType';
import {SUB_COMPONENT_PROPERTY_TYPES} from 'app/config/constants';
import {
  useOptionDescriptions,
  useOptionNames
} from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';

interface ILightingComponentPart {
  component: any;
  componentIndex: number;
}

export const LightingComponentPart: FC<ILightingComponentPart> = ({
                                                                    component,
                                                                    componentIndex
                                                                  }: ILightingComponentPart) => {
  const componentTypesOptions = useComponentType();
  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const componentTypesLabel = componentTypesOptions?.find(
    option => option?.id === SUB_COMPONENT_PROPERTY_TYPES.STEP2_LIGHTING
  )?.translateLabel;
  const options = useMemo(() => {
    return component?.componentProperties
      ?.map((property, propertyIndex) => ({
        ...property,
        label: nameOptions?.find(nameOption => nameOption.id === property?.cpNameId)?.label,
        children: (
          <>
            <div className="properties-header-description">
              {descriptionOptions?.find(descriptionOption => descriptionOption?.id === property?.cpDescriptionId)?.label}
            </div>
          </>
        ),
        path: `interiorSection.roomLightings[${componentIndex}].componentProperties[${propertyIndex}].isSelected`,
      }))
      ?.filter(item => item?.isSelected);
  }, [component, nameOptions, descriptionOptions, componentIndex]);

  if (!component) {
    return <></>;
  }

  return (
    <div className={'ml-4'}>
      <ComponentCollapse
        header={isOpen => (
          <HeaderCard
            label={
              <div>
                {componentTypesLabel} {componentIndex + 1}
              </div>
            }
            isHeader={false}
            isOpen={isOpen}
          />
        )}
        id={`component-lighting-${componentIndex}`}
        defaultOpen={false}
      >
        <div className={'component-properties'}>
          <PropertiesOptions
            currentStep={FURNISHING_STEP}
            options={options}
            disabled={true}
            name={'lighting-component'}
            optionIdLabel={`option-component-${component?.id}`}
          />
        </div>
      </ComponentCollapse>
    </div>
  );
};
