import { useAppSelector } from 'app/config/store';
import React, { useContext } from 'react';
import Translate, { translate } from 'app/shared/layout/Translation/translate';
import {
  MAX_LENGTH,
  MIN,
  REQUIRED
} from 'app/shared/util/validation';
import { Col, Row } from 'reactstrap';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import { useOptions } from 'app/shared/hooks/useOptions';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import BadgeInPublic from 'app/shared/layout/BadgeInPublic/BadgeInPublic';

const BasicInformation = ({ isDetail = false, isNew = false }) => {
  const projectEntity = useAppSelector(state => state.project.entity);
  const { objectConstructionTypesOptions } = useOptions(TRANSLATED_DICTS_KEYS.OBJECT_CONSTRUCTION_TYPES, true);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const { projectTypesOptions } = useOptions(TRANSLATED_DICTS_KEYS.PROJECT_TYPES, true);
  const { onChangeInput, onChangeSelect } = useContext(HomeObjectContext);
  const fields = [
    ...(!isNew
      ? [
          {
            name: 'sourceName',
            id: 'sourceName',
            readOnly: true,
            label: <Translate contentKey="proEcoApp.project.sourceName" />,
            onChange: onChangeInput,
            validate: {
              ...REQUIRED(),
              ...MAX_LENGTH(50),
            },
            value: projectEntity.sourceName ?? translate('global.messages.na', null, null, currentLocale, false),
            col: { lg: '3', md: '3' },
          },
        ]
      : []),
    {
      name: 'privateName',
      id: 'privateName',
      readOnly: isDetail,
      label: <Translate contentKey="proEcoApp.project.privateName" />,
      onChange: onChangeInput,
      validate: {
        ...REQUIRED(),
        ...MAX_LENGTH(50),
      },
      value: projectEntity.privateName,
      col: { lg: '3', md: '3' },
    },
    {
      name: 'name',
      id: 'name',
      readOnly: isDetail,
      label: (
        <BadgeInPublic>
          <Translate contentKey="proEcoApp.project.name" />
        </BadgeInPublic>
      ),
      onChange: onChangeInput,
      validate: {
        ...REQUIRED(),
        ...MAX_LENGTH(50),
      },
      value: projectEntity.name,
      col: { lg: '3', md: '3' },
    },

    {
      name: 'totalSquare',
      id: 'totalSquare',
      readOnly: isDetail,
      label: <Translate contentKey="proEcoApp.project.totalSquare" />,
      onChange: onChangeInput,
      value: projectEntity.totalSquare,
      min: 0,
      validate: { ...REQUIRED(), ...MIN(0) },
      type: 'decimalNumber',
      numberDecimalPlaces: 3,
      onWheel: event => event.currentTarget.blur(),
      col: { lg: '3', md: '3' },
      appendText: (
        <span className={'unit-append'}>
          m<sup>2</sup>
        </span>
      ),
      classCol: 'no-mr',
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: 'projectTypeId',
      id: 'projectTypeId',
      type: 'select',
      onChange: onChangeSelect(''),
      validate: { ...REQUIRED() },
      value: projectTypesOptions.find(projectTypesOption => projectTypesOption?.id === projectEntity.projectTypeId),
      options: projectTypesOptions,
      label: <Translate contentKey="proEcoApp.project.projectType" />,
      col: { lg: '3', md: '3' },
      classCol: 'no-mr',
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: 'objectConstructionTypeId',
      id: 'objectConstructionTypeId',
      type: 'select',
      onChange: onChangeSelect(''),
      validate: { ...REQUIRED() },
      value: objectConstructionTypesOptions.find(
        objectConstructionTypesOption => objectConstructionTypesOption?.id === projectEntity.objectConstructionTypeId
      ),
      options: objectConstructionTypesOptions,
      label: <Translate contentKey="proEcoApp.project.objectConstructionType" />,
      col: { lg: '3', md: '3' },
      classCol: 'no-mr',
    },
    {
      type: 'textarea',
      name: 'description',
      id: 'description',
      rows: 4,
      readOnly: isDetail,
      style: { resize: 'none' },
      label: <Translate contentKey="proEcoApp.project.description" />,
      onChange: onChangeInput,
      value: projectEntity.description,
      col: { lg: '12', md: '12' },
      validate: {
        ...MAX_LENGTH(100),
      },
    },
  ];

  return (
    <Row className="justify-content-center mb-4">
      <Col xs={12} lg={12} xl={12}>
        <div className={'step-header'}>
          <Translate contentKey="proEcoApp.project.basic" />
        </div>
        <div className={'step-description'}>
          <Translate contentKey="proEcoApp.project.basicDescription" />
        </div>
        <Row>
          <PartForm fields={fields} />
        </Row>
      </Col>
    </Row>
  );
};
export default React.memo(BasicInformation);
