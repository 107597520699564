import React, { FC } from 'react';
import { ArrangementComponent } from 'app/modules/administration/arrangement/Components/arrangement-section/arrangement-component';
import ItemCollapse from 'app/modules/administration/arrangement/Components/ItemCollapse';
import Translate from 'app/shared/layout/Translation/translate';

interface IFurnishingFloorComponent {
  floorComponents: any;
  isEdit: boolean;
  isNew: boolean;
  isConfiguration: boolean;
}

export const FurnishingFloorComponent: FC<IFurnishingFloorComponent> = ({ floorComponents, isNew, isEdit, isConfiguration }) => {
  if (!floorComponents) {
    return <></>;
  }
  return (
    <>
      <ItemCollapse
        header={isOpen =>
          floorComponents?.length >= 1 ? (
            <div className={`subsection-header w-100 ${isOpen ? 'open' : ''}`}>
              1. <Translate contentKey="proEcoApp.arrangement.furnishings" />
            </div>
          ) : null
        }
        id={`subsection`}
        defaultOpen={true}
      >
        <ArrangementComponent
          component={floorComponents}
          path={`interiorSection.floorComponent`}
          isNew={isNew}
          isEdit={isEdit}
          componentIndex={0}
          isConfiguration={isConfiguration}
          isRequiredSection={false}
          isInterior={true}
        />
      </ItemCollapse>
    </>
  );
};
