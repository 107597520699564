import {IWallRoomColor} from "app/shared/model/wall-room-color.model";
import {IRoomProject} from "app/shared/model/room-project.model";
import {IRoomType} from "app/shared/model/room-type.model";

export interface IWallRoom {
  id?: number;
  wallSize?: number | null;
  wallCountOrder?: number | null;
  roomTypeId?: IRoomType;
  wallRoomColors?: IWallRoomColor[] | null;
  roomProject?: IRoomProject | null;
}

export const defaultValue: Readonly<IWallRoom> = {};
