/**
 * @type {UserConfig}
 */
const pluginConfig = {
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false
  force_consent: true,
  gui_options: {
    consent_modal: {
      layout: 'cloud',                      // box,cloud,bar
      position: 'middle center',           // bottom,middle,top + left,right,center
      transition: 'zoom'                 // zoom,slide
    },
    settings_modal: {
      layout: 'box',                      // box,bar
      transition: 'zoom'                 // zoom,slide
    }
  },

  onFirstAction(user_preferences, cookie) {
    // callback triggered only once
    // eslint-disable-next-line no-undef
    // const analyticsEnabled = window.CookieConsentApi.allowedCategory('analytics');
  },

  onAccept(cookie) {
    // callback triggered only once
    // eslint-disable-next-line no-undef
    if (window.CookieConsentApi.allowedCategory('analytics')) {
      // window.CookieConsentApi.loadScript('app/shared/layout/CookieConsent/ga-config.js', function () {
      // script loaded ...
      // });
    }
  },

  onChange(cookie, changed_preferences) {
    // ...
  },

  languages: {
    pl: {
      consent_modal: {
        title: 'Ta strona używa plików cookies.',
        description: 'Używamy cookies w celach funkcjonalnych, aby ułatwić użytkownikom korzystanie z witryny oraz w celu tworzenia anonimowych statystyk serwisu. Jeżeli nie blokujesz plików cookies, to zgadzasz się na ich używanie oraz zapisanie w pamięci urządzenia. <button type="button" data-cc="c-settings" id="#btn-settings" class="c-bn c_link" aria-haspopup="dialog">Zarzadzaj</button>',
        primary_btn: {
          text: 'Zaakceptuj cookie',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
          class: 'btn-first-type'
        },
        secondary_btn: {
          text: 'Odrzuć',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
          class: 'btn-first-type'
        }
      },
      settings_modal: {
        title: 'Ustawienia cookie',
        save_settings_btn: 'Zapisz ustawienia',
        accept_all_btn: 'Zaakceptuj',
        reject_all_btn: 'Odrzuć',
        close_btn_label: 'Zamknij',
        cookie_table_headers: [
          {col1: 'Nazwa'},
          {col2: 'Data ważności'},
          {col3: 'Opis'},
        ],
        blocks: [
          {
            title: 'Szczegóły',
            description:
              'Używam plików cookie, aby zapewnić podstawowe funkcje witryny i poprawić komfort korzystania z niej. Możesz wybrać dla każdej kategorii opcję włączenia/wyłączenia w dowolnym momencie. Aby uzyskać więcej informacji na temat plików cookie i innych wrażliwych danych, przeczytaj pełną <a href="/privacy-policy" class="cc-link">politykę prywatności</a>.',
          },
          {
            title: 'Niezbędne',
            description:
              'Te pliki cookie są niezbędne do prawidłowego funkcjonowania strony internetowej. Bez tych plików cookie strona nie działałaby poprawnie',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Preferencje i statystyka',
            description:
              'Ten rodzaj cookies pomaga nam poprawiać efektywność prowadzonych działań marketingowych oraz dostosowywać je do Twoich potrzeb i preferencji np. poprzez zapamiętanie wszelkich wyborów dokonywanych na stronach. Dodatkowo pozwala nam mierzyć ilość wizyt i zbierać informacje o źródłach ruchu, dzięki czemu możemy poprawić działanie naszej strony. ',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: '_ga_*',
                col2: '1 rok 1 miesiąc 4 dni',
                col3: 'Google Analytics ustawia to cookie aby przechowywać liczbę odwiedzin strony.',
                is_regex: true
              },
              {
                col1: '^_ga',
                col2: '1 rok 1 miesiąc 4 dni',
                col3: 'Google Analytics ustawia to cookie dane odwiedzającego, sesji oraz kampanii oraz w celu śledzenia użytkowania witryny, To cookie przechowuje informacje anonimowo i przypisuje losowo generowany numer aby rozróżnić poszczególnych odwiedzających.',
                is_regex: true
              },
            ],
          },
          {
            title: 'Więcej informacji',
            description:
              ' Aby uzyskać więcej informacji na temat plików cookie i innych wrażliwych danych, przeczytaj pełną <a href="/privacy-policy" class="cc-link">politykę prywatności</a> oraz <a href="/cookies-policy" class="cc-link">politykę cookies</a>.',
          },
        ],
      },
    },
    en: {
      consent_modal: {
        title: 'We use cookies!',
        description:
          'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.  <button type="button" data-cc="c-settings" id="#btn-settings" class="c-bn c_link" aria-haspopup="dialog">Settings</button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
          class: 'btn-first-type'
        },
        secondary_btn: {
          text: 'Reject',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
          class: 'btn-first-type'
        }
      },
      settings_modal: {
        title: 'Cookie Settings',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [
          {col1: 'Name'},
          {col2: 'Expiration'},
          {col3: 'Description'},
        ],
        blocks: [
          {
            title: 'Details',
            description:
              'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy-policy" class="cc-link">privacy policy</a>.',
          },
          {
            title: 'Necessary',
            description:
              'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Performance and Analytics cookies',
            description:
              'These cookies allow the website to remember the choices you have made in the past',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: '_ga_*',
                col2: '1 year 1 month 4 days',
                col3: 'Google Analytics sets this cookie to store and count page views.',
              },
              {
                col1: '_ga',
                col2: '1 year 1 month 4 days',
                col3: 'Google Analytics sets this cookie to calculate visitor, session and campaign data and track site usage for the site\'s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognise unique visitors.',
              },
            ],
          },
          {
            title: 'More information',
            description:
              'For more details relative to cookies and other sensitive data, please read the full <a href="/privacy-policy" class="cc-link">privacy policy</a> and <a href="/cookies-policy" class="cc-link">cookies policy</a>.',
          },
        ],
      },
    },
  },
};

export default pluginConfig;
