import './footer.scss';

import React, { useContext } from 'react';
import { useAppSelector } from 'app/config/store';
import { Link } from 'react-router-dom';
import { PreviewContext, selectorValue } from 'app/entities/content-v2/homepage-wrapper';
import { translate } from 'app/shared/layout/Translation/translate';
import { DisplayData } from 'app/shared/hooks/useContentText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { stripTag } from 'app/shared/util/entity-utils';
import TwitterIcon from 'app/shared/layout/Icon/TwitterIcon';

const Footer = () => {
  const { isPreview, locale, onChangeStep } = useContext(PreviewContext);
  const currentStep = useAppSelector(state => state.content.currentStep);

  const homepageData = useAppSelector(state => state.content.homepage);
  const contentData = useAppSelector(state => state.content.entities);

  const text1 = selectorValue(homepageData, contentData, 'homepage.section4.text1', `${translate('footer.company', null, null, locale)}`);
  const text14 = selectorValue(homepageData, contentData, 'homepage.section4.text14', `${translate('footer.address', null, null, locale)}`);

  const renderTextLink = (key, defaultValue, defaultLink) => {
    const text = selectorValue(homepageData, contentData, `homepage.section4.text${key}`, defaultValue);
    const blogId = selectorValue(homepageData, contentData, `homepage.section4.text${key}`, undefined, 'blogId');
    const redirectUrl = selectorValue(homepageData, contentData, `homepage.section4.text${key}`, undefined, 'redirectUrl');
    return [text, blogId ? `/blog/${blogId}` : redirectUrl || defaultLink];
  };

  const text2 = selectorValue(homepageData, contentData, 'homepage.section4.text2', `${translate('footer.documents', null, null, locale)}`);

  const [text3, link3] = renderTextLink(3, translate('footer.cookiesPolicy', null, null, locale), '/cookies-policy');
  const [text4, link4] = renderTextLink(4, translate('footer.termsOfService', null, null, locale), '/content/files/terms-of-service.pdf');
  const [text5, link5] = renderTextLink(5, translate('footer.subsidies', null, null, locale), '/content/files/subsidies.pdf');
  const [text7, link7] = renderTextLink(7, translate('footer.privacyPolicy', null, null, locale), '/privacy-policy');
  const [text8, link8] = renderTextLink(8, translate('footer.designServices', null, null, locale), '/');
  const [text9, link9] = renderTextLink(9, translate('footer.smartHomes', null, null, locale), '/');
  const [text11, link11] = renderTextLink(11, translate('footer.showHouses', null, null, locale), '/');
  const [text12, link12] = renderTextLink(12, translate('footer.cooperation', null, null, locale), '/');
  const [text13, link13] = renderTextLink(13, translate('footer.forMedia', null, null, locale), '/');

  const text6 = selectorValue(
    homepageData,
    contentData,
    'homepage.section4.text6',
    `${translate('footer.importantLinks', null, null, locale)}`
  );
  const text10 = selectorValue(homepageData, contentData, 'homepage.section4.text10', `${translate('footer.contact', null, null, locale)}`);

  return (
    <div className={`footer ${isPreview ? 'preview' : ''}`}>
      <div className={'information'}>
        <div className="address">
          <img alt="logo-light" src="../../../../content/images/logo_light.png" />
          <div className={'footer-information-content'}>
            <div className={`${isPreview && currentStep === 0 ? 'current-editor' : ''}`} onClick={onChangeStep(0)}>
              <DisplayData html={text1} />
            </div>
            <div className={`${isPreview && currentStep === 1 ? 'current-editor' : ''}`} onClick={onChangeStep(1)}>
              <DisplayData html={text14} />
            </div>
          </div>
        </div>
        <div className={`${isPreview && currentStep === 2 ? 'current-editor' : ''}`} onClick={onChangeStep(2)}>
          <div className="link-header">
            <DisplayData html={text2} />
          </div>
          {stripTag(text3) ? (
            <Link to={link3} {...(link3.includes('.pdf') && { target: '_blank' })}>
              <DisplayData html={text3} />
            </Link>
          ) : null}

          {stripTag(text4) ? (
            <Link to={link4} {...(link4.includes('.pdf') && { target: '_blank' })}>
              <DisplayData html={text4} />
            </Link>
          ) : null}

          {stripTag(text5) ? (
            <Link to={link5} {...(link5.includes('.pdf') && { target: '_blank' })}>
              <DisplayData html={text5} />
            </Link>
          ) : null}
        </div>
        <div className={`${isPreview && currentStep === 3 ? 'current-editor' : ''}`} onClick={onChangeStep(3)}>
          <div className="link-header">
            <DisplayData html={text6} />
          </div>
          {stripTag(text7) ? (
            <Link to={link7} {...(link7.includes('.pdf') && { target: '_blank' })}>
              <DisplayData html={text7} />
            </Link>
          ) : null}
          {stripTag(text8) ? (
            <Link to={link8} {...(link8.includes('.pdf') && { target: '_blank' })}>
              <DisplayData html={text8} />
            </Link>
          ) : null}
          {stripTag(text9) ? (
            <Link to={link9} {...(link9.includes('.pdf') && { target: '_blank' })}>
              <DisplayData html={text9} />
            </Link>
          ) : null}
        </div>
        <div className={`${isPreview && currentStep === 4 ? 'current-editor' : ''}`} onClick={onChangeStep(4)}>
          <div className="link-header">
            <DisplayData html={text10} />
          </div>
          {stripTag(text11) ? (
            <Link to={link11} {...(link11.includes('.pdf') && { target: '_blank' })}>
              <DisplayData html={text11} />
            </Link>
          ) : null}

          {stripTag(text12) ? (
            <Link to={link12} {...(link12.includes('.pdf') && { target: '_blank' })}>
              <DisplayData html={text12} />
            </Link>
          ) : null}
          {stripTag(text13) ? (
            <Link to={link13} {...(link13.includes('.pdf') && { target: '_blank' })}>
              <DisplayData html={text13} />
            </Link>
          ) : null}
        </div>
      </div>
      <div className="media-wrapper">
        <FontAwesomeIcon icon={faFacebookSquare} />
        <FontAwesomeIcon icon={faInstagram} />
        <TwitterIcon color={'white'} className={''} />
        <FontAwesomeIcon icon={faYoutube} />
      </div>
    </div>
  );
};

export default Footer;
