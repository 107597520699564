import React, {FC, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Col, Row} from 'reactstrap';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {AvForm} from 'availity-reactstrap-validation';
import ButtonLoading from 'app/shared/layout/ButtonLoading/ButtonLoading';
import Translate from 'app/shared/layout/Translation/translate';
import {parseInt} from 'lodash';
import {
  createArrangement,
  reset,
  shareArrangement,
  updateArrangement
} from 'app/modules/administration/arrangement/arrangement.reducer';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import useCustomForm from 'app/shared/hooks/useCustomForm';
import {convertCurrency, stringifySerializer} from 'app/shared/util/entity-utils';
import {useRole} from 'app/shared/hooks/useRole';
import '../../arrangement.scss';
import parsePhoneNumberFromString from 'libphonenumber-js';
import {ShareModal} from 'app/modules/administration/arrangement/Components/arrangement-section-summary/share-modal';
import {OrderModal} from 'app/modules/administration/arrangement/Components/arrangement-section-summary/order-modal';
import {CreateModal} from 'app/modules/administration/arrangement/Components/arrangement-section-summary/create-modal';
import {
  ButtonsWrapper
} from 'app/modules/administration/arrangement/Components/arrangement-section-summary/buttons-wrapper';
import dayjs from 'dayjs';
import {
  LoginNotification
} from 'app/modules/administration/arrangement/Components/arrangement-section/login-notification/login-notification';
import {createEntity as addNewCartItem} from 'app/entities/cart-item/cart-item.reducer';
import {ConfigurationLayoutContext} from 'app/app';
import {
  WallRoomPart
} from 'app/modules/administration/arrangement/Components/arrangement-section-summary/wall-room-part';
import {
  EquipmentPart
} from 'app/modules/administration/arrangement/Components/arrangement-section-summary/equipment-part';
import {HeaderCard} from 'app/modules/administration/arrangement/Components/arrangement-section/header-card';
import ComponentCollapse from 'app/modules/administration/arrangement/Components/ComponentCollapse';

import {useRoomTypeOptions} from 'app/modules/administration/home-object-v2/hooks/useRoomTypeOptions';
import {IsSubmitForShareContext} from 'app/modules/administration/arrangement/arrangement-update';
import {
  ComponentPart
} from 'app/modules/administration/arrangement/Components/arrangement-section-summary/component-part';
import {
  FloorComponentPart
} from 'app/modules/administration/arrangement/Components/arrangement-section-summary/floor-component-part';
import {
  LightingComponentPart
} from 'app/modules/administration/arrangement/Components/arrangement-section-summary/lighting-component-part';
import {useOptions} from 'app/shared/hooks/useOptions';
import {TRANSLATED_DICTS_KEYS} from 'app/entities/translate-dict-item/constants';
import wallRooms from "app/modules/administration/home-object-v2/wall-rooms";

interface IArrangementSectionSummary {
  isNew: boolean;
  isEdit: boolean;
  isConfiguration: boolean;
  isShare: boolean;
  vatRate: any;
  isSubmit: boolean;
  setIsSubmit: (arg: boolean) => void;
}

const ArrangementSectionSummary: FC<IArrangementSectionSummary> = ({
                                                                     isNew,
                                                                     isEdit,
                                                                     isConfiguration,
                                                                     isShare,
                                                                     vatRate,
                                                                     isSubmit,
                                                                     setIsSubmit,
                                                                   }: IArrangementSectionSummary) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const {id} = useParams<{ id?: string }>();
  const isDetail = !!id && !isEdit && !isConfiguration;
  const urlSearchParams = new URLSearchParams(history.location.search);
  const clientFullName = useAppSelector(state => state.authentication.account?.fullName || '');
  const [offerId] = [parseInt(urlSearchParams.get('offerId'))];
  const account = useAppSelector(state => state.authentication.account);
  const {isAdministrator, isClient} = useRole();
  const {setCurrentTab, tabs, currentTab, changeCurrentTab} = useContext(ConfigurationLayoutContext);
  const [isAddToCart, setIsAddToCart] = useState(false);
  const updating = useAppSelector(state => state.arrangement.updating);
  const updateSuccess = useAppSelector(state => state.arrangement.updateSuccess);
  const updateCartSuccess = useAppSelector(state => state.cartItem.updateSuccess);
  const arrangementId = useAppSelector(state => state.arrangement.entity?.arrangement?.id);

  const arrangementData = useAppSelector(state => state.arrangement.entity);
  const arrangementName = arrangementData?.arrangement?.arrangementName;
  const arrangementPrice = useAppSelector(state => state.arrangement.entity?.arrangement?.netPrice);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenOrder, setIsOpenOrder] = useState(false);
  const [isOpenShare, setIsOpenShare] = useState(false);
  const {onChangeSelect, fieldsState, onChangeInput, setFieldsState} = useCustomForm({initFields: {}});
  const [isDisabled, setIsDisabled] = useState(false);
  const isEditForClient = useMemo(() => pathname.includes('/edit'), [pathname]);
  const formRef = useRef(null);
  const roomTypeOptions = useRoomTypeOptions();
  const {setIsSubmitForShare, isSubmitForShare} = useContext(IsSubmitForShareContext);

  const shellAndCores = useAppSelector(state => state.arrangement.entity.shellAndCores);

  const floorComponents = useAppSelector(state => state.arrangement.entity.interiorSection?.floorComponent);

  const wallRoomComponents = useAppSelector(state => state.arrangement.entity.interiorSection?.wallRoomComponents);
  const {subsectionTypesOptions} = useOptions(TRANSLATED_DICTS_KEYS.SUBSECTION_TYPES, true);

  const hasWallRoomComponents = useMemo(() => {
    return wallRoomComponents?.filter(wallRoomComponent => wallRoomComponent?.wallRooms?.filter(wallRoom => wallRoom?.wallRoomColors?.filter(wallRoomColor => wallRoomColor?.isSelected)?.length > 0)?.length > 0)?.length > 0;
  }, [wallRoomComponents]);

  const roomEquipments = useAppSelector(state => state.arrangement.entity.interiorSection?.furnishingSubsection?.roomEquipments);

  const hasEquipment = useMemo(() => {
    if (roomEquipments?.filter(roomEquipment => roomEquipment?.equipments?.filter(equipment => equipment?.materialGroups?.filter(materialGroup => materialGroup.isSelected)?.length > 0)?.length > 0)?.length > 0) {
      return true;
    }
    return roomEquipments?.filter(roomEquipment => roomEquipment?.equipmentSets?.filter(equipment => equipment?.materialGroups?.filter(materialGroup => materialGroup.isSelected)?.length > 0)?.length > 0)?.length > 0;

  }, [roomEquipments]);

  const roomLightings = useAppSelector(state => state.arrangement.entity.interiorSection?.roomLightings);

  const hasLightings = useMemo(() => {
    return roomLightings?.filter(roomLighting => roomLighting?.componentProperties?.filter(item => item?.isSelected)?.length > 0)?.length > 0;
  }, [roomLightings]);

  const canAddToCart = isClient || !isAuthenticated;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (changeCurrentTab !== undefined && changeCurrentTab !== currentTab) {
      setCurrentTab(changeCurrentTab);
    }
  }, [changeCurrentTab]);

  useEffect(() => {
    if (isSubmitForShare) {
      setIsSubmit(true);
      setCurrentTab(tabs.length - 1);
    }
  }, [isSubmitForShare]);

  useEffect(() => {
    if (isSubmit && updateSuccess) {
      if (isAdministrator) {
        dispatch(reset());
        history.push('/admin/arrangements');
      } else if (isConfiguration) {
        setIsSubmitForShare(true);
        history.push({
          pathname: `/arrangements/${arrangementId}/configuration`,
          search: stringifySerializer({arrangementId, offerId}),
        });
      }
    }
  }, [updateSuccess, isSubmit]);

  useEffect(() => {
    if (isAddToCart && updateCartSuccess) {
      if (isConfiguration) {
        history.push('/cart');
      }
    }
  }, [updateCartSuccess, isAddToCart]);

  useEffect(() => {
    if (isAddToCart && updateSuccess) {
      dispatch(
        addNewCartItem({
          arrangementId,
          offerId: offerId ? offerId : arrangementData?.arrangement?.offerArrangements?.[0]?.offerId,
          quantity: 1,
        })
      );
    }
  }, [updateSuccess, isAddToCart]);

  useEffect(() => {
    if (account) {
      const phones = `${account.phones?.[0]?.sign}${account.phones?.[0]?.countryPrefix}${account.phones?.[0]?.phoneNumber}`;
      const initData = {
        ...account,
        phones: parsePhoneNumberFromString(phones)?.formatInternational(),
      };

      setFieldsState(initData);
    }
  }, [account]);

  useEffect(() => {
    if (arrangementName) {
      setFieldsState(prev => {
        return {
          ...prev,
          arrangementName,
        };
      });
    }
  }, [arrangementName]);

  useEffect(() => {
    if (offerId) {
      setFieldsState(prev => {
        return {
          ...prev,
          offerArrangements: [{offerId}],
        };
      });
    }
  }, [offerId]);

  const handleSubmit = event => {
    setIsSubmit(true);
    localStorage.removeItem(`arrangement-${id}`);

    if (!isAuthenticated) {
      event.preventDefault();
      return;
    }
    const today = dayjs();

    if (isNew) {
      dispatch(
        createArrangement({
          ...arrangementData,
          arrangement: {
            vatRate,
            arrangementTypeId: arrangementData?.arrangement?.arrangementTypeId,
            projectSeasonId: arrangementData?.arrangement?.projectSeasonId,
            netPrice: arrangementData?.arrangement?.netPrice,
            grossPrice: arrangementData?.arrangement?.grossPrice,
            arrangementName,
            ...(fieldsState?.offerArrangements ? {offerArrangements: [{offerId: fieldsState?.offerArrangements?.id}]} : {}),
          },
        })
      );
      return;
    }

    if (isClient && !isEditForClient) {
      const newArrangementName = `${arrangementName?.split('/')?.[0]}/${clientFullName}/${today.format('YYYY/MM/DD')}`;

      dispatch(
        createArrangement({
          ...arrangementData,
          arrangement: {
            vatRate,
            arrangementTypeId: arrangementData?.arrangement?.arrangementTypeId,
            projectSeasonId: arrangementData?.arrangement?.projectSeasonId,
            netPrice: arrangementData?.arrangement?.netPrice,
            grossPrice: arrangementData?.arrangement?.grossPrice,
            parentArrangementId: arrangementId,
            arrangementName: newArrangementName,
            offerArrangements: [{offerId: offerId ? offerId : arrangementData?.arrangement?.offerArrangements?.[0]?.offerId}],
          },
        })
      );
      return;
    }
    if (isEdit) {
      dispatch(updateArrangement(arrangementData));
      return;
    }
  };

  const handlePlaceOrder = () => {
    setIsDisabled(true);
  };

  const openEdit = event => {
    const arrangementIdDetail = arrangementId;
    event?.preventDefault();
    history.push(isAdministrator ? `/admin/arrangements/${arrangementIdDetail}/edit` : `/arrangements/${arrangementIdDetail}/edit`);
  };

  const addToOffer = () => {
    setIsOpenCreate(true);
  };

  const toggleCloseCreateModal = () => {
    setIsOpenCreate(false);
    setTimeout(() => {
      setFieldsState(prev => ({...prev, offerArrangements: null}));
    }, 100);
  };

  const openShare = event => {
    event.preventDefault();
    dispatch(shareArrangement(id));
    setIsOpenShare(true);
  };

  const orderOnline = event => {
    event.preventDefault();
    if (isSubmit) {
      dispatch(
        addNewCartItem({
          arrangementId,
          offerId: offerId ? offerId : arrangementData?.offerArrangements?.[0]?.offerId,
          quantity: 1,
        })
      );
    } else {
      formRef.current.submit();
    }

    setIsAddToCart(true);
  };

  const contactWithExpert = event => {
    event.preventDefault();
    window.open('/contact', '_blank');
  };

  const toggleCloseShareModal = () => setIsOpenShare(false);
  const toggleCloseOrderModal = () => setIsOpenOrder(false);

  return (
    <div className="mb-4">
      <AvForm onValidSubmit={handleSubmit} className="arrangement-wrapper position-relative" ref={formRef}>
        <Row>
          <Col xs={12} md={8}>
            {shellAndCores?.map((shellAndCore, shellAndCoreIndex) => {
              const subsectionTypeLabel = subsectionTypesOptions?.find(
                subsectionTypesOption => subsectionTypesOption?.id === shellAndCore?.subsectionId
              )?.label;

              return (
                <ComponentCollapse
                  header={isOpen => <HeaderCard label={subsectionTypeLabel} isOpen={isOpen}/>}
                  id={`component-floor`}
                  key={`shellAndCore-${shellAndCoreIndex}`}
                  defaultOpen={false}
                  className={'component-properties-wrapper'}
                >
                  {shellAndCore?.components?.map((component, componentIndex) => (
                    <ComponentPart
                      key={componentIndex}
                      component={component}
                      componentIndex={componentIndex}
                      shellAndCoreIndex={shellAndCoreIndex}
                    />
                  ))}
                </ComponentCollapse>
              );
            })}

            <FloorComponentPart component={floorComponents} componentIndex={0}/>

            {
              hasWallRoomComponents ?
                <ComponentCollapse
                  header={isOpen => <HeaderCard label={<Translate contentKey="proEcoApp.arrangement.interiorWall"/>}
                                                isOpen={isOpen}/>}
                  id={`component-floor`}
                  defaultOpen={false}
                  className={'component-properties-wrapper'}
                >
                  {wallRoomComponents?.map((roomProject, roomProjectIndex) => {
                    const roomTypeLabel = roomTypeOptions.find(
                      roomTypeOption => roomTypeOption.id === roomProject?.roomTypeId
                    )?.translateLabel;

                    if (roomProject?.wallRooms?.filter(wallRoom => wallRoom?.wallRoomColors?.filter(wallRoomColor => wallRoomColor?.isSelected)?.length > 0)?.length > 0) {
                      return null;
                    }

                    return (
                      <>
                        <HeaderCard isOpen={true} label={roomTypeLabel} isHeader={false}/>

                        {roomProject?.wallRooms?.map((wallRoom, wallRoomIndex) => {
                          return (
                            <WallRoomPart
                              key={`wallRoom-${wallRoom.id}`}
                              wallRoom={wallRoom}
                              wallRoomIndex={wallRoomIndex}
                              isDetail={isDetail}
                              path={`interiorSection.wallRoomComponents[${roomProjectIndex}].wallRooms[${wallRoomIndex}].wallRoomColors`}
                            />
                          );
                        })}
                      </>
                    );
                  })}
                </ComponentCollapse> : null
            }
            {
              hasEquipment ?
                <ComponentCollapse
                  header={isOpen => <HeaderCard label={<Translate contentKey="proEcoApp.arrangement.furnishings"/>}
                                                isOpen={isOpen}/>}
                  id={`equipment-set`}
                  defaultOpen={false}
                  className={'component-properties-wrapper'}
                >
                  {roomEquipments?.map((roomProject, roomProjectIndex) => {
                    const roomTypeLabel = roomTypeOptions.find(
                      roomTypeOption => roomTypeOption.id === roomProject?.roomTypeId
                    )?.translateLabel;

                    if (!(roomProject?.equipments?.filter(equipment => equipment?.materialGroups?.filter(materialGroup => materialGroup.isSelected)?.length > 0)?.length > 0) && !(roomProject?.equipmentSets?.filter(equipmentSet => equipmentSet?.materialGroups?.filter(materialGroup => materialGroup.isSelected)?.length > 0)?.length > 0)) {
                      return null;
                    }

                    return (
                      <div key={roomProjectIndex}>
                        <HeaderCard isOpen={true} label={roomTypeLabel} isHeader={false}/>
                        <EquipmentPart
                          equipments={roomProject?.equipments}
                          isSet={false}
                          optionIdLabel={`option-equipment-${roomProject?.id}`}
                          isDetail={isDetail}
                          path={`interiorSection.furnishingSubsection.roomEquipments[${roomProjectIndex}].equipments`}
                        />
                        <EquipmentPart
                          equipments={roomProject?.equipmentSets}
                          isSet={true}
                          optionIdLabel={`option-equipment-${roomProject?.id}`}
                          isDetail={isDetail}
                          path={`interiorSection.furnishingSubsection.roomEquipments[${roomProjectIndex}].equipmentSets`}
                        />
                      </div>
                    );
                  })}
                </ComponentCollapse> : null
            }
            {
              hasLightings ?
                <ComponentCollapse
                  header={isOpen => <HeaderCard label={<Translate contentKey="proEcoApp.arrangement.lighting"/>}
                                                isOpen={isOpen}/>}
                  id={`component-lighting`}
                  defaultOpen={false}
                  className={'component-properties-wrapper'}
                >
                  {roomLightings?.map((roomLighting, roomLightingIndex) => {
                    const roomTypeLabel = roomTypeOptions.find(
                      roomTypeOption => roomTypeOption.id === roomLighting?.roomTypeId
                    )?.translateLabel;

                    if (roomLighting?.componentProperties?.filter(item => item?.isSelected)?.length === 0) {
                      return null;
                    }

                    return (
                      <div key={roomLightingIndex} className={'ml-4'}>
                        <HeaderCard isOpen={true} label={roomTypeLabel} isHeader={false}/>
                        <LightingComponentPart component={roomLighting} componentIndex={roomLightingIndex}
                                               key={roomLightingIndex}/>
                      </div>
                    );
                  })}
                </ComponentCollapse> : null
            }

            <div className={'arrangement-section-price'}>
              <Translate className="mr-1" contentKey={`proEcoApp.arrangement.${vatRate ? 'gross' : 'net'}PriceLabel`}/>
              <span className={'total-price'}>{convertCurrency(arrangementPrice, vatRate)}</span>
            </div>
            <Row className={'my-4'}>
              <Col md={6}>
                {!isShare && (
                  <ButtonLoading
                    type="button"
                    color="second-type"
                    updating={updating}
                    disabled={updating}
                    onClick={contactWithExpert}
                    text="proEcoApp.arrangement.contactWithExpert"
                    classNames={'btn-default-size w-100 mb-2'}
                    id="contactWithExpert"
                  />
                )}
              </Col>
              <Col md={6}>
                {canAddToCart && (
                  <>
                    <div id="orderOnline">
                      <ButtonLoading
                        type="button"
                        color="first-type"
                        updating={updating}
                        disabled={isDetail}
                        onClick={orderOnline}
                        text="proEcoApp.arrangement.addToCart"
                        classNames={'btn-default-size w-100'}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <ButtonsWrapper
            isSubmit={isSubmit}
            isEdit={isEdit}
            isShare={isShare}
            openShare={openShare}
            offerId={offerId}
            addToOffer={addToOffer}
            isDetail={isDetail}
            isConfiguration={isConfiguration}
            isNew={isNew}
            openEdit={openEdit}
          />
        </Row>
      </AvForm>

      <CreateModal
        isOpen={isOpenCreate}
        onSubmit={handleSubmit}
        toggleClose={toggleCloseCreateModal}
        fieldsState={fieldsState}
        onChangeSelect={onChangeSelect}
      />
      {!updating && <ShareModal toggleClose={toggleCloseShareModal} onSubmit={handleSubmit} isOpen={isOpenShare}/>}

      <OrderModal
        isOpen={isOpenOrder}
        toggleClose={toggleCloseOrderModal}
        onSubmit={handlePlaceOrder}
        onChangeInput={onChangeInput}
        fieldsState={fieldsState}
        setFieldsState={setFieldsState}
        isDisabled={isDisabled}
      />
      {!isAuthenticated && isSubmit && <LoginNotification/>}
    </div>
  );
};

export default ArrangementSectionSummary;
