import {BLOG_TYPES} from "app/config/constants";

export interface IBlogContent {
  id?: number;
  number?: number;
  title?: string;
  text?: string;
  imageDescription?: string;
  imagePath?: string;
  imageUrl?: string;
  tagNames?: string;
}

export interface IBlog {
  id?: number;
  authorName?: string;
  header?: string;
  description?: string;
  languageId?: string | null;
  nextId?: number;
  previousId?: number;
  isActive?: number;
  imageUrl?: string;
  imagePath?: string;
  blogContents?: IBlogContent[];
  blogTagIds?: any[];
  tagNames?: string;
  blogTypeId?: number;
  displayOrder?: number;
}

export const defaultValue: Readonly<IBlog> = {
  blogContents: [],
};
