export interface IRoomType {
  id?: number;
  name?: string;
  description?: string | null;
  enumKey?: string;
  label?: string;
  isActive?: boolean;
}

export const defaultValue: Readonly<IRoomType> = {
  isActive: false,
};
