import { useMemo } from 'react';
import { getDictOptions } from 'app/shared/util/select-utils';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import { useAppSelector } from 'app/config/store';
import { HAS_MATERIALS } from 'app/config/constants';

type IUseMaterialOptions = {
  isDetail: boolean;
  hasMaterial?: boolean;
  componentTypeId?: number;
};

export const useMaterialOptions = ({ isDetail = true, componentTypeId, hasMaterial }: IUseMaterialOptions) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const materialTypeDict = useAppSelector(state => state.materialType.entities);

  const materialTypeOptions = useMemo(
    () => getDictOptions(materialTypeDict, TRANSLATED_DICTS.MATERIAL_TYPES, null, isDetail),
    [materialTypeDict, isDetail, currentLocale]
  );

  return useMemo(() => {
    if (HAS_MATERIALS?.includes(componentTypeId) || hasMaterial) {
      return materialTypeOptions;
    }
    return [];
  }, [currentLocale, componentTypeId, materialTypeDict]);
};
