import { IWallColorType } from 'app/shared/model/wall-color-type.model';
import { IWallRoom } from 'app/shared/model/wall-room.model';

export interface IWallRoomColor {
  id?: number;
  imagePath?: string;
  imageUrl?: string;
  wallColorType?: IWallColorType | null;
  wallColorTypeId?: IWallColorType | null;
  wallRoom?: IWallRoom | null;
  imageLayerName?: string | null;
}

export const defaultValue: Readonly<IWallRoomColor> = {};
