import React from 'react';
import {
  createEntity,
  deactivateEntity,
  restoreEntity,
  getEntities,
  getEntity,
  reset,
  updateEntity,
} from 'app/entities/arrangement-type/arrangement-type.reducer';
import DictionaryRoutes from 'app/modules/dictionaries/Components/dictionaryRoutes';

const Routes = () => (
  <DictionaryRoutes
    updateEntity={updateEntity}
    createEntity={createEntity}
    getEntities={getEntities}
    getEntity={getEntity}
    reset={reset}
    deleteEntity={deactivateEntity}
    restoreEntity={restoreEntity}
  />
);

export default Routes;
