import React, {useEffect, useMemo} from 'react';
import {
  reset,
  createEntity,
  getEntity,
  updateEntity
} from "app/entities/component-property-description/component-property-description.reducer";
import Translate, {translate} from "app/shared/layout/Translation/translate";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useAppSelector} from "app/config/store";
import {useDispatch} from "react-redux";
import {UpdatePage} from "app/shared/layout/Pages/UpdatePage";
import FormGenerator from "app/shared/layout/FormGenerator/FormGenerator";
import {get, omit} from "lodash";

interface IComponentPropertyTypeUpdateWrapper {
  isReadOnly?: boolean;
}

const ComponentPropertyDescriptionUpdateWrapper = ({isReadOnly}: IComponentPropertyTypeUpdateWrapper) => {
  const dispatch = useDispatch();
  const {id} = useParams<{ id: string }>();
  const isNew = useMemo(() => !id, [id]);
  const {pathname} = useLocation();
  const searchEditPath = pathname.split('/').slice(-1)[0];
  const isEdit = useMemo(() => isNew || searchEditPath === 'edit', [isNew, searchEditPath]);
  const dictEntity = useAppSelector(state => state.componentPropertyDescription.entity);
  const updating = useAppSelector(state => state.componentPropertyDescription.updating);

  const isEmptyDescriptionFormTypeEntity = useMemo(() => !get(dictEntity, 'id', false), [dictEntity]);
  const loading = useAppSelector(state => state.componentPropertyDescription.loading);
  const isLoading = useMemo(() => loading || (!isNew && isEmptyDescriptionFormTypeEntity), [loading, isEmptyDescriptionFormTypeEntity, isNew]);
  const history = useHistory();
  const updateSuccess = useAppSelector(state => state.componentPropertyDescription.updateSuccess);

  const defaultFields = [
    {
      readOnly: isReadOnly,
      name: 'descriptionPl',
      id: `descriptionPl`,
      label: <Translate contentKey={`proEcoApp.componentPropertyDescription.descriptionPl`}>Description Pl</Translate>,
      validate: {
        required: {value: true, errorMessage: translate('entity.validation.required')}
      },
    },
    {
      readOnly: isReadOnly,
      name: 'descriptionEn',
      id: 'descriptionEn',
      label: <Translate contentKey={`proEcoApp.componentPropertyDescription.descriptionEn`}>Description En</Translate>,
      validate: {
        required: {value: true, errorMessage: translate('entity.validation.required')}
      },
    },
  ];

  const saveEntity = (event, values) => {
    const entity = {
      ...dictEntity,
      ...values,
    };
    if (isNew) {
      dispatch(createEntity(omit({...entity, isActive: true}, ['id'])));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  useEffect(() => {
    if (updateSuccess) {
      history.push('/admin/dict/component-property-description');
    }
  }, [updateSuccess]);

  return (
    <UpdatePage
      withAppPrefix
      pathName={'component-property-descriptions'}
      i18nGroupPrefix={'componentPropertyDescription'}
      isLoading={isLoading}
      getEntity={getEntity}
      reset={reset}
      isReadOnly={!isEdit}
    >
      <FormGenerator
        model={isNew ? {} : dictEntity}
        fields={defaultFields}
        onValidSubmit={saveEntity}
        isNew={isNew}
        isSave={isEdit}
        pathButtonEdit={`/admin/dict/component-property-description/${dictEntity.id}/edit`}
        updating={updating}
        isButtonsCenter
      />
    </UpdatePage>
  )
}
export default ComponentPropertyDescriptionUpdateWrapper;
