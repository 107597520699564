import React, { memo, useContext } from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import AddRowWrapper from 'app/modules/administration/home-object-v2/add-row-wrapper';
import ComponentPropertyAdditionalMaterialItem from 'app/modules/administration/home-object-v2/component-property-additional-material-item';

export const ComponentPropertyAdditionalMaterials = ({
  componentTypeLabel,
  componentTypeId,
  componentPropertyAdditionalMaterials,
  path,
  isDetail = false,
}) => {
  const { onAddRow } = useContext(HomeObjectContext);
  return (
    <>
      <div className={'home-object-title'}>
        <span className={'component-header'}>
          <AddRowWrapper handleAdd={() => onAddRow(path)} showAdd={!isDetail}>
            <Translate contentKey="proEcoApp.project.componentPropertyAdditionalMaterialTitle" interpolate={{ type: componentTypeLabel }} />
          </AddRowWrapper>
        </span>
      </div>

      {componentPropertyAdditionalMaterials?.map((componentPropertyAdditionalMaterial, componentPropertyAdditionalMaterialIndex) => {
        return (
          <ComponentPropertyAdditionalMaterialItem
            key={componentPropertyAdditionalMaterial?.id ?? componentPropertyAdditionalMaterial?.key}
            componentTypeId={componentTypeId}
            componentPropertyAdditionalMaterial={componentPropertyAdditionalMaterial}
            componentPropertyAdditionalMaterialIndex={componentPropertyAdditionalMaterialIndex}
            path={path}
            isDetail={isDetail}
            showDelete={!isDetail}
          />
        );
      })}
    </>
  );
};

export default ComponentPropertyAdditionalMaterials;
