import React, { useEffect, useMemo, useState } from "react";
import "./MenuCartDropdown.scss";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { MenuCart } from "app/shared/layout/TopNavMenu/Components/MenuCart/MenuCart";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/config/store";
import { getEntities as getCartItems } from "app/entities/cart-item/cart-item.reducer";
import { defaultPagination } from "app/shared/util/pagination.constants";
import {sumBy} from "lodash";

export const MenuCartDropdown = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const isActive = pathname === '/cart' || pathname === ' /cart/delivery-data' || pathname === '/cart/finish' || pathname === '/cart/delivery-and-payment' || pathname === '/cart/summary';
  const [isOpenCart, setIsOpenCart] = useState(false);
  const handleToggleIsOpenCart = () => setIsOpenCart(prev => !prev);
  const cartItems = useAppSelector(state => state.cartItem.entities);
  const cartItemsLength = useMemo(() => {
    return sumBy(cartItems, 'quantity');
  }, [cartItems]);

  useEffect(() => {
    if (isAuthenticated && pathname !== '/login' && pathname !== '/cart') {
      dispatch(getCartItems(defaultPagination));
    }
  }, [isAuthenticated]);

  return (
    <div>
      {isOpenCart? <div className={"menu-cart-backdrop"}/>: null}
      <Dropdown isOpen={isOpenCart} toggle={handleToggleIsOpenCart} className="menu-cart-dropdown">
        <DropdownToggle caret>
          <div>
            {!isActive? <img
              src={`content/images/shopping-cart.png`} alt="shopping-cart"
              className="header-icon"
            />: null}
            {isActive? <img
              src={`content/images/shopping-cart-active.png`} alt="shopping-cart"
              className="header-icon"
            />: null}
            <div className={`badge-number ${isActive ? 'active' : ''}`}><span>{cartItemsLength}</span></div>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <MenuCart handleClose={handleToggleIsOpenCart}/>
        </DropdownMenu>
      </Dropdown>
    </div>

  )
}
