import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { calcPriceWithPromotionValue } from 'app/shared/util/entity-utils';
import { updateArrangementData } from 'app/modules/administration/arrangement/arrangement.reducer';

export const useCalcNetPrice = () => {
  const dispatch = useAppDispatch();
  const vatRate = useAppSelector(state => state.arrangement.entity.vatRate || 0);
  const promotionValue = useAppSelector(state => state.arrangement?.entity?.promotion?.promotionValue);
  const shellAndCores = useAppSelector(state => state.arrangement.entity.shellAndCores);

  const floorComponent = useAppSelector(state => state.arrangement.entity.interiorSection?.floorComponent);

  const wallRoomComponents = useAppSelector(state => state.arrangement.entity.interiorSection?.wallRoomComponents);

  const roomEquipments = useAppSelector(state => state.arrangement.entity.interiorSection?.furnishingSubsection?.roomEquipments);
  const roomLightings = useAppSelector(state => state.arrangement.entity.interiorSection?.roomLightings);

  useEffect(() => {
    let totalPrice = 0;
    if (shellAndCores) {
      shellAndCores?.map(shellAndCore => {
        shellAndCore?.components?.map(component => {
          component?.materialGroups?.map(materialGroup => {
            totalPrice += materialGroup?.componentProperties?.reduce((sum, componentProperty) => {
              if (componentProperty?.isSelected) {
                return sum + +componentProperty?.totalPrice;
              }
              return sum;
            }, 0);
          });
        });
      });
    }
    if (floorComponent) {
      floorComponent?.materialGroups?.map(materialGroup => {
        totalPrice += materialGroup?.componentProperties?.reduce((sum, componentProperty) => {
          if (componentProperty?.isSelected) {
            return sum + +componentProperty?.totalPrice;
          }
          return sum;
        }, 0);
      });
    }

    if (roomEquipments) {
      roomEquipments?.map(roomEquipment => {
        roomEquipment?.equipments?.map(equipment => {
          totalPrice += equipment?.materialGroups?.reduce((sum, materialGroup) => {
            if (materialGroup?.isSelected) {
              return sum + +materialGroup?.price;
            }
            return sum;
          }, 0);
        });
        roomEquipment?.equipmentSets?.map(equipmentSet => {
          totalPrice += equipmentSet?.materialGroups?.reduce((sum, materialGroup) => {
            if (materialGroup?.isSelected) {
              return sum + +materialGroup?.price;
            }
            return sum;
          }, 0);
        });
      });
    }

    if (wallRoomComponents) {
      wallRoomComponents?.map(roomProject => {
        roomProject?.wallRooms?.map(wallRoom => {
          totalPrice += wallRoom?.wallRoomColors?.reduce((sum, wallRoomColor) => {
            if (wallRoomColor?.isSelected) {
              return sum + +wallRoomColor?.price;
            }
            return sum;
          }, 0);
        });
      });
    }

    if (roomLightings) {
      roomLightings?.map(roomLighting => {
        totalPrice += roomLighting?.componentProperties?.reduce((sum, componentProperty) => {
          if (componentProperty?.isSelected) {
            return sum + +componentProperty?.totalPrice;
          }
          return sum;
        }, 0);
      });
    }

    const netPrice = calcPriceWithPromotionValue(totalPrice, promotionValue);
    const grossPrice = netPrice * (1 + vatRate);
    dispatch(updateArrangementData({ netPrice, grossPrice }));
  }, [shellAndCores, floorComponent, wallRoomComponents, roomEquipments, roomLightings]);
};
