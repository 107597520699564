import React, { FC } from "react";
import Translate from "app/shared/layout/Translation/translate";
import { Col, Row } from "reactstrap";
import CustomInput from "app/shared/layout/CustomInput/CustomInput";
import { EMAIL, MAX_LENGTH, REQUIRED } from "app/shared/util/validation";
import CheckBox from "app/shared/layout/CheckBox/CheckBox";
import ButtonLoading from "app/shared/layout/ButtonLoading/ButtonLoading";
import BasicFormDialog from "app/shared/layout/Dialog/BasicFormDialog";
import { useAppSelector } from "app/config/store";

interface IOderModal {
  isOpen: boolean;
  toggleClose: () => void;
  onSubmit: () => void;
  fieldsState: any;
  onChangeInput: (ev: any) => void;
  setFieldsState: (item) => void;
  isDisabled: boolean;
}

export const OrderModal: FC<IOderModal> = (
  {
    isOpen, toggleClose, onSubmit, fieldsState, onChangeInput, setFieldsState, isDisabled
  }: IOderModal) => {
  const updating = useAppSelector(state => state.arrangement.updating);

  return (
    <BasicFormDialog
      isOpen={isOpen}
      toggleClose={toggleClose}
      onValidSubmit={onSubmit}
      childrenHeader={<Translate contentKey={`proEcoApp.arrangement.orderOnline`} className={'text-center'}/>}
    >
      <Row>
        <Col md={4} xs={4} className={'create-order-section'}>
          <Translate contentKey={'proEcoApp.arrangement.personalDetail'}/>
        </Col>
        <Col md={8} xs={8}>
          <CustomInput
            name="firstName"
            readOnly={true}
            label={<Translate contentKey={'register.form.firstName'}/>}
            value={fieldsState.firstName}
            validate={{
              ...REQUIRED(),
            }}
          />
          <CustomInput
            name="lastName"
            readOnly={true}
            value={fieldsState.lastName}
            label={<Translate contentKey={'register.form.lastName'}/>}
            validate={{
              ...REQUIRED(),
            }}
          />
          <CustomInput
            name="email"
            readOnly={true}
            value={fieldsState.email}
            label={<Translate contentKey={'register.form.email'}/>}
            validate={{
              ...REQUIRED(),
              ...MAX_LENGTH(50),
              ...EMAIL(),
            }}
          />

          <CustomInput
            type={'phone'}
            name="phones"
            readOnly={true}
            label={<Translate contentKey={'register.form.phone'}/>}
            value={fieldsState.phones}
            onChange={onChangeInput}
            validate={{
              ...REQUIRED(),
              ...MAX_LENGTH(50),
            }}
          />
        </Col>
        <Col md={4} xs={4} className={'create-order-section'}>
          <Translate contentKey={'proEcoApp.arrangement.constructionLocation'}/>
        </Col>
        <Col md={8} xs={8}>
          <CustomInput
            name="address"
            label={<Translate contentKey={'register.form.address'}/>}
            value={fieldsState.address}
            validate={{
              ...REQUIRED(),
            }}
            readOnly={true}
          />
          <div className={'d-flex'}>
            <CustomInput
              name="city"
              readOnly={true}
              label={<Translate contentKey={'register.form.city'}/>}
              value={fieldsState.city}
              validate={{
                ...REQUIRED(),
              }}
            />
            <CustomInput
              name="zipcode"
              readOnly={true}
              label={<Translate contentKey={'register.form.zipcode'}/>}
              value={fieldsState.zipcode}
              validate={{
                ...REQUIRED(),
                ...MAX_LENGTH(50),
              }}
            />
          </div>
        </Col>
        <Col md={4} xs={4} className={'mt-3 create-order-section'}>
          <Translate contentKey={'proEcoApp.arrangement.agreement'}/>
        </Col>
        <Col md={8} xs={8} className={'mt-3'}>
          <CheckBox
            name="isConfirmPolicy"
            avGroupClassName="confirm-checkbox"
            value={fieldsState.isConfirmPolicy}
            onChange={event => {
              setFieldsState(prev => ({ ...prev, isConfirmPolicy: event.target.checked }));
            }}
            isRequired={true}
            label={
              <>
                <Translate contentKey={'register.form.term'}/>
                <Translate className="link ml-1 mr-1" onClick={() => {
                }} contentKey={'register.form.termLink'}/>
                <Translate contentKey={'register.form.termLast'}/>
              </>
            }
            type={'checkbox'}
          />
        </Col>

        <Col className={'d-flex justify-content-end mt-5'}>
          <ButtonLoading
            color="first-type"
            updating={isDisabled}
            disabled={isDisabled}
            text="entity.action.send"
            classNames={'btn-default-size'}
            id="save"
          />

          <ButtonLoading
            type="button"
            color="only-border"
            updating={updating}
            disabled={updating}
            onClick={toggleClose}
            text="entity.action.cancel"
            classNames={'btn-default-size'}
            id="cancel"
          />
        </Col>
      </Row>
    </BasicFormDialog>
  )
}
