import { useAppSelector } from 'app/config/store';
import { useMemo } from 'react';

export const useHomeObjectLoading = (id: number | string) => {
  const categoryDetailLoading = useAppSelector(state => state.categoryDetail.loading);
  const componentTypeLoading = useAppSelector(state => state.componentType.loading);
  const roomTypeLoading = useAppSelector(state => state.roomType.loading);
  const imageOptionLoading = useAppSelector(state => state.imageOption.loading);
  const projectLoading = useAppSelector(state => state.project.loading);
  // const translateDictItemLoading = useAppSelector(state => state.translateDictItem.loading);
  const componentPropertyNameLoading = useAppSelector(state => state.componentPropertyName.loading);

  const componentPropertyDescriptionLoading = useAppSelector(state => state.componentPropertyDescription.loading);

  const equipmentTypeLoading = useAppSelector(state => state.equipmentType.loading);

  const wallColorTypeLoading = useAppSelector(state => state.wallColorType.loading);

  const materialTypeLoading = useAppSelector(state => state.materialType.loading);

  return useMemo(
    () =>
      categoryDetailLoading ||
      componentTypeLoading ||
      roomTypeLoading ||
      imageOptionLoading ||
      // translateDictItemLoading ||
      equipmentTypeLoading ||
      wallColorTypeLoading ||
      componentPropertyDescriptionLoading ||
      componentPropertyNameLoading ||
      (id ? projectLoading : false),
    [
      categoryDetailLoading,
      componentTypeLoading,
      roomTypeLoading,
      imageOptionLoading,
      projectLoading,
      // translateDictItemLoading,
      equipmentTypeLoading,
      wallColorTypeLoading,
      componentPropertyDescriptionLoading,
      componentPropertyNameLoading,
      materialTypeLoading,
    ]
  );
};
