import React from 'react';
import BasicFormDictionaries from 'app/shared/layout/FormGenerator/Components/BasicFormDictionaries';
import { IDictListItem } from 'app/modules/dictionaries/constants';
import { useEntityInformation } from 'app/shared/layout/DictionariesView/hooks/useEntityInformation';

export interface DictItemFormProps {
  isNew?: boolean;
  isSave?: boolean;
  isReadOnly?: boolean;
  dictListItem: IDictListItem | { translate: string; path?: string; editTranslationPathPrefix?: string };
  createEntity?: any;
  updateEntity?: () => void;
  isModal?: boolean;
  afterSuccess?: (id: string) => void;
  formRef?: any;
  additionalFields?: any;
  children?: any;
  customSave?: (values) => void;
  titleFields?: string;
  dictPath?: string;
  number?: number;
  isCategoryDetail?: boolean;
}

const DictItemForm: React.FC<DictItemFormProps> = ({
  isNew,
  isReadOnly,
  isSave,
  dictListItem,
  createEntity,
  updateEntity,
  isModal,
  afterSuccess,
  formRef,
  additionalFields,
  children,
  customSave,
  titleFields,
  dictPath,
  number,
  isCategoryDetail,
}: DictItemFormProps) => {
  const { dictTypeEntity, updateSuccess, updating } = useEntityInformation(dictListItem?.translate, isCategoryDetail);

  return (
    <BasicFormDictionaries
      isReadOnly={isReadOnly}
      isNew={isNew}
      isSave={isSave}
      entityPath={dictListItem.path}
      i18nGroupPrefix={dictListItem.translate}
      dictionaryEntity={dictTypeEntity}
      dictPath={dictPath}
      translationPath={dictListItem.editTranslationPathPrefix}
      updateSuccess={updateSuccess}
      updating={updating}
      updateEntity={updateEntity}
      createEntity={createEntity}
      isModal={isModal}
      afterSuccess={afterSuccess}
      formRef={formRef}
      additionalFields={additionalFields}
      customSave={customSave}
      titleFields={titleFields}
      number={number}
    >
      {children}
    </BasicFormDictionaries>
  );
};

export default DictItemForm;
