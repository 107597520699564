import React, { FC, useMemo } from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import ButtonLoading from 'app/shared/layout/ButtonLoading/ButtonLoading';
import BasicFormDialog from 'app/shared/layout/Dialog/BasicFormDialog';
import { useHistory } from 'react-router';
import { stringifySerializer } from 'app/shared/util/entity-utils';
import { Button } from 'reactstrap';

interface IModalAgreeLicense {
  setArrangementIdClone: (item) => void;
  isOpen: boolean;
  arrangementIdClone?: number;
  offerId: number;
}

export const ModalAgreeLicense: FC<IModalAgreeLicense> = ({
  setArrangementIdClone,
  isOpen,
  arrangementIdClone,
  offerId,
}: IModalAgreeLicense) => {
  const history = useHistory();
  const toggleClose = () => setArrangementIdClone(null);

  const configurationLocation = useMemo(
    () => ({
      pathname: `/arrangements/${arrangementIdClone}/configuration`,
      search: stringifySerializer({ arrangementId: arrangementIdClone, offerId }),
    }),
    [arrangementIdClone, offerId]
  );
  const handleConfiguration = () => {
    toggleClose();
  };

  const handleLogin = () => {
    history.push('/login', {
      from: configurationLocation,
    });
  };

  return (
    <BasicFormDialog
      isOpen={isOpen}
      toggleClose={toggleClose}
      onValidSubmit={handleConfiguration}
      childrenHeader={<Translate contentKey={`proEcoApp.offer.arrangement.configurationAgreeTitle`} />}
    >
      <div className={'pt-1 pb-3'}>
        <Translate contentKey={`proEcoApp.offer.arrangement.licenseDescriptions`} />
      </div>

      <div className={'flex-column flex-md-row d-flex flex-gap-2 mt-3'}>
        <Button type="submit" color="only-border" className={'btn-default-size'}>
          <Translate contentKey="proEcoApp.offer.arrangement.continue" />
        </Button>

        <ButtonLoading
          type={'button'}
          onClick={handleLogin}
          disabled={false}
          updating={false}
          text={'global.menu.login'}
          classNames="btn-default-size"
        />
      </div>
    </BasicFormDialog>
  );
};
