import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {createEntitySlice, EntityState, serializeAxiosError} from 'app/shared/reducers/reducer.utils';
import {defaultValue, IQuestionTag} from 'app/shared/model/question-tag.model';

const initialState: EntityState<IQuestionTag> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/question-tags';

export const getEntities = createAsyncThunk('questionTag/fetch_entity_list', async (params: any) => {
  return axios.get<IQuestionTag[]>(apiUrl, {params});
});

export const getEntity = createAsyncThunk(
  'questionTag/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IQuestionTag>(requestUrl);
  },
  {serializeError: serializeAxiosError}
);

export const createEntity = createAsyncThunk(
  'questionTag/create_entity',
  async (entity: IQuestionTag, thunkAPI) => {
    return await axios.post<IQuestionTag>(apiUrl, cleanEntity(entity));
  },
  {serializeError: serializeAxiosError}
);

export const updateEntity = createAsyncThunk(
  'questionTag/update_entity',
  async (entity: IQuestionTag, thunkAPI) => {
    return await axios.put<IQuestionTag>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  {serializeError: serializeAxiosError}
);


export const deactivateQuestionTag = createAsyncThunk(
  'questionTag/deactivate_entity',
  async (id: string | number, thunkAPI) => {
    const response = await axios.put(`${apiUrl}/${id}/deactivate`);
    thunkAPI.dispatch(getEntities({}));
    return response;
  },
  {serializeError: serializeAxiosError}
);

export const restoreQuestionTag = createAsyncThunk(
  'questionTag/restore_entity',
  async (id: string | number, thunkAPI) => {
    const response = await axios.put(`${apiUrl}/${id}/restore`);
    thunkAPI.dispatch(getEntities({}));
    return response;
  },
  {serializeError: serializeAxiosError}
);

export const partialUpdateEntity = createAsyncThunk(
  'questionTag/partial_update_entity',
  async (entity: IQuestionTag, thunkAPI) => {
    return await axios.patch<IQuestionTag>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  {serializeError: serializeAxiosError}
);

export const deleteEntity = createAsyncThunk(
  'questionTag/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.delete<IQuestionTag>(requestUrl);
  },
  {serializeError: serializeAxiosError}
);

// slice

export const QuestionTagSlice = createEntitySlice({
  name: 'questionTag',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(restoreQuestionTag, deactivateQuestionTag), state => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, restoreQuestionTag, deactivateQuestionTag), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const {reset} = QuestionTagSlice.actions;

// Reducer
export default QuestionTagSlice.reducer;
