import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import Translate from 'app/shared/layout/Translation/translate';
import {
  HomeObjectContext,
  LayerContext,
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import {
  REQUIRED, MIN,
} from 'app/shared/util/validation';
import { Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import DeleteRowWrapper from 'app/modules/administration/home-object-v2/delete-row-wrapper';
import SubComponentProperty from 'app/modules/administration/home-object-v2/sub-component-property';
import { useSubcomponentPropertyTypes } from 'app/modules/administration/home-object-v2/hooks/useSubcomponentPropertyTypes';
import { debounce } from 'lodash';
import ComponentPropertyAdditionalMaterials from 'app/modules/administration/home-object-v2/component-property-additional-materials';
import ComponentPropertyConstructions from 'app/modules/administration/home-object-v2/component-property-constructions';
import { useAppDispatch } from 'app/config/store';
import { updateCPTotalPrice } from 'app/entities/project/project.reducer';
import { getNumber, roundPrice } from 'app/shared/util/entity-utils';
import {CATEGORIES, GROUP_TYPE, NONE_BUILDING_TYPE, SUB_COMPONENT_PROPERTY_TYPES} from 'app/config/constants';
import { useUploadedImages } from 'app/modules/administration/home-object-v2/hooks/useUploadedImages';
import { CustomOption } from 'app/shared/layout/CustomInput/Components/CustomSelect';
import BadgeInPublic from 'app/shared/layout/BadgeInPublic/BadgeInPublic';
import { useBuildingTypeOptions } from 'app/modules/administration/home-object-v2/hooks/useBuildingTypeOptions';
import { useMaterialOptions } from 'app/modules/administration/home-object-v2/hooks/useMaterialOptions';
import { useOptionDescriptions, useOptionNames } from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';

const CP_HAS_ADDITIONAL_MATERIALS = [2, 3, 4];
const CP_HAS_CONSTRUCTIONS = [
  SUB_COMPONENT_PROPERTY_TYPES.FLOOR_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.INTERNAL_WALL_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.FACADE_TYPE,
  SUB_COMPONENT_PROPERTY_TYPES.ROOF_TYPE,
];
const mapTypeIdToCategoryId = type => {
  if (type === SUB_COMPONENT_PROPERTY_TYPES.FLOOR_TYPE) {
    return CATEGORIES.FLOOR_TYPE;
  }

  if (type === SUB_COMPONENT_PROPERTY_TYPES.INTERNAL_WALL_TYPE) {
    return CATEGORIES.INTERNAL_WALL_TYPE;
  }

  if (type === SUB_COMPONENT_PROPERTY_TYPES.FACADE_TYPE) {
    return CATEGORIES.FACADE_TYPE;
  }

  if (type === SUB_COMPONENT_PROPERTY_TYPES.ROOF_TYPE) {
    return CATEGORIES.ROOF_TYPE;
  }

  if (type === SUB_COMPONENT_PROPERTY_TYPES.EXTERIOR_DOOR_TYPES) {
    return CATEGORIES.EXTERIOR_DOOR_TYPES;
  }

  if (type === SUB_COMPONENT_PROPERTY_TYPES.WINDOW_TYPES) {
    return CATEGORIES.WINDOW_TYPES;
  }
  if (type === SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_TYPES) {
    return CATEGORIES.ELECTRICIAN_TYPES;
  }
  if (type === SUB_COMPONENT_PROPERTY_TYPES.VENTILATION_TYPES) {
    return CATEGORIES.VENTILATION_TYPE;
  }
  if (type === SUB_COMPONENT_PROPERTY_TYPES.AIR_CONDITIONING_TYPES) {
    return CATEGORIES.AIR_CONDITIONING_TYPE;
  }

  if (type === SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_ADDONS) {
    return CATEGORIES.ELECTRICIAN_ADDON_TYPE;
  }
  if (type === SUB_COMPONENT_PROPERTY_TYPES.PLUMBING_HEAT_TYPE) {
    return CATEGORIES.PLUMBING_HEAT_TYPE;
  }
  if (type === SUB_COMPONENT_PROPERTY_TYPES.ELECTRICIAN_INTELLIGENT_HOME) {
    return CATEGORIES.ELECTRICIAN_TYPE_INTELLIGENT_HOME_TYPE;
  }
  if (type === SUB_COMPONENT_PROPERTY_TYPES.HYDRAULICS_TYPES) {
    return CATEGORIES.HYDRAULICS_TYPES;
  }
  return null;
};
type IComponentPropertyItem = {
  componentProperty?: any;
  componentPropertyIndex?: number;
  counter?: string;
  componentTypeLabel?: string;
  componentTypeId?: number;
  path?: string;
  showDelete?: boolean;
  isDetail?: boolean;
  isInterior?: boolean;
};
export const ComponentPropertyItem = memo<IComponentPropertyItem>(
  ({
    componentProperty,
    componentPropertyIndex,
    counter,
    componentTypeLabel,
    componentTypeId,
    path,
    showDelete,
    isDetail = false,
    isInterior = false,
  }) => {
    const { layers, layerInteriors } = useContext(LayerContext);

    const layerOptions = useMemo(() => (!isInterior ? layers : layerInteriors), [isInterior, layers, layerInteriors]);

    const dispatch = useAppDispatch();
    const { onChangeInputNumber, onRemoveRow, selectPropertyOption, onChangeSelect } = useContext(HomeObjectContext);
    const { subComponentPropertyTypeOptions } = useSubcomponentPropertyTypes(componentTypeId, isDetail);
    const imageOptions = useUploadedImages(mapTypeIdToCategoryId(componentTypeId), isDetail);
    const buildingTypeOptions = useBuildingTypeOptions();
    const nameOptions = useOptionNames();
    const descriptionOptions = useOptionDescriptions();

    const isGroup = useMemo(()=> {
      return GROUP_TYPE?.includes(componentTypeId);
    }, [componentTypeId,GROUP_TYPE]);

    const quantity = useMemo(
      () =>
        componentProperty?.subComponentProperties?.reduce(
          (sum, subComponentProperty) => sum + getNumber(subComponentProperty?.quantity, 0),
          0
        ),
      [componentProperty?.subComponentProperties]
    );

    const materialTypesOptions = useMaterialOptions({ isDetail, componentTypeId });

    const totalPriceSum = useMemo(() => {
      return (
        getNumber(
          componentProperty?.subComponentProperties?.reduce(
            (sum, subComponentProperty) => sum + getNumber(subComponentProperty?.totalPrice, 0),
            0
          ),
          0
        ) +
        getNumber(
          componentProperty?.componentPropertyAdditionalMaterials?.reduce(
            (sum, componentPropertyAdditionalMaterial) => sum + getNumber(componentPropertyAdditionalMaterial?.totalPrice, 0),
            0
          ),
          0
        ) +
        getNumber(
          componentProperty?.componentPropertyConstructions?.reduce(
            (sum, componentPropertyConstruction) => sum + getNumber(componentPropertyConstruction?.totalPrice, 0),
            0
          ),
          0
        )
      );
    }, [
      componentProperty?.componentPropertyConstructions,
      componentProperty?.subComponentProperties,
      componentProperty?.componentPropertyAdditionalMaterials,
    ]);

    useEffect(() => {
      if (!isInterior) {
        const totalPrice = roundPrice(totalPriceSum + getNumber(componentProperty?.labour, 0));
        dispatch(
          updateCPTotalPrice({
            name: `${path}[${componentPropertyIndex}].totalPrice`,
            value: totalPrice,
          })
        );
      }
    }, [totalPriceSum, isInterior]);

    const updateTotalPrice = debounce(
      useCallback(
        value => {
          const totalPrice = roundPrice(totalPriceSum + getNumber(value, 0));

          dispatch(
            updateCPTotalPrice({
              name: `${path}[${componentPropertyIndex}].totalPrice`,
              value: totalPrice,
            })
          );
        },
        [updateCPTotalPrice, totalPriceSum, path, componentPropertyIndex]
      ),
      500
    );

    const handleChangeLabour = useCallback(
      ev => {
        onChangeInputNumber(ev);
        updateTotalPrice(ev.target.value);
      },
      [updateTotalPrice, onChangeInputNumber]
    );

    const fieldComponentProperty = [
      {
        readOnly: isDetail,
        name: `${path}[${componentPropertyIndex}].cpNameId`,
        id: `${path}[${componentPropertyIndex}].cpNameId`,
        type: 'select',
        onChange: onChangeSelect(''),
        value: nameOptions?.find(nameOption => nameOption.id === componentProperty?.cpNameId),
        options: nameOptions,
        isClearable: true,
        validate: {
          ...REQUIRED(),
        },
        label: (
          <BadgeInPublic>
            <Translate contentKey="proEcoApp.project.name" />
          </BadgeInPublic>
        ),
        col: { lg: '2', md: '2' },
      },
      {
        readOnly: isDetail,
        name: `${path}[${componentPropertyIndex}].cpDescriptionId`,
        id: `${path}[${componentPropertyIndex}].cpDescriptionId`,
        type: 'select',
        onChange: onChangeSelect(''),
        value: descriptionOptions?.find(descriptionOption => descriptionOption.id === componentProperty?.cpDescriptionId),
        options: descriptionOptions,
        isClearable: true,
        label: (
          <BadgeInPublic>
            <Translate contentKey="proEcoApp.project.description" />
          </BadgeInPublic>
        ),
        col: { lg: '2', md: '2' },
      },
      ...(materialTypesOptions?.length
        ? [
            {
              readOnly: isDetail,
              name: `${path}[${componentPropertyIndex}].materialTypeId`,
              id: `${path}[${componentPropertyIndex}].materialTypeId`,
              type: 'select',
              onChange: onChangeSelect(''),
              value: materialTypesOptions?.find(materialTypesOption => materialTypesOption.id === componentProperty?.materialTypeId),
              options: materialTypesOptions,
              isClearable: true,
              label: isGroup ? <Translate contentKey="proEcoApp.project.materialTypeGroup" />: <Translate contentKey="proEcoApp.project.materialTypeId" />,
              col: { lg: '2', md: '2' },
            },
          ]
        : []),
      {
        autoComplete: 'off',
        readOnly: false,
        name: `${path}[${componentPropertyIndex}].quantity`,
        id: `${path}[${componentPropertyIndex}].quantity`,
        value: quantity,
        className: 'quantity-disable',
        onChange: ev => ev.preventDefault(),
        onKeyPress: ev => ev.preventDefault(),
        type: 'text',
        label: (
          <BadgeInPublic>
            <Translate contentKey="proEcoApp.project.quantity" />
          </BadgeInPublic>
        ),
        col: { lg: '2', md: '2' },
      },
      ...(!isInterior && !NONE_BUILDING_TYPE?.includes(componentTypeId)
        ? [
            {
              autoComplete: 'off',
              readOnly: isDetail,
              name: `${path}[${componentPropertyIndex}].buildingTypeId`,
              id: `${path}[${componentPropertyIndex}].buildingTypeId`,
              type: 'select',
              onChange: onChangeSelect(''),
              isClearable: true,
              value: buildingTypeOptions?.find(buildingTypeOption => buildingTypeOption.value === componentProperty?.buildingTypeId),
              options: buildingTypeOptions,
              label: <Translate contentKey="proEcoApp.project.buildingTypeId" className={'mr-2'} />,
              validate: {
                ...REQUIRED(),
              },
              col: {
                lg: '2',
                md: '2',
              },
            },
          ]
        : []),
      {
        autoComplete: 'off',
        readOnly: isDetail,
        name: `${path}[${componentPropertyIndex}].labour`,
        id: `${path}[${componentPropertyIndex}].labour`,
        onChange: handleChangeLabour,
        onWheel: event => event.currentTarget.blur(),
        value: '' + componentProperty?.labour,
        label: <Translate contentKey="proEcoApp.project.labourCost" />,
        appendText: (
          <span className={'unit-append'}>
            <Translate contentKey="currency.PLN" />
          </span>
        ),
        validate: {
          ...MIN(0),
          ...REQUIRED(),
        },
        min: 0,
        type: 'decimalNumber',
        col: {
          lg: '2',
          md: '2',
        },
      },
      {
        autoComplete: 'off',
        readOnly: true,
        name: `${path}[${componentPropertyIndex}].price`,
        id: `${path}[${componentPropertyIndex}].price`,
        value: componentProperty?.totalPrice,
        label: (
          <BadgeInPublic>
            <Translate contentKey="proEcoApp.project.totalNetPrice" />
          </BadgeInPublic>
        ),
        appendText: (
          <span className={'unit-append'}>
            <Translate contentKey="currency.PLN" />
          </span>
        ),
        col: {
          lg: '2',
          md: '2',
        },
      },
      {
        autoComplete: 'off',
        readOnly: isDetail,
        name: `${path}[${componentPropertyIndex}].uploadedImageId`,
        id: `${path}[${componentPropertyIndex}].uploadedImageId`,
        type: 'select',
        onChange: onChangeSelect(''),
        isClearable: true,
        value: imageOptions?.find(imageOption => imageOption.value === componentProperty?.uploadedImageId),
        options: imageOptions,
        label: <Translate contentKey="proEcoApp.project.uploadedImageId" className={'mr-2'} />,
        col: {
          lg: '2',
          md: '2',
        },
        components: {
          Option: CustomOption,
        },
      },
      {
        autoComplete: 'off',
        readOnly: isDetail,
        name: `${path}[${componentPropertyIndex}].imageLayerName`,
        id: `${path}[${componentPropertyIndex}].imageLayerName`,
        type: 'select',
        onChange: selectPropertyOption(`${path}[${componentPropertyIndex}]`),
        isClearable: true,
        value: layerOptions?.find(layer => layer.value === componentProperty?.imageLayerName),
        options: layerOptions || [],
        label: (
          <BadgeInPublic>
            <Translate contentKey="proEcoApp.project.imageLayerName" className={'mr-2'} />
            {componentProperty?.loading ? (
              <Spinner className={'uploaded'} />
            ) : componentProperty?.imagePath ? (
              <FontAwesomeIcon icon={faCircleCheck} className={'uploaded'} />
            ) : (
              ''
            )}
          </BadgeInPublic>
        ),
        col: {
          lg: '4',
          md: '4',
        },
      },
    ];
    const returnComponentLabel = useMemo(() => {
      if (componentPropertyIndex === 0) {
        return ``;
      }
      return `${counter}${componentPropertyIndex + 1} ${componentTypeLabel} `;
    }, [counter, componentTypeLabel, componentPropertyIndex]);

    const returnComponentPropertyClass = useMemo(() => {
      if (componentPropertyIndex === 0) {
        return '';
      }
      return 'ml-5';
    }, [componentPropertyIndex, isInterior]);

    return (
      <>
        <div key={componentProperty?.id ?? componentProperty?.key} className={returnComponentPropertyClass}>
          {returnComponentLabel && <div className={'home-object-title component-header'}>{returnComponentLabel}</div>}
          <DeleteRowWrapper showDelete={showDelete} handleDelete={() => onRemoveRow(path, componentPropertyIndex)}>
            <Row>
              <PartForm fields={fieldComponentProperty} />
            </Row>
          </DeleteRowWrapper>
          <SubComponentProperty
            subComponentProperties={componentProperty?.subComponentProperties}
            componentTypeId={componentTypeId}
            isDetail={isDetail}
            path={`${path}[${componentPropertyIndex}]`}
            subComponentPropertyTypeOptions={subComponentPropertyTypeOptions}
          />

          {CP_HAS_ADDITIONAL_MATERIALS?.includes(componentTypeId) && (
            <ComponentPropertyAdditionalMaterials
              {...{
                componentPropertyAdditionalMaterials: componentProperty?.componentPropertyAdditionalMaterials,
                componentTypeId,
                componentTypeLabel,
                path: `${path}[${componentPropertyIndex}].componentPropertyAdditionalMaterials`,
                isDetail,
              }}
            />
          )}

          {CP_HAS_CONSTRUCTIONS?.includes(componentTypeId) && (
            <ComponentPropertyConstructions
              {...{
                componentPropertyConstructions: componentProperty?.componentPropertyConstructions,
                componentTypeLabel,
                componentTypeId,
                path: `${path}[${componentPropertyIndex}].componentPropertyConstructions`,
                isDetail,
              }}
            />
          )}
        </div>
      </>
    );
  }
);

export default ComponentPropertyItem;
