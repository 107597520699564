import { IPropertyOptionTypePrice } from 'app/shared/model/property-option-type-price.model';

export interface IWallColorType {
  id?: number | string;
  name?: string;
  description?: string | null;
  enumKey?: string;
  isActive?: boolean;
  price?: number | null;
  imagePath?: string | null;
  imageUrl?: string | null;
  propertyPrice?: IPropertyOptionTypePrice | null;
}

export const defaultValue: Readonly<IWallColorType> = {
  isActive: false,
};
