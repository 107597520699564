import React, { FC, memo, ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

type IDeleteRowWrapper = {
  showDelete?: boolean;
  handleDelete?: () => void;
  children?: ReactNode;
};
export const DeleteRowWrapper: FC<IDeleteRowWrapper> = ({ showDelete, handleDelete, children }) => {
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
  return (
    <div className={'w-100'} onMouseLeave={() => setIsShowDelete(false)} onMouseEnter={() => setIsShowDelete(true)}>
      <div className={`position-relative w-100`}>
        {children}
        {showDelete && isShowDelete && (
          <div className="btn-trash last-row">
            <FontAwesomeIcon onClick={handleDelete} icon={faTrashAlt} />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(DeleteRowWrapper);
