import { useEffect } from 'react';
import { getEntities as getComponentType } from 'app/entities/component-type/component-type.reducer';
import { DEFAULT_OFFER_PARAM, defaultPagination } from 'app/shared/util/pagination.constants';
import { getEntities as getRoomType } from 'app/entities/room-type/room-type.reducer';
import { getEntities as getEquipmentTypes } from 'app/entities/equipment-type/equipment-type.reducer';
import { getEntities as getWallColorTypes } from 'app/entities/wall-color-type/wall-color-type.reducer';
import { getEntities as getOffers } from 'app/entities/offer/offer.reducer';
import { getEntities as getVats } from 'app/entities/vat/vat.reducer';
import { useAppDispatch } from 'app/config/store';
import { getDictEnumsIfNeeded } from 'app/entities/translate-dict-item/translate-dict-item.reducer';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import { getEntities as getMaterialTypes } from 'app/entities/material-type/material-type.reducer';
import { getEntities as getOptionNames } from 'app/entities/component-property-name/component-property-name.reducer';
import { getEntities as getOptionDescriptions } from 'app/entities/component-property-description/component-property-description.reducer';
import { getEntities as getCategoriesDetails } from 'app/entities/category-detail/category-detail.reducer';
import { CATEGORY_TYPE } from 'app/config/constants';

export const useFetchList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getComponentType(defaultPagination));
    dispatch(getRoomType(defaultPagination));
    dispatch(
      getCategoriesDetails({
        category: [
          CATEGORY_TYPE.FLOOR_TYPE,
          CATEGORY_TYPE.INTERNAL_WALL_TYPE,
          CATEGORY_TYPE.ROOF_TYPE,
          CATEGORY_TYPE.FACADE_TYPE,
          CATEGORY_TYPE.EXTERIOR_DOOR_TYPES,
          CATEGORY_TYPE.WINDOW_TYPES,
          CATEGORY_TYPE.HYDRAULICS_TYPES,
          CATEGORY_TYPE.ELECTRICIAN_ADDON_TYPES,
          CATEGORY_TYPE.PLUMBING_HEAT_TYPES,
          CATEGORY_TYPE.ELECTRICIAN_TYPE_INTELLIGENT_HOME_TYPES,
          CATEGORY_TYPE.VENTILATION_TYPES,
          CATEGORY_TYPE.AIR_CONDITIONING_TYPES,
          CATEGORY_TYPE.ELECTRICIAN_TYPES,
          CATEGORY_TYPE.INTERNAL_WALL_ADDITIONAL_MATERIAL_TYPES,
          CATEGORY_TYPE.INTERNAL_WALL_CONSTRUCTIONS,
          CATEGORY_TYPE.FACADE_ADDITIONAL_MATERIAL_TYPE,
          CATEGORY_TYPE.FACADE_CONSTRUCTION_TYPE,
          CATEGORY_TYPE.ROOF_ADDITIONAL_MATERIAL_TYPES,
          CATEGORY_TYPE.ROOF_CONSTRUCTION_TYPE,
          CATEGORY_TYPE.FLOOR_CONSTRUCTIONS,
          CATEGORY_TYPE.STEP2_INTERIOR_FLOOR_TYPES,
          CATEGORY_TYPE.STEP2_INTERIOR_LIGHTING_TYPES,
        ],
        ...defaultPagination,
      })
    );
    dispatch(getEquipmentTypes(defaultPagination));
    dispatch(getWallColorTypes(defaultPagination));
    dispatch(getOffers({ ...defaultPagination, ...DEFAULT_OFFER_PARAM }));
    dispatch(getVats(defaultPagination));
    dispatch(getDictEnumsIfNeeded(TRANSLATED_DICTS.PRICE_TYPES));
    dispatch(getMaterialTypes(defaultPagination));
    dispatch(getOptionNames(defaultPagination));
    dispatch(getOptionDescriptions(defaultPagination));
  }, []);
};
