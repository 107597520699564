import { useAppSelector } from 'app/config/store';

export const useEntityInformation = (dictListItemTranslate: string, isCategoryDetail: boolean) => {
  const loading = useAppSelector(state => (isCategoryDetail ? state.categoryDetail.loading : state[dictListItemTranslate].loading));
  const updateSuccess = useAppSelector(state =>
    isCategoryDetail ? state.categoryDetail.updateSuccess : state[dictListItemTranslate].updateSuccess
  );
  const dictTypeEntity = useAppSelector(state => (isCategoryDetail ? state.categoryDetail.entity : state[dictListItemTranslate].entity));
  const updating = useAppSelector(state => (isCategoryDetail ? state.categoryDetail.updating : state[dictListItemTranslate].updating));
  return { loading, updateSuccess, dictTypeEntity, updating };
};
