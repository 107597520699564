import React, { useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import FurnishingForm from 'app/modules/administration/home-object-v2/Components/home-object-sections/furnishing-form';
import { reset } from 'app/entities/project/project.reducer';
import { MENU } from 'app/config/constants';

import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const FurnishingStep = ({ isDetail = false }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector(state => state.project.updateSuccess);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(reset());
      history.push(MENU.ADMIN.HOME_OBJECT);
    }
  }, [updateSuccess]);

  return (
    <Row className="justify-content-center">
      <Col xs={12}>
        <Card>
          <FurnishingForm isDetail={isDetail} />
        </Card>
      </Col>
    </Row>
  );
};
