import React, { FC } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import { DictionaryItemList } from "app/modules/dictionaries/Components/dictionaryItemList";
import DictionaryItemUpdate from "app/modules/dictionaries/Components/dictionaryItemUpdate";

interface IDictionaryDictionaryRoutes {
  getEntity?: any;
  reset?: () => void;
  createEntity?: any;
  updateEntity?: any;
  getEntities?: any;
  deleteEntity?: any;
  restoreEntity?: any;
  additionalFields?: any;
  additionalTableColumns?: any;
}

const DictionaryRoutes: FC<IDictionaryDictionaryRoutes> = (
  {
    getEntity,
    getEntities,
    updateEntity,
    createEntity,
    deleteEntity,
    restoreEntity,
    reset,
    additionalFields,
    additionalTableColumns
  }: IDictionaryDictionaryRoutes) => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <ErrorBoundaryRoute
        exact path={`${url}/new`} component={DictionaryItemUpdate}
        componentProps={{ reset, createEntity, additionalFields }}
      />
      <ErrorBoundaryRoute
        exact path={`${url}/:id/edit`} component={DictionaryItemUpdate}
        componentProps={{ getEntity, reset, createEntity, updateEntity, additionalFields }}
      />
      <ErrorBoundaryRoute
        exact path={`${url}/:id`} component={DictionaryItemUpdate}
        componentProps={{ getEntity, reset, additionalFields, isReadOnly: true }}
      />
      <ErrorBoundaryRoute
        path={url} component={DictionaryItemList}
        componentProps={{ deleteEntity, getEntities, reset, restoreEntity, additionalTableColumns }}
      />
    </Switch>
  );
}

export default DictionaryRoutes;
