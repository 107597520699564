import React, { memo, useContext } from 'react';
import ComponentPropertyItem from 'app/modules/administration/home-object-v2/component-property-item';
import AddRowWrapper from 'app/modules/administration/home-object-v2/add-row-wrapper';
import { uniqueId } from 'lodash';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import BadgeInPublic from 'app/shared/layout/BadgeInPublic/BadgeInPublic';

type IComponentProperty = {
  componentProperties: any[];
  componentIndex: number;
  path: string;
  componentTypeLabel: string;
  componentTypeId: number;
  isDetail: boolean;
  showDelete: boolean;
  isInterior: boolean;
};
export const ComponentProperty = memo<IComponentProperty>(
  ({
    componentProperties = [],
    componentIndex,
    path,
    componentTypeLabel,
    componentTypeId,
    isDetail = false,
    showDelete = false,
    isInterior = false,
  }) => {
    const { onAddRow } = useContext(HomeObjectContext);
    return (
      <>
        <div className={'home-object-title mt-48'}>
          <AddRowWrapper
            handleAdd={() =>
              onAddRow(path, {
                key: uniqueId(),
                componentPropertyTypeId: componentTypeId,
              })
            }
            showAdd={!isDetail}
          >
            <BadgeInPublic>
              <span className={'component-header'}>{`${isInterior ? '' : `${componentIndex + 1}.`} ${componentTypeLabel}`}</span>
            </BadgeInPublic>
          </AddRowWrapper>
        </div>
        {componentProperties?.map((componentProperty, componentPropertyIndex) => {
          return (
            <ComponentPropertyItem
              key={componentProperty?.id ?? componentPropertyIndex}
              componentProperty={componentProperty}
              componentPropertyIndex={componentPropertyIndex}
              counter={isInterior ? '' : `${componentIndex + 1}.`}
              componentTypeLabel={componentTypeLabel}
              componentTypeId={componentTypeId}
              path={path}
              showDelete={showDelete}
              isDetail={isDetail}
              isInterior={isInterior}
            />
          );
        })}
      </>
    );
  }
);

export default ComponentProperty;
