import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import PageWithTableWrapper from 'app/shared/layout/PageWrapper/PageWithTableWrapper';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import CollapseWithOverflowText from 'app/shared/layout/Collapse/CollapseWithOverflowText';
import { renderButton } from 'app/shared/layout/DynamicColumnTable/Components/Buttons';
import Translate from 'app/shared/layout/Translation/translate';
import { useHistory } from 'react-router';
import DeleteIcon from 'app/shared/layout/Icon/DeleteIcon';
import EditIcon from 'app/shared/layout/Icon/EditIcon';
import { deleteEntity, getEntities } from './component-property-description.reducer';
import { getDictEnumsIfNeeded } from 'app/entities/translate-dict-item/translate-dict-item.reducer';
import { IComponentPropertyDescription } from 'app/shared/model/component-property-description.model';
import ComponentPropertyDescriptionFilter
  from "app/entities/component-property-description/component-property-description-filter";

const TABLE_COLUMN_VISIBILITY_STORAGE_KEY = 'componentPropertyDescription/TABLE_COLUMN_VISIBILITY_STORAGE_KEY';

const permanentColumns = ['descriptionPl', 'descriptionEn'];
const tableColumns = [];

export const ComponentPropertyDescriptionList = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const componentPropertyDescriptionList = useAppSelector(state => state.componentPropertyDescription.entities);
  const loading = useAppSelector(state => state.componentPropertyDescription.loading);
  const totalItems = useAppSelector(state => state.componentPropertyDescription.totalItems);
  const updateSuccess = useAppSelector(state => state.componentPropertyDescription.updateSuccess);
  const [searchCriteria, setSearchCriteria] = useState({});

  useEffect(() => {
    dispatch(getDictEnumsIfNeeded(TRANSLATED_DICTS.SYSTEM_SERVICES));
  }, []);

  const getComponentPropertyDescriptionList = params => {
    dispatch(getEntities({ ...params }));
  };

  const renderTableCell = (data: any, fieldId: string) => {
    const value = data[fieldId];
    switch (fieldId) {
      case 'isActive':
        return (
          <td key={fieldId}>
            <Translate contentKey={`status.${value?.toString()}`} />
          </td>
        );
      default:
        return (
          <CollapseWithOverflowText key={fieldId} id={fieldId} text={value} maxLength={50}>
            {value}
          </CollapseWithOverflowText>
        );
    }
  };

  const openEdit = (id: number | string) => event => {
    event.stopPropagation();
    history.push({ pathname: `/admin/dict/component-property-description/${id}`, state: { isUpdate: true } });
  };

  const deleteComponentPropertyDescription = row => event => {
    event.stopPropagation();
    dispatch(deleteEntity(row.id));
  };

  const renderActionCell = (entity: IComponentPropertyDescription) => {
    return (
      <div key="entity-actions" className="d-flex">
        {renderButton(
          openEdit(entity?.id),
          'entity.action.edit',
          <EditIcon />,
          'color-bg',
          `edit-componentPropertyDescription-${entity?.id}`,
          false
        )}
        {renderButton(
          deleteComponentPropertyDescription(entity),
          'entity.action.deactivate',
          <DeleteIcon />,
          'color-bg',
          `delete-componentPropertyDescription-${entity?.id}`,
          false
        )}
      </div>
    );
  };

  const composeOnClickTableRow = entity => () => {
    history.push(`/admin/dict/component-property-description/${entity.id}`);
  };

  const search = params => {
    setSearchCriteria(params);
  };

  return (
    <PageWithTableWrapper
      composeOnClickTableRow={composeOnClickTableRow}
      titleContentKey="proEcoApp.componentPropertyDescription.home.title"
      subTitleContentKey="proEcoApp.componentPropertyDescription.home.subtitle"
      totalItems={totalItems}
      renderActionCell={renderActionCell}
      persistenceKey={TABLE_COLUMN_VISIBILITY_STORAGE_KEY}
      items={[...componentPropertyDescriptionList]}
      permanentColumns={permanentColumns}
      tableColumns={tableColumns}
      renderTableCell={renderTableCell}
      loading={loading}
      i18nGroupName="proEcoApp.componentPropertyDescription"
      defaultSort={'createdAt'}
      defaultOrder={'desc'}
      buttonCreateNew={{
        isLink: true,
        link: '/admin/dict/component-property-description/new',
        translateCreateNew: 'proEcoApp.componentPropertyDescription.home.createLabel',
      }}
      updateSuccess={updateSuccess}
      getEntities={getComponentPropertyDescriptionList}
      searchCriteria={searchCriteria}
      setSearchCriteria={setSearchCriteria}
      canAdvancedSearch
      childrenSearchForm={<ComponentPropertyDescriptionFilter search={search}/>}
      pathArray={[
        { path: 'admin/dashboard', translation: 'global.menu.admin.mainPage' },
        { path: 'admin/dict', translation: 'global.menu.admin.dictionaries' },
        {
          path: 'admin/dict/component-property-description',
          translation: 'global.menu.entities.componentPropertyDescription',
        },
      ]}
    />
  );
};

export default ComponentPropertyDescriptionList;
