import React, { FC, useState } from 'react';
import { Button } from 'reactstrap';
import Translate from '../Translation/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from 'app/config/store';
import { useHistory } from 'react-router';
import { MenuCartDropdown } from 'app/shared/layout/TopNavMenu/Components/MenuCart/MenuCartDropdown';
import { Link, useLocation } from 'react-router-dom';
import MenuMobile from 'app/shared/layout/TopNavMenu/Components/MenuMobile';
import { CartNavigate } from 'app/shared/layout/TopNavMenu/Components/MenuCart/CartNavigate/CartNavigate';
import HomeMenu from 'app/shared/layout/TopNavMenu/Components/HomeMenu/HomeMenu';
import ConfigurationStep from 'app/shared/layout/TopNavMenu/Components/ConfigurationStep/ConfigurationStep';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';

interface IAccountMenu {
  isCart?: boolean;
  isHomeObject?: boolean;
  isConfiguration?: boolean;
}

export const AccountMenuMobile: FC<IAccountMenu> = ({ isCart, isConfiguration, isHomeObject }: IAccountMenu) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const isHiddenConfigurator = pathname === '/configurator' || pathname.includes('offer') || pathname.includes('arrangements');

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [isUserProfile, setIsUserProfile] = useState(false);
  const handleOpenMenu = () => setIsOpenMenu(true);

  const handleOpenUserProfile = () => {
    if (!isAuthenticated) {
      history.push('/login');
    } else {
      setIsOpenMenu(true);
      setIsUserProfile(true);
    }
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
    setIsUserProfile(false);
  };

  return (
    <div className="account-menu">
      <div onClick={handleOpenUserProfile} className="welcome-button">
        <img src="content/images/user.png" alt="user" className="header-icon" />
        <FontAwesomeIcon className="arrow" icon={faAngleDown} />
      </div>
      <MenuCartDropdown />
      {
        <Link to="/content/files/subsidies.pdf" target="_blank" rel="noreferrer">
          <img src="content/images/euLogo.svg" alt="EU logo" className="eu-logo-image-mobile" />
        </Link>
      }
      <Button onClick={handleOpenMenu} className={'btn-collapse mobile-menu-btn'} aria-label="btn-collapse">
        <FontAwesomeIcon icon={faBars} />
      </Button>
      <MenuMobile isOpen={isOpenMenu} toggleClose={handleCloseMenu} isUserProfile={isUserProfile}>
        {!isHiddenConfigurator && !isCart && (
          <Link to="/configurator" className="btn btn-first-type top-button mx-4 w-auto" onClick={handleCloseMenu}>
            <Translate contentKey={'global.menu.configurator'} />
          </Link>
        )}
        {isCart && <CartNavigate />}
        {!isHomeObject && !isCart && !isConfiguration && <HomeMenu toggleClose={handleCloseMenu} />}
      </MenuMobile>
    </div>
  );
};
