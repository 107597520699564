import { useAppSelector } from 'app/config/store';
import { useMemo } from 'react';
import { LANGUAGE } from 'app/config/constants';

export const useUploadedImages = (categoryId?, isDetail = true) => {
  const uploadedImageDict = useAppSelector(state => state.imageOption.entities);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  return useMemo(() => {
    return uploadedImageDict
      ?.filter(item => (isDetail ? true : item.isActive) && (categoryId ? item.categoryId === categoryId : true))
      .map(otherEntity => ({
        ...otherEntity,
        id: otherEntity.id,
        key: otherEntity.id,
        value: otherEntity.id,
        image: otherEntity.uploadedImageUrl,
        label: currentLocale === LANGUAGE.PL ? otherEntity?.namePl : otherEntity?.nameEn,
      }));
  }, [uploadedImageDict, isDetail, currentLocale]);
};
