import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useAppSelector } from 'app/config/store';
import { AvForm } from 'availity-reactstrap-validation';
import { ButtonsWrapper } from 'app/modules/administration/arrangement/Components/arrangement-section/buttons-wrapper';
import { ConfigurationLayoutContext } from 'app/app';
import Translate from 'app/shared/layout/Translation/translate';

import { FurnishingFloorComponent } from 'app/modules/administration/arrangement/Components/arrangement-section/furnishing-floor-component';
import { FurnishingWallRoomColor } from 'app/modules/administration/arrangement/Components/arrangement-section/furnishing-wall-room-color';
import { FurnishingEquipments } from 'app/modules/administration/arrangement/Components/arrangement-section/furnishing-equipments';
import { FurnishingLightings } from 'app/modules/administration/arrangement/Components/arrangement-section/furnishing-lightings';

const iconImageUrl = 'content/images/logo-icon.svg';

const Furnishing = ({ isNew, isEdit, isConfiguration, isShare, offerId }) => {
  const { setCurrentTab, setErrorMessage, errorMessage, currentTab, changeCurrentTab, setChangeCurrentTab } =
    useContext(ConfigurationLayoutContext);

  const arrangementName = useAppSelector(state => state.arrangement?.entity?.arrangement?.arrangementName);
  const floorComponents = useAppSelector(state => state.arrangement.entity.interiorSection.floorComponent);

  const wallRoomComponents = useAppSelector(state => state.arrangement.entity.interiorSection.wallRoomComponents);

  const roomEquipments = useAppSelector(state => state.arrangement.entity.interiorSection?.furnishingSubsection?.roomEquipments);

  const roomLightings = useAppSelector(state => state.arrangement.entity.interiorSection.roomLightings);

  const formRef = useRef(null);

  useEffect(() => {
    if (changeCurrentTab !== undefined && changeCurrentTab !== currentTab) {
      formRef?.current?.click();
    }
  }, [changeCurrentTab]);

  const handleInvalidSubmit = () => {
    setChangeCurrentTab(undefined);
    setErrorMessage('proEcoApp.arrangement.validateError');
  };

  const handleSubmit = event => {
    event.preventDefault();
    setErrorMessage('');
    if (changeCurrentTab !== undefined) {
      setCurrentTab(changeCurrentTab);
    } else {
      setCurrentTab(prev => prev + 1);
    }
    setChangeCurrentTab(undefined);
  };
  const counterWallRoomNumber = useMemo(() => ((floorComponents?.length ?? 0) < 1 ? 1 : 2), [floorComponents]);

  return (
    <AvForm onValidSubmit={handleSubmit} className="position-relative" onInvalidSubmit={handleInvalidSubmit}>
      <input type="submit" style={{ display: 'none' }} ref={formRef} />

      <div className={'arrangement-header'}>
        <img src={iconImageUrl} alt="icon" />
        {arrangementName}
      </div>
      <FurnishingFloorComponent floorComponents={floorComponents} isEdit={isEdit} isConfiguration={isConfiguration} isNew={isNew} />

      <FurnishingWallRoomColor
        isEdit={isEdit}
        isConfiguration={isConfiguration}
        isNew={isNew}
        wallRoomComponents={wallRoomComponents}
        counter={counterWallRoomNumber}
      />
      <FurnishingEquipments
        isEdit={isEdit}
        isConfiguration={isConfiguration}
        isNew={isNew}
        roomEquipments={roomEquipments}
        counter={counterWallRoomNumber + 1}
      />
      <FurnishingLightings
        isEdit={isEdit}
        isConfiguration={isConfiguration}
        isNew={isNew}
        roomLightings={roomLightings}
        counter={counterWallRoomNumber + 2}
      />

      <div>{errorMessage ? <Translate className={'invalid-feedback'} contentKey={errorMessage} /> : null}</div>
      <ButtonsWrapper
        isShare={isShare}
        isNew={isNew}
        isEdit={isEdit}
        isConfiguration={isConfiguration}
        offerId={offerId}
        withSummary={false}
      />
    </AvForm>
  );
};

export default Furnishing;
