import React, { FC, useMemo } from 'react';
import ItemCollapse from 'app/modules/administration/arrangement/Components/ItemCollapse';

import PropertiesOptions from 'app/modules/administration/arrangement/Components/arrangement-section-summary/select-properties-options/properties-options';
import { Translate } from 'react-jhipster';
import { HeaderCard } from 'app/modules/administration/arrangement/Components/arrangement-section/header-card';
import { useWallColorTypeOptions } from 'app/modules/administration/home-object-v2/hooks/useWallColorTypeOptions';

import { FURNISHING_STEP } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import { useOptionDescriptions, useOptionNames } from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';
import SelectPropertyName from 'app/modules/administration/arrangement/Components/select-properties-options/select-property-name';

interface IWallRoomPart {
  wallRoom: any;
  wallRoomIndex: number;
  isDetail?: boolean;
  path?: string;
}

export const WallRoomPart: FC<IWallRoomPart> = ({ wallRoom, wallRoomIndex, path }: IWallRoomPart) => {
  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const wallRoomColors = useMemo(
    () =>
      wallRoom?.wallRoomColors
        ?.map((wallRoomColor, wallRoomColorIndex) => {
          return {
            ...wallRoomColor,
            label:
              nameOptions?.find(nameOption => nameOption.id === wallRoomColor?.cpNameId)?.label,
            children: (
              <>
                <div className="properties-header-description">
                  {descriptionOptions?.find(descriptionOption => descriptionOption?.id === wallRoomColor?.cpDescriptionId)?.label}
                </div>
              </>
            ),
            path: `${path}[${wallRoomColorIndex}].isSelected`,
          };
        })
        ?.filter(item => item?.isSelected),
    [wallRoom?.wallRoomColors, nameOptions, path]
  );

  return (
    <div className={'ml-4'}>
      <ItemCollapse
        header={isOpen => (
          <HeaderCard
            label={
              <span>
                <Translate contentKey="proEcoApp.project.wall" /> {wallRoomIndex + 1}
              </span>
            }
            isOpen={isOpen}
            isHeader={false}
          />
        )}
        id={`subsection-${wallRoom?.wallCountOrder}`}
        defaultOpen={false}
      >
        <PropertiesOptions options={wallRoomColors} disabled={true} isRequiredSection={false} currentStep={FURNISHING_STEP} />
      </ItemCollapse>
    </div>
  );
};
