import React from 'react';
import { getEntities, reset, restoreEntity, deactivateEntity, updateEntity, createEntity, getEntity } from './component-type.reducer';
import DictionaryRoutes from 'app/modules/dictionaries/Components/dictionaryRoutes';

const Routes = () => {
  return (
    <DictionaryRoutes
      updateEntity={updateEntity}
      createEntity={createEntity}
      getEntities={getEntities}
      getEntity={getEntity}
      reset={reset}
      deleteEntity={deactivateEntity}
      restoreEntity={restoreEntity}
    />
  );
};

export default Routes;
