import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { convertCurrency } from 'app/shared/util/entity-utils';
import './select-properties-option.scss';
import Translate from 'app/shared/layout/Translation/translate';
import { Button } from 'reactstrap';

import { ConfigurationLayoutContext } from 'app/app';
import { updateSelectedOption } from 'app/modules/administration/arrangement/arrangement.reducer';
import { useAppDispatch } from 'app/config/store';
import { SHELL_AND_CORE_STEP } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';

interface IPropertiesOptionPart {
  option: any;
  disabled?: boolean;
  onlyLabel?: boolean;
  currentStep?: number;
  path?: string;
  optionIdLabel?: string;
  vatRate?: any;
}

export const PropertiesOption: FC<IPropertiesOptionPart> = ({ option, path, disabled, vatRate, currentStep, optionIdLabel }) => {
  const dispatch = useAppDispatch();
  const disabledClassName = disabled ? 'disabled' : '';
  const { setCurrentTab, setScrollTo } = useContext(ConfigurationLayoutContext);

  const isObligatory = useMemo(() => currentStep === SHELL_AND_CORE_STEP, [currentStep]);
  const onEdit = () => {
    setCurrentTab(currentStep);
    setScrollTo(optionIdLabel);
  };
  const onDelete = () => {
    if (isObligatory) {
      return;
    }

    dispatch(updateSelectedOption({ name: path, value: !option?.isSelected }));
  };

  return (
    <div>
      <div className={'properties-option-wrapper'}>
        <div className={`properties-option ${disabledClassName}`}>
          <div className="properties-wrapper">
            <div className={'properties-title'}>
              <span>{option.label}</span>
            </div>
            {option?.children}
            <div>
              <span className="properties-price">{convertCurrency(option?.totalPrice || option?.price, vatRate)}</span>
            </div>
          </div>
        </div>
        <div className={'properties-button'}>
          <div>
            <Button type="button" color="option-type" onClick={onEdit}>
              <Translate contentKey="entity.action.edit" />
            </Button>
          </div>
          <div>
            <Button type="button" color="inactive-option-type" onClick={onDelete}>
              <Translate contentKey="entity.action.delete" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
