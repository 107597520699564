import React, {memo, useCallback, useContext, useEffect, useMemo} from 'react';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import Translate from 'app/shared/layout/Translation/translate';
import {
  HomeObjectContext,
  LayerContext,
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import DeleteRowWrapper from 'app/modules/administration/home-object-v2/delete-row-wrapper';
import {useAppDispatch} from 'app/config/store';
import {removeOptionHomeObject, updateCPTotalPrice, updateHomeObject} from 'app/entities/project/project.reducer';
import {useOptions} from 'app/shared/hooks/useOptions';
import {TRANSLATED_DICTS_KEYS} from 'app/entities/translate-dict-item/constants';
import {useEquipmentTypes} from 'app/modules/administration/home-object-v2/hooks/useEquipmentTypes';
import {Row, Spinner} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import {
    onChangePositiveInput,
    REQUIRED,
} from 'app/shared/util/validation';
import {debounce} from 'lodash';
import {CATEGORIES} from 'app/config/constants';
import {useUploadedImages} from 'app/modules/administration/home-object-v2/hooks/useUploadedImages';
import {CustomOption} from 'app/shared/layout/CustomInput/Components/CustomSelect';
import BadgeInPublic from 'app/shared/layout/BadgeInPublic/BadgeInPublic';
import EquipmentMembers from 'app/modules/administration/home-object-v2/equipment-members';
import {getNumber, roundPrice} from 'app/shared/util/entity-utils';
import {useMaterialOptions} from 'app/modules/administration/home-object-v2/hooks/useMaterialOptions';
import {
  useOptionDescriptions,
  useOptionNames
} from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';

const mapTypeToCategoryId = isSet => {
  if (isSet) {
    return CATEGORIES.SETS_TYPES;
  }

  return CATEGORIES.EQUIPMENT_TYPES;
};

type IEquipmentItem = {
  equipment: any;
  equipmentIndex: number;
  path: string;
  isDetail: boolean;
  showDelete: boolean;
  isSet: boolean;
}
export const EquipmentItem = memo<IEquipmentItem>(({
                                                     equipment,
                                                     equipmentIndex,
                                                     path,
                                                     isDetail = false,
                                                     showDelete,
                                                     isSet = false
                                                   }) => {
  const dispatch = useAppDispatch();
  const {
    onChangeSelect,
    onChangeInput,
    onRemoveRow,
    selectPropertyOption,
    calcTotalPrice,
    onChangeInputNumber,
  } = useContext(HomeObjectContext);
  const {priceTypesOptions} = useOptions(TRANSLATED_DICTS_KEYS.PRICE_TYPES, true);
  const materialTypesOptions = useMaterialOptions({isDetail, hasMaterial: true});
  const imageOptions = useUploadedImages(mapTypeToCategoryId(isSet), isDetail);
  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const equipmentTypeOptions = useEquipmentTypes(isDetail);
  const equipmentType = useMemo(
    () => equipmentTypeOptions.find(equipmentTypeOption => equipmentTypeOption.id === equipment?.equipmentTypeId),
    [equipmentTypeOptions, equipment?.equipmentTypeId]
  );

  const {layerInteriors} = useContext(LayerContext);

  const totalPriceSum = useMemo(() => {
    if (!isSet) {
      return 0;
    }
    return getNumber(
      equipment?.equipmentMembers?.reduce((sum, equipmentMember) => sum + getNumber(equipmentMember?.price, 0), 0),
      0
    );
  }, [equipment?.equipmentMembers, isSet]);

  const updateTotalPrice = debounce((quantity, netPurchasingPrice) => {
    const totalPrice = calcTotalPrice(quantity, netPurchasingPrice, equipment?.labour);
    dispatch(updateHomeObject({name: `${path}[${equipmentIndex}].price`, value: totalPrice}));
  }, 300);

  useEffect(() => {
    if (isSet) {
      const totalPrice = roundPrice(totalPriceSum + getNumber(equipment?.labour, 0));
      dispatch(
        updateHomeObject({
          name: `${path}[${equipmentIndex}].price`,
          value: totalPrice,
        })
      );
    }
  }, [totalPriceSum, equipment?.labour, isSet]);


  const handleChangeInput = useCallback(
    ev => {
      onChangeInputNumber(ev);
      updateTotalPrice(ev.target.value, equipmentType?.propertyPrice?.netPurchasingPrice);
    },
    [onChangeInputNumber, updateTotalPrice, equipmentType?.propertyPrice?.netPurchasingPrice]
  );

  const handleChangeType = useCallback(
    (objSelected, meta) => {
      if (meta.action === 'clear' && objSelected === null) {
        dispatch(
          removeOptionHomeObject({
            keys: [meta?.name],
          })
        );
      } else {
        onChangeSelect()(objSelected, meta);
      }

      updateTotalPrice(equipment?.quantity, objSelected?.propertyPrice?.netPurchasingPrice);
    },
    [removeOptionHomeObject, updateTotalPrice, onChangeSelect, equipment?.quantity]
  );

  const handleChangeLabour = useCallback(
    ev => {
      onChangeInputNumber(ev);
      const totalPrice = roundPrice(totalPriceSum + getNumber(ev?.target?.value, 0));

      dispatch(updateHomeObject({name: `${path}[${equipmentIndex}].price`, value: totalPrice}));
    },
    [updateTotalPrice, onChangeInputNumber]
  );


  const materialType = useMemo(() => {
    return materialTypesOptions?.find(materialTypesOption => materialTypesOption.id === (isSet ? equipment?.materialTypeId : equipmentType?.materialTypeId))
  }, [materialTypesOptions, isSet, equipmentType, equipment?.materialTypeId]);

  const fieldComponent = [
    {
      readOnly: isDetail,
      name: `${path}[${equipmentIndex}].cpNameId`,
      id: `${path}[${equipmentIndex}].cpNameId`,
      type: 'select',
      onChange: onChangeSelect(''),
      value: nameOptions?.find(nameOption => nameOption.id === equipment?.cpNameId),
      options: nameOptions,
      isClearable: true,
      validate: {
        ...REQUIRED(),
      },
      label: <Translate contentKey="proEcoApp.project.name"/>,
      col: {lg: '2', md: '2'},
    },
    {
      readOnly: isDetail,
      name: `${path}[${equipmentIndex}].cpDescriptionId`,
      id: `${path}[${equipmentIndex}].cpDescriptionId`,
      type: 'select',
      onChange: onChangeSelect(''),
      value: descriptionOptions?.find(descriptionOption => descriptionOption.id === equipment?.cpDescriptionId),
      options: descriptionOptions,
      isClearable: true,
      label: <Translate contentKey="proEcoApp.project.description"/>,
      col: {lg: '2', md: '2'},
    },
    ...(isSet
      ? []
      : [
        {
          readOnly: isDetail,
          name: `${path}[${equipmentIndex}].equipmentTypeId`,
          id: `${path}[${equipmentIndex}].equipmentTypeId`,
          type: 'select',
          onChange: handleChangeType,
          value: equipmentType,
          options: equipmentTypeOptions,
          isClearable: true,
          validate: {
            ...REQUIRED(),
          },
          label: (
            <BadgeInPublic>
              {isSet ? (
                <Translate contentKey="proEcoApp.project.setTypeId"/>
              ) : (
                <Translate contentKey="proEcoApp.project.equipmentTypeId"/>
              )}
            </BadgeInPublic>
          ),
          col: {lg: '2', md: '2'},
        },
      ]),
    {
      readOnly: !isSet,
      name: `${path}[${equipmentIndex}].materialTypeId`,
      id: `${path}[${equipmentIndex}].materialTypeId`,
      type: 'select',
      onChange: onChangeSelect(''),
      value: materialType,
      options: materialTypesOptions,
      isClearable: true,
      label: <Translate contentKey="proEcoApp.project.materialTypeGroup"/>,
      col: {lg: '2', md: '2'},
    },

    ...(isSet
      ? []
      : [
        {
          readOnly: true,
          name: `${path}[${equipmentIndex}].priceTypeId`,
          id: `${path}[${equipmentIndex}].priceTypeId`,
          type: 'select',
          onChange: onChangeSelect(''),
          value: priceTypesOptions?.find(priceTypesOption => priceTypesOption.id === equipmentType?.propertyPrice?.priceTypeId),
          options: priceTypesOptions,
          isClearable: true,
          label: <Translate contentKey="proEcoApp.project.priceTypeId"/>,
          col: {lg: '2', md: '2'},
        },
        {
          autoComplete: 'off',
          readOnly: isDetail,
          type: 'number',
          onKeyPress: onChangePositiveInput,
          name: `${path}[${equipmentIndex}].quantity`,
          id: `${path}[${equipmentIndex}].quantity`,
          onChange: handleChangeInput,
          min: 0,
          validate: {
            ...REQUIRED(),
          },
          onWheel: event => event.currentTarget.blur(),
          value: equipment.quantity,
          label: <Translate contentKey="proEcoApp.project.quantity" />,
          col: { lg: '2', md: '2' },
        },
        {
          autoComplete: 'off',
          readOnly: true,
          name: `${path}[${equipmentIndex}].pricePerUnit`,
          id: `${path}[${equipmentIndex}].pricePerUnit`,
          value: equipmentType?.propertyPrice?.netPurchasingPrice,
          label: <Translate contentKey="proEcoApp.project.pricePerUnit"/>,
          appendText: (
            <span className={'unit-append'}>
                <Translate contentKey="currency.PLN"/>
              </span>
          ),
          col: {lg: '2', md: '2'},
        },
      ]),
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: `${path}[${equipmentIndex}].labour`,
      id: `${path}[${equipmentIndex}].labour`,
      onChange: handleChangeLabour,
      value: equipment?.labour,
      min: 0,
      onWheel: event => event.currentTarget.blur(),
      label: <Translate contentKey="proEcoApp.project.labourCost"/>,
      type: 'decimalNumber',
      validate: {
        ...REQUIRED(),
      },
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN"/>
        </span>
      ),
      col: {lg: '2', md: '2'},
    },
    {
      autoComplete: 'off',
      readOnly: true,
      name: `${path}[${equipmentIndex}].price`,
      id: `${path}[${equipmentIndex}].price`,
      onChange: onChangeInput,
      value: equipment?.price,
      label: (
        <BadgeInPublic>
          <Translate contentKey="proEcoApp.project.totalNetPrice"/>
        </BadgeInPublic>
      ),
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN"/>
        </span>
      ),
      col: {lg: '2', md: '2'},
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: `${path}[${equipmentIndex}].uploadedImageId`,
      id: `${path}[${equipmentIndex}].uploadedImageId`,
      type: 'select',
      onChange: onChangeSelect(''),
      isClearable: true,
      value: imageOptions?.find(imageOption => imageOption.value === equipment?.uploadedImageId),
      options: imageOptions,
      label: (
        <BadgeInPublic>
          <Translate contentKey="proEcoApp.project.uploadedImageId" className={'mr-2'}/>
        </BadgeInPublic>
      ),
      col: {
        lg: '2',
        md: '2',
      },
      components: {
        Option: CustomOption,
      },
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: `${path}[${equipmentIndex}].imageLayerName`,
      id: `${path}[${equipmentIndex}].imageLayerName`,
      type: 'select',
      onChange: selectPropertyOption(`${path}[${equipmentIndex}]`),
      isClearable: true,
      value: layerInteriors?.find(layer => layer.value === equipment?.imageLayerName),
      options: layerInteriors || [],
      label: (
        <BadgeInPublic>
          <Translate contentKey="proEcoApp.project.imageLayerName" className={'mr-2'}/>
          {equipment?.loading ? (
            <Spinner className={'uploaded'}/>
          ) : equipment?.imagePath ? (
            <FontAwesomeIcon icon={faCircleCheck} className={'uploaded'}/>
          ) : (
            ''
          )}
        </BadgeInPublic>
      ),
      col: {lg: '4', md: '4'},
    },
  ];

  return (
    <div>
      <DeleteRowWrapper showDelete={showDelete} handleDelete={() => onRemoveRow(path, equipmentIndex)}>
        <Row>
          <PartForm fields={fieldComponent}/>
        </Row>
        {isSet && <EquipmentMembers equipmentMembers={equipment?.equipmentMembers}
                                    path={`${path}[${equipmentIndex}].equipmentMembers`}/>}
      </DeleteRowWrapper>
    </div>
  );
});

export default EquipmentItem;
