import React, { FC, useContext, useMemo, useRef } from 'react';
import { NavbarLayoutContext } from 'app/shared/layout/DefaultLayout/default-layout';
import { useAppSelector } from 'app/config/store';
import { useLoadImage } from 'app/modules/administration/arrangement/hooks/useLoadImage';
import { useCalcScale } from 'app/modules/administration/arrangement/hooks/useCalcScale';
import ImageBuildingWrapper from 'app/modules/administration/arrangement/Components/image-building/image-building-wrapper';

export interface IImageLayerProps {
  isFixed?: boolean;
  isShow?: boolean;
  isRenderSeason?: boolean;
  isInterior?: boolean;
  backgroundWidth?: number;
}

const ImageBuilding: FC<IImageLayerProps> = ({ isFixed = false, isRenderSeason = false, isInterior = false, isShow, backgroundWidth }) => {
  const arrangement = useAppSelector(state => state.arrangement.entity);
  const { navbarTop } = useContext(NavbarLayoutContext);
  const imageRef = useRef(null);
  const projectSeasonId = useAppSelector(state => state.arrangement.entity?.arrangement?.projectSeasonId);
  const psdImageHeight = arrangement?.psdImageHeight;
  const interiorPsdImageHeight = arrangement?.interiorPsdImageHeight;

  const { loaded, onLoadImage, layerLoadedOptions } = useLoadImage(arrangement, isInterior);
  const width = useMemo(() => (isInterior ? arrangement?.interiorPsdImageWidth : arrangement?.psdImageWidth), [arrangement]);
  const { scale, backgroundLayerRef } = useCalcScale(width, backgroundWidth, isShow);
  const height = useMemo(
    () => (isInterior ? interiorPsdImageHeight : psdImageHeight) * scale,
    [psdImageHeight, interiorPsdImageHeight, scale]
  );
  return (
    <div
      ref={backgroundLayerRef}
      style={{
        width: '100%',
        ...(loaded ? { height: '100%' } : {}),
        ...(isShow
          ? {}
          : {
              position: 'absolute',
              overflow: 'hidden',
              left: '-10000px',
              top: 0,
              visible: 'hidden',
            }),
      }}
    >
      <ImageBuildingWrapper
        height={height}
        navbarTop={navbarTop}
        arrangement={arrangement}
        isFixed={isFixed}
        loaded={loaded}
        imageRef={imageRef}
        onLoadImage={onLoadImage}
        isInterior={isInterior}
        isRenderSeason={isRenderSeason}
        layerLoadedOptions={layerLoadedOptions}
        scale={scale}
        isShow={isShow}
        projectSeasonId={projectSeasonId}
      />
    </div>
  );
};

export default ImageBuilding;
