import React, { FC } from 'react';

import ItemCollapse from 'app/modules/administration/arrangement/Components/ItemCollapse';
import Translate from 'app/shared/layout/Translation/translate';
import { FurnishingLightingItem } from 'app/modules/administration/arrangement/Components/arrangement-section/furnishing-lighting-item';

interface IFurnishingLightings {
  roomLightings: any[];
  counter: number;
  isEdit: boolean;
  isNew: boolean;
  isConfiguration: boolean;
}

export const FurnishingLightings: FC<IFurnishingLightings> = ({ roomLightings, isNew, isEdit, counter, isConfiguration }) => {
  if (roomLightings?.length < 1) {
    return <></>;
  }
  return (
    <>
      <ItemCollapse
        header={isOpen =>
          roomLightings?.length >= 1 ? (
            <div className={`subsection-header w-100 ${isOpen ? 'open' : ''}`}>
              {counter}. <Translate contentKey="proEcoApp.arrangement.lighting" />
            </div>
          ) : null
        }
        id={`subsection`}
        defaultOpen={true}
      >
        {roomLightings?.map((roomLighting, roomLightingIndex) => (
          <FurnishingLightingItem
            key={roomLightingIndex}
            isNew={isNew}
            isEdit={isEdit}
            isConfiguration={isConfiguration}
            lighting={roomLighting}
            path={`interiorSection.roomLightings[${roomLightingIndex}]`}
          />
        ))}
      </ItemCollapse>
    </>
  );
};
