import React from 'react';
import {
  deactivateEntity,
  restoreEntity,
  getEntities,
  reset,
} from 'app/entities/language/language.reducer';
import {Switch, useRouteMatch} from "react-router-dom";
import ErrorBoundaryRoute from "app/shared/error/error-boundary-route";
import {DictionaryItemList} from "app/modules/dictionaries/Components/dictionaryItemList";
import LanguageUpdateWrapper from "app/entities/language/language-update-wrapper";
const Routes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <ErrorBoundaryRoute
        exact path={`${url}/new`} component={LanguageUpdateWrapper}
      />
      <ErrorBoundaryRoute
        exact path={`${url}/:id/edit`} component={LanguageUpdateWrapper}
      />
      <ErrorBoundaryRoute
        exact path={`${url}/:id`} component={LanguageUpdateWrapper}
        componentProps={{ isReadOnly: true }}
      />
      <ErrorBoundaryRoute
        path={url} component={DictionaryItemList}
        componentProps={{
          deleteEntity: deactivateEntity,
          restoreEntity,
          getEntities,
          reset,
        }}
      />
    </Switch>
  );
}

export default Routes;
