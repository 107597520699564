import React from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import BadgeInPublic from './BadgeInPublic';

const BadgeInPublicInfo = () => (
  <div className={'d-flex my-3'}>
    <BadgeInPublic className="mr-1" /> - <Translate className="ml-1" contentKey="badgeInPublicFieldConfigure" />
  </div>
);

export default BadgeInPublicInfo;
