import React, { useContext } from 'react';
import {
  REQUIRED
} from 'app/shared/util/validation';
import Translate from 'app/shared/layout/Translation/translate';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import WallRoomColors from 'app/modules/administration/home-object-v2/wall-room-colors';
import { Row } from 'reactstrap';

export const WallRoomItem = ({ wallRoom, wallRoomIndex, path, isDetail = false }) => {
  const { onChangeInput } = useContext(HomeObjectContext);

  const fields = [
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: `${path}[${wallRoomIndex}].wallSize`,
      id: `${path}[${wallRoomIndex}].wallSize`,
      onWheel: event => event.currentTarget.blur(),
      type: 'decimalNumber',
      numberDecimalPlaces: 3,
      onChange: onChangeInput,
      value: wallRoom?.wallSize,
      validate: { ...REQUIRED() },
      min: 0,
      appendText: (
        <span className={'unit-append'}>
          m<sup>2</sup>
        </span>
      ),
      label: <Translate contentKey="proEcoApp.project.wallSize" />,
      col: { lg: '2', md: '2' },
    },
  ];
  return (
    <>
      <div className={'component-header'}>
        <span>
          {' '}
          <Translate contentKey="proEcoApp.project.walls" /> {wallRoomIndex + 1}
        </span>
      </div>
      <Row>
        <PartForm fields={fields} />
      </Row>

      <WallRoomColors
        path={`${path}[${wallRoomIndex}].wallRoomColors`}
        wallRoomColors={wallRoom?.wallRoomColors}
        isDetail={isDetail}
        wallSize={wallRoom?.wallSize}
      />
    </>
  );
};

export default WallRoomItem;
