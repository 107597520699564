import Translate from "app/shared/layout/Translation/translate";
import React, { FC, useMemo } from "react";
import "./MenuCart.scss";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import { CartItem } from "./CartItem";
import {returnCurrencyFormat, roundPrice} from "app/shared/util/entity-utils";
import { useAppDispatch, useAppSelector } from "app/config/store";
import {
  deleteEntity as deleteCartItem,
  updateEntity as updateCartItem
} from "app/entities/cart-item/cart-item.reducer";
import { ICartItem } from "app/shared/model/cart-item.model";
import {sumBy} from "lodash";

const currency = "zł";

interface IMenuCart {
  handleClose: () => void;
}

export const MenuCart: FC<IMenuCart> = ({ handleClose }: IMenuCart) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector(state => state.cartItem.entities);

  const summaryPrice = useMemo(() => {
    return sumBy(cartItems, 'cartItemValue');
  }, [cartItems]);

  const onClickGoToCart = () => {
    history.push('/cart');
    handleClose();
  }

  const handleRemoveCartItem = (cartItem: ICartItem) => () => {
    if (cartItem?.quantity > 1) {
      const newEntity = {
        ...cartItem,
        quantity: cartItem.quantity - 1
      }
      dispatch(updateCartItem(newEntity));
    } else dispatch(deleteCartItem(cartItem?.id));
  }

  return (
    <div className="menu-cart">
      <div className="header">
        <Translate contentKey="proEcoApp.cart.cartContents"/>
      </div>
      <div className="cart-items-wrapper">
        {
          cartItems?.length > 0 ? cartItems?.map((item, index) =>
              <CartItem
                key={index}
                id={item.id}
                quantity={item.quantity}
                currency={currency}
                price={roundPrice(item?.cartItemValue)}
                arrangementName={item?.arrangement?.arrangementName}
                handleRemove={handleRemoveCartItem(item)}
              />
            ) :
            <Translate contentKey="proEcoApp.cart.yourCartIsEmpty"/>
        }
      </div>
      <div className="line"/>
      <div className="summary">
        <Translate contentKey="proEcoApp.cart.cartValue"/>
        <span className="price">
          {`${returnCurrencyFormat(roundPrice(summaryPrice))} ${currency}`}
        </span>
      </div>
      <Button onClick={onClickGoToCart} color="first-type" className="w-100 mt-3">
        <Translate contentKey="proEcoApp.cart.goToCart"/>
      </Button>
    </div>
  )
}
