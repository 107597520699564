import React, { useMemo } from 'react';
import { useAppSelector } from 'app/config/store';

export const useOptionNames = () => {
  const optionNames = useAppSelector(state => state.componentPropertyName.entities);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  return useMemo(() => {
    return optionNames?.map(optionName => ({
      id: optionName.id,
      key: optionName.id,
      value: optionName.id,
      translateLabel: currentLocale === 'pl' ? optionName?.namePl : optionName?.nameEn,
      label: currentLocale === 'pl' ? optionName?.namePl : optionName?.nameEn,
    }));
  }, [currentLocale, optionNames]);
};

export const useOptionDescriptions = () => {
  const optionDescriptions = useAppSelector(state => state.componentPropertyDescription.entities);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  return useMemo(() => {
    return optionDescriptions?.map(optionDescription => ({
      id: optionDescription.id,
      key: optionDescription.id,
      value: optionDescription.id,
      translateLabel: currentLocale === 'pl' ? optionDescription?.descriptionPl : optionDescription?.descriptionEn,
      label: currentLocale === 'pl' ? optionDescription?.descriptionPl : optionDescription?.descriptionEn,
    }));
  }, [currentLocale, optionDescriptions]);
};
