import { useAppSelector } from 'app/config/store';
import { useLocation } from 'react-router-dom';

export const useEntitiesInformation = (dictList: any[], searchPathText: string, isCategoryDetail: boolean, categoryDetailName: string) => {
  const { pathname } = useLocation();
  const searchPath = pathname.replace(searchPathText, '');
  const dictListItem = dictList.find(elem => elem.path === searchPath);
  const entitiesList = useAppSelector(state =>
    isCategoryDetail ? state.categoryDetail.categories?.[categoryDetailName] : state[dictListItem.translate].entities
  );
  const totalItems = useAppSelector(state =>
    isCategoryDetail ? state.categoryDetail.totalItems : state[dictListItem.translate].totalItems
  );
  const loading = useAppSelector(state => (isCategoryDetail ? state.categoryDetail.loading : state[dictListItem.translate].loading));
  const updateSuccess = useAppSelector(state =>
    isCategoryDetail ? state.categoryDetail.updateSuccess : state[dictListItem.translate].updateSuccess
  );
  const dictTypeEntity = useAppSelector(state => (isCategoryDetail ? state.categoryDetail.entity : state[dictListItem.translate].entity));
  return { entitiesList, totalItems, loading, updateSuccess, dictTypeEntity, dictListItem };
};
