import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getArrangement,
  getArrangementShared,
  getEntityV2 as getArrangementAdminConfig,
  updateArrangementData,
  updateArrangementEntity,
} from './arrangement.reducer';
import './arrangement.scss';
import ShallAndCore from 'app/modules/administration/arrangement/Components/arrangement-section/shall-and-core';
import ArrangementSectionSummary from './Components/arrangement-section-summary/arrangement-section-summary';
import { returnVatRate } from 'app/shared/util/entity-utils';
import { getEntity as getOffer } from 'app/entities/offer/offer.reducer';
import { ConfigurationLayoutContext } from 'app/app';
import Furnishing from 'app/modules/administration/arrangement/Components/arrangement-section/furnishing';
import { useRole } from 'app/shared/hooks/useRole';
import { Col, Row } from 'reactstrap';
import ImageBuilding from 'app/modules/administration/arrangement/Components/image-building/image-building';
import { useFetchList } from 'app/modules/administration/arrangement/hooks/useFetchList';
import ArrangementSummaryRight from 'app/modules/administration/arrangement/Components/arrangement-section-summary/arrangement-summary-right';
import { useConfigurationLoading } from 'app/modules/administration/arrangement/hooks/useConfigurationLoading';
import { ComponentLoader } from 'app/shared/layout/Loader/Loader';
import { LoginNotificationButton } from 'app/modules/administration/arrangement/Components/arrangement-section/login-notification/login-notification';
import { ModalAgreeLicense } from 'app/modules/home/Subpages/offers/arrangement-of-project/modal-agree-license';
import ArrangementSlider from 'app/modules/administration/arrangement/Components/SummarySlider/ArrangementSlider';
import { translate } from 'app/shared/layout/Translation/translate';
import { getDictEnumsIfNeeded } from 'app/entities/translate-dict-item/translate-dict-item.reducer';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import ConfigurationStepMobile from 'app/modules/administration/arrangement/Components/configuration-step-mobile/configuration-step-mobile';
import './Components/image-building/image-building.scss';

export interface IIsSubmitForShareContext {
  isSubmitForShare?: boolean;
  setIsSubmitForShare?: (value: boolean) => void;
}

export const IsSubmitForShareContext = createContext<IIsSubmitForShareContext>(null);

export const useArrangementParam = () => {
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const offer = useAppSelector(state => state.offer.entity);
  const vats = useAppSelector(state => state.vat.entities);
  const { isClient } = useRole();

  const arrangement = useAppSelector(state => state.arrangement.entity);
  const arrangementData = useAppSelector(state => state.arrangement.entity.arrangement);
  const [offerId, projectId, arrangementName, arrangementTypeId] = useMemo(
    () => [
      urlSearchParams.get('offerId') ||
        (isClient && arrangementData?.offerArrangements?.length === 1 ? arrangementData?.offerArrangements?.[0]?.offerId : ''),
      urlSearchParams.get('projectId') || arrangement?.id,
      urlSearchParams.get('arrangementName') || arrangementData?.arrangementName,
      urlSearchParams.get('arrangementTypeId') || arrangementData?.arrangementTypeId,
    ],
    [isClient, arrangementData?.offerArrangements, arrangement?.id, arrangementData?.arrangementName, arrangementData?.arrangementTypeId]
  );

  const vatRate = useMemo(
    () => (Number(offerId) === offer?.id ? returnVatRate(vats, offer?.vatTypeId) : 0),
    [vats, offer?.vatTypeId, offerId]
  );

  return {
    vatRate,
    offerId,
    projectId,
    arrangementName,
    arrangementTypeId,
  };
};

export const ArrangementUpdate = ({ isDetail = false, isShare = false, isConfiguration = false }) => {
  const { id } = useParams<{ id?: string }>();
  const [isNew] = useState(!id);

  const { pathname } = useLocation();
  const { currentTab, tabs } = useContext(ConfigurationLayoutContext);
  const dispatch = useAppDispatch();
  const arrangementProjectId = useAppSelector(state => state.arrangement.entity.projectId);
  const isEdit = /\/edit/.test(pathname) && !!id;
  const { vatRate, offerId, projectId, arrangementName, arrangementTypeId } = useArrangementParam();
  const { isClient } = useRole();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  useFetchList();
  const loading = useConfigurationLoading();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitForShare, setIsSubmitForShare] = useState(false);
  const isLeaveModal = useMemo(
    () =>
      (location.pathname?.includes('/arrangements/') && !isSubmit) ||
      (location.pathname?.includes('/admin/arrangements/new') && !isSubmit) ||
      (location.pathname?.includes('/admin/arrangements/') && location.pathname?.includes('/edit') && !isSubmit),
    [isSubmit, location.pathname]
  );

  const [arrangementIdClone, setArrangementIdClone] = useState<any>(false);

  useEffect(() => {
    dispatch(getDictEnumsIfNeeded(TRANSLATED_DICTS.SUBSECTION_TYPES));
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setArrangementIdClone(id);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (arrangementName && arrangementTypeId) {
      dispatch(updateArrangementData({ arrangementName, arrangementTypeId }));
    }
  }, [arrangementName, arrangementTypeId]);

  useEffect(() => {
    if (offerId) {
      dispatch(getOffer(offerId));
    }
  }, [offerId]);

  useEffect(() => {
    if (!isNew && id && !isShare) {
      const cachedArrangement = JSON.parse(localStorage.getItem(`arrangement-${id}`));

      if (cachedArrangement) {
        dispatch(updateArrangementEntity(cachedArrangement));
      } else {
        dispatch(getArrangement(id));
      }
    }
  }, [id, isNew, isShare]);

  useEffect(() => {
    if (isShare && id) {
      dispatch(getArrangementShared(id));
    }
  }, [id, isShare]);

  useEffect(() => {
    if (isNew) {
      const currentProjectId = projectId || arrangementProjectId;

      if (!isClient && currentProjectId) {
        dispatch(getArrangementAdminConfig(currentProjectId));
      }
    }
  }, [isNew, projectId, arrangementProjectId, isClient]);

  if (loading) {
    return <ComponentLoader />;
  }

  return (
    <div className="arrangement-update app-page-size page-with-table-wrapper default-padding ml-auto mr-auto">
      <ConfigurationStepMobile />
      <IsSubmitForShareContext.Provider value={{ isSubmitForShare, setIsSubmitForShare }}>
        <Prompt message={translate('proEcoApp.project.leaveModalText')} when={isLeaveModal} />
        {!isAuthenticated && <LoginNotificationButton />}
        <ModalAgreeLicense
          setArrangementIdClone={setArrangementIdClone}
          isOpen={!!arrangementIdClone}
          arrangementIdClone={arrangementIdClone}
          offerId={offerId}
        />
        <Row className={'arrangement-wrapper'}>
          <Col xs={12} md={8} className={currentTab !== tabs?.length - 1 ? 'sticky-for-mobile': ''}>
            <ImageBuilding isFixed={true} isShow={currentTab === 0} isRenderSeason={false} isInterior={false} />
            <ImageBuilding isFixed={true} isShow={currentTab === 1 && tabs?.length === 3} isRenderSeason={false} isInterior={true} />
            <ArrangementSlider tabs={tabs} isShow={currentTab === tabs?.length - 1} />
          </Col>
          <Col xs={12} md={4}>
            {currentTab === 0 ? (
              <ShallAndCore isNew={isNew} isEdit={isEdit} isConfiguration={isConfiguration} isShare={isShare} offerId={offerId} />
            ) : null}
            {currentTab === 1 && tabs?.length === 3 ? (
              <Furnishing isNew={isNew} isEdit={isEdit} isConfiguration={isConfiguration} isShare={isShare} offerId={offerId} />
            ) : null}
            {currentTab === tabs?.length - 1 ? (
              <ArrangementSummaryRight
                isEdit={isEdit}
                isShare={isShare}
                vatRate={vatRate}
                isConfiguration={isConfiguration}
                isNew={isNew}
              />
            ) : null}
          </Col>
        </Row>

        {currentTab === tabs?.length - 1 ? (
          <ArrangementSectionSummary
            isNew={isNew}
            isEdit={isEdit}
            isConfiguration={isConfiguration}
            isShare={isShare}
            vatRate={vatRate}
            isSubmit={isSubmit}
            setIsSubmit={setIsSubmit}
          />
        ) : null}
      </IsSubmitForShareContext.Provider>
    </div>
  );
};

export default ArrangementUpdate;
