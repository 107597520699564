import React, { memo, useCallback, useContext } from 'react';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import Translate from 'app/shared/layout/Translation/translate';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import DeleteRowWrapper from 'app/modules/administration/home-object-v2/delete-row-wrapper';
import AddRowWrapper from 'app/modules/administration/home-object-v2/add-row-wrapper';
import { useAppDispatch } from 'app/config/store';
import { removeOptionHomeObject, updateHomeObject } from 'app/entities/project/project.reducer';
import { useOptions } from 'app/shared/hooks/useOptions';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';
import { Row } from 'reactstrap';
import { onChangePositiveInput, REQUIRED } from 'app/shared/util/validation';
import { debounce } from 'lodash';
import { getNumber } from 'app/shared/util/entity-utils';
import BadgeInPublic from 'app/shared/layout/BadgeInPublic/BadgeInPublic';
import { useMaterialOptions } from 'app/modules/administration/home-object-v2/hooks/useMaterialOptions';

type ISubComponentPropertyItem = {
  componentTypeId: number;
  subComponentPropertyTypeOptions: any;
  subComponentPropertyIndex: number;
  subComponentProperty: any;
  path: string;
  options: any;
  isDetail: boolean;
  showDelete: boolean;
};
export const SubComponentPropertyItem = memo<ISubComponentPropertyItem>(
  ({
    componentTypeId,
    subComponentPropertyTypeOptions,
    subComponentPropertyIndex,
    subComponentProperty,
    path,
    options,
    isDetail = false,
    showDelete = false,
  }) => {
    const dispatch = useAppDispatch();
    const { onChangeInputNumber, onChangeSelect, onAddRow, onRemoveRow, calcTotalPrice } = useContext(HomeObjectContext);
    const { priceTypesOptions } = useOptions(TRANSLATED_DICTS_KEYS.PRICE_TYPES, true);
    const materialTypesOptions = useMaterialOptions({ isDetail, componentTypeId });

    const subComponentPropertyType = subComponentPropertyTypeOptions?.find(
      subComponentPropertyTypeOption => subComponentPropertyTypeOption.id === subComponentProperty?.subComponentPropertyTypeId
    );

    const updateTotalPrice = useCallback(
      debounce((quantity, netPurchasingPrice) => {
        const totalPrice = calcTotalPrice(getNumber(quantity, 0), getNumber(netPurchasingPrice, 0));
        dispatch(updateHomeObject({ name: `${path}[${subComponentPropertyIndex}].totalPrice`, value: totalPrice }));
      }, 500),
      [updateHomeObject, path, subComponentPropertyIndex]
    );
    const handleChangeInput = useCallback(
      ev => {
        onChangeInputNumber(ev);
        updateTotalPrice(ev.target.value, subComponentPropertyType?.propertyPrice?.netPurchasingPrice);
      },
      [onChangeInputNumber, updateTotalPrice, subComponentPropertyType?.propertyPrice?.netPurchasingPrice]
    );

    const handleChangeType = useCallback(
      (objSelected, meta) => {
        if (meta.action === 'clear' && objSelected === null) {
          dispatch(
            removeOptionHomeObject({
              keys: [meta?.name],
            })
          );
        } else {
          onChangeSelect()(objSelected, meta);
        }

        updateTotalPrice(subComponentProperty?.quantity, objSelected?.propertyPrice?.netPurchasingPrice);
      },
      [removeOptionHomeObject, updateTotalPrice, onChangeSelect, subComponentProperty?.quantity]
    );

    const fieldComponent = [
      {
        readOnly: isDetail,
        name: `${path}[${subComponentPropertyIndex}].subComponentPropertyTypeId`,
        id: `${path}[${subComponentPropertyIndex}].subComponentPropertyTypeId`,
        type: 'select',
        onChange: handleChangeType,
        value: subComponentPropertyType,
        validate: {
          ...REQUIRED(),
        },
        options,
        isClearable: true,
        label: (
          <BadgeInPublic>
            <Translate contentKey="proEcoApp.project.subComponentPropertyTypeId" />
          </BadgeInPublic>
        ),
        childrenLabel: <AddRowWrapper handleAdd={() => onAddRow(path)} showAdd={subComponentPropertyIndex === 0 && !isDetail} />,
        col: { lg: '2', md: '2' },
      },
      ...(materialTypesOptions?.length
        ? [
            {
              readOnly: true,
              name: `${path}[${subComponentPropertyIndex}].materialTypeId`,
              id: `${path}[${subComponentPropertyIndex}].materialTypeId`,
              type: 'select',
              onChange: onChangeSelect(''),
              value: materialTypesOptions?.find(materialTypesOption => materialTypesOption.id === subComponentPropertyType?.materialTypeId),
              options: materialTypesOptions,
              isClearable: true,
              label: <Translate contentKey="proEcoApp.project.materialTypeId" />,
              col: { lg: '2', md: '2' },
            },
          ]
        : []),
      {
        readOnly: true,
        name: `${path}[${subComponentPropertyIndex}].priceTypeId`,
        id: `${path}[${subComponentPropertyIndex}].priceTypeId`,
        type: 'select',
        onChange: onChangeSelect(''),
        value: priceTypesOptions.find(priceTypesOption => priceTypesOption.id === subComponentPropertyType?.propertyPrice?.priceTypeId),
        options: priceTypesOptions,
        isClearable: true,
        label: <Translate contentKey="proEcoApp.project.priceTypeId" />,
        col: { lg: '2', md: '2' },
      },
      {
        autoComplete: 'off',
        readOnly: isDetail,
        type: 'number',
        onKeyPress: onChangePositiveInput,
        name: `${path}[${subComponentPropertyIndex}].quantity`,
        id: `${path}[${subComponentPropertyIndex}].quantity`,
        onChange: handleChangeInput,
        min: 0,
        validate: {
          ...REQUIRED(),
        },
        onWheel: event => event.currentTarget.blur(),
        value: subComponentProperty.quantity,
        label: (
          <BadgeInPublic>
            <Translate contentKey="proEcoApp.project.quantity" />
          </BadgeInPublic>
        ),
        col: { lg: '2', md: '2' },
      },
      {
        autoComplete: 'off',
        readOnly: true,
        name: `${path}[${subComponentPropertyIndex}].pricePerUnit`,
        id: `${path}[${subComponentPropertyIndex}].pricePerUnit`,
        value: subComponentPropertyType?.propertyPrice?.netPurchasingPrice,
        label: <Translate contentKey="proEcoApp.project.pricePerUnit" />,
        appendText: (
          <span className={'unit-append'}>
            <Translate contentKey="currency.PLN" />
          </span>
        ),
        col: { lg: '2', md: '2' },
      },
      {
        autoComplete: 'off',
        readOnly: true,
        name: `${path}[${subComponentPropertyIndex}].totalPrice`,
        id: `${path}[${subComponentPropertyIndex}].totalPrice`,
        // onChange: onChangeInput,
        value: subComponentProperty?.totalPrice,
        label: <Translate contentKey="proEcoApp.project.totalNetPrice" />,
        appendText: (
          <span className={'unit-append'}>
            <Translate contentKey="currency.PLN" />
          </span>
        ),
        col: { lg: '2', md: '2' },
      },
    ];

    return (
      <div key={subComponentProperty?.id ?? subComponentProperty?.key}>
        <DeleteRowWrapper showDelete={showDelete} handleDelete={() => onRemoveRow(path, subComponentPropertyIndex)}>
          <Row>
            <PartForm fields={fieldComponent} />
          </Row>
        </DeleteRowWrapper>
      </div>
    );
  }
);

export default SubComponentPropertyItem;
