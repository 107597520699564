import { useAppSelector } from 'app/config/store';
import { useMemo } from 'react';
import { getDictOptions } from 'app/shared/util/select-utils';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import { ADDITIONAL_MATERIAL_TYPES, CATEGORY_TYPE } from 'app/config/constants';

export const useAdditionalMaterialTypes = (componentTypeId?: number, isDetail = true) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const internalWallAdditionalMaterialTypeDict = useAppSelector(
    state => state.categoryDetail.categories[CATEGORY_TYPE.INTERNAL_WALL_ADDITIONAL_MATERIAL_TYPES]
  );
  const facadeAdditionalMaterialTypeDict = useAppSelector(
    state => state.categoryDetail.categories[CATEGORY_TYPE.FACADE_ADDITIONAL_MATERIAL_TYPE]
  );
  const roofAdditionalMaterialTypeDict = useAppSelector(
    state => state.categoryDetail.categories[CATEGORY_TYPE.ROOF_ADDITIONAL_MATERIAL_TYPES]
  );

  const internalWallAdditionalMaterialTypeOptions = useMemo(
    () => getDictOptions(internalWallAdditionalMaterialTypeDict, TRANSLATED_DICTS.CP_ADDITIONAL_MATERIAL_TYPES, null, isDetail),
    [internalWallAdditionalMaterialTypeDict, isDetail, currentLocale]
  );
  const facadeAdditionalMaterialTypeOptions = useMemo(
    () => getDictOptions(facadeAdditionalMaterialTypeDict, TRANSLATED_DICTS.CP_ADDITIONAL_MATERIAL_TYPES, null, isDetail),
    [facadeAdditionalMaterialTypeDict, isDetail, currentLocale]
  );
  const roofAdditionalMaterialTypeOptions = useMemo(
    () => getDictOptions(roofAdditionalMaterialTypeDict, TRANSLATED_DICTS.CP_ADDITIONAL_MATERIAL_TYPES, null, isDetail),
    [roofAdditionalMaterialTypeDict, isDetail, currentLocale]
  );

  return useMemo(() => {
    if (componentTypeId === ADDITIONAL_MATERIAL_TYPES.INTERNAL_WALL_ADDITIONAL_MATERIAL_TYPES) {
      return internalWallAdditionalMaterialTypeOptions;
    }

    if (componentTypeId === ADDITIONAL_MATERIAL_TYPES.FACADE_ADDITIONAL_MATERIAL_TYPE) {
      return facadeAdditionalMaterialTypeOptions;
    }

    if (componentTypeId === ADDITIONAL_MATERIAL_TYPES.ROOF_ADDITIONAL_MATERIAL_TYPE) {
      return roofAdditionalMaterialTypeOptions;
    }
  }, [internalWallAdditionalMaterialTypeDict, roofAdditionalMaterialTypeDict, facadeAdditionalMaterialTypeDict, componentTypeId]);
};
