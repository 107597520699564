import { IOffer } from 'app/shared/model/offer.model';
import { IOrderDeliveryInformation } from 'app/shared/model/order-delivery-information.model';
import { IInvoiceOrderInformation } from 'app/shared/model/invoice-order-information.model';

export interface IOrder {
  withInvoice?: boolean;
  id?: number;
  orderNumber?: string;
  amount?: string;
  proformaInvoiceId?: number;
  billedDate?: string | null;
  paymentDate?: string | null;
  offer?: IOffer;
  providerPaymentUrl?: string;
  orderStatusTypeId?: any;
  deliveryInformation?: IOrderDeliveryInformation;
  invoiceInformation?: IInvoiceOrderInformation;
  cartItemIds?: number[];
  cartItems?: any[];
  agreements?: {
    contractTermsAgreement?: boolean;
    gdprAgreement?: boolean;
    marketingAgreement?: boolean;
  };
  advancedPaymentTypeId?: number;
  discountCode?: string | any;
  discountCodeId?: number;
  paymentMethodId?: number;
  previewImageUrls?: string[];
  isFinancingOption?: boolean;
}

export const defaultValue: Readonly<IOrder> = {};
