import React, { memo, useContext } from 'react';
import Equipments from 'app/modules/administration/home-object-v2/equipments';
import WallRooms from 'app/modules/administration/home-object-v2/wall-rooms';
import Translate from 'app/shared/layout/Translation/translate';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import { useRoomTypeOptions } from 'app/modules/administration/home-object-v2/hooks/useRoomTypeOptions';
import { Row } from 'reactstrap';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import { REQUIRED } from 'app/shared/util/validation';
import BadgeInPublic from 'app/shared/layout/BadgeInPublic/BadgeInPublic';
import LightingInteriorComponent from 'app/modules/administration/home-object-v2/lighting-interior-component';

export const RoomProjectItem = ({ roomProject, roomProjectIndex, path, isDetail = false }) => {
  const { onChangeSelect, onChangeCheckbox } = useContext(HomeObjectContext);
  const roomTypeOptions = useRoomTypeOptions(isDetail);

  const fields = [
    {
      readOnly: isDetail,
      name: `${path}[${roomProjectIndex}].roomTypeId`,
      id: `${path}[${roomProjectIndex}].roomTypeId`,
      type: 'select',
      onChange: onChangeSelect(),
      value: roomTypeOptions.find(roomTypeOption => roomTypeOption.id === roomProject?.roomTypeId),
      options: roomTypeOptions,
      validate: {
        ...REQUIRED(),
      },
      isClearable: true,
      label: (
        <BadgeInPublic>
          <Translate contentKey="proEcoApp.project.roomTypeId" interpolate={{ num: roomProjectIndex + 1 }} />
        </BadgeInPublic>
      ),
      col: { lg: '2', md: '2' },
    },
    {
      id: `${path}[${roomProjectIndex}].isEmptyFurnishing`,
      name: `${path}[${roomProjectIndex}].isEmptyFurnishing`,
      label: <Translate contentKey={'proEcoApp.project.isEmptyFurnishing'} />,
      type: 'checkbox',
      value: roomProject?.isEmptyFurnishing,
      onChange: onChangeCheckbox(),
      avGroupClassName: 'd-flex align-items-center',
      classCol: 'd-flex',
      col: { lg: '2', md: '2' },
    },
  ];

  return (
    <div>
      <div className={'step-header'}>
        <span>
          {' '}
          <Translate contentKey="proEcoApp.project.room" /> {roomProjectIndex + 1}
        </span>
      </div>
      <Row>
        <PartForm fields={fields} />
      </Row>
      {!roomProject?.isEmptyFurnishing && (
        <>
          <WallRooms wallRooms={roomProject.wallRooms} path={`${path}[${roomProjectIndex}].wallRooms`} isDetail={isDetail} />
          <Equipments
            {...{
              equipments: roomProject?.equipments,
              path: `${path}[${roomProjectIndex}].equipments`,
              isDetail,
            }}
          />
          <Equipments
            {...{
              isSet: true,
              equipments: roomProject?.equipmentSets,
              path: `${path}[${roomProjectIndex}].equipmentSets`,
              isDetail,
            }}
          />
          <LightingInteriorComponent
            isDetail={isDetail}
            lightingCPs={roomProject?.lightingCPs}
            path={`${path}[${roomProjectIndex}].lightingCPs`}
          />
        </>
      )}
    </div>
  );
};

export default memo(RoomProjectItem);
