import axios from 'axios';

import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

export const ACTION_TYPES = {
  INIT_PASSWORD: 'account/INIT_PASSWORD',
};

const apiUrl = 'api/account/init-password';

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
  successMessage: null,
};

export type InitPasswordState = Readonly<typeof initialState>;

interface IParams {
  changePassKey: string;
  newPassword: string;
}
export const initPassword = createAsyncThunk(
  ACTION_TYPES.INIT_PASSWORD,
  async ({ changePassKey, newPassword }: IParams) => {
    const requestUrl = `${apiUrl}?key=${changePassKey}`;
    const result = await axios.post(requestUrl, { password: newPassword });
    if (result.status === 200) {
      result.headers['x-lectuloapp-alert'] = 'password.initialize.messages.success';
    } else {
      result.headers['x-lectuloapp-alert'] = 'password.initialize.messages.error';
    }
    return result;
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const InitPasswordSlice = createSlice({
  name: 'initPassword',
  initialState: initialState as InitPasswordState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(initPassword), (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
        state.errorMessage = null;
        state.updateFailure = false;
      })
      .addMatcher(isPending(initPassword), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updateFailure = false;
        state.loading = true;
      });
  },
});

export const { reset } = InitPasswordSlice.actions;

// Reducer
export default InitPasswordSlice.reducer;
