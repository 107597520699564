import React, { FC, useCallback, useMemo } from 'react';
import ItemCollapse from 'app/modules/administration/arrangement/Components/ItemCollapse';

import { IComponents } from 'app/shared/model/component.model';
import { ArrangementGroupedMaterial } from 'app/modules/administration/arrangement/Components/arrangement-section/arrangement-grouped-material';
import { useComponentType } from 'app/modules/administration/arrangement/hooks/useComponentType';
import { GROUP_COMPONENT_TYPE } from 'app/config/constants';
import { HeaderCard } from 'app/modules/administration/arrangement/Components/arrangement-section/header-card';

interface IArrangementComponent {
  component: IComponents;
  componentIndex: number;
  isEdit: boolean;
  isNew: boolean;
  isConfiguration: boolean;
  isRequiredSection?: boolean;
  isMultiChoice?: boolean;
  isInterior?: boolean;
  path: string;
  groupType?: string;
}

export const ArrangementComponent: FC<IArrangementComponent> = ({
  component,
  componentIndex,
  path,
  isEdit,
  isNew,
  isConfiguration,
  isInterior = false,
  isRequiredSection = true,
  isMultiChoice = false,
  groupType = GROUP_COMPONENT_TYPE.DEFAULT,
}: IArrangementComponent) => {
  const componentTypesOptions = useComponentType();
  const componentTypesLabel = useMemo(
    () => componentTypesOptions?.find(option => option?.id === component?.componentTypeId)?.label,
    [componentTypesOptions, component?.componentTypeId]
  );
  const renderHeader = useCallback(
    isOpen => {
      if (groupType === GROUP_COMPONENT_TYPE.ELECTRICIAN && componentIndex === 0) {
        return <></>;
      }
      if (groupType === GROUP_COMPONENT_TYPE.ELECTRICIAN && componentIndex !== 0) {
        return (
          <div className={`component-header w-100 ${isOpen ? 'open' : ''}`}>
            {componentTypesLabel} {isRequiredSection ? '*' : ''}
          </div>
        );
      }

      return <HeaderCard label={`${componentTypesLabel} ${isRequiredSection ? '*' : ''}`} isOpen={isOpen} />;
    },
    [groupType, componentIndex, isRequiredSection, componentTypesLabel]
  );
  return (
    <div key={componentIndex} id={`option-component-${component?.id}`}>
      <ItemCollapse
        header={renderHeader}
        alwaysOpen={groupType === GROUP_COMPONENT_TYPE.ELECTRICIAN && componentIndex !== 0}
        id={`component-${componentIndex}`}
        defaultOpen={true}
      >
        <ArrangementGroupedMaterial
          componentIndex={componentIndex}
          materialGroups={component?.materialGroups}
          path={path}
          isNew={isNew}
          isEdit={isEdit}
          isInterior={isInterior}
          isConfiguration={isConfiguration}
          isRequiredSection={isRequiredSection}
          isMultiChoice={isMultiChoice}
        />
      </ItemCollapse>
    </div>
  );
};
