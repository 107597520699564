import React, { FC } from 'react';
import { Badge } from 'reactstrap';
import Translate from 'app/shared/layout/Translation/translate';

interface IBadgeInPublic {
  children?: React.ReactNode;
  className?: string;
}

const BadgeInPublic: FC<IBadgeInPublic> = ({ children, className }: IBadgeInPublic) => {
  return (
    <div className={`${className} d-inline`}>
      {children}
      <Badge className="ml-2">
        <Translate contentKey="invoice.client" />
      </Badge>
    </div>
  );
};

export default BadgeInPublic;
