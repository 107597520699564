import React, { useEffect, useMemo } from 'react';
import { reset, createEntity, getEntity, updateEntity } from 'app/entities/component-property-name/component-property-name.reducer';
import Translate, { translate } from 'app/shared/layout/Translation/translate';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from 'app/config/store';
import { useDispatch } from 'react-redux';
import { UpdatePage } from 'app/shared/layout/Pages/UpdatePage';
import FormGenerator from 'app/shared/layout/FormGenerator/FormGenerator';
import { get, omit } from 'lodash';

interface IComponentPropertyTypeUpdateWrapper {
  isReadOnly?: boolean;
}

const ComponentPropertyNameUpdateWrapper = ({ isReadOnly }: IComponentPropertyTypeUpdateWrapper) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const isNew = useMemo(() => !id, [id]);
  const { pathname } = useLocation();
  const searchEditPath = pathname.split('/').slice(-1)[0];
  const isEdit = useMemo(() => isNew || searchEditPath === 'edit', [isNew, searchEditPath]);
  const dictEntity = useAppSelector(state => state.componentPropertyName.entity);
  const updating = useAppSelector(state => state.componentPropertyName.updating);
  const isEmptyNameFormTypeEntity = useMemo(() => !get(dictEntity, 'id', false), [dictEntity]);
  const loading = useAppSelector(state => state.componentPropertyName.loading);
  const updateSuccess = useAppSelector(state => state.componentPropertyName.updateSuccess);
  const isLoading = useMemo(() => loading || (!isNew && isEmptyNameFormTypeEntity), [loading, isEmptyNameFormTypeEntity, isNew]);
  const history = useHistory();

  const defaultFields = [
    {
      readOnly: isReadOnly,
      name: 'namePl',
      id: `namePl`,
      label: <Translate contentKey={`proEcoApp.componentPropertyName.namePl`}>Name Pl</Translate>,
      validate: {
        required: { value: true, errorMessage: translate('entity.validation.required') },
      },
    },
    {
      readOnly: isReadOnly,
      name: 'nameEn',
      id: 'nameEn',
      label: <Translate contentKey={`proEcoApp.componentPropertyName.nameEn`}>Name En</Translate>,
      validate: {
        required: { value: true, errorMessage: translate('entity.validation.required') },
      },
    },
  ];

  const saveEntity = (event, values) => {
    const entity = {
      ...dictEntity,
      ...values,
    };
    if (isNew) {
      dispatch(createEntity(omit({ ...entity, isActive: true }, ['id'])));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  useEffect(() => {
    if (updateSuccess) {
      history.push('/admin/dict/component-property-name');
    }
  }, [updateSuccess]);

  return (
    <UpdatePage
      withAppPrefix
      pathName={'component-property-names'}
      i18nGroupPrefix={'componentPropertyName'}
      isLoading={isLoading}
      getEntity={getEntity}
      reset={reset}
      isReadOnly={!isEdit}
    >
      <FormGenerator
        model={isNew ? {} : dictEntity}
        fields={defaultFields}
        onValidSubmit={saveEntity}
        isNew={isNew}
        isSave={isEdit}
        pathButtonEdit={`/admin/dict/component-property-name/${dictEntity.id}/edit`}
        updating={updating}
        isButtonsCenter
      />
    </UpdatePage>
  );
};
export default ComponentPropertyNameUpdateWrapper;
