import React, { FC } from 'react';
import { AccountMenu } from './AccountMenu';
import { Navbar, NavbarBrand } from 'reactstrap';
import HomeMenu from 'app/shared/layout/TopNavMenu/Components/HomeMenu/HomeMenu';

import { CartNavigate } from './Components/MenuCart/CartNavigate/CartNavigate';
import ConfigurationStep from './Components/ConfigurationStep/ConfigurationStep';
import { BasePriceWrapper } from 'app/modules/administration/home-object-v2/Components/home-object-sections/base-price-wrapper/base-price-wrapper';

interface ITopNav {
  isConfiguration?: boolean;
  isHomeObject?: boolean;
  isCart?: boolean;
}

const TopNav: FC<ITopNav> = ({ isConfiguration = false, isHomeObject = false, isCart = false }) => {
  return (
    <Navbar expand="lg">
      <NavbarBrand href="/">
        <div className="brand-icon">
          <img src="content/images/logo-dark.png" alt="Logo" />
        </div>
      </NavbarBrand>
      {isCart && <CartNavigate />}
      {!isHomeObject && !isCart && !isConfiguration && <HomeMenu />}
      {isConfiguration && <ConfigurationStep className="d-none d-xl-block" />}
      {isHomeObject && <BasePriceWrapper />}
      <div className="d-flex align-items-center">
        <AccountMenu isCart={isCart} />
      </div>
    </Navbar>
  );
};

export default TopNav;
