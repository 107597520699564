import React, { FC, useMemo } from 'react';
import ComponentCollapse from 'app/modules/administration/arrangement/Components/ComponentCollapse';
import { IComponents } from 'app/shared/model/component.model';
import { HeaderCard } from 'app/modules/administration/arrangement/Components/arrangement-section/header-card';
import PropertiesOptions from 'app/modules/administration/arrangement/Components/arrangement-section-summary/select-properties-options/properties-options';

import { FURNISHING_STEP } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import { useComponentType } from 'app/modules/administration/arrangement/hooks/useComponentType';
import { flatMap } from 'lodash';
import { useOptionDescriptions, useOptionNames } from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';
import SelectPropertyName from 'app/modules/administration/arrangement/Components/select-properties-options/select-property-name';

interface IFloorComponentPart {
  component: IComponents;
  componentIndex: number;
}

export const FloorComponentPart: FC<IFloorComponentPart> = ({ component }: IFloorComponentPart) => {
  const componentTypesOptions = useComponentType();
  const componentTypesLabel = componentTypesOptions?.find(option => option?.id === component?.componentTypeId)?.translateLabel;
  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const options = useMemo(() => {
    return flatMap(
      component?.materialGroups?.map((materialGroup, materialGroupIndex) => {
        return materialGroup.componentProperties
          ?.map((property, propertyIndex) => ({
            ...property,
            label: nameOptions?.find(nameOption => nameOption.id === property?.cpNameId)?.label,
            children: (
              <>
                <div className="properties-header-description">
                  {descriptionOptions?.find(descriptionOption => descriptionOption?.id === property?.cpDescriptionId)?.label}
                </div>
              </>
            ),
            path: `interiorSection.floorComponent.materialGroups[${materialGroupIndex}].componentProperties[${propertyIndex}].isSelected`,
          }))
          ?.filter(item => item?.isSelected);
      })
    );
  }, [component, nameOptions, descriptionOptions]);
  if (!component) {
    return <></>;
  }
  return (
    <div>
      <ComponentCollapse
        header={isOpen => (options?.length >= 1 ? <HeaderCard label={componentTypesLabel} isOpen={isOpen} /> : null)}
        id={`component-floor`}
        defaultOpen={false}
        className={'component-properties-wrapper'}
      >
        <div className={'component-properties'}>
          <PropertiesOptions
            currentStep={FURNISHING_STEP}
            options={options}
            disabled={true}
            name={'floor-component'}
            optionIdLabel={`option-component-${component?.id}`}
          />
        </div>
      </ComponentCollapse>
    </div>
  );
};
