import {useCalcNetPrice} from 'app/modules/administration/arrangement/hooks/useCalcNetPrice';
import {useAppSelector} from 'app/config/store';
import Translate from 'app/shared/layout/Translation/translate';
import {convertCurrency, roundPrice} from 'app/shared/util/entity-utils';
import React from 'react';

const ArrangementPrice = ({vatRate}) => {
  useCalcNetPrice();
  const arrangementPrice = useAppSelector(state => state.arrangement.entity?.arrangement?.netPrice);
  if (!arrangementPrice) {
    return <></>
  }
  return (
    <div className={'arrangement-section-price'}>
      <Translate className="mr-1" contentKey={`proEcoApp.arrangement.${vatRate ? 'gross' : 'net'}PriceLabel`}/>
      <span className={'total-price'}>{convertCurrency(roundPrice(arrangementPrice), vatRate)}</span>
    </div>
  );
};

export default ArrangementPrice;
