import React, { FC, useMemo } from 'react';
import { IMaterialGroup } from 'app/shared/model/component.model';
import TabsCustom from 'app/modules/administration/arrangement/Components/TabsCustom';
import { useMaterialOptions } from 'app/modules/administration/home-object-v2/hooks/useMaterialOptions';
import { ArrangementGroupedMaterialItem } from 'app/modules/administration/arrangement/Components/arrangement-section/arrangement-grouped-material-item';

interface IArrangementGroupedMaterial {
  materialGroups: any[];
  componentIndex?: number;
  isEdit: boolean;
  isNew: boolean;
  isInterior: boolean;
  isConfiguration: boolean;
  isRequiredSection?: boolean;
  isMultiChoice?: boolean;
  path: string;
}

export const ArrangementGroupedMaterial: FC<IArrangementGroupedMaterial> = ({
  materialGroups,
  isInterior = false,
  path,
  isEdit,
  isNew,
  isRequiredSection,
  isConfiguration,
  isMultiChoice,
}: IArrangementGroupedMaterial) => {
  const isDetail = !isEdit && !isNew;

  const materialTypesOptions = useMaterialOptions({ isDetail, hasMaterial: true });
  const selected = useMemo(() => {
    const temp = [];
    materialGroups?.map(materialGroup => {
      materialGroup?.componentProperties?.map(componentProperty => {
        if (componentProperty?.isSelected) {
          temp.push(componentProperty?.id);
        }
      });
    });
    return temp;
  }, [materialGroups]);

  if (materialGroups?.length === 1) {
    return (
      <ArrangementGroupedMaterialItem
        materialGroup={materialGroups[0]}
        materialGroupIndex={0}
        isConfiguration={isConfiguration}
        isRequiredSection={isRequiredSection}
        isMultiChoice={isMultiChoice}
        isEdit={isEdit}
        isNew={isNew}
        path={path}
        selected={selected}
        isInterior={isInterior}
      />
    );
  }

  const groupedComponentPropertyOptions = materialGroups?.map((materialGroup, materialGroupIndex) => {
    const materialTypeId = materialTypesOptions?.find(materialTypesOption => materialTypesOption.id === materialGroup?.materialTypeId);

    return {
      materialTypeId,
      label: materialTypeId?.label,
      isActive: 0,
      component: (
        <ArrangementGroupedMaterialItem
          materialGroup={materialGroup}
          materialGroupIndex={materialGroupIndex}
          isConfiguration={isConfiguration}
          isEdit={isEdit}
          isNew={isNew}
          isRequiredSection={isRequiredSection}
          isMultiChoice={isMultiChoice}
          path={path}
          selected={selected}
          isInterior={isInterior}
        />
      ),
    };
  });

  return (
    <div className={'arrangement-grouped-material'}>
      <TabsCustom tabs={groupedComponentPropertyOptions} activeTab={0} />
    </div>
  );
};
