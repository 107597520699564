import { useAppSelector } from 'app/config/store';
import { useMemo } from 'react';
import { getDictOptions } from 'app/shared/util/select-utils';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import { CATEGORY_TYPE, CONSTRUCTION_TYPES } from 'app/config/constants';

export const useConstructionTypes = (componentTypeId?: number, isDetail = true) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const internalWallConstructionTypeDict = useAppSelector(
    state => state.categoryDetail.categories[CATEGORY_TYPE.INTERNAL_WALL_CONSTRUCTIONS]
  );
  const facadeConstructionTypeDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.FACADE_CONSTRUCTION_TYPE]);
  const floorConstructionTypeDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.FLOOR_CONSTRUCTIONS]);
  const roofConstructionTypeDict = useAppSelector(state => state.categoryDetail.categories[CATEGORY_TYPE.ROOF_CONSTRUCTION_TYPE]);

  const internalWallConstructionTypeOptions = useMemo(
    () => getDictOptions(internalWallConstructionTypeDict, TRANSLATED_DICTS.CP_CONSTRUCTION_TYPES, null, isDetail),
    [internalWallConstructionTypeDict, isDetail, currentLocale]
  );
  const facadeConstructionTypeOptions = useMemo(
    () => getDictOptions(facadeConstructionTypeDict, TRANSLATED_DICTS.CP_CONSTRUCTION_TYPES, null, isDetail),
    [facadeConstructionTypeDict, isDetail, currentLocale]
  );
  const roofConstructionTypeOptions = useMemo(
    () => getDictOptions(roofConstructionTypeDict, TRANSLATED_DICTS.CP_CONSTRUCTION_TYPES, null, isDetail),
    [roofConstructionTypeDict, isDetail, currentLocale]
  );
  const floorConstructionTypeOptions = useMemo(
    () => getDictOptions(floorConstructionTypeDict, TRANSLATED_DICTS.CP_CONSTRUCTION_TYPES, null, isDetail),
    [floorConstructionTypeDict, isDetail, currentLocale]
  );

  return useMemo(() => {
    if (componentTypeId === CONSTRUCTION_TYPES.INTERNAL_WALL_CONSTRUCTION_TYPE) {
      return internalWallConstructionTypeOptions;
    }

    if (componentTypeId === CONSTRUCTION_TYPES.FACADE_CONSTRUCTION_TYPE) {
      return facadeConstructionTypeOptions;
    }

    if (componentTypeId === CONSTRUCTION_TYPES.ROOF_CONSTRUCTION_TYPES) {
      return roofConstructionTypeOptions;
    }

    if (componentTypeId === CONSTRUCTION_TYPES.FLOOR_CONSTRUCTION_TYPE) {
      return floorConstructionTypeOptions;
    }
    return [];
  }, [floorConstructionTypeDict, internalWallConstructionTypeDict, roofConstructionTypeDict, facadeConstructionTypeDict, componentTypeId]);
};
