import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity, roundPrice, stringifySerializer } from 'app/shared/util/entity-utils';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IProject } from 'app/shared/model/project.model';
import { compact, get, omit, set, sum, unset } from 'lodash';
import { ICondition } from 'app/shared/model/condition.model';
import { COMPONENT_IN_INTERIOR } from 'app/config/constants';

const initialState = {
  loading: false,
  isSuccessFetch: true,
  errorMessage: null,
  entities: [] as ReadonlyArray<IProject>,
  entity: defaultValue,
  conditions: {} as ICondition,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  validateSuccess: false,
  validateLoading: false,
};

const apiUrl = 'api/projects';
const apiUploadUrl = 'api/files/upload';

// Actions

export const cloneHomeObject = createAsyncThunk(
  'project/clone_entity',
  async (id: number, thunkAPI) => {
     const result = await axios.post(`${apiUrl}/clone/${id}`);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getFilterConditions = createAsyncThunk('project/filter_condition', async (params: any) => {
  const requestUrl = `${apiUrl}/filter-condition`;
  return axios.get<ICondition>(requestUrl, {
    params,
    paramsSerializer: stringifySerializer,
  });
});

export const getEntities = createAsyncThunk('project/fetch_entity_list', async (params: any) => {
  return axios.get<IProject[]>(apiUrl, {
    params,
    paramsSerializer: stringifySerializer,
  });
});

export const getEntity = createAsyncThunk(
  'project/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IProject>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const checkValidate = createAsyncThunk(
  'project/check_validate',
  async (_, thunkAPI) => {
    const requestUrl = `${apiUrl}/validate`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const entity = thunkAPI.getState()?.project?.entity;

    return axios.post<IProject>(requestUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'project/create_entity',
  async (_, thunkAPI) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const entity = thunkAPI.getState()?.project?.entity;
    const result = await axios.post<IProject>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const uploadPsd = createAsyncThunk(
  'project/upload_psd',
  async (data: { formData: any; isInterior?: boolean }, thunkAPI) => {
    return axios.post<any>(`${apiUploadUrl}`, data?.formData, { headers: { ['Content-Type']: 'multipart/form-data' } });
  },
  { serializeError: serializeAxiosError }
);

export const uploadBaseLayer = createAsyncThunk(
  'project/upload__base_layer',
  async (data: { formData: any; isInterior?: boolean }, thunkAPI) => {
    return axios.post<any>(`${apiUploadUrl}`, data?.formData, { headers: { ['Content-Type']: 'multipart/form-data' } });
  },
  { serializeError: serializeAxiosError }
);

export const uploadPreviewLayer = createAsyncThunk(
  'project/upload_preview_layer',
  async (data: { formData: any; isInterior?: boolean }, thunkAPI) => {
    return axios.post<any>(`${apiUploadUrl}`, data?.formData, { headers: { ['Content-Type']: 'multipart/form-data' } });
  },
  { serializeError: serializeAxiosError }
);

export const uploadImageOption = createAsyncThunk(
  'project/upload_image_psd',
  async (data: { path: any; formData?: any }, thunkAPI) => {
    return axios.post<any>(`${apiUploadUrl}`, data?.formData, { headers: { ['Content-Type']: 'multipart/form-data' } });
  },
  { serializeError: serializeAxiosError }
);

export const deactivateEntity = createAsyncThunk(
  'project/deactivate_entity',
  async (projectId: number, thunkAPI) => {
    return axios.put(`${apiUrl}/${projectId}/deactivate`);
  },
  { serializeError: serializeAxiosError }
);

export const restoreEntity = createAsyncThunk(
  'project/restore_entity',
  async (project: IProject, thunkAPI) => {
    return await axios.put(`${apiUrl}/${project?.id}/restore`, project);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'project/update_entity',
  async (_, thunkAPI) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const entity = thunkAPI.getState()?.project?.entity;
    const result = await axios.put<IProject>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'project/partial_update_entity',
  async (entity: IProject, thunkAPI) => {
    const result = await axios.patch<IProject>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'project/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IProject>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ProjectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    updateHomeObjectEntity(state, action) {
      state.entity = {
        ...state.entity,
        ...action.payload,
      };
      state.validateSuccess = false;
    },
    removeOptionHomeObject(state, action) {
      const newEntity = { ...state.entity };
      const keys = action.payload?.keys;
      if (action.payload?.keys) {
        keys.map(key => {
          unset(newEntity, key);
        });
      }

      state.entity = newEntity;
      state.validateSuccess = false;
    },
    updateHomeObject(state, action) {
      const newEntity = { ...state.entity };
      if (action.payload?.pathComponent && action.payload.value !== get(newEntity, `${action.payload.name}`)) {
        unset(newEntity, action.payload.pathComponent);
      }
      set(newEntity, action.payload.name, action.payload.value);
      state.entity = newEntity;
      state.validateSuccess = false;
    },

    updateCPTotalPrice(state, action) {
      const newEntity = { ...state.entity };
      set(newEntity, action.payload.name, action.payload.value);

      const totalPriceArr = newEntity?.components
        ?.filter(component => !COMPONENT_IN_INTERIOR?.includes(component.componentTypeId))
        ?.map(component => component?.componentProperties[0]?.totalPrice);

      set(newEntity, 'totalBasePrice', roundPrice(sum(totalPriceArr)));

      state.entity = newEntity;
      state.validateSuccess = false;
    },
    updateBuckHomeObject(state, action) {
      const newEntity = { ...state.entity };
      const obj = get(newEntity, action.payload.name, action.payload.value);
      set(newEntity, action.payload.name, { ...obj, ...action.payload.value });
      state.entity = newEntity;
      state.validateSuccess = false;
    },
    removeRow(state, action) {
      const key = action?.payload?.key;
      const index = action?.payload?.index;
      const newEntity = { ...state.entity };

      if (key) {
        unset(newEntity, `${key}.[${index}]`);
        set(newEntity, key, compact(get(newEntity, key)));
        state.entity = newEntity;
      } else {
        state.entity = newEntity;
      }
      state.validateSuccess = false;
    },

    addRow(state, action) {
      const key = action?.payload?.key;
      const defaultData = action?.payload?.defaultValue;
      const newEntity = { ...state.entity };
      if (key) {
        const length = get(newEntity, key)?.length || 0;
        set(newEntity, `${key}[${length}]`, defaultData);
        state.entity = newEntity;
      }
      state.validateSuccess = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccessFetch = true;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(checkValidate), (state, action) => {
        state.validateLoading = false;
        state.updating = false;
        state.validateSuccess = true;
      })
      .addMatcher(isPending(uploadPsd), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
      })
      .addMatcher(isPending(getFilterConditions), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.conditions = {};
      })
      .addMatcher(isPending(checkValidate), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.validateLoading = true;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getFilterConditions), (state, action) => {
        state.loading = false;
        state.conditions = action?.payload?.data;
      })

      .addMatcher(isFulfilled(uploadPsd), (state, action) => {
        const psdImageUrl = action?.payload?.data?.url;
        const psdImagePath = action?.payload?.data?.path;
        const isInterior = action?.meta?.arg?.isInterior || false;
        if (!isInterior) {
          set(state, `entity.psdImagePath`, psdImagePath);
          set(state, `entity.psdImageUrl`, psdImageUrl);
          const components = get(state, 'entity.components');
          set(
            state,
            'entity.components',
            components?.map(component => ({
              ...component,
              componentProperties: component?.componentProperties?.map(componentProperty => ({
                ...omit(componentProperty, [
                  'imageLayerName',
                  'positionTop',
                  'positionLeft',
                  'imageWidth',
                  'imageHeight',
                  'imageOrder',
                  'imagePath',
                  'loading',
                ]),
              })),
            }))
          );
        } else {
          set(state, `entity.interiorPsdImagePath`, psdImagePath);
          set(state, `entity.interiorPsdImageUrl`, psdImageUrl);
          const roomProjects = get(state, 'entity.roomProjects');

          set(
            state,
            'entity.roomProjects',
            roomProjects?.map(roomProject => ({
              ...roomProject,
              equipments: roomProject?.equipments?.map(equipment => ({
                ...omit(equipment, [
                  'imageLayerName',
                  'positionTop',
                  'positionLeft',
                  'imageWidth',
                  'imageHeight',
                  'imageOrder',
                  'imagePath',
                  'loading',
                ]),
              })),
              equipmentSets: roomProject?.equipmentSets?.map(setItem => ({
                ...omit(setItem, [
                  'imageLayerName',
                  'positionTop',
                  'positionLeft',
                  'imageWidth',
                  'imageHeight',
                  'imageOrder',
                  'imagePath',
                  'loading',
                ]),
              })),
              wallRooms: roomProject?.wallRooms?.map(wallRoom => ({
                ...wallRoom,
                wallRoomColors: wallRoom?.wallRoomColors?.map(wallRoomColor => ({
                  ...omit(wallRoomColor, [
                    'imageLayerName',
                    'positionTop',
                    'positionLeft',
                    'imageWidth',
                    'imageHeight',
                    'imageOrder',
                    'imagePath',
                    'loading',
                  ]),
                })),
              })),
            }))
          );
        }

        const sectionSeasons = get(state, 'entity.sectionSeasons');
        set(
          state,
          'entity.sectionSeasons',
          sectionSeasons?.map(sectionSeason => ({
            ...sectionSeason,
            ...omit(sectionSeason, [
              'imageLayerName',
              'positionTop',
              'positionLeft',
              'imageWidth',
              'imageHeight',
              'imageOrder',
              'imagePath',
              'loading',
            ]),
          }))
        );
      })

      .addMatcher(isPending(uploadImageOption), (state, action) => {
        state.errorMessage = null;
        state.updateSuccess = false;

        set(state, `${action?.meta?.arg?.path}.loading`, true);
      })

      .addMatcher(isPending(uploadBaseLayer), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
      })

      .addMatcher(isFulfilled(uploadBaseLayer), (state, action) => {
        const baseLayerPath = action?.payload?.data?.path;
        const baseLayerUrl = action?.payload?.data?.url;
        const isInterior = action?.meta?.arg?.isInterior || false;

        if (!isInterior) {
          set(state, `entity.baseLayerPath`, baseLayerPath);
          set(state, `entity.baseLayerUrl`, baseLayerUrl);
        } else {
          set(state, `entity.interiorBaseLayerPath`, baseLayerPath);
          set(state, `entity.interiorBaseLayerUrl`, baseLayerUrl);
        }
      })

      .addMatcher(isPending(uploadPreviewLayer), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
      })

      .addMatcher(isFulfilled(uploadPreviewLayer), (state, action) => {
        const baseLayerPath = action?.payload?.data?.path;
        const baseLayerUrl = action?.payload?.data?.url;
        const isInterior = action?.meta?.arg?.isInterior || false;

        if (!isInterior) {
          set(state, `entity.previewLayerPath`, baseLayerPath);
          set(state, `entity.previewLayerUrl`, baseLayerUrl);
        } else {
          set(state, `entity.interiorPreviewLayerPath`, baseLayerPath);
          set(state, `entity.interiorPreviewLayerUrl`, baseLayerUrl);
        }
      })

      .addMatcher(isFulfilled(uploadImageOption), (state, action) => {
        state.loading = false;
        const imagePath = action?.payload?.data?.path;

        set(state, `entity.${action?.meta?.arg?.path}.imagePath`, imagePath);
        set(state, `entity.${action?.meta?.arg?.path}.loading`, false);
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(restoreEntity, deactivateEntity, cloneHomeObject), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.isSuccessFetch = false;
      })
      .addMatcher(
        isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, restoreEntity, deactivateEntity, cloneHomeObject),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      )
      .addMatcher(
        isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity, restoreEntity, deactivateEntity, cloneHomeObject),
        (state, action) => {
          state.errorMessage = action.error.message;
          state.updateSuccess = false;
          state.updating = false;
          state.loading = false;
        }
      )
      .addMatcher(isRejected(checkValidate), (state, action) => {
        state.validateSuccess = false;
        state.updating = false;
      });
  },
});

export const {
  reset,
  updateBuckHomeObject,
  updateHomeObjectEntity,
  updateHomeObject,
  removeRow,
  removeOptionHomeObject,
  addRow,
  updateCPTotalPrice,
} = ProjectSlice.actions;

// Reducer
export default ProjectSlice.reducer;
