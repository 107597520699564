import React, { FC } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { AccountMenuMobile } from 'app/shared/layout/TopNavMenu/AccountMenuMobile';

interface ITopNav {
  isConfiguration?: boolean;
  isHomeObject?: boolean;
  isCart?: boolean;
}

const TopNavMobile: FC<ITopNav> = ({ isConfiguration = false, isHomeObject = false, isCart = false }) => {
  return (
    <Navbar expand="lg">
      <NavbarBrand href="/">
        <div className="brand-icon">
          <img src="content/images/logo-dark.png" alt="Logo" />
        </div>
      </NavbarBrand>
      <div className="d-flex align-items-center">
        <AccountMenuMobile isCart={isCart} isConfiguration={isConfiguration} isHomeObject={isHomeObject} />
      </div>
    </Navbar>
  );
};

export default TopNavMobile;
