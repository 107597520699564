import React, { createContext, useCallback, useEffect, useState } from 'react';
import './steps/content.scss';
import { LANGUAGE_IDS } from 'app/config/constants';
import { changeCurrentStep, getEntities } from 'app/entities/content-v2/content.reducer';
import { useDispatch } from 'react-redux';
import { Storage } from 'react-jhipster';

interface IPreviewContext {
  isPreview?: boolean;
  locale?: any;
  setLocale?: any;
  onChangeStep?: any;
  selectorValue?: any;
  languageId?: any;
  tabIndex?: any;
  setTabIndex?: any;
}

export const selectorValue = (tempData, originalData, contentKey, defaultValue, key = 'contentText') => {
  if (tempData?.find(data => data.contentKey === contentKey)) {
    return tempData?.find(data => data.contentKey === contentKey)?.[key];
  }
  if (originalData?.find(data => data.contentKey === contentKey)) {
    return originalData?.find(data => data.contentKey === contentKey)?.[key];
  }
  return defaultValue;
};

export const PreviewContext = createContext<IPreviewContext>({
  onChangeStep() {},
});

export const HomepageWrapper = ({ children, match }) => {
  const dispatch = useDispatch();
  const langKey = Storage.session.get('locale', 'pl');
  const [locale, setLocale] = useState(langKey);
  const [tabIndex, setTabIndex] = useState(0);

  const onChangeStep = useCallback(
    step => ev => {
      ev.preventDefault();
      dispatch(changeCurrentStep(step));
    },
    []
  );

  useEffect(() => {
    dispatch(getEntities({ languageId: LANGUAGE_IDS[locale], sort: 'id,asc' }));
  }, [locale]);

  useEffect(() => {
    dispatch(changeCurrentStep(0));
    setTabIndex(0);
  }, [match]);

  return (
    <PreviewContext.Provider
      value={{
        isPreview: true,
        locale,
        setLocale,
        onChangeStep,
        languageId: LANGUAGE_IDS[locale],
        tabIndex,
        setTabIndex,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
};
export default HomepageWrapper;
