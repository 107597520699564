import React, { useRef } from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import { Button, Col, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import ButtonLoading from 'app/shared/layout/ButtonLoading/ButtonLoading';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';

const ComponentPropertyDescriptionFilter = props => {
  const { loading } = props;
  const formRef = useRef(null);

  const onSubmit = (event, values) => {
    props.search(values);
  };

  const onClear = () => {
    formRef?.current?.reset();
    props.search({});
  };

  const fields = [
    {
      autoComplete: 'off',
      readOnly: false,
      name: 'descriptionPl',
      id: 'descriptionPl',
      label: <Translate contentKey="proEcoApp.componentPropertyDescription.descriptionPl" />,
      col: { lg: '3', md: '6' },
    },
    {
      autoComplete: 'off',
      readOnly: false,
      name: 'descriptionEn',
      id: 'descriptionEn',
      label: <Translate contentKey="proEcoApp.componentPropertyDescription.descriptionEn" />,
      col: { lg: '3', md: '6' },
    },
  ];

  return (
    <div className="search-panel">
      <AvForm onValidSubmit={onSubmit} ref={formRef}>
        <Row>
          {fields && <PartForm fields={fields} />}
          <Col sm={12} className={'mt-4 d-flex flex-column flex-sm-row flex-gap-2'}>
            <ButtonLoading updating={loading} disabled={loading} color="first-type" text={'entity.action.search'} id={'search-entity'} />
            <Button type="button" color="second-type" onClick={onClear}>
              <Translate contentKey="entity.action.clear" />
            </Button>
          </Col>
        </Row>
      </AvForm>
    </div>
  );
};

export default ComponentPropertyDescriptionFilter;
