import React, { memo, useContext, useMemo } from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import AddRowWrapper from 'app/modules/administration/home-object-v2/add-row-wrapper';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import EquipmentMemberItem from 'app/modules/administration/home-object-v2/equipment-member-item';

export const EquipmentMembers = ({ equipmentMembers = [], path, isDetail = false }) => {
  const { onAddRow } = useContext(HomeObjectContext);

  const equipmentMemberTypes = useMemo(
    () => equipmentMembers?.map(equipmentMember => equipmentMember?.equipmentTypeId),
    [equipmentMembers]
  );

  return (
    <div className={'ml-5'}>
      <div className={'home-object-title'}>
        <span className={'component-header'}>
          <AddRowWrapper handleAdd={() => onAddRow(path)} showAdd={!isDetail}>
            <Translate contentKey="proEcoApp.project.equipment" />
          </AddRowWrapper>
        </span>
      </div>
      {equipmentMembers?.map((equipmentMember, equipmentMemberIndex) => {
        return (
          <EquipmentMemberItem
            key={equipmentMember?.id ?? equipmentMemberIndex}
            equipmentMember={equipmentMember}
            equipmentMemberIndex={equipmentMemberIndex}
            path={path}
            isDetail={isDetail}
            showDelete={!isDetail}
            equipmentMemberTypes={equipmentMemberTypes}
          />
        );
      })}
    </div>
  );
};

export default memo(EquipmentMembers);
