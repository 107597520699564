import { useMemo } from 'react';
import { getDictOptions } from 'app/shared/util/select-utils';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import { useAppSelector } from 'app/config/store';

export const useWallColorTypeOptions = (isDetail = true) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const wallRoomColorTypesList = useAppSelector(state => state.wallColorType.entities);
  return useMemo(
    () => getDictOptions(wallRoomColorTypesList, TRANSLATED_DICTS.WALL_COLOR_TYPES, null, isDetail),
    [wallRoomColorTypesList, isDetail, currentLocale]
  );
};
