import React, { FC, useCallback, useState } from 'react';
import { ModalSwitchBuildingType } from 'app/shared/layout/CustomInput/SwitchBuildingType/ModalSwitchBuildingType';
import './SwichBuildingType.scss';
import Translate from 'app/shared/layout/Translation/translate';
import CustomInput from 'app/shared/layout/CustomInput/CustomInput';
import { Button, ButtonGroup, Label } from 'reactstrap';
import { BUILDING_TYPE_ID, useBuildingTypeOptions } from 'app/modules/administration/home-object-v2/hooks/useBuildingTypeOptions';
import { useAppSelector } from 'app/config/store';

interface ISwitchBuildingType {
  name?: string;
  validate?: any;
}

const SWITCH_SEASON_IDS = [1, 2];
export const SwitchBuildingType: FC<ISwitchBuildingType> = ({ name = 'buildingTypeId', validate }: ISwitchBuildingType) => {
  const [isOpenModal, setIsOpenModal] = useState<number | null>(null);
  const buildingTypeId = useAppSelector(state => state.arrangement.entity?.arrangement?.buildingTypeId || BUILDING_TYPE_ID.SUMMER_TYPE);

  const isSwitchAble = useAppSelector(state => state.arrangement.entity?.isSwitchAble);

  const buildingTypeOptions = useBuildingTypeOptions();
  const options = buildingTypeOptions?.filter(buildingTypeOption => SWITCH_SEASON_IDS?.includes(buildingTypeOption.id));
  const handleClose = () => {
    setIsOpenModal(null);
  };

  const onClickSwitchChange = useCallback(
    (id: number) => () => {
      setIsOpenModal(id);
    },
    [setIsOpenModal]
  );

  if (!isSwitchAble) {
    return null;
  }

  return (
    <div className="switch-building-type-wrapper">
      <Label>
        <Translate contentKey="configuration.switchBuildingType.buildingType" />
      </Label>
      <CustomInput
        label="configuration.switchBuildingType.buildingType"
        name={name}
        type="hidden"
        readOnly
        validate={validate}
        value={buildingTypeId}
      />
      <ButtonGroup className="switch-building-type">
        {options.map(item => (
          <Button
            color="switch"
            className={buildingTypeId === item.value ? 'active' : ''}
            type="button"
            onClick={onClickSwitchChange(item.value)}
            key={item.value}
          >
            {item.label}
          </Button>
        ))}
      </ButtonGroup>
      <ModalSwitchBuildingType isOpen={!!isOpenModal} handleClose={handleClose} buildingTypeId={isOpenModal} />
    </div>
  );
};
