import React, { memo, useContext } from 'react';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import Translate from 'app/shared/layout/Translation/translate';
import {
  HomeObjectContext,
  LayerContext,
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import { useOptions } from 'app/shared/hooks/useOptions';
import DeleteRowWrapper from 'app/modules/administration/home-object-v2/delete-row-wrapper';
import { Row, Spinner } from 'reactstrap';
import { useAdditionalMaterialTypes } from 'app/modules/administration/home-object-v2/hooks/useAdditionalMaterialTypes';
import { removeOptionHomeObject, updateHomeObject } from 'app/entities/project/project.reducer';
import { useAppDispatch } from 'app/config/store';
import { onChangePositiveInput, REQUIRED } from 'app/shared/util/validation';
import { debounce } from 'lodash';
import { getNumber } from 'app/shared/util/entity-utils';

type IComponentPropertyAdditionalMaterials = {
  componentTypeId: number;
  componentPropertyAdditionalMaterial: any;
  componentPropertyAdditionalMaterialIndex: number;
  path?: string;
  showDelete: boolean;
  isDetail: boolean;
}
export const ComponentPropertyAdditionalMaterials = memo<IComponentPropertyAdditionalMaterials>(({
  componentTypeId,
  componentPropertyAdditionalMaterial,
  componentPropertyAdditionalMaterialIndex,
  path,
  showDelete = false,
  isDetail = false,
}) => {
  const dispatch = useAppDispatch();
  const { onChangeInputNumber, onChangeSelect, onRemoveRow, calcTotalPrice } = useContext(HomeObjectContext);
  const { priceTypesOptions } = useOptions(TRANSLATED_DICTS_KEYS.PRICE_TYPES, true);
  const additionalMaterialTypeOptions = useAdditionalMaterialTypes(componentTypeId, isDetail);
  const componentPropertyAdditionalMaterialType = additionalMaterialTypeOptions.find(
    additionalMaterialTypeOption =>
      additionalMaterialTypeOption.id === componentPropertyAdditionalMaterial?.componentPropertyAdditionalMaterialTypeId
  );
  const updateTotalPrice = debounce(ev => {
    const totalPrice = calcTotalPrice(
      getNumber(ev.target.value, 0),
      getNumber(componentPropertyAdditionalMaterialType?.propertyPrice?.netPurchasingPrice, 0)
    );
    dispatch(
      updateHomeObject({
        name: `${path}[${componentPropertyAdditionalMaterialIndex}].totalPrice`,
        value: totalPrice,
      })
    );
  }, 300);

  const handleChangeInput = ev => {
    onChangeInputNumber(ev);
    updateTotalPrice(ev);
  };

  const handleChangeType = (objSelected, meta) => {
    if (meta.action === 'clear' && objSelected === null) {
      dispatch(
        removeOptionHomeObject({
          keys: [meta?.name],
        })
      );
    } else {
      onChangeSelect()(objSelected, meta);
    }
    const totalPrice = calcTotalPrice(
      getNumber(componentPropertyAdditionalMaterial?.quantity, 0),
      getNumber(objSelected?.propertyPrice?.netPurchasingPrice, 0)
    );
    dispatch(
      updateHomeObject({
        name: `${path}[${componentPropertyAdditionalMaterialIndex}].totalPrice`,
        value: totalPrice,
      })
    );
  };

  const fieldComponent = [
    {
      readOnly: isDetail,
      name: `${path}[${componentPropertyAdditionalMaterialIndex}].componentPropertyAdditionalMaterialTypeId`,
      id: `${path}[${componentPropertyAdditionalMaterialIndex}].componentPropertyAdditionalMaterialTypeId`,
      type: 'select',
      onChange: handleChangeType,
      value: componentPropertyAdditionalMaterialType,
      options: additionalMaterialTypeOptions,
      isClearable: true,
      label: (
        <>
          <Translate contentKey="proEcoApp.project.subComponentPropertyTypeId" />
        </>
      ),
      col: { lg: '2', md: '2' },
    },
    {
      readOnly: true,
      name: `${path}[${componentPropertyAdditionalMaterialIndex}].priceTypeId`,
      id: `${path}[${componentPropertyAdditionalMaterialIndex}].priceTypeId`,
      type: 'select',
      value: priceTypesOptions.find(
        priceTypesOption => priceTypesOption.id === componentPropertyAdditionalMaterialType?.propertyPrice?.priceTypeId
      ),
      options: priceTypesOptions,
      isClearable: true,
      label: <Translate contentKey="proEcoApp.project.priceTypeId" />,
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: `${path}[${componentPropertyAdditionalMaterialIndex}].quantity`,
      id: `${path}[${componentPropertyAdditionalMaterialIndex}].quantity`,
      onChange: handleChangeInput,
      onWheel: event => event.currentTarget.blur(),
      onKeyPress: onChangePositiveInput,
      type: 'number',
      min: 0,
      validate: {
        ...(componentPropertyAdditionalMaterialType ? REQUIRED() : {}),
      },

      value: componentPropertyAdditionalMaterial.quantity,
      label: <Translate contentKey="proEcoApp.project.quantity" />,
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: true,
      name: `${path}[${componentPropertyAdditionalMaterialIndex}].pricePerUnit`,
      id: `${path}[${componentPropertyAdditionalMaterialIndex}].pricePerUnit`,
      value: componentPropertyAdditionalMaterialType?.propertyPrice?.netPurchasingPrice,
      label: <Translate contentKey="proEcoApp.project.pricePerUnit" />,
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN" />
        </span>
      ),
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: true,
      name: `${path}[${componentPropertyAdditionalMaterialIndex}].price`,
      id: `${path}[${componentPropertyAdditionalMaterialIndex}].price`,
      value: componentPropertyAdditionalMaterial.totalPrice,
      label: <Translate contentKey="proEcoApp.project.totalNetPrice" />,
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN" />
        </span>
      ),
      col: { lg: '2', md: '2' },
    },
  ];

  return (
    <div key={componentPropertyAdditionalMaterial?.id ?? componentPropertyAdditionalMaterial?.key}>
      <DeleteRowWrapper showDelete={showDelete} handleDelete={() => onRemoveRow(path, componentPropertyAdditionalMaterialIndex)}>
        <Row>
          <PartForm fields={fieldComponent} />
        </Row>
      </DeleteRowWrapper>
    </div>
  );
});

export default ComponentPropertyAdditionalMaterials;
