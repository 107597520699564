import React, { FC } from "react";
import {translate} from "app/shared/layout/Translation/translate";
import "./base-price-wrapper.scss";
import {useAppSelector} from "app/config/store";
import {convertCurrency} from "app/shared/util/entity-utils";


export const BasePriceWrapper: FC= () => {
  const totalBasePrice = useAppSelector(state => state.project.entity.totalBasePrice)
  return (<div className="base-price-wrapper">
    {`${translate('proEcoApp.project.totalBasePrice')}:`}
    <span className="total-price">{convertCurrency(totalBasePrice)}</span>
  </div>)
};
