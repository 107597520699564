import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale from './locale';
import authentication from './authentication';
import applicationProfile from './application-profile';

import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import arrangement from 'app/modules/administration/arrangement/arrangement.reducer';
import register from 'app/modules/account/register/register.reducer';
import activate from 'app/modules/account/activate/activate.reducer';
import password from 'app/modules/account/password/password.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
import translateDictItem from 'app/entities/translate-dict-item/translate-dict-item.reducer';
import language from 'app/entities/language/language.reducer';
import initPassword from 'app/modules/account/activate/init-password.reducer';
import materialType from 'app/entities/material-type/material-type.reducer';
import providerTransaction from 'app/modules/administration/arrangement/provider-transaction.reducer';
// prettier-ignore
import offerType from 'app/entities/offer-type/offer-type.reducer';
// prettier-ignore
import offerPriority from 'app/entities/offer-priority/offer-priority.reducer';
// prettier-ignore
import systemService from 'app/entities/system-service/system-service.reducer';
// prettier-ignore
import offer from 'app/entities/offer/offer.reducer';
// prettier-ignore
import service from 'app/entities/service/service.reducer';
// prettier-ignore
import promotion from 'app/entities/promotion/promotion.reducer';
// prettier-ignore
import content from 'app/entities/content-v2/content.reducer';
// prettier-ignore
import pageType from 'app/entities/page-type/page-type.reducer';
// prettier-ignore
import contentType from 'app/entities/content-type/content-type.reducer';
// prettier-ignore
import preferredContactType from 'app/entities/preferred-contact-type/preferred-contact-type.reducer';
// prettier-ignore
import contactFormStatus from 'app/entities/contact-form-status/contact-form-status.reducer';
// prettier-ignore
import contactForm from 'app/entities/contact-form/contact-form.reducer';
// prettier-ignore
import project from 'app/entities/project/project.reducer';
// prettier-ignore
import projectSeason from 'app/entities/project-season/project-season.reducer';
// prettier-ignore
import projectType from 'app/entities/project-type/project-type.reducer';
// prettier-ignore
import section from 'app/entities/section/section.reducer';
// prettier-ignore
import subsection from 'app/entities/subsection/subsection.reducer';
// prettier-ignore
import subsectionType from 'app/entities/subsection-type/subsection-type.reducer';
// prettier-ignore
import component from 'app/entities/components/component.reducer';
// prettier-ignore
import componentType from 'app/entities/component-type/component-type.reducer';
// prettier-ignore
import equipment from 'app/entities/equipment/equipment.reducer';
// prettier-ignore
import equipmentType from 'app/entities/equipment-type/equipment-type.reducer';
import setType from 'app/entities/set-type/set-type.reducer';
// prettier-ignore
import arrangementType from 'app/entities/arrangement-type/arrangement-type.reducer';
// prettier-ignore
import arrangementDetail from 'app/entities/arrangement-details/arrangement-detail.reducer';
// prettier-ignore
import componentProperty from 'app/entities/component-property/component-property.reducer';
// prettier-ignore
import componentPropertyType from 'app/entities/component-property-type/component-property-type.reducer';
// prettier-ignore
import componentPropertyOption from 'app/entities/component-property-option/component-property-option.reducer';
// prettier-ignore
import equipmentProperty from 'app/entities/equipment-property/equipment-property.reducer';
// prettier-ignore
import country from 'app/entities/country/country.reducer';
// prettier-ignore
import order from 'app/entities/order/order.reducer';
// prettier-ignore
import orderStatusType from 'app/entities/order-status-type/order-status-type.reducer';
// prettier-ignore
import globalParam from 'app/entities/global-param/global-param.reducer';
import vatType from 'app/entities/vat-type/vat-type.reducer';
import invoice from 'app/entities/invoice/invoice.reducer';
// prettier-ignore
import priceType from 'app/entities/price-type/price-type.reducer';
import vat from 'app/entities/vat/vat.reducer';
// prettier-ignore
import propertyOptionTypePrice from 'app/entities/property-option-type-price/property-option-type-price.reducer';
import objectConstructionType from 'app/entities/object-construction-type/object-construction-type.reducer';
// prettier-ignore
import question from 'app/entities/question/question.reducer';
// prettier-ignore
import answer from 'app/entities/answer/answer.reducer';
// prettier-ignore
import questionTag from 'app/entities/question-tag/question-tag.reducer';
// prettier-ignore
import blogTag from 'app/entities/blog-tag/blog-tag.reducer';
// prettier-ignore
import blog from 'app/entities/blog/blog.reducer';
// prettier-ignore
import blogContent from 'app/entities/blog-content/blog-content.reducer';
// prettier-ignore
import cartItemStatus from 'app/entities/cart-item-status/cart-item-status.reducer';
// prettier-ignore
import deliveryType from 'app/entities/delivery-type/delivery-type.reducer';
// prettier-ignore
import advancedPaymentType from 'app/entities/advanced-payment-type/advanced-payment-type.reducer';
// prettier-ignore
import cartItem from 'app/entities/cart-item/cart-item.reducer';
// prettier-ignore
import orderDeliveryInformation from 'app/entities/order-delivery-information/order-delivery-information.reducer';
// prettier-ignore
import invoiceOrderInformation from 'app/entities/invoice-order-information/invoice-order-information.reducer';
// prettier-ignore
import paymentMethod from 'app/entities/payment-method/payment-method.reducer';
// prettier-ignore
import paymentType from 'app/entities/payment-type/payment-type.reducer';
// prettier-ignore
import deliveryCostRange from 'app/entities/delivery-cost-range/delivery-cost-range.reducer';
// prettier-ignore
import approval from 'app/entities/approval/approval.reducer';
// prettier-ignore
import discountCodeStatus from 'app/entities/discount-code-status/discount-code-status.reducer';
// prettier-ignore
import discountCodeType from 'app/entities/discount-code-type/discount-code-type.reducer';
// prettier-ignore
import discountCode from 'app/entities/discount-code/discount-code.reducer';
import { combineReducers } from 'redux';
// prettier-ignore
import roomType from 'app/entities/room-type/room-type.reducer';
// prettier-ignore
import roomProject from 'app/entities/room-project/room-project.reducer';
// prettier-ignore
import wallRoom from 'app/entities/wall-room/wall-room.reducer';
// prettier-ignore
import wallColorType from 'app/entities/wall-color-type/wall-color-type.reducer';
// prettier-ignore
import wallRoomColor from 'app/entities/wall-room-color/wall-room-color.reducer';
import categoryDetail from 'app/entities/category-detail/category-detail.reducer';
// prettier-ignore
import category from 'app/entities/category/category.reducer';

import imageOption from 'app/entities/image-option/image-option.reducer';
// prettier-ignore
import floorMaterialType from 'app/entities/floor-material-type/floor-material-type.reducer';
import facadeMaterialType from 'app/entities/facade-material-type/facade-material-type.reducer';
import exteriorDoorMaterialType from 'app/entities/exterior-door-material-type/exterior-door-material-type.reducer';
import interiorDoorMaterialType from 'app/entities/interior-door-material-type/interior-door-material-type.reducer';
import interiorDoorType from 'app/entities/interior-door-type/interior-door-type.reducer';
import windowMaterialType from 'app/entities/window-material-type/window-material-type.reducer';
import wallColorAdditionalMaterialType from 'app/entities/wall-color-additional-material-type/wall-color-additional-material-type.reducer';
import componentPropertyName from 'app/entities/component-property-name/component-property-name.reducer';
import componentPropertyDescription from 'app/entities/component-property-description/component-property-description.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = combineReducers({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  translateDictItem,
  language,
  offerType,
  offerPriority,
  systemService,
  offer,
  service,
  promotion,
  content,
  pageType,
  contentType,
  preferredContactType,
  contactFormStatus,
  contactForm,
  project,
  projectSeason,
  projectType,
  section,
  subsection,
  subsectionType,
  component,
  componentType,
  equipment,
  equipmentType,
  setType,
  materialType,
  arrangement,
  arrangementType,
  arrangementDetail,
  componentProperty,
  componentPropertyType,
  componentPropertyName,
  componentPropertyDescription,
  componentPropertyOption,
  equipmentProperty,
  country,
  order,
  providerTransaction,
  orderStatusType,
  globalParam,
  priceType,
  propertyOptionTypePrice,
  objectConstructionType,
  question,
  answer,
  questionTag,
  blogTag,
  blog,
  blogContent,
  cartItemStatus,
  deliveryType,
  advancedPaymentType,
  cartItem,
  orderDeliveryInformation,
  invoiceOrderInformation,
  paymentMethod,
  paymentType,
  deliveryCostRange,
  approval,
  discountCodeStatus,
  discountCodeType,
  discountCode,
  categoryDetail,

  floorMaterialType,
  facadeMaterialType,
  exteriorDoorMaterialType,
  interiorDoorMaterialType,
  interiorDoorType,
  windowMaterialType,
  imageOption,
  roomType,
  roomProject,
  wallRoom,
  wallColorType,
  wallColorAdditionalMaterialType,
  wallRoomColor,
  category,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  initPassword,
  invoice,
  vatType,
  vat,
  loadingBar,
});

export default rootReducer;
