import Translate from 'app/shared/layout/Translation/translate';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import './authorized.scss';
import { useRole } from 'app/shared/hooks/useRole';
import { useAppSelector } from 'app/config/store';

interface IAuthorizedMenu {
  toggleClose?: () => void;
  isUserProfile?: boolean;
}

const AuthorizedMenu: FC<IAuthorizedMenu> = ({ toggleClose, isUserProfile }: IAuthorizedMenu) => {
  const { isClient, isAdministrator, isSystem, isAdmin, isMarketing, isSale, isPurchasing, isDesign } = useRole();

  if (isSystem) {
    return (
      <div className="authorized-menu">
        <Link onClick={toggleClose} to="/logout">
          <Translate contentKey="global.menu.account.logout" />
        </Link>
      </div>
    );
  }
  if (isAdministrator) {
    const isSystemAdmin = isSystem || isAdmin;
    const canAccessHomeObject = !isSale && !isMarketing && !isPurchasing;
    const canAccessConfiguration = !isSale && !isPurchasing && !isMarketing;
    const canAccessClientRequest = !isDesign && !isPurchasing && !isMarketing;

    return (
      <div className="authorized-menu">
        <Translate className="menu-section" contentKey="global.menu.admin.analytics" />
        <Link onClick={toggleClose} to="/admin/dashboard">
          <Translate contentKey="global.menu.admin.dashboard" />
        </Link>
        {(canAccessHomeObject || !isMarketing || canAccessConfiguration) && (
          <Translate className="menu-section" contentKey="global.menu.admin.newProject" />
        )}
        {canAccessHomeObject && (
          <Link onClick={toggleClose} to="/admin/home-object">
            <Translate contentKey="global.menu.admin.homeObjectsDirectory" />
          </Link>
        )}
        {canAccessConfiguration && (
          <Link onClick={toggleClose} to="/admin/arrangements">
            <Translate contentKey="global.menu.admin.arrangements" />
          </Link>
        )}

        {renderMenuContent(toggleClose)}
        {isSystemAdmin && canAccessClientRequest && <Translate className="menu-section" contentKey="global.menu.admin.systemManagement" />}
        {isSystemAdmin && (
          <Link onClick={toggleClose} to="/admin/finances">
            <Translate contentKey="global.menu.admin.finances" />
          </Link>
        )}
        {canAccessClientRequest && (
          <Link onClick={toggleClose} to="/admin/requests">
            <Translate contentKey="global.menu.admin.contactForms" />
          </Link>
        )}

        {isSystemAdmin && (
          <>
            <Link onClick={toggleClose} to="/admin/dict">
              <Translate contentKey="global.menu.admin.dictionaries" />
            </Link>
            <Translate className="menu-section" contentKey="global.menu.admin.userManagement" />
            <Link onClick={toggleClose} to="/admin/users">
              <Translate contentKey="global.menu.admin.users" />
            </Link>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="authorized-menu">
      {!isAdministrator && (
        <>
          <Translate className="menu-section" contentKey="global.menu.admin.content" />
          <Link className="home-menu-item" to="/" onClick={toggleClose}>
            <Translate contentKey="global.menu.homePage.homepage" />
          </Link>
          <Link className="home-menu-item" to="/offer" onClick={toggleClose}>
            <Translate contentKey="global.menu.homePage.products" />
          </Link>
          <Link to="/our-services" onClick={toggleClose}>
            <Translate contentKey="global.menu.homePage.services" />
          </Link>
          <Link to="/how-it-works" onClick={toggleClose}>
            <Translate contentKey="global.menu.homePage.howItWorks" />
          </Link>
          <Link to="/faq" onClick={toggleClose}>
            <Translate contentKey="global.menu.homePage.faq" />
          </Link>
          <Link to="/blog" onClick={toggleClose}>
            <Translate contentKey="global.menu.homePage.blog" />
          </Link>
          <Link to="/contact" onClick={toggleClose}>
            <Translate contentKey="global.menu.homePage.contact" />
          </Link>
          <Link to="/about-us" onClick={toggleClose}>
            <Translate contentKey="global.menu.homePage.aboutUs" />
          </Link>
          <Translate className="menu-section" contentKey="global.menu.account.myProfile" />
          <Link onClick={toggleClose} to="/arrangements">
            <Translate contentKey="global.menu.entities.configurations" />
          </Link>
          <Link onClick={toggleClose} to="/orders">
            <Translate contentKey="global.menu.entities.orders" />
          </Link>
          <Link onClick={toggleClose} to="/finances">
            <Translate contentKey="global.menu.admin.invoice" />
          </Link>
          <Link onClick={toggleClose} to="/account/profile">
            <Translate contentKey="global.menu.account.personalData" />
          </Link>
        </>
      )}
    </div>
  );
};

export default AuthorizedMenu;

const renderMenuContent = toggleClose => {
  const { isMarketing, isSale, isPurchasing, isDesign } = useRole();
  const canAccessOffer = !isSale && !isMarketing && !isDesign && !isPurchasing;
  const canAccessOrder = !isSale && !isPurchasing && !isMarketing && !isDesign;
  const canAccessCms = !isSale && !isPurchasing && !isDesign;
  const canAccessService = !isSale && !isPurchasing && !isMarketing && !isDesign;
  const canAccessSpecificOffer = !isSale && !isMarketing && !isDesign;
  const canAccessDiscountCode = !isDesign && !isPurchasing;

  return (
    <>
      {(canAccessSpecificOffer || canAccessDiscountCode || canAccessCms) && (
        <Translate className="menu-section" contentKey="global.menu.admin.content" />
      )}

      {canAccessOffer && (
        <Link onClick={toggleClose} to="/admin/offers">
          <Translate contentKey="global.menu.admin.offers" />
        </Link>
      )}
      {canAccessService && (
        <Link onClick={toggleClose} to="/admin/services">
          <Translate contentKey="global.menu.admin.services" />
        </Link>
      )}
      {canAccessSpecificOffer && (
        <Link onClick={toggleClose} to="/admin/promotions">
          <Translate contentKey="global.menu.admin.promotions" />
        </Link>
      )}

      {canAccessDiscountCode && (
        <Link onClick={toggleClose} to="/admin/discount-code">
          <Translate contentKey="global.menu.admin.discountCode" />
        </Link>
      )}
      {canAccessCms && (
        <Link onClick={toggleClose} to="/admin/cms-v2">
          <Translate contentKey="global.menu.admin.cms" />
        </Link>
      )}
      {canAccessOrder && (
        <Link onClick={toggleClose} to="/admin/orders">
          <Translate contentKey="global.menu.admin.orders" />
        </Link>
      )}
    </>
  );
};
