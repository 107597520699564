import React, {useContext, useMemo} from 'react';
import { REQUIRED } from 'app/shared/util/validation';
import Translate from 'app/shared/layout/Translation/translate';
import {
  HomeObjectContext,
  LayerContext,
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import { Row, Spinner } from 'reactstrap';
import { useOptions } from 'app/shared/hooks/useOptions';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import DeleteRowWrapper from 'app/modules/administration/home-object-v2/delete-row-wrapper';
import {SUMMER_SEASON_ID} from "app/config/constants";


export const SeasonItem = ({ seasonItem, seasonIndex, path, isDetail = false, projectSeasonIds, isInterior = false }) => {
  const { onRemoveRow, selectPropertyOption, onChangeSelect } = useContext(HomeObjectContext);
  const {layers, layerInteriors} = useContext(LayerContext);

  const layerOptions = useMemo(() => (!isInterior ? layers : layerInteriors), [isInterior, layers, layerInteriors]);

  const { projectSeasonsOptions } = useOptions(TRANSLATED_DICTS_KEYS.PROJECT_SEASONS, true);
  const fields = [
    {
      autoComplete: 'off',
      readOnly: isDetail || seasonIndex === 0,
      name: `${path}[${seasonIndex}].projectSeasonId`,
      id: `${path}[${seasonIndex}].projectSeasonId`,
      type: 'select',
      onChange: onChangeSelect(''),
      value: projectSeasonsOptions.find(projectSeasonsOption => projectSeasonsOption.id === seasonItem?.projectSeasonId),
      validate: { ...(seasonIndex === 0 ? REQUIRED() : {}) },
      options: projectSeasonsOptions?.filter( projectSeasonsOption => !projectSeasonIds?.includes(projectSeasonsOption?.id)),
      label: <Translate contentKey="proEcoApp.project.projectSeasonId" />,
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: `${path}[${seasonIndex}].imageLayerName`,
      id: `${path}[${seasonIndex}].imageLayerName`,
      type: 'select',
      onChange: selectPropertyOption(`${path}[${seasonIndex}]`),
      isClearable: true,
      value: layerOptions?.find(layer => layer.value === seasonItem?.imageLayerName),
      options: layerOptions || [],
      validate: { ...REQUIRED() },
      label: (
        <>
          <Translate contentKey="proEcoApp.project.imageLayerName" className={'mr-2'} />
          {seasonItem?.loading ? (
            <Spinner className={'uploaded'} />
          ) : seasonItem?.imagePath ? (
            <FontAwesomeIcon icon={faCircleCheck} className={'uploaded'} />
          ) : (
            ''
          )}
        </>
      ),
      col: { lg: '4', md: '4' },
    },
  ];
  return (
    <>
      <DeleteRowWrapper showDelete={seasonItem.projectSeasonId !== SUMMER_SEASON_ID} handleDelete={() => onRemoveRow(path, seasonIndex)}>
        <Row>
          <PartForm fields={fields} />
        </Row>
      </DeleteRowWrapper>
    </>
  );
};

export default SeasonItem;
