import React, {useMemo} from 'react';
import ItemCollapse from 'app/modules/administration/arrangement/Components/ItemCollapse';
import { ArrangementComponents } from 'app/modules/administration/arrangement/Components/arrangement-section/arrangement-components';

const ShallAndCoreItem = ({ shallAndCoreIndex, components, subsectionType, isConfiguration, isEdit, isNew }) => {
  const componentMapeds= useMemo(() => components?.map((component, componentIndex) => (
    {...component, componentIndex})), [components]);
  return (
    <div key={shallAndCoreIndex}>
      <ItemCollapse
        header={isOpen => (
          <div className={`subsection-header w-100 ${isOpen ? 'open' : ''}`}>
            {shallAndCoreIndex + 1}. {subsectionType?.label}
          </div>
        )}
        id={`subsection-${shallAndCoreIndex}`}
        defaultOpen={true}
      >
        <ArrangementComponents
          components={componentMapeds}
          isConfiguration={isConfiguration}
          isEdit={isEdit}
          isNew={isNew}
          path={`shellAndCores[${shallAndCoreIndex}].components`}
        />
      </ItemCollapse>
    </div>
  );
};

export default ShallAndCoreItem;
