import React from 'react';
import {
  createEntity,
  getEntities,
  getEntity,
  reset,
  deactivateEntity,
  updateEntity,
  restoreEntity,
} from 'app/entities/category-detail/category-detail.reducer';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useRole } from 'app/shared/hooks/useRole';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import OptionUpdateWrapper from 'app/shared/layout/OptionDictionary/option-update-wrapper';
import { DictionaryItemList } from 'app/modules/dictionaries/Components/dictionaryItemList';

const Routes = () => {
  const { url } = useRouteMatch();
  const { isAdmin, isSystem, isDesign, isMarketing } = useRole();
  return (
    <Switch>
      <ErrorBoundaryRoute
        exact
        path={`${url}/new`}
        component={OptionUpdateWrapper}
        componentProps={{
          createEntity,
          getEntities,
          reset,
          additionalTableColumns: ['propertyPrice'],
          canAction: isAdmin || isSystem || isDesign,
          canEdit: !isMarketing,
          isCategoryDetail: true,
        }}
      />
      <ErrorBoundaryRoute
        exact
        path={`${url}/:id/edit`}
        component={OptionUpdateWrapper}
        componentProps={{
          updateEntity,
          getEntity,
          getEntities,
          reset,
          additionalTableColumns: ['propertyPrice'],
          canAction: isAdmin || isSystem || isDesign,
          canEdit: !isMarketing,
          isCategoryDetail: true,
        }}
      />
      <ErrorBoundaryRoute
        exact
        path={`${url}/:id`}
        component={OptionUpdateWrapper}
        componentProps={{
          isReadOnly: true,
          getEntity,
          isCategoryDetail: true,
        }}
      />

      <ErrorBoundaryRoute
        path={url}
        component={DictionaryItemList}
        componentProps={{
          deleteEntity: deactivateEntity,
          restoreEntity,
          getEntities,
          reset,
          additionalTableColumns: ['propertyPrice'],
          canAction: isAdmin || isSystem || isDesign,
          canEdit: !isMarketing,
          isCategoryDetail: true,
        }}
      />
    </Switch>
  );
};

export default Routes;
