import React, { FC, useMemo } from 'react';

import { IComponents } from 'app/shared/model/component.model';
import { ArrangementComponent } from 'app/modules/administration/arrangement/Components/arrangement-section/arrangement-component';
import { GROUP_COMPONENT_TYPE, HAS_GROUP_COMPONENT, OPTIONAL_COMPONENTS, SUB_COMPONENT_PROPERTY_TYPES } from 'app/config/constants';
import { groupBy } from 'lodash';
import { ArrangementGroupedMaterial } from 'app/modules/administration/arrangement/Components/arrangement-section/arrangement-grouped-material';
import ItemCollapse from 'app/modules/administration/arrangement/Components/ItemCollapse';
import { HeaderCard } from 'app/modules/administration/arrangement/Components/arrangement-section/header-card';
import { useComponentType } from 'app/modules/administration/arrangement/hooks/useComponentType';

interface IArrangementComponent {
  components: IComponents[];
  isEdit: boolean;
  isNew: boolean;
  isConfiguration: boolean;
  path: string;
}

export const ArrangementComponents: FC<IArrangementComponent> = ({
  components,
  isEdit,
  isNew,
  isConfiguration,
  path,
}: IArrangementComponent) => {
  const componentTypesOptions = useComponentType();

  const groupComponents = useMemo(() => {
    return groupBy(components, component => {
      if (HAS_GROUP_COMPONENT?.includes(component?.componentTypeId)) {
        return GROUP_COMPONENT_TYPE.ELECTRICIAN;
      }
      return GROUP_COMPONENT_TYPE.DEFAULT;
    });
  }, [components]);

  return (
    <>
      {Object?.keys(groupComponents)?.map(groupKey => {
        if (groupKey === 'ELECTRICIAN') {
          const componentTypesLabel = componentTypesOptions?.find(option => option?.id === groupComponents?.[groupKey]?.[0]?.componentTypeId)?.label;
          return (
            <ItemCollapse
              header={isOpen => <HeaderCard label={` ${componentTypesLabel} *`} isOpen={isOpen} />}
              id={`component-${0}`}
              defaultOpen={true}
            >
              {groupComponents?.[groupKey]?.map((component) => (
                <ArrangementComponent
                  key={component?.componentIndex}
                  groupType={groupKey}
                  component={component}
                  path={`${path}[${component?.componentIndex}]`}
                  isNew={isNew}
                  isEdit={isEdit}
                  componentIndex={component?.componentIndex}
                  isConfiguration={isConfiguration}
                  isRequiredSection={!OPTIONAL_COMPONENTS?.includes(component?.componentTypeId)}
                  isMultiChoice={component?.componentTypeId === SUB_COMPONENT_PROPERTY_TYPES?.ELECTRICIAN_ADDONS}
                />
              ))}
            </ItemCollapse>
          );
        }
        return (
          <>
            {groupComponents?.[groupKey]?.map((component) => (
              <ArrangementComponent
                key={component?.componentIndex}
                component={component}
                path={`${path}[${component?.componentIndex}]`}
                isNew={isNew}
                isEdit={isEdit}
                componentIndex={component?.componentIndex}
                isConfiguration={isConfiguration}
                isRequiredSection={!OPTIONAL_COMPONENTS?.includes(component?.componentTypeId)}
                isMultiChoice={component?.componentTypeId === SUB_COMPONENT_PROPERTY_TYPES?.ELECTRICIAN_ADDONS}
              />
            ))}
          </>
        );
      })}
    </>
  );
};
