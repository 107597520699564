import React, { FC, useState } from 'react';
import { Button } from 'reactstrap';
import Translate from '../Translation/translate';
import Menu from './Components/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from 'app/config/store';
import { useHistory } from 'react-router';
import { MenuCartDropdown } from 'app/shared/layout/TopNavMenu/Components/MenuCart/MenuCartDropdown';
import { Link, useLocation } from 'react-router-dom';

interface IAccountMenu {
  isCart?: boolean;
}

export const AccountMenu: FC<IAccountMenu> = ({ isCart }: IAccountMenu) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const isHiddenConfigurator = pathname === '/configurator' || pathname.includes('offer') || pathname.includes('arrangements');

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const firstName = useAppSelector(state => state.authentication.account.firstName);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [isUserProfile, setIsUserProfile] = useState(false);
  const handleOpenMenu = () => setIsOpenMenu(true);

  const handleOpenUserProfile = () => {
    if (!isAuthenticated) {
      history.push('/login');
    } else {
      setIsOpenMenu(true);
      setIsUserProfile(true);
    }
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
    setIsUserProfile(false);
  };

  return (
    <div className="account-menu">
      <div onClick={handleOpenUserProfile} className="welcome-button">
        <img src="content/images/user.png" alt="user" className="header-icon" />
        <div>
          <Translate contentKey="global.menu.welcome" />
          {!firstName && <Translate contentKey="global.menu.login" />}
        </div>
        <FontAwesomeIcon className="arrow" icon={faAngleDown} />
      </div>
      <MenuCartDropdown />
      {!isHiddenConfigurator && !isCart && (
        <Link to="/configurator" className="btn btn-first-type top-button ml-1">
          <Translate contentKey={'global.menu.configurator'} />
        </Link>
      )}
      {
        <Link to="/content/files/subsidies.pdf" target="_blank" rel="noreferrer">
          <img src="content/images/euLogo.svg" alt="EU logo" className="eu-logo-image" />
        </Link>
      }
      <Button color="only-text" className="ml-3 top-button" onClick={handleOpenMenu}>
        <Translate contentKey={'global.menu.menu'} />
      </Button>
      <Menu isOpen={isOpenMenu} toggleClose={handleCloseMenu} isUserProfile={isUserProfile} />
    </div>
  );
};
