import React, { FC, useMemo } from 'react';
import { validateInvalidField } from 'app/shared/layout/CustomInput/CustomInput';
import { REQUIRED } from 'app/shared/util/validation';
import { AvField } from 'availity-reactstrap-validation';
import { IOption } from 'app/shared/util/select-utils';
import { IEquipmentPropertyOption } from 'app/shared/model/equipment-property-option.model';
import { useArrangementParam } from 'app/modules/administration/arrangement/arrangement-update';
import { PropertiesOption } from 'app/modules/administration/arrangement/Components/arrangement-section-summary/select-properties-options/properties-option';
import { SHELL_AND_CORE_STEP } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import { FormFeedback } from 'reactstrap';
import Translate from 'app/shared/layout/Translation/translate';

export interface IPropertiesOption extends IOption {
  imageUrl?: string;
  isDefault?: boolean;
  price?: number;
  path?: string;
}

interface ISelectCustom {
  options?: IPropertiesOption[] | IEquipmentPropertyOption[];
  hasImage?: boolean;
  currentStep?: number;
  name?: any;
  value?: any;
  disabled?: boolean;
  onlyLabel?: boolean;
  className?: string;
  optionIdLabel?: string;
  isRequiredSection?: boolean;
}

const PropertiesOptions: FC<ISelectCustom> = ({
  options = [],
  onlyLabel = false,
  name,
  value,
  disabled = false,
  className = '',
  currentStep,
  optionIdLabel,
  isRequiredSection,
}) => {
  const { vatRate } = useArrangementParam();
  const isObligatory = useMemo(() => currentStep === SHELL_AND_CORE_STEP, [currentStep]);
  return (
    <div className={`select-properties-options ${className}`}>
      {options?.map((option, i) => (
        <PropertiesOption
          currentStep={currentStep}
          optionIdLabel={optionIdLabel}
          key={option?.id || i}
          option={option}
          disabled={disabled}
          onlyLabel={onlyLabel}
          path={option.path}
          vatRate={vatRate}
        />
      ))}
      {!disabled && (
        <AvField
          {...{
            errorMessage: validateInvalidField,
            type: 'hidden',
            name: `${name}-${options?.[0]?.id}`,
            value,
            validate: {
              ...(isRequiredSection ? REQUIRED() : {}),
            },
          }}
        />
      )}
      {isObligatory && (
        <FormFeedback style={{ marginLeft: '16px' }}>
          <Translate contentKey="proEcoApp.arrangement.required" />
        </FormFeedback>
      )}
    </div>
  );
};

export default PropertiesOptions;
