import React from 'react';
import { getEntities, reset, deactivateEntity, restoreEntity } from 'app/entities/wall-color-type/wall-color-type.reducer';
import { Switch, useRouteMatch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { DictionaryItemList } from 'app/modules/dictionaries/Components/dictionaryItemList';
import WallColorTypeUpdateWrapper from 'app/entities/wall-color-type/wall-color-type-update-wrapper';

const Routes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <ErrorBoundaryRoute exact path={`${url}/new`} component={WallColorTypeUpdateWrapper} />
      <ErrorBoundaryRoute exact path={`${url}/:id/edit`} component={WallColorTypeUpdateWrapper} />
      <ErrorBoundaryRoute exact path={`${url}/:id`} component={WallColorTypeUpdateWrapper} componentProps={{ isReadOnly: true }} />

      <ErrorBoundaryRoute
        path={url}
        component={DictionaryItemList}
        componentProps={{
          deleteEntity: deactivateEntity,
          restoreEntity,
          getEntities,
          reset,
        }}
      />
    </Switch>
  );
};

export default Routes;
