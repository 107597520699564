import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';

import { cleanEntity, stringifySerializer, updateOrCreate } from 'app/shared/util/entity-utils';
import {createEntitySlice, EntityState, isRejectedAction, serializeAxiosError} from 'app/shared/reducers/reducer.utils';
import { defaultValue, IBlog } from 'app/shared/model/blog.model';

const initialState: EntityState<IBlog> = {
  isFetched: false,
  loading: false,
  errorMessage: null,
  entities: [],
  otherPages: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/blogs';
const apiUploadUrl = 'api/files/upload';

// Actions

export const getEntities = createAsyncThunk('blog/fetch_entity_list', async (params: any) => {
  return axios.get<IBlog[]>(apiUrl, {
    params,
    paramsSerializer: stringifySerializer,
  });
});

export const getOtherPages = createAsyncThunk('blog/fetch_other_pages', async (params: any) => {
  return axios.get<IBlog[]>(apiUrl, {
    params,
    paramsSerializer: stringifySerializer,
  });
});

export const getEntity = createAsyncThunk(
  'blog/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IBlog>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'blog/create_entity',
  async (entity: IBlog) => {
    return await axios.post<IBlog>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'blog/update_entity',
  async (entity: IBlog, thunkAPI) => {
    return await axios.put<IBlog>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deactivateBlog = createAsyncThunk(
  'blog/deactivate_entity',
  async (entity: IBlog, thunkAPI) => {
    const response = await axios.put(`${apiUrl}/${entity?.id}/deactivate`, entity);
    return response;
  },
  { serializeError: serializeAxiosError }
);

export const restoreBlog = createAsyncThunk(
  'blog/restore_entity',
  async (entity: IBlog, thunkAPI) => {
    const response = await axios.put(`${apiUrl}/${entity?.id}/restore`, entity);
    return response;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'blog/partial_update_entity',
  async (entity: IBlog, thunkAPI) => {
    return await axios.patch<IBlog>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'blog/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.delete<IBlog>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const uploadImage = createAsyncThunk(
  'blog/upload_image',
  async (data: { formData: any; number: any }, thunkAPI) => {
    return axios.post<any>(`${apiUploadUrl}`, data?.formData, { headers: { ['Content-Type']: 'multipart/form-data' } });
  },
  { serializeError: serializeAxiosError }
);

export const uploadMainImage = createAsyncThunk(
  'blog/upload-main-image',
  async (data: { formData: any; contentObj?: any }, thunkAPI) => {
    return axios.post<any>(`${apiUploadUrl}`, data?.formData, { headers: { ['Content-Type']: 'multipart/form-data' } });
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BlogSlice = createEntitySlice({
  name: 'blog',
  initialState,
  reducers: {
    updateBlog(state, action) {
      state.entity = {
        ...state.entity,
        ...action.payload,
      };
    },
    updateBlogContents(state, action) {
      updateOrCreate(state.entity.blogContents, action.payload, 'number');
    },

    removeRow(state, action) {
      state.entity.blogContents.splice(action?.payload?.index, 1);
    },

    addRow(state, action) {
      const defaultData = action?.payload?.defaultValue;
      state.entity.blogContents = [...state.entity.blogContents, ...defaultData];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.isFetched = true;
        state.entity = {
          ...action.payload.data,
          blogContents: action.payload.data?.blogContents.map((blogContent, number) => ({ ...blogContent, number })),
        };
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = defaultValue;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getOtherPages), (state, action) => {
        return {
          ...state,
          otherPages: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(restoreBlog, deactivateBlog), state => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(uploadImage), (state, action) => {
        state.loading = false;
        updateOrCreate(
          state.entity.blogContents,
          {
            ...state.entity.blogContents?.[action.meta.arg.number],
            imageUrl: action.payload.data.url,
            imagePath: action.payload.data.path,
          },
          'number'
        );
      })
      .addMatcher(isFulfilled(uploadMainImage), (state, action) => {
        state.entity = {
          ...state.entity,
          imageUrl: action.payload.data.url,
          imagePath: action.payload.data.path,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, uploadImage, getOtherPages), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, restoreBlog, deactivateBlog), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected( getEntity), state => {
        state.errorMessage = null;
        state.loading = false;
        state.isFetched = true;
      });
  },
});

export const { reset, removeRow, addRow, updateBlog, updateBlogContents } = BlogSlice.actions;

// Reducer
export default BlogSlice.reducer;
