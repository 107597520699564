import { IRoomType } from 'app/shared/model/room-type.model';
import { IProject } from 'app/shared/model/project.model';
import {IWallRoom} from "app/shared/model/wall-room.model";

export interface IRoomProject {
  id?: number;
  wallCount?: number | null;
  psdImagePath?: string;
  roomType?: IRoomType | null;
  roomTypeId?: string,
  psdImageUrl?: string,
  project?: IProject | null;
  wallRooms?: IWallRoom[];
}

export const defaultValue: Readonly<IRoomProject> = {};
