import React from "react";
import RequiredAsterisk from "app/shared/layout/RequiredAsterisk";
import Translate from "app/shared/layout/Translation/translate";

const ObligatoryInfo = () => {
  return (
   <div className={"my-3"}>
     <RequiredAsterisk/> -  <Translate contentKey="mandatoryField" />
   </div>
  )
}

export default ObligatoryInfo;
