import Translate from 'app/shared/layout/Translation/translate';
import React from 'react';
import './login-notification.scss';
import BasicDialog from 'app/shared/layout/Dialog/BasicDialog';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import { useAppSelector } from 'app/config/store';

export const LoginNotification = () => {
  const history = useHistory();
  const location = useLocation();
  const arrangement = useAppSelector(state => state.arrangement.entity);
  return (
    <BasicDialog toggleClose={() => {}} isOpen={true}>
      <div className="modal-title text-center">
        <Translate contentKey={`loginNotification`} />
      </div>
      <div className={'modal-button'}>
        <Button
          color="first-type"
          className="btn-default-size"
          onClick={() => {
            localStorage.setItem(`arrangement-${arrangement?.arrangement?.id}`, JSON.stringify(arrangement));
            history.push('/login', { from: location });
          }}
        >
          <Translate contentKey={`login.form.button`} />
        </Button>
      </div>
    </BasicDialog>
  );
};

export const LoginNotificationButton = () => {
  const history = useHistory();
  const location = useLocation();
  const arrangement = useAppSelector(state => state.arrangement.entity);
  return (
    <a
      color="first-type"
      className="login-notification"
      onClick={() => {
        localStorage.setItem(`arrangement-${arrangement?.arrangement?.id}`, JSON.stringify(arrangement));
        history.push('/login', { from: location });
      }}
    >
      <Translate contentKey={'loginNotification'} />
    </a>
  );
};
