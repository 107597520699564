import React, { FC } from "react";
import BasicFormDialog from "app/shared/layout/Dialog/BasicFormDialog";
import Translate, { translate } from "app/shared/layout/Translation/translate";
import { Button, Col, Row } from "reactstrap";
import CustomInput from "app/shared/layout/CustomInput/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "app/config/store";
import { toast } from "react-toastify";

interface IShareModal {
  toggleClose: () => void;
  onSubmit: (event, values) => void;
  isOpen?: boolean;
}

export const ShareModal: FC<IShareModal> = ({ toggleClose, onSubmit, isOpen }: IShareModal) => {
  const arrangementData = useAppSelector(state => state.arrangement.entity);
  const link = arrangementData.uuid ? `${window.location.origin}/configuration/${arrangementData.uuid}` : '';

  const onCopy = () => {
    navigator.clipboard.writeText(link);
    toast.success(translate('proEcoApp.arrangement.isCopied'));
  };

  return (
    <BasicFormDialog
      toggleClose={toggleClose}
      onValidSubmit={onSubmit}
      isOpen={isOpen}
      childrenHeader={<Translate contentKey={`proEcoApp.arrangement.shareTitle`} className={'text-center'}/>}
    >
      <Translate contentKey={`proEcoApp.arrangement.shareDescriptions`} className={'text-center'}/>
      <Row className="mt-2">
        <Col xs={12} md={8}>
          <CustomInput
            {...{
              autoComplete: 'off',
              readOnly: true,
              name: 'link',
              id: 'link',
              value: link,
            }}
          />
        </Col>
        <Col xs={12} md={4}>
          <Button onClick={onCopy} color={'first-type'} className="w-100">
            <FontAwesomeIcon icon={faCopy}/>
            <Translate className="ml-1" contentKey="proEcoApp.arrangement.copy"/>
          </Button>
        </Col>
      </Row>
    </BasicFormDialog>
  )
}

