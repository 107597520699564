import { omit } from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import { AvField } from 'availity-reactstrap-validation';
import { regexDecimalNumber } from 'app/shared/util/validation';

interface ICustomDecimalNumberInput {
  name: string;
  readOnly?: boolean;
  onChange?: (event) => void;
  numberDecimalPlaces?: number;
  errorMessage?: string;
  valueProps?: any;
}

export const CustomDecimalNumberInput: FC<ICustomDecimalNumberInput> = ({
  name,
  readOnly,
  onChange,
  numberDecimalPlaces = 2,
  errorMessage,
  valueProps = null,
  ...props
}: ICustomDecimalNumberInput) => {
  const [value, setValue] = useState(valueProps);

  const handleOnChange = useCallback(
    event => {
      event.preventDefault();
      const valueTmp = event.target.value;
      const regexInformation = regexDecimalNumber(numberDecimalPlaces, valueTmp);
      if (regexInformation.regex) {
        setValue(regexInformation.newValue);
        return regexInformation.newValue;
      } else {
        setValue(value);
        return value;
      }
    },
    [setValue, value]
  );
  const handleOnKeyPress = useCallback(
    ev => {
      const charCode = ev.which ? ev.which : ev.keyCode;
      if (charCode === 8) {
        return true;
      } else if (
        !(charCode > 31 && (charCode < 48 || charCode > 57)) ||
        ((charCode === 46 || charCode === 44) && ev.target.value.length > 0)
      ) {
        const keyTmp = ev.key === ',' ? '.' : ev.key;
        const valueTmp = `${ev.target.value}${keyTmp}`;
        const regexInformation = regexDecimalNumber(numberDecimalPlaces, valueTmp);
        return regexInformation.regex ? true : ev.preventDefault();
      } else ev.preventDefault();
    },
    [setValue, value]
  );

  const handleOnKeyUp = useCallback(
    ev => {
      ev.preventDefault();
      const regexInformation = regexDecimalNumber(numberDecimalPlaces, ev.target.value);
      ev.target.value = regexInformation.newValue;
      setValue(regexInformation.newValue);
      return true;
    },
    [setValue, value]
  );

  return (
    <AvField
      {...omit(props, ['children', 'childrenLabel', 'customLabelClassName'])}
      name={name}
      readOnly={readOnly}
      disabled={readOnly}
      value={value}
      type="text"
      onChange={handleOnChange}
      onKeyPress={handleOnKeyPress}
      onKeyUp={handleOnKeyUp}
      errorMessage={errorMessage}
    />
  );
};
