import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  addRow,
  removeOptionHomeObject,
  removeRow,
  reset,
  updateBuckHomeObject,
  updateHomeObject,
  updateHomeObjectEntity,
  uploadBaseLayer,
  uploadImageOption,
  uploadPreviewLayer,
} from 'app/entities/project/project.reducer';
import { useAppDispatch } from 'app/config/store';
import PSD from 'psd.js';
import { debounce, isEmpty, round, uniqueId } from 'lodash';
import { useParams } from 'react-router';
import { BASIC_LAYER, PREVIEW_LAYER } from 'app/modules/dictionaries/constants';
import { MENU } from 'app/config/constants';
import { b64toBlob } from 'app/shared/util/entity-utils';

export const getLayerName = layer => {
  let name = layer?.name;

  if (layer.parent && layer.parent.name) {
    name = getLayerName(layer.parent) + ' - ' + name;
  }
  return name;
};

export const useLayerOptions = (psdImage: string) => {
  const [layers, setLayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (!isEmpty(psdImage) && id) {
      setLoading(true);
      parseLayers(psdImage);
    }
  }, [psdImage, id]);

  const parseLayers = async fileUrl => {
    const psdFile = await PSD.fromURL(fileUrl);
    const allLayers = psdFile.tree().descendants();
    const layerList = allLayers.filter(allLayer => allLayer.childless());
    const newLayers = layerList.map((layer, layerIndex) => {
      const layerItem: any = {};
      const layerItemName = getLayerName(layer);
      layerItem.visible = false;
      layerItem.src = layer.layer.image.toBase64();
      layerItem.id = layerItemName;
      layerItem.order = layerIndex;
      layerItem.top = layer?.coords.top;
      layerItem.width = layer.width;
      layerItem.height = layer.height;
      layerItem.left = layer?.coords.left;
      layerItem.value = layerItemName;
      layerItem.label = layerItemName;
      return layerItem;
    });

    setLayers(newLayers);
    setLoading(false);
  };
  return { layers, setLayers, loading, setLoading };
};
export const useTransformSection = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [button, setButton] = useState(null);

  const onAddRow = (key, defaultValue = { key: uniqueId() }) => {
    dispatch(
      addRow({
        key,
        defaultValue,
      })
    );
  };

  const onRemoveRow = (key, index) => {
    dispatch(removeRow({ key, index }));
  };

  const onChangeSelect = pathComponent => (objSelected, meta) => {
    dispatch(updateHomeObject({ name: meta.name, value: objSelected?.value, pathComponent }));
  };

  const onChangeCheckbox = pathComponent => event => {
    dispatch(updateHomeObject({ name: event.target.name, value: event.target.checked, pathComponent }));
  };

  const onChangeInput = debounce(ev => {
    dispatch(updateHomeObject({ name: ev.target.name, value: ev.target.value }));
  }, 300);

  const onChangeInputNumber = ev => {
    dispatch(updateHomeObject({ name: ev.target.name, value: Number(ev.target.value) }));
  };

  const selectPropertyOption = useCallback(
    pathOption => (objSelected, meta) => {
      if (meta.action === 'clear' && objSelected === null) {
        dispatch(
          removeOptionHomeObject({
            keys: [
              `${pathOption}.imageLayerName`,
              `${pathOption}.positionTop`,
              `${pathOption}.positionLeft`,
              `${pathOption}.imageWidth`,
              `${pathOption}.imageHeight`,
              `${pathOption}.imageOrder`,
            ],
          })
        );
      } else {
        const formData = new FormData();
        formData.append('file', b64toBlob(objSelected.src), 'option.png');
        dispatch(uploadImageOption({ path: pathOption, formData }));
        dispatch(
          updateBuckHomeObject({
            name: pathOption,
            value: {
              imageLayerName: objSelected?.value,
              positionTop: objSelected.top,
              positionLeft: objSelected.left,
              imageWidth: objSelected.width,
              imageHeight: objSelected.height,
              imageOrder: objSelected.order,
            },
          })
        );
      }
    },
    [removeOptionHomeObject, updateBuckHomeObject, uploadImageOption]
  );

  const parseFilePsd = (file, setIsLoading, setLayers, isInterior = false) => {
    setIsLoading(true);

    const reader = new FileReader();
    reader.onload = async (e: any) => {
      try {
        const psd = new PSD(new Uint8Array(e.target.result));
        psd.parse();
        const psdTree = psd.tree();
        const allLayers = psdTree.descendants();
        const layerList = allLayers.filter(allLayer => allLayer.childless());

        const basicLayer = layerList.find(
          (layerItem, layerItemIndex) => layerItem?.name?.toLowerCase().endsWith(BASIC_LAYER) || layerItemIndex === layerList.length - 1
        );

        const buildingImage = basicLayer.layer.image.toBase64();
        const data = new FormData();

        data.append('file', b64toBlob(buildingImage), 'building-image.png');
        dispatch(
          uploadBaseLayer({
            formData: data,
            isInterior,
          })
        );
        const previewLayer = layerList.find(layerItem => layerItem?.name?.toLowerCase().endsWith(PREVIEW_LAYER)) || layerList[0];
        if (previewLayer) {
          const previewImage = previewLayer.layer.image.toBase64();

          const formData = new FormData();

          formData.append('file', b64toBlob(previewImage), 'building-preview-image.png');
          dispatch(
            uploadPreviewLayer({
              formData,
              isInterior,
            })
          );
        }
        if (isInterior) {
          dispatch(
            updateHomeObjectEntity({
              interiorPositionTop: basicLayer.coords.top,
              interiorPositionLeft: basicLayer.coords.left,
              interiorPsdImageWidth: psdTree.width,
              interiorPsdImageHeight: psdTree.height,
              interiorBaseLayerWidth: basicLayer.width,
              interiorBaseLayerHeight: basicLayer.height,
            })
          );
        } else {
          dispatch(
            updateHomeObjectEntity({
              positionTop: basicLayer.coords.top,
              positionLeft: basicLayer.coords.left,
              psdImageWidth: psdTree.width,
              psdImageHeight: psdTree.height,
              baseLayerWidth: basicLayer.width,
              baseLayerHeight: basicLayer.height,
            })
          );
        }

        const newLayers = layerList.map(async (layer, layerIndex) => {
          const layerItem: any = {};
          const name = getLayerName(layer);
          layerItem.label = name;
          layerItem.value = name;
          layerItem.top = layer?.coords.top;
          layerItem.order = layerIndex;
          layerItem.width = layer.width;
          layerItem.height = layer.height;
          layerItem.left = layer?.coords.left;
          layerItem.src = layer.layer.image.toBase64();
          return await layerItem;
        });

        await Promise.all(newLayers).then(result => {
          setLayers(result);
          setIsLoading(false);
          return true;
        });
      } catch (error) {
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
        return false;
      }
    };
    reader.onerror = function (e: any) {
      return false;
    };
    reader.readAsArrayBuffer(file);
    return true;
  };

  const onClickCancel = () => {
    dispatch(reset());
    history.push(MENU.ADMIN.HOME_OBJECT);
  };

  const calcTotalPrice = (quantity = 0, netPurchasingPrice = 0, labour = 0) => {
    const totalPrice = quantity * netPurchasingPrice || 0;
    return round(totalPrice + Number(labour), 2);
  };

  return {
    onChangeSelect,
    onChangeInput,
    onChangeInputNumber,
    onRemoveRow,
    onAddRow,
    button,
    setButton,
    onClickCancel,
    onChangeCheckbox,
    selectPropertyOption,
    parseFilePsd,
    calcTotalPrice,
  };
};
