import './default-layout.scss';
import React, { createContext, useState } from 'react';
import Footer from 'app/shared/layout/footer/footer';
import { useAppSelector } from 'app/config/store';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation } from 'react-router-dom';
import Loader from 'app/shared/layout/Loader/Loader';
import TopNavWrapper from "app/shared/layout/TopNavMenu/TopNavWrapper";

export interface INavbarLayout {
  navbarTop?: number;
  setNavbarTop?: (navbarTop: number) => void;
}

export const NavbarLayoutContext = createContext<INavbarLayout>({});

const DefaultLayout = ({ children }) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const { pathname } = useLocation();
  const withBGImage = pathname === '/' || pathname === '/welcome' || pathname === '/logout';
  const withoutFooter = pathname === '/welcome' || pathname === '/logout';
  const withFooter =
    pathname === '/blog' ||
    pathname === '/privacy-policy' ||
    pathname.includes('/blog/', 0) ||
    pathname === '/faq' ||
    pathname === '/configurator' ||
    pathname === '/how-it-works' ||
    pathname === '/contact' ||
    pathname === '/about-us' ||
    pathname === '/our-services' ||
    pathname === '/cart' ||
    pathname === '/offer' ||
    pathname === '/offer/list' ||
    (pathname.includes('/offer/', 0) && pathname.includes('/home-object'));
  const [navbarTop, setNavbarTop] = useState(107);

  if (!currentLocale) return <Loader />;

  return (
    <div id="app-container" className={withBGImage || withFooter ? 'without-margin' : ''}>
      <NavbarLayoutContext.Provider value={{ navbarTop, setNavbarTop }}>
        <TopNavWrapper/>
        <main style={{ position: 'absolute', top: navbarTop, left: 0, width: '100%' }}>
          <div id="app-view-container">
            <ErrorBoundary>
              <DndProvider backend={HTML5Backend}>
                <div className="view-routes container-fluid">{children}</div>
              </DndProvider>
            </ErrorBoundary>
          </div>
          {((withBGImage && !withoutFooter) || withFooter) && <Footer />}
        </main>
      </NavbarLayoutContext.Provider>
    </div>
  );
};

export default DefaultLayout;

export const NotFoundLayout = ({ children }) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  if (!currentLocale) return <Loader />;

  return (
    <div id="app-container">
      <main>
        <div id="app-view-container">
          <ErrorBoundary>
            <DndProvider backend={HTML5Backend}>
              <div className="view-routes container-fluid">{children}</div>
            </DndProvider>
          </ErrorBoundary>
        </div>
      </main>
    </div>
  );
};
export const ConfigurationLayout = ({ children }) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const [navbarTop, setNavbarTop] = useState(107);

  if (!currentLocale) return <Loader />;

  return (
    <div id="app-container">
      <NavbarLayoutContext.Provider value={{ navbarTop, setNavbarTop }}>
      <TopNavWrapper isConfiguration={true} />
      <main style={{ position: 'absolute', top: navbarTop, left: 0, width: '100%' }}>
        <div id="app-view-container">
          <ErrorBoundary>
            <DndProvider backend={HTML5Backend}>
              <div className="view-routes container-fluid">{children}</div>
            </DndProvider>
          </ErrorBoundary>
        </div>
      </main>
      </NavbarLayoutContext.Provider>
    </div>
  );
};


export const HomeObjectLayout = ({ children }) => {
  const [navbarTop, setNavbarTop] = useState(107);

  return (
    <div id="app-container">
      <NavbarLayoutContext.Provider value={{ navbarTop, setNavbarTop }}>
        <TopNavWrapper isHomeObject={true} />
        <main style={{ position: 'absolute', top: navbarTop, left: 0, width: '100%' }}>
          <div id="app-view-container">
            <ErrorBoundary>
              <DndProvider backend={HTML5Backend}>
                <div className="view-routes container-fluid">{children}</div>
              </DndProvider>
            </ErrorBoundary>
          </div>
        </main>
      </NavbarLayoutContext.Provider>
    </div>
  );
};
