export interface IImageOption {
  id?: number;
  namePl?: string;
  nameEn?: string;
  isActive?: boolean;
  uploadedImagePath?: string;
  uploadedImageUrl?: string;
  categoryId?: number;
}

export const defaultValue: Readonly<IImageOption> = {};
