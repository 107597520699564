import { IOrder } from 'app/shared/model/order.model';

export interface IInvoiceOrderInformation {
  id?: number;
  companyName?: string;
  tin?: string;
  street?: string;
  buildingNumber?: string;
  flatNumber?: string;
  zipcode?: string;
  city?: string;
  order?: IOrder;
}

export const defaultValue: Readonly<IInvoiceOrderInformation> = {};
