import React from 'react';
import Translate, { translate } from 'app/shared/layout/Translation/translate';

export const BUILDING_TYPE_ID = {
  SUMMER_TYPE: 1,
  ALL_SEASON_TYPE: 2,
  ALL: 3,
};
export const useBuildingTypeOptions = () => {
  return [
    {
      id: 1,
      key: 1,
      value: 1,
      enumKey: 'SUMMER_TYPE',
      translateLabel: <Translate contentKey={'proEcoApp.project.summerType'} />,
      label: translate(`proEcoApp.project.summerType`),
    },
    {
      id: 2,
      key: 2,
      value: 2,
      enumKey: 'ALL_SEASON_TYPE',
      translateLabel: <Translate contentKey={'proEcoApp.project.allSeasonType'} />,
      label: translate(`proEcoApp.project.allSeasonType`),
    },
    {
      id: 3,
      key: 3,
      value: 3,
      enumKey: 'ALL',
      translateLabel: <Translate contentKey={'proEcoApp.project.all'} />,
      label: translate(`proEcoApp.project.all`),
    },
  ];
};
