import React, { FC, useMemo } from 'react';
import ComponentCollapse from 'app/modules/administration/arrangement/Components/ComponentCollapse';
import { IComponents } from 'app/shared/model/component.model';
import { HeaderCard } from 'app/modules/administration/arrangement/Components/arrangement-section/header-card';
import PropertiesOptions from 'app/modules/administration/arrangement/Components/arrangement-section-summary/select-properties-options/properties-options';

import {
  SHELL_AND_CORE_STEP,
  FURNISHING_STEP,
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import { useComponentType } from 'app/modules/administration/arrangement/hooks/useComponentType';
import { flatMap } from 'lodash';
import { SUB_COMPONENT_PROPERTY_TYPES } from 'app/config/constants';
import { useOptionDescriptions, useOptionNames } from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';
import SelectPropertyName from 'app/modules/administration/arrangement/Components/select-properties-options/select-property-name';

interface IComponentPart {
  component: IComponents;
  componentIndex: number;
  shellAndCoreIndex: number;
}

export const ComponentPart: FC<IComponentPart> = ({ component, componentIndex, shellAndCoreIndex }: IComponentPart) => {
  const materialGroups = component?.materialGroups;

  const componentTypesOptions = useComponentType();
  const componentTypesLabel = componentTypesOptions?.find(option => option?.id === component?.componentTypeId)?.translateLabel;

  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const options = useMemo(() => {
    return flatMap(
      materialGroups?.map((materialGroup, materialGroupIndex) => {
        return materialGroup?.componentProperties
          ?.map((property, propertyIndex) => ({
            ...property,
            label: nameOptions?.find(nameOption => nameOption.id === property?.cpNameId)?.label,
            children: (
              <>
                <div className="properties-header-description">
                  {descriptionOptions?.find(descriptionOption => descriptionOption?.id === property?.cpDescriptionId)?.label}
                </div>
              </>
            ),
            path: `shellAndCores[${shellAndCoreIndex}].components[${componentIndex}].materialGroups[${materialGroupIndex}].componentProperties[${propertyIndex}].isSelected`,
          }))
          ?.filter(item => item?.isSelected);
      })
    );
  }, [materialGroups, nameOptions, descriptionOptions]);

  return (
    <div key={componentIndex} className={'ml-4'}>
      <ComponentCollapse
        header={isOpen => (options?.length >= 1 ? <HeaderCard label={componentTypesLabel} isOpen={isOpen}  isHeader={false}/> : null)}
        id={`component-${componentIndex}`}
        defaultOpen={false}
      >
        <div className={'component-properties'}>
          <PropertiesOptions
            currentStep={
              SUB_COMPONENT_PROPERTY_TYPES?.ELECTRICIAN_ADDONS !== component.componentTypeId ? SHELL_AND_CORE_STEP : FURNISHING_STEP
            }
            options={options}
            disabled={true}
            name={'component'}
            optionIdLabel={`option-component-${component?.id}`}
          />
        </div>
      </ComponentCollapse>
    </div>
  );
};
