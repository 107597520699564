import { ImageLoader } from 'app/shared/layout/Loader/Loader';
import ImageLayer from 'app/modules/administration/arrangement/Components/ImageLayer';
import React, { FC } from 'react';
import "./image-building-wrapper.scss";

interface IImageBuildingWrapper {
  loaded?: boolean;
  isFixed?: boolean;
  height: number;
  navbarTop: number;
  arrangement: any;
  isShow?: boolean;
  scale: number;
  imageRef: any;
  onLoadImage: (number) => void;
  layerLoadedOptions: any[];
  isRenderSeason: boolean;
  isInterior: boolean;
  projectSeasonId: number;
}

const ImageBuildingWrapper: FC<IImageBuildingWrapper> = ({
  loaded,
  height,
  navbarTop,
  isFixed,
  arrangement,
  isShow,
  scale,
  imageRef,
  onLoadImage,
  isRenderSeason,
  layerLoadedOptions,
  isInterior,
  projectSeasonId,
}: IImageBuildingWrapper) => {
  return (
    <div className="building-image-wrapper">
      {!loaded && <ImageLoader />}
      <div
        id={'image-preview'}
        className={`${isFixed ? 'layers' : ''}`}
        style={{
          width: '100%',
          overflow: 'hidden',
          height,
          ...(isFixed
            ? {
                position: 'sticky',
                top: navbarTop,
                left: 0,
              }
            : {
                position: 'relative',
              }),
          ...(loaded
            ? {}
            : {
                position: 'absolute',
                overflow: 'hidden',
                left: '-10000px',
                top: 0,
                visible: 'hidden',
              }),
        }}
      >
        <img
          src={arrangement?.interiorBaseLayerUrl}
          style={{
            width: arrangement.interiorBaseLayerWidth * scale,
            height: arrangement?.interiorBaseLayerHeight * scale,
            position: 'absolute',
            top: (arrangement?.interiorPositionTop || 0) * scale,
            left: (arrangement?.interiorPositionLeft || 0) * scale,
            opacity: isShow && isInterior ? 1 : 0,
          }}
          className={'basic-layer'}
          ref={imageRef}
          onLoad={() => onLoadImage(layerLoadedOptions?.length)}
        />
        <img
          src={arrangement?.baseLayerUrl}
          style={{
            width: arrangement.baseLayerWidth * scale,
            height: arrangement?.baseLayerHeight * scale,
            position: 'absolute',
            top: (arrangement?.positionTop || 0) * scale,
            left: (arrangement?.positionLeft || 0) * scale,
            opacity: isShow && !isInterior ? 1 : 0,
          }}
          className={'basic-layer'}
          ref={imageRef}
          onLoad={() => onLoadImage(layerLoadedOptions?.length)}
        />

        {layerLoadedOptions?.map((layer, i) => (
          <ImageLayer
            key={layer?.id}
            item={layer}
            scale={scale}
            alt={`option-img-${isInterior}-${layer?.optionName}`}
            imageOrder={layer?.imageOrder}
            onLoad={() => onLoadImage(i)}
            isInterior={isInterior}
            isShow={isShow}
          />
        ))}

        {arrangement?.interiorSectionSeasons?.map(sectionSeason => {
          if (sectionSeason?.projectSeasonId === projectSeasonId) {
            return (
              <ImageLayer
                key={`season-${sectionSeason?.id}`}
                item={{ ...sectionSeason, isSelected: isRenderSeason }}
                scale={scale}
                alt={'interior-section-season-img'}
                isShow={isShow && isInterior}
              />
            );
          }

          return (
            <ImageLayer
              key={`season-${sectionSeason?.id}`}
              item={{ ...sectionSeason, isSelected: false }}
              scale={scale}
              alt={'season-img'}
              isShow={isShow && isInterior}
            />
          );
        })}

        {arrangement?.sectionSeasons?.map(sectionSeason => {
          if (sectionSeason?.projectSeasonId === projectSeasonId) {
            return (
              <ImageLayer
                key={`season-${sectionSeason?.id}`}
                item={{ ...sectionSeason, isSelected: isRenderSeason }}
                scale={scale}
                alt={'season-img'}
                isShow={isShow && !isInterior}
              />
            );
          }

          return (
            <ImageLayer
              key={`season-${sectionSeason?.id}`}
              item={{ ...sectionSeason, isSelected: false }}
              scale={scale}
              alt={'season-img'}
              isShow={isShow && !isInterior}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ImageBuildingWrapper;
