import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {createEntitySlice, EntityState, serializeAxiosError} from 'app/shared/reducers/reducer.utils';
import {defaultValue, ICategory} from 'app/shared/model/category.model';
import {CATEGORY_TYPE} from 'app/config/constants';

const initialState: EntityState<ICategory> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/categories/detail';

// Actions

export const getEntities = createAsyncThunk('interiorDoorType/fetch_entity_list', async (params: any) => {
  return axios.get<ICategory[]>(apiUrl, { params: { ...params, category: CATEGORY_TYPE.INTERIOR_DOOR_TYPES } });
});

export const getEntity = createAsyncThunk(
  'interiorDoorType/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}?category=${CATEGORY_TYPE.INTERIOR_DOOR_TYPES}`;
    return axios.get<ICategory>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const deactivateEntity = createAsyncThunk(
  'interiorDoorType/deactivate_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}/deactivate?category=${CATEGORY_TYPE.INTERIOR_DOOR_TYPES}`;
    return await axios.put<ICategory>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const restoreEntity = createAsyncThunk(
  'interiorDoorType/restore_entity',
  async (id: string | number, thunkAPI) => {
    return await axios.put(`${apiUrl}/${id}/restore?category=${CATEGORY_TYPE.INTERIOR_DOOR_TYPES}`);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'interiorDoorType/create_entity',
  async (entity: ICategory, thunkAPI) => {
    const result = await axios.post<ICategory>(`${apiUrl}?category=${CATEGORY_TYPE.INTERIOR_DOOR_TYPES}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'interiorDoorType/update_entity',
  async (entity: ICategory, thunkAPI) => {
    const result = await axios.put<ICategory>(`${apiUrl}/${entity.id}?category=${CATEGORY_TYPE.INTERIOR_DOOR_TYPES}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'interiorDoorType/partial_update_entity',
  async (entity: ICategory, thunkAPI) => {
    const result = await axios.patch<ICategory>(`${apiUrl}/${entity.id}?category=${CATEGORY_TYPE.INTERIOR_DOOR_TYPES}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'interiorDoorType/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}?category=${CATEGORY_TYPE.INTERIOR_DOOR_TYPES}`;
    const result = await axios.delete<ICategory>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const CategorySlice = createEntitySlice({
  name: 'interiorDoorType',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deactivateEntity, restoreEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, deactivateEntity, restoreEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CategorySlice.actions;

// Reducer
export default CategorySlice.reducer;
