import React, { memo, useMemo } from 'react';
import WallRoomColorItem from 'app/modules/administration/home-object-v2/wall-room-color-item';
import { useWallColorTypeOptions } from 'app/modules/administration/home-object-v2/hooks/useWallColorTypeOptions';

export const WallRoomColors = ({ wallRoomColors = [], path, isDetail = false, wallSize }) => {
  const wallColorTypesOptions = useWallColorTypeOptions(isDetail);
  const wallColorTypes = useMemo(() => wallRoomColors?.map(wallRoomColor => wallRoomColor?.wallColorTypeId), [wallRoomColors]);

  return (
    <>
      {wallRoomColors?.map((wallRoomColor, wallRoomColorIndex) => {
        const options = wallColorTypesOptions?.filter(
          wallColorTypesOption =>
            !wallColorTypes?.includes(wallColorTypesOption?.id) || wallRoomColor.wallColorTypeId === wallColorTypesOption.id
        );
        return (
          <WallRoomColorItem
            key={wallRoomColor?.id ?? wallRoomColor?.key}
            wallRoomColor={wallRoomColor}
            wallRoomColorIndex={wallRoomColorIndex}
            path={path}
            isDetail={isDetail}
            wallSize={wallSize}
            options={options}
            showDelete={!isDetail && wallRoomColors?.length > 1}
          />
        );
      })}
    </>
  );
};

export default memo(WallRoomColors);
