import dayjs from 'dayjs';
import { IDiscountCodeType } from 'app/shared/model/discount-code-type.model';
import { IDiscountCodeStatus } from 'app/shared/model/discount-code-status.model';

export interface IDiscountCode {
  id?: number;
  name?: string;
  validFrom?: string;
  validTo?: string | null;
  quantity?: number | null;
  discountValue?: number;
  code?: string;
  discountCodeType?: IDiscountCodeType;
  discountCodeStatus?: IDiscountCodeStatus;
  discountCodeTypeId?: number;
  discountCodeStatusId?: number;
}

export const defaultValue: Readonly<IDiscountCode> = {};
