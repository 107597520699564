import React from 'react';

import { Switch, useRouteMatch } from "react-router-dom";
import ErrorBoundaryRoute from "app/shared/error/error-boundary-route";
import ComponentPropertyDescriptionUpdateWrapper
  from "app/entities/component-property-description/component-property-description-update-wrapper";
import componentPropertyDescriptionList
  from "app/entities/component-property-description/component-property-description-list";

const Routes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <ErrorBoundaryRoute
        exact path={`${url}/new`} component={ComponentPropertyDescriptionUpdateWrapper}
      />
      <ErrorBoundaryRoute
        exact path={`${url}/:id/edit`} component={ComponentPropertyDescriptionUpdateWrapper}
      />
      <ErrorBoundaryRoute
        exact path={`${url}/:id`} component={ComponentPropertyDescriptionUpdateWrapper}
        componentProps={{ isReadOnly: true }}
      />
      <ErrorBoundaryRoute
        path={url} component={componentPropertyDescriptionList}
      />
    </Switch>
  );
}

export default Routes;
