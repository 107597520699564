import { IArrangementType } from 'app/shared/model/arrangement-type.model';
import { IProject } from 'app/shared/model/project.model';
import {IPromotion} from "app/shared/model/promotion.model";

export interface IArrangement {
  id?: number;
  arrangementName?: string;
  isActive?: boolean;
  vatTypeId?: any;
  vatRate?: any;
  currencyId?: any;
  arrangementType?: IArrangementType;
  price?: any;
  netPrice?: any;
  grossPrice?: any;
  arrangementTypeId?: any;
  project?: IProject;
  projectId?: any;
  parentArrangementId?: any;
  projectSeasonId?: any;
  arrangementDetails?: IArrangementDetails [];
  promotion?: IPromotion;
  wallRoomColorIds?: number [];
  wallRoomColors?: IWallRoomColorIds [];
  offerArrangements?: any[];
  uuid?: string;
}

export interface IArrangementDetails {
  id?: number;
  price?: number;
  equipmentPropertyOptionId?: number;
  componentPropertyOptionId?: number;
  componentPropertyOptionTypeId?: number;
  equipmentPropertyOptionTypeId?: number;
  imageLayerName?: string;
}

export interface IWallRoomColorIds {
  id?: number;
  netSellingPrice?: number;
  price?: number;
  wallColorTypeId?: number;
  imageLayerName?: string;
  wallRoomId?: string;
}

export const defaultValue: Readonly<IArrangement> = {
  isActive: false,
  arrangementDetails: [],
  wallRoomColors: [],
  wallRoomColorIds: [],
};
