import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IContactForm } from 'app/shared/model/contact-form.model';

const initialState: EntityState<IContactForm> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  location: null,
};

const apiUrl = 'api/contact-forms';
const apiMapUrl = 'https://www.mapquestapi.com/geocoding/v1/address?key=oPTTAAmIFLlBUl3GCtqEPiMjMuuGrDCm';

// Actions

export const getEntities = createAsyncThunk('contactForm/fetch_entity_list', async (params: any) => {
  return axios.get<IContactForm[]>(apiUrl, { params });
});

export const getEntity = createAsyncThunk(
  'contactForm/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IContactForm>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'contactForm/create_entity',
  async (entity: IContactForm, thunkAPI) => {
    return axios.post<IContactForm>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'contactForm/update_entity',
  async (entity: IContactForm, thunkAPI) => {
    const result = await axios.put<IContactForm>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const searchLocation = createAsyncThunk('contactForm/fetch_location', async (address: string) => {
  return axios.post<any>(apiMapUrl, {
    maxResults: 1,
    location: address,
  });
});

export const partialUpdateEntity = createAsyncThunk(
  'contactForm/partial_update_entity',
  async (entity: IContactForm, thunkAPI) => {
    const result = await axios.patch<IContactForm>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'contactForm/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<IContactForm>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const approveEntity = createAsyncThunk(
  'contactForm/approve_entity',
  async (entity: any, thunkAPI) => {
    return axios.put(`${apiUrl}/${entity.id}/approve`, entity);
  },
  { serializeError: serializeAxiosError }
);

export const rejectEntity = createAsyncThunk(
  'contactForm/reject_entity',
  async (entity: any, thunkAPI) => {
    return axios.put(`${apiUrl}/${entity.id}/reject`, entity);
  },
  { serializeError: serializeAxiosError }
);

export const closeEntity = createAsyncThunk(
  'contactForm/close_entity',
  async (entityId: number | string, thunkAPI) => {
    return axios.put(`${apiUrl}/${entityId}/close`);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ContactFormSlice = createEntitySlice({
  name: 'contactForm',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(approveEntity, rejectEntity, closeEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(searchLocation), (state, action) => {
        const location = action.payload?.data?.results[0].locations[0].latLng;

        return {
          ...state,
          loading: false,
          location,
        };
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, approveEntity, rejectEntity, closeEntity),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = ContactFormSlice.actions;

// Reducer
export default ContactFormSlice.reducer;
