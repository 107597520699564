import React, { useEffect } from 'react';
import DictionaryItemUpdate from 'app/modules/dictionaries/Components/dictionaryItemUpdate';
import { createEntity, getEntity, reset, updateEntity } from 'app/entities/wall-color-type/wall-color-type.reducer';

import Translate, { translate } from 'app/shared/layout/Translation/translate';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';
import { MIN, onChangePositiveNegativeNumber, REQUIRED } from 'app/shared/util/validation';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { useOptions } from 'app/shared/hooks/useOptions';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import { Row } from 'reactstrap';
import { useCustomFormArr } from 'app/shared/hooks/useCustomForm';
import { convertEnumKey } from 'app/shared/util/entity-utils';
import FileWrapper from 'app/shared/layout/CustomInput/Components/FileWrapper';
import { useRole } from 'app/shared/hooks/useRole';
import { omit } from 'lodash';

interface IWallColorTypeUpdateWrapper {
  isReadOnly?: boolean;
}

interface IFieldsState {
  propertyOptionTypePrice: any;
  imagePath: any;
  name?: string;
  enumKey?: string;
  description?: string;
}

const initFieldsState: IFieldsState = {
  name: null,
  enumKey: null,
  description: null,
  propertyOptionTypePrice: {
    priceTypeId: null,
    netSellingPrice: null,
  },
  imagePath: null,
};

const WallColorTypeUpdateWrapper = ({ isReadOnly }: IWallColorTypeUpdateWrapper) => {
  const { id } = useParams<{ id?: string }>();
  const isNew = !id;
  const dispatch = useAppDispatch();
  const wallColorTypeEntity = useAppSelector(state => state.wallColorType.entity);
  const { priceTypesOptions } = useOptions(TRANSLATED_DICTS_KEYS.PRICE_TYPES);
  const { isSale, isPurchasing } = useRole();
  const canEdit = !isSale && !isPurchasing;

  const { fieldsState, setFieldsState, onChangeSelect, onChangeInput } = useCustomFormArr({ initFields: { ...initFieldsState } });

  useEffect(() => {
    if (wallColorTypeEntity) {
      setFieldsState({
        ...wallColorTypeEntity,
        propertyPrice: {
          ...wallColorTypeEntity?.propertyPrice,
          priceTypeId: priceTypesOptions?.find(priceTypesOption => priceTypesOption.id === wallColorTypeEntity?.propertyPrice?.priceTypeId),
        },
      });
    }
  }, [wallColorTypeEntity, priceTypesOptions]);

  useEffect(() => {
    if (wallColorTypeEntity && isNew) {
      setFieldsState(prev => ({ ...prev, enumKey: convertEnumKey(fieldsState.name) }));
    }
  }, [fieldsState.name]);

  const additionalFields = [
    {
      readOnly: isReadOnly,
      name: 'name',
      id: `wall-color-type-name`,
      label: <Translate contentKey={`proEcoApp.wallColorType.name`} />,
      validate: {
        required: { value: true, errorMessage: translate('entity.validation.required') },
        maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
      },
      value: fieldsState.name,
      onChange: onChangeInput,
    },
    {
      readOnly: isReadOnly,
      name: 'description',
      type: 'textarea',
      textareaLengthMax: 200,
      id: `wall-color-type-description`,
      label: <Translate contentKey={`proEcoApp.wallColorType.description`} />,
      validate: {
        required: { value: true, errorMessage: translate('entity.validation.required') },
        maxLength: { value: 200, errorMessage: translate('entity.validation.maxlength', { max: 200 }) },
      },
    },
    {
      name: 'enumKey',
      label: <Translate contentKey={`proEcoApp.wallColorType.enumKey`} />,
      id: `wall-color-type-enumKey`,
      type: 'hidden',
      value: fieldsState.enumKey,
    },
  ];

  const pricesFields = [
    {
      readOnly: isReadOnly,
      name: 'propertyPrice.priceTypeId',
      id: 'propertyPrice.priceTypeId',
      validate: {
        ...REQUIRED(),
      },
      type: 'select',
      options: priceTypesOptions,
      value: fieldsState?.propertyPrice?.priceTypeId,
      onChange: onChangeSelect,
      label: <Translate contentKey="proEcoApp.wallColorType.priceTypeId" />,
      col: { sm: 6 },
    },
    {
      readOnly: isReadOnly,
      name: 'propertyPrice.netPurchasingPrice',
      id: 'propertyPrice.netPurchasingPrice',
      validate: {
        ...MIN(0),
        ...REQUIRED(),
      },
      value: fieldsState?.propertyPrice?.netPurchasingPrice,
      onChange: onChangeInput,
      onKeyPress: onChangePositiveNegativeNumber,
      label: <Translate contentKey="proEcoApp.wallColorType.netSellingPrice" />,
      col: { sm: 6 },
    },
  ];

  const setImage = item => {
    setFieldsState(prev => ({ ...prev, imageUrl: item }));
  };

  const customSave = values => {
    const entity = {
      ...omit(values, ['imagePath', 'image', 'file', 'required']),
      imagePath: typeof fieldsState.imageUrl === 'string' ? wallColorTypeEntity?.imagePath : null,
      isActive: id ? wallColorTypeEntity?.isActive : true,
    };
    const formData = new FormData();
    formData.append(
      'wallColorTypeData',
      new Blob([JSON.stringify(id ? { ...entity, id } : entity)], {
        type: 'application/json',
      })
    );
    formData.append('image', fieldsState.imageUrl);
    if (id) {
      dispatch(updateEntity({ entity: formData, id }));
    } else {
      dispatch(createEntity(formData));
    }
  };

  return (
    <DictionaryItemUpdate
      updateEntity={updateEntity}
      createEntity={createEntity}
      customSave={customSave}
      reset={reset}
      getEntity={getEntity}
      additionalFields={additionalFields}
      number={1}
      pathArray={[
        { path: 'admin/dashboard', translation: 'global.menu.admin.mainPage' },
        { path: 'admin/dict', translation: 'global.menu.admin.dictionaries' },
        {
          path: `admin/dict/wall-color-type`,
          translation: `proEcoApp.wallColorType.home.title`,
        },
        {
          path: '',
          translation: id ? (isReadOnly ? 'global.form.detailForm' : 'global.form.editForm') : 'global.form.createForm',
        },
      ]}
    >
      <FileWrapper
        readOnly={isReadOnly || !canEdit}
        image={fieldsState.imageUrl}
        setImage={setImage}
        validate={{ maxSize: { value: 10 } }}
        acceptFile="image/png, image/jpeg"
      />
      <Row>
        <PartForm fields={pricesFields} number={2} titleClass="mt-4" />
      </Row>
    </DictionaryItemUpdate>
  );
};
export default WallColorTypeUpdateWrapper;
