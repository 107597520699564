import React, { useMemo } from 'react';
import { useAppSelector } from 'app/config/store';
import { getDictOptions } from 'app/shared/util/select-utils';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';

export const useComponentType = (isDetail = false) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const componentTypeList = useAppSelector(state => state.componentType.entities);

  return useMemo(() => {
    return getDictOptions(componentTypeList, TRANSLATED_DICTS.COMPONENT_TYPES, null, isDetail);
  }, [componentTypeList, isDetail, currentLocale]);
};
