import React, { useEffect } from 'react';
import {
  deactivateEntity,
  restoreEntity,
  getEntities,
  reset,
  createEntity,
  updateEntity,
  getEntity,
} from 'app/entities/category-detail/category-detail.reducer';
import { Switch, useRouteMatch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { DictionaryItemList } from 'app/modules/dictionaries/Components/dictionaryItemList';
import { useRole } from 'app/shared/hooks/useRole';
import OptionUpdateWrapper from 'app/shared/layout/OptionDictionary/option-update-wrapper';
import {
  getEntities as getFloorMaterialTypes,
  reset as resetMaterialType,
} from 'app/entities/floor-material-type/floor-material-type.reducer';
import { defaultPagination } from 'app/shared/util/pagination.constants';

import { useAppDispatch } from 'app/config/store';

const Routes = () => {
  const { url } = useRouteMatch();
  const dispatch = useAppDispatch();
  const { isAdmin, isSystem, isDesign, isMarketing } = useRole();

  useEffect(() => {
    dispatch(getFloorMaterialTypes(defaultPagination));
    return () => {
      dispatch(resetMaterialType());
      dispatch(reset());
    };
  }, []);

  return (
    <Switch>
      <ErrorBoundaryRoute
        exact
        path={`${url}/new`}
        component={OptionUpdateWrapper}
        componentProps={{
          createEntity,
          getEntities,
          reset,
          additionalTableColumns: ['materialTypeId', 'propertyPrice'],
          canAction: isAdmin || isSystem || isDesign,
          canEdit: !isMarketing,
          isCategoryDetail: true,
        }}
      />
      <ErrorBoundaryRoute
        exact
        path={`${url}/:id/edit`}
        component={OptionUpdateWrapper}
        componentProps={{
          updateEntity,
          getEntity,
          getEntities,
          reset,
          additionalTableColumns: ['materialTypeId', 'propertyPrice'],
          canAction: isAdmin || isSystem || isDesign,
          canEdit: !isMarketing,
          isCategoryDetail: true,
        }}
      />
      <ErrorBoundaryRoute
        exact
        path={`${url}/:id`}
        component={OptionUpdateWrapper}
        componentProps={{
          isReadOnly: true,
          getEntity,
          isCategoryDetail: true,
        }}
      />

      <ErrorBoundaryRoute
        path={url}
        component={DictionaryItemList}
        componentProps={{
          deleteEntity: deactivateEntity,
          restoreEntity,
          getEntities,
          reset,
          additionalTableColumns: ['materialTypeId', 'propertyPrice'],
          canAction: isAdmin || isSystem || isDesign,
          canEdit: !isMarketing,
          isCategoryDetail: true,
        }}
      />
    </Switch>
  );
};

export default Routes;
