import axios from 'axios';
import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';

import {serializeAxiosError} from 'app/shared/reducers/reducer.utils';
import {get, omit, set, unset} from 'lodash';
import {IProject, defaultValue as projectDefault} from 'app/shared/model/project.model';
import {IArrangement as IOldArrangement, defaultValue as arrangementDefault} from 'app/shared/model/arrangement.model';
import {getFileNameFromContentDisposition} from 'app/shared/util/entity-utils';
import {SUMMER_SEASON_ID} from "app/config/constants";

export type IArrangement = IProject &
  IOldArrangement & {
  previewLayerPath?: string;
  previewLayerUrl?: string;
  previewImageUrl?: string;
  interiorPreviewLayerPath?: string;
  interiorPreviewLayerUrl?: string;
  interiorPreviewImageUrl?: string;
  hasArrangement?: boolean;
  arrangement: {
    id?: number;
    buildingTypeId?: number;
    arrangementName?: string;
    arrangementTypeId?: number;
    netPrice?: number;
    grossPrice?: number;
    projectSeasonId?: number;
    parentArrangementId?: number;
    offerArrangements?: any[];
    vatRate?: number;
  };
};

const entityDefault: IArrangement = {
  arrangement: {
    projectSeasonId: SUMMER_SEASON_ID
  },
  ...projectDefault,
  ...arrangementDefault,
};

const initialState = {
  loading: false,
  loadingSuccess: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IArrangement>,
  entity: {} as IArrangement,
  transformDataFinish: false,
  exportData: null,
  updating: false,
  updateSuccess: false,
  updateSubmitSuccess: false,
  totalItems: 0,
};

const apiUrl = 'api/arrangements';
const apiProjectUrl = 'api/projects';
export const getEntity = createAsyncThunk(
  'arrangements/fetch_entity_config',
  async (id: string | number) => {
    const requestUrl = `${apiProjectUrl}/${id}`;
    return axios.get<IArrangement>(requestUrl);
  },
  {serializeError: serializeAxiosError}
);

export const getEntityV2 = createAsyncThunk(
  'arrangements/fetch_entity_config-v2',
  async (id: string | number) => {
    const requestUrl = `${apiProjectUrl}/${id}/v2`;
    return axios.get<IArrangement>(requestUrl);
  },
  {serializeError: serializeAxiosError}
);

export const getArrangements = createAsyncThunk('arrangements/fetch_arrangements', async (params: any) => {
  return axios.get<IArrangement[]>(apiUrl, {params});
});

export const getArrangementsSimplify = createAsyncThunk('arrangements/fetch_arrangements_simplify', async (params: any) => {
  const requestUrl = `${apiUrl}/simplify`;
  return axios.get<IArrangement[]>(requestUrl, {params});
});

export const getArrangement = createAsyncThunk(
  'arrangements/fetch_arrangement_detail',
  async (id: string) => {
    const requestUrl = `${apiUrl}/${id}/config`;
    return axios.get<IArrangement>(requestUrl);
  },
  {serializeError: serializeAxiosError}
);

export const getArrangementShared = createAsyncThunk(
  'arrangements/fetch_arrangement_shared_detail',
  async (id: string) => {
    const requestUrl = `${apiUrl}/share/${id}`;
    const result = await axios.get<IArrangement>(requestUrl);
    if (result.status === 200) {
      unset(result, "headers['x-proecoapp-alert']");
      return result;
    }
    return result;
  },
  {serializeError: serializeAxiosError}
);

export const createArrangement = createAsyncThunk(
  'arrangements/create_arrangement',
  async (arrangement: IArrangement) => {
    return await axios.post<IArrangement>(apiUrl, arrangement);
  },
  {serializeError: serializeAxiosError}
);

export const shareArrangement = createAsyncThunk(
  'arrangements/share_arrangement',
  async (id: string) => {
    const requestUrl = `${apiUrl}/${id}/share`;
    return await axios.put<string>(requestUrl);
  },
  {serializeError: serializeAxiosError}
);

export const updateArrangement = createAsyncThunk(
  'arrangements/update_arrangement',
  async (arrangement: IArrangement) => {
    const requestUrl = `${apiUrl}/${arrangement?.arrangement?.id}`;
    return axios.put<IArrangement>(requestUrl, arrangement);
  },
  {serializeError: serializeAxiosError}
);

export const deleteArrangement = createAsyncThunk(
  'arrangements/delete_arrangement',
  async (id: string) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<IArrangement>(requestUrl);
  },
  {serializeError: serializeAxiosError}
);

export const deactivateArrangement = createAsyncThunk(
  'arrangements/deactivate_entity',
  async (arrangement: IArrangement, thunkAPI) => {
    await axios.put(`${apiUrl}/${arrangement?.id}/deactivate`, arrangement);
    thunkAPI.dispatch(getArrangements({}));
    return;
  },
  {serializeError: serializeAxiosError}
);

type ICloneArrangement = {
  arrangementId: number;
  name: string;
};
export const cloneArrangement = createAsyncThunk(
  'arrangements/clone_entity',
  async (data: ICloneArrangement, thunkAPI) => {
    const result = await axios.post(`${apiUrl}/clone`, data);
    thunkAPI.dispatch(getArrangements({}));
    return result;
  },
  {serializeError: serializeAxiosError}
);

type IDownloadArrangement = { id: number; };
export const downloadArrangement = createAsyncThunk(
  'arrangements/download_arrangement',
  async ({id}: IDownloadArrangement, thunkAPI) => {
    const requestUrl = `${apiUrl}/generate-pdf/${id}`;
    const result = await axios.get(requestUrl, {
      responseType: 'blob',
    });
    return result;
  },
  {serializeError: serializeAxiosError}
);

export const restoreArrangement = createAsyncThunk(
  'arrangements/restore_entity',
  async (arrangement: IArrangement, thunkAPI) => {
    const response = await axios.put(`${apiUrl}/${arrangement?.id}/restore`, arrangement);
    thunkAPI.dispatch(getArrangements({}));
    return response;
  },
  {serializeError: serializeAxiosError}
);

export type arrangementManagementState = Readonly<typeof initialState>;

export const ArrangementManagementSlice = createSlice({
  name: 'arrangement',
  initialState: initialState as arrangementManagementState,
  reducers: {
    reset() {
      return initialState;
    },
    updateArrangementEntity(state, action) {
      return {
        ...state,
        entity: {
          ...state?.entity,
          ...action.payload,
        },
      };
    },
    updateBuildingType(state, action) {
      state.entity.arrangement.buildingTypeId = action.payload?.buildingTypeId;
      state.entity?.shellAndCores?.forEach(shellAndCore => {
        shellAndCore?.components?.forEach(component => {
          component?.materialGroups?.forEach(materialGroup => {
            materialGroup?.componentProperties?.forEach(componentProperty => {
              componentProperty.isSelected = false;
            });
          });
        });
      });
    },

    updateArrangementData(state, action) {
      state.entity.arrangement = {
        ...state.entity.arrangement,
        ...action.payload,
      };
    },

    updateSelectedOption(state, action) {
      const newEntity = {...state.entity};
      if (action.payload.pathGroup) {
        const materialGroups = get(newEntity, action.payload.pathGroup);
        materialGroups?.map((materialGroup, materialGroupIndex) => {
          const options = materialGroup?.[action.payload.pathGroupKey];
          set(
            newEntity,
            `${action.payload.pathGroup}[${materialGroupIndex}].${action.payload.pathGroupKey}`,
            options.map(option => ({...option, isSelected: false}))
          );
        });
      }
      set(newEntity, action.payload.name, action.payload.value);
      state.entity = newEntity;
    },

    updateSelectSingleOption(state, action) {
      const newEntity = {...state.entity};
      if (action.payload.path) {
        const options = get(state.entity, action.payload.path);
        set(
          newEntity,
          action.payload.path,
          options?.map(option => ({...option, isSelected: false}))
        );
      }
      set(newEntity, action.payload.name, action.payload.value);
      state.entity = newEntity;
    },

    updateMultiSelectedOption(state, action) {
      const newEntity = {...state.entity};
      if (action.payload.path) {
        const options = get(state.entity, action.payload.path);
        set(
          newEntity,
          action.payload.path,
          options.map(option => ({...option, isSelected: false}))
        );
      }
      set(newEntity, action.payload.name, action.payload.value);
      state.entity = newEntity;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getArrangement.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
        state.loadingSuccess = true;
        state.transformDataFinish = false;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = {
          ...state?.entity,
          ...omit(action.payload.data, 'arrangement'),
        };
        state.loadingSuccess = true;
        state.transformDataFinish = false;
      })
      .addCase(getEntityV2.fulfilled, (state, action) => {
        state.loading = false;
        const data = omit(action.payload.data, 'arrangement');
        state.entity = {...state.entity, ...data,};
        if (!action?.payload?.data?.arrangement?.projectSeasonId) {
          state.entity.arrangement.projectSeasonId = SUMMER_SEASON_ID;
        }
        state.loadingSuccess = true;
        state.transformDataFinish = false;
      })
      .addCase(getArrangementShared.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingSuccess = true;
        state.entity = action.payload.data;
        state.transformDataFinish = false;
      })
      .addCase(deleteArrangement.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = entityDefault;
      })
      .addMatcher(isFulfilled(getArrangements, getArrangementsSimplify), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(restoreArrangement, deactivateArrangement, cloneArrangement), state => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(createArrangement, updateArrangement), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.updateSubmitSuccess = true;
        state.entity = {
          ...action.payload.data,
          netPrice: state.entity.netPrice,
        };
        state.transformDataFinish = false;
      })
      .addMatcher(isFulfilled(downloadArrangement), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = true;
        state.exportData = {
          data: action?.payload?.data,
          fileName: getFileNameFromContentDisposition(action?.payload?.headers['content-disposition']),
        };
      })
      .addMatcher(isFulfilled(shareArrangement), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.entity.uuid = action.payload.data;
      })
      .addMatcher(isPending(getArrangements, getArrangementsSimplify, getArrangement, getArrangementShared, getEntity, getEntityV2), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.loadingSuccess = false;
      })
      .addMatcher(isPending(createArrangement, updateArrangement), state => {
        state.errorMessage = null;
        state.updateSubmitSuccess = false;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(
        isPending(shareArrangement, deleteArrangement, restoreArrangement, deactivateArrangement, cloneArrangement, downloadArrangement),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      )
      .addMatcher(
        isRejected(
          getArrangements,
          getArrangementsSimplify,
          getArrangement,
          createArrangement,
          updateArrangement,
          deleteArrangement,
          restoreArrangement,
          deactivateArrangement,
          cloneArrangement,
          downloadArrangement,
          getEntity,
          getEntityV2
        ),
        (state, action) => {
          state.loading = false;
          state.updating = false;
          state.updateSuccess = false;
          state.errorMessage = action.error.message;
        }
      );
  },
});

export const {
  reset,
  updateArrangementEntity,
  updateBuildingType,
  updateSelectSingleOption,
  updateSelectedOption,
  updateMultiSelectedOption,
  updateArrangementData,
} = ArrangementManagementSlice.actions;

// Reducer
export default ArrangementManagementSlice.reducer;
