import React from 'react';
import { translate } from 'app/shared/layout/Translation/translate';
import { AvField } from 'availity-reactstrap-validation';
import { TagsInput } from 'react-tag-input-component';
import './tag-input.scss';
import { FormFeedback } from 'reactstrap';

interface ITagInput {
  validate?: any;
  label?: any;
  readOnly?: any;
  value?: any[];
  name?: any;
  placeholder?: any;
  onChange?: any;
  beforeAddValidate?: any;
  errorMessage?: any;
  onBlur?: any;
}

const TagInput = ({
  validate,
  label,
  readOnly,
  value,
  name,
  placeholder,
  onChange,
  errorMessage,
  onBlur,
  beforeAddValidate,
  ...restProps
}: ITagInput) => {
  return (
    <div className="tag-component">
      <TagsInput
        value={value}
        onChange={onChange}
        name={name}
        separators={['Enter', ';', ',']}
        placeHolder={placeholder}
        onBlur={onBlur}
        beforeAddValidate={beforeAddValidate}
        {...restProps}
      />
      {errorMessage ? <FormFeedback>{errorMessage}</FormFeedback>: null}
      {validate && (
        <AvField
          errorMessage={translate('global.messages.validate.invalidField') || 'This field is invalid'}
          type="hidden"
          name={name}
          value={value ? value : ''}
          validate={validate}
        />
      )}
    </div>
  );
};

export default TagInput;
