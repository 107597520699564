import React, { FC, useContext, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DESKTOP_QUERY } from 'app/config/constants';
import { Storage } from 'react-jhipster';
import { NavbarLayoutContext } from 'app/shared/layout/DefaultLayout/default-layout';
import { useMediaQuery } from 'react-responsive';
import TopNav from 'app/shared/layout/TopNavMenu/TopNav';
import TopNavMobile from 'app/shared/layout/TopNavMenu/TopNavMobile';

interface ITopNav {
  isConfiguration?: boolean;
  isHomeObject?: boolean;
}

const TopNavWrapper: FC<ITopNav> = ({ isConfiguration = false, isHomeObject = false }) => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const { setNavbarTop } = useContext(NavbarLayoutContext);
  const isDesktop = useMediaQuery(DESKTOP_QUERY);
  const { pathname } = useLocation();

  const isCart =
    pathname === '/cart/delivery-data' ||
    pathname === '/cart/delivery-and-payment' ||
    pathname === '/cart/summary' ||
    pathname === '/cart/finish';

  useLayoutEffect(() => {
    setNavbarTop(navbarRef.current.clientHeight);
  },[]);

  return (
    <div ref={navbarRef} className={'fixed-top'}>
      {isDesktop ? (
        <TopNav isCart={isCart} isConfiguration={isConfiguration} isHomeObject={isHomeObject} />
      ) : (
        <TopNavMobile isCart={isCart} isConfiguration={isConfiguration} isHomeObject={isHomeObject} />
      )}
    </div>
  );
};

export default TopNavWrapper;
