import { useMemo } from 'react';
import { getDictOptions } from 'app/shared/util/select-utils';
import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import { useAppSelector } from 'app/config/store';

export const useRoomTypeOptions = (isDetail = true) => {
  const roomTypesDict = useAppSelector(state => state.roomType.entities);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  return useMemo(
    () => getDictOptions(roomTypesDict, TRANSLATED_DICTS.ROOM_TYPES, null, isDetail),
    [roomTypesDict, isDetail, currentLocale]
  );
};
