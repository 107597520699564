import {useAppDispatch, useAppSelector} from 'app/config/store';
import React, {useEffect} from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import {
  MIN,
  onChangePositiveInput,
  REQUIRED
} from 'app/shared/util/validation';
import {Col, Row} from 'reactstrap';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import Seasons from "app/modules/administration/home-object-v2/seasons";
import {updateHomeObjectEntity} from "app/entities/project/project.reducer";
import {InformationIcon} from "app/shared/layout/InformationIcon/InformationIcon";

interface IFields {
  isActive?: any;
}

export const Others = ({isDetail = false}) => {
  const interiorSectionSeasons = useAppSelector(state => state.project.entity.interiorSectionSeasons);
  const dispatch = useAppDispatch();
  const numberOfCar = useAppSelector(state => state.project.entity.numberOfCar);
  const craneHour = useAppSelector(state => state.project.entity.craneHour);
  const sectionSeasons = useAppSelector(state => state.project.entity?.sectionSeasons);
  useEffect(() => {
    if (sectionSeasons?.length) {
      dispatch(updateHomeObjectEntity({
        interiorSectionSeasons: sectionSeasons?.map((sectionSeason, sectionSeasonIndex) => ({
          projectSeasonId: sectionSeason?.projectSeasonId,
          ...interiorSectionSeasons?.[sectionSeasonIndex],
        }))
      }))
    }
  }, [sectionSeasons]);
  const handleChangeInput = ev => {
    dispatch(
      updateHomeObjectEntity({
        [ev?.target?.name]: Number(ev.target.value),
      })
    );
  };

  const fields = [
    {
      name: 'numberOfCar',
      id: 'numberOfCar',
      readOnly: isDetail,
      label: (
        <InformationIcon
          idIcon="number-of-car-icon"
          textContentKey="proEcoApp.project.numberOfCars"
          tooltipContentKey="proEcoApp.project.numberOfCarsToolTip"
        />
      ),
      onChange: handleChangeInput,
      onKeyPress: onChangePositiveInput,
      validate: {...REQUIRED(), ...MIN(1)},
      value: numberOfCar,
      col: {lg: '3', md: '3'},
      classCol: 'no-mr',
    },
    {
      name: 'craneHour',
      id: 'craneHour',
      readOnly: isDetail,
      label: (
        <InformationIcon
          idIcon="crane-hour-icon"
          textContentKey="proEcoApp.project.craneHours"
          tooltipContentKey="proEcoApp.project.craneHoursToolTip"
        />
      ),
      onChange: handleChangeInput,
      onKeyPress: onChangePositiveInput,
      validate: {...REQUIRED(), ...MIN(1)},
      value: craneHour,
      col: {lg: '3', md: '3'},
      classCol: 'no-mr',
    },
  ];

  return (
    <Row className="justify-content-center">
      <Col xs={12} lg={12} xl={12}>
        <div className={'step-header'}>
          <Translate contentKey="proEcoApp.project.other"/>
        </div>
        <div className={'step-description'}>
          <Translate contentKey="proEcoApp.project.othersDescription"/>
        </div>
        <Seasons
          seasons={interiorSectionSeasons}
          path={'interiorSectionSeasons'}
          isDetail={isDetail}
          isInterior={true}
        />

        <Row>
          <PartForm fields={fields}/>
        </Row>
      </Col>
    </Row>
  );
};
