import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity, stringifySerializer } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IOffer } from 'app/shared/model/offer.model';
import { IProject } from 'app/shared/model/project.model';
import qs from "query-string";

const initialState: EntityState<IOffer> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/offers';

// Actions

export const getEntities = createAsyncThunk('offer/fetch_entity_list', async (params: any) => {
  return axios.get<IOffer[]>(apiUrl, {
    params,
    paramsSerializer : (p) => qs.stringify(p, { arrayFormat: 'none' })
  });
});

export const getOffersPopular = createAsyncThunk('offer/fetch_entity_list', async (params: any) => {
  return axios.get<IProject[]>(`${apiUrl}/popular`, { params });
});

export const getEntity = createAsyncThunk(
  'offer/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IOffer>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'offer/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<IOffer>(apiUrl, entity, { headers: { ['Content-Type']: 'multipart/form-data' } });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'offer/update_entity',
  async (data: { entity: any; id: number | string }, thunkAPI) => {
    const result = await axios.put(`${apiUrl}/${data.id}`, data.entity, { headers: { ['Content-Type']: 'multipart/form-data' } });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'offer/partial_update_entity',
  async (entity: IOffer, thunkAPI) => {
    const result = await axios.patch<IOffer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'offer/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IOffer>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deactivateOffer = createAsyncThunk(
  'offer/deactivate_entity',
  async (offerId: number, thunkAPI) => {
    return axios.put(`${apiUrl}/${offerId}/deactivate`);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const OfferSlice = createEntitySlice({
  name: 'offer',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getOffersPopular), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deactivateOffer), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities, getEntity, getOffersPopular), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, deactivateOffer), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = OfferSlice.actions;

// Reducer
export default OfferSlice.reducer;
