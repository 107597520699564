import React from 'react';
import { TwitterPicker } from 'react-color';
import './color-picker-option.scss';

export const ColorPickerOption = ({ config, expanded, onExpandEvent, onChange, currentState }) => {
  const { color } = currentState;

  const stopPropagation = event => {
    event.stopPropagation();
  };
  const onChangeComplete = colorSelected => {
    onChange('color', colorSelected.hex);
  };

  const renderModal = () => {
    return (
      <div onClick={stopPropagation} className={'color-picker'}>
        <TwitterPicker colors={config.colors} color={color} onChangeComplete={onChangeComplete} />
      </div>
    );
  };

  return (
    <div aria-haspopup="true" aria-expanded={expanded} aria-label="rdw-color-picker" className={'color-picker-wrapper'}>
      <div onClick={onExpandEvent} className={'color-picker-swatch'}>
        <div className={'color-picker-button'} style={{ background: color }} />
      </div>
      {expanded ? renderModal() : undefined}
    </div>
  );
};
