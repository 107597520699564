import { useEffect, useRef, useState } from 'react';

export const useCalcScale = (width: number, backgroundWidth: number, isShow?: boolean) => {
  const backgroundLayerRef = useRef(null);
  const [scale, setScale] = useState(0);
  useEffect(() => {
    if (backgroundLayerRef?.current?.offsetWidth) {
      setScale((backgroundWidth ?? backgroundLayerRef?.current?.offsetWidth) / width);
    }
  }, [width, backgroundWidth, backgroundLayerRef?.current?.offsetWidth, isShow]);
  return { scale, backgroundLayerRef };
};
