import { IQuestionTag } from 'app/shared/model/question-tag.model';
import { IAnswer } from 'app/shared/model/answer.model';

export interface IQuestion {
  id?: number;
  text?: string;
  isPopular?: boolean | null;
  questionTag?: IQuestionTag;
  questionTagId?: number;
  isActive?: boolean;
  answers?: IAnswer[];
  textPl?: string;
  textEn?: string;
  displayOrder?: number;
}

export const defaultValue: Readonly<IQuestion> = {
  isPopular: false,
};
