import { TRANSLATED_DICTS } from 'app/entities/translate-dict-item/constants';
import ProjectType from 'app/entities/project-type/index';
import EquipmentType from 'app/entities/equipment-type';
import MaterialType from 'app/entities/material-type';
import ArrangementType from 'app/entities/arrangement-type';
import OfferPriority from 'app/entities/offer-priority';
import SystemService from 'app/entities/system-service';
import PreferredContactType from 'app/entities/preferred-contact-type';
import ObjectConstructionType from 'app/entities/object-construction-type';
import BlogTag from 'app/entities/blog-tag';
import QuestionTag from 'app/entities/question-tag';
import WallColorType from 'app/entities/wall-color-type';
import Language from 'app/entities/language';
import FloorType from 'app/entities/floor-type';
import InternalWallAdditionalMaterialType from 'app/entities/internal-wall-additional-material-type';
import InternalWallType from 'app/entities/internal-wall-type';
import InternalWallConstruction from 'app/entities/internal-wall-construction';
import FacadeType from 'app/entities/facade-type';
import FacadeAdditionalMaterialType from 'app/entities/facade-additional-material-type';
import FacadeConstructionType from 'app/entities/facade-construction-type';
import RoofAdditionalMaterialType from 'app/entities/roof-additional-material-type';
import RoofType from 'app/entities/roof-type';
import RoofConstructionType from 'app/entities/roof-construction-type';
import ExteriorDoorType from 'app/entities/exterior-door-type';
import WindowType from 'app/entities/window-type';
import ElectricianType from 'app/entities/electrician-type';
import HydraulicsType from 'app/entities/hydraulics-type';
import { CATEGORY_TYPE, SUB_COMPONENT_PROPERTY_TYPES } from 'app/config/constants';
import SubsectionType from 'app/entities/subsection-type';
import ComponentType from 'app/entities/component-type';
import FloorConstruction from 'app/entities/floor-construction';
import ElectricianTypeIntelligentHomeType from 'app/entities/electrician-type-intelligent-home-type';
import WallColorAdditionalMaterialType from 'app/entities/wall-color-additional-material-type';
import VentilationType from 'app/entities/ventilation-type';
import AirConditioningType from 'app/entities/air-conditioning-type';
import PlumbingHeatType from 'app/entities/plumbing-heat-type';
import ElectricianAddonType from 'app/entities/electrician-addon-type';
import Step2InteriorFloorType from 'app/entities/step2-interior-floor-type';
import Step2InteriorLightingType from 'app/entities/step2-interior-lighting-type';
import ComponentPropertyName from 'app/entities/component-property-name';
import ComponentPropertyDescription from 'app/entities/component-property-description';

export interface IDictListItem {
  translate: string;
  path: string;
  editTranslationPathPrefix?: string;
  dict?: string;
  component: any;
  bgColor?: string;
  className?: string;
  dictMaterial?: number;
  categoryDetailName?: string;
  isGroup?: boolean;
}

export const BASIC_LAYER = 'basic_layer';
export const PREVIEW_LAYER = 'preview_layer';

export const DICT_LIST: IDictListItem[] = [
  {
    translate: 'projectType',
    path: 'project-type',
    editTranslationPathPrefix: 'project-types',
    dict: TRANSLATED_DICTS.PROJECT_TYPES,
    component: ProjectType,
  },
  {
    translate: 'arrangementType',
    path: 'arrangement-type',
    editTranslationPathPrefix: 'arrangement-types',
    dict: TRANSLATED_DICTS.ARRANGEMENT_TYPES,
    component: ArrangementType,
  },
  {
    translate: 'blogTag',
    path: 'blog-tag',
    editTranslationPathPrefix: 'blog-tags',
    dict: TRANSLATED_DICTS.BLOG_TAGS,
    component: BlogTag,
  },
  {
    translate: 'questionTag',
    path: 'question-tag',
    editTranslationPathPrefix: 'question-tags',
    dict: TRANSLATED_DICTS.QUESTION_TAGS,
    component: QuestionTag,
  },
  // {
  //   translate: 'offerType',
  //   path: 'offer-type',
  //   editTranslationPathPrefix: 'offer-types',
  //   dict: TRANSLATED_DICTS.OFFER_TYPES,
  //   component: OfferType,
  // },
  {
    translate: 'offerPriority',
    path: 'offer-priority',
    editTranslationPathPrefix: 'offer-priorities',
    dict: TRANSLATED_DICTS.OFFER_PRIORITIES,
    component: OfferPriority,
  },
  {
    translate: 'systemService',
    path: 'system-service',
    editTranslationPathPrefix: 'system-services',
    dict: TRANSLATED_DICTS.SYSTEM_SERVICES,
    component: SystemService,
  },
  {
    translate: 'preferredContactType',
    path: 'preferred-contact-type',
    editTranslationPathPrefix: 'preferred-contact-types',
    dict: TRANSLATED_DICTS.PREFERRED_CONTACT_TYPES,
    component: PreferredContactType,
  },
  {
    translate: 'language',
    path: 'language',
    editTranslationPathPrefix: 'languages',
    dict: TRANSLATED_DICTS.LANGUAGES,
    component: Language,
  },
  {
    translate: 'subsectionType',
    path: 'subsection-type',
    editTranslationPathPrefix: 'subsection-types',
    dict: TRANSLATED_DICTS.SUBSECTION_TYPES,
    component: SubsectionType,
  },
  {
    translate: 'componentType',
    path: 'component-type',
    editTranslationPathPrefix: 'component-types',
    dict: TRANSLATED_DICTS.COMPONENT_TYPES,
    component: ComponentType,
  },
];

export const PROPERTY_OPTIONS: IDictListItem[] = [];

export const DICT_CONFIGURATION_LIST: IDictListItem[] = [
  {
    translate: 'floorType',
    path: 'floor-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    categoryDetailName: CATEGORY_TYPE.FLOOR_TYPE,
    component: FloorType,
    bgColor: '#FFBB33',
    dictMaterial: SUB_COMPONENT_PROPERTY_TYPES.FLOOR_TYPE,
    isGroup: true,
  },
  {
    translate: 'floorConstruction',
    path: 'floor-construction',
    editTranslationPathPrefix: 'component-property-construction-types',
    dict: TRANSLATED_DICTS.CP_CONSTRUCTION_TYPES,
    component: FloorConstruction,
    bgColor: '#FFBB33',
    categoryDetailName: CATEGORY_TYPE.FLOOR_CONSTRUCTIONS,
  },
  {
    translate: 'internalWallType',
    path: 'internal-wall-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    categoryDetailName: CATEGORY_TYPE.INTERNAL_WALL_TYPE,
    component: InternalWallType,
    bgColor: '#0066FF',
  },
  {
    translate: 'internalWallAdditionalMaterialType',
    path: 'internal-wall-additional-material-type',
    editTranslationPathPrefix: 'component-property-additional-material-types',
    dict: TRANSLATED_DICTS.CP_ADDITIONAL_MATERIAL_TYPES,
    component: InternalWallAdditionalMaterialType,
    bgColor: '#0066FF',
    categoryDetailName: CATEGORY_TYPE.INTERNAL_WALL_ADDITIONAL_MATERIAL_TYPES,
  },
  {
    translate: 'internalWallConstruction',
    path: 'internal-wall-construction',
    editTranslationPathPrefix: 'component-property-construction-types',
    dict: TRANSLATED_DICTS.CP_CONSTRUCTION_TYPES,
    component: InternalWallConstruction,
    bgColor: '#0066FF',
    categoryDetailName: CATEGORY_TYPE.INTERNAL_WALL_CONSTRUCTIONS,
  },
  {
    translate: 'wallColorType',
    path: 'wall-color-type',
    editTranslationPathPrefix: 'wall-color-types',
    dict: TRANSLATED_DICTS.WALL_COLOR_TYPES,
    component: WallColorType,
    bgColor: '#0066FF',
  },
  {
    translate: 'wallColorAdditionalMaterialType',
    path: 'wall-color-additional-material-type',
    editTranslationPathPrefix: 'wall-color-additional-material-types',
    dict: TRANSLATED_DICTS.WALL_COLOR_ADDITIONAL_MATERIAL_TYPES,
    component: WallColorAdditionalMaterialType,
    bgColor: '#0066FF',
  },
  {
    translate: 'facadeType',
    path: 'facade-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: FacadeType,
    bgColor: '#38C1C9',
    dictMaterial: SUB_COMPONENT_PROPERTY_TYPES.FACADE_TYPE,
    categoryDetailName: CATEGORY_TYPE.FACADE_TYPE,
    isGroup: true,
  },

  {
    translate: 'facadeAdditionalMaterialType',
    path: 'facade-additional-material-type',
    editTranslationPathPrefix: 'component-property-additional-material-types',
    dict: TRANSLATED_DICTS.CP_ADDITIONAL_MATERIAL_TYPES,
    component: FacadeAdditionalMaterialType,
    bgColor: '#38C1C9',
    categoryDetailName: CATEGORY_TYPE.FACADE_ADDITIONAL_MATERIAL_TYPE,
  },
  {
    translate: 'facadeConstructionType',
    path: 'facade-construction-type',
    editTranslationPathPrefix: 'component-property-construction-types',
    dict: TRANSLATED_DICTS.CP_CONSTRUCTION_TYPES,
    component: FacadeConstructionType,
    bgColor: '#38C1C9',
    categoryDetailName: CATEGORY_TYPE.FACADE_CONSTRUCTION_TYPE,
  },
  {
    translate: 'roofType',
    path: 'roof-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: RoofType,
    bgColor: '#45B873',
    categoryDetailName: CATEGORY_TYPE.ROOF_TYPE,
  },

  {
    translate: 'roofAdditionalMaterialType',
    path: 'roof-additional-material-type',
    editTranslationPathPrefix: 'component-property-additional-material-types',
    dict: TRANSLATED_DICTS.CP_ADDITIONAL_MATERIAL_TYPES,
    component: RoofAdditionalMaterialType,
    bgColor: '#45B873',
    categoryDetailName: CATEGORY_TYPE.ROOF_ADDITIONAL_MATERIAL_TYPES,
  },
  {
    translate: 'roofConstructionType',
    path: 'roof-construction-type',
    editTranslationPathPrefix: 'component-property-construction-types',
    dict: TRANSLATED_DICTS.CP_CONSTRUCTION_TYPES,
    component: RoofConstructionType,
    bgColor: '#45B873',
    categoryDetailName: CATEGORY_TYPE.ROOF_CONSTRUCTION_TYPE,
  },

  {
    translate: 'exteriorDoorType',
    path: 'exterior-door-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: ExteriorDoorType,
    bgColor: '#FFBB33',
    dictMaterial: SUB_COMPONENT_PROPERTY_TYPES.EXTERIOR_DOOR_TYPES,
    categoryDetailName: CATEGORY_TYPE.EXTERIOR_DOOR_TYPES,
    isGroup: true,
  },
  {
    translate: 'windowType',
    path: 'window-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: WindowType,
    dictMaterial: SUB_COMPONENT_PROPERTY_TYPES.WINDOW_TYPES,
    categoryDetailName: CATEGORY_TYPE.WINDOW_TYPES,
    bgColor: '#FFBB33',
    isGroup: true,
  },
  {
    translate: 'electricianType',
    path: 'electrician-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: ElectricianType,
    bgColor: '#38C1C9',
    categoryDetailName: CATEGORY_TYPE.ELECTRICIAN_TYPES,
  },
  {
    translate: 'electricianTypeIntelligentHomeType',
    path: 'electrician-type-intelligent-home-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: ElectricianTypeIntelligentHomeType,
    bgColor: '#38C1C9',
    categoryDetailName: CATEGORY_TYPE.ELECTRICIAN_TYPE_INTELLIGENT_HOME_TYPES,
  },
  {
    translate: 'electricianAddonType',
    path: 'electrician-addon-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: ElectricianAddonType,
    bgColor: '#38C1C9',
    categoryDetailName: CATEGORY_TYPE.ELECTRICIAN_ADDON_TYPES,
  },
  {
    translate: 'plumbingHeatType',
    path: 'plumbing-heat-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: PlumbingHeatType,
    bgColor: '#38C1C9',
    categoryDetailName: CATEGORY_TYPE.PLUMBING_HEAT_TYPES,
  },
  {
    translate: 'hydraulicsType',
    path: 'hydraulics-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: HydraulicsType,
    bgColor: '#38C1C9',
    categoryDetailName: CATEGORY_TYPE.HYDRAULICS_TYPES,
  },

  // {
  //   translate: 'setType',
  //   path: 'set-type',
  //   editTranslationPathPrefix: 'equipment-types',
  //   dict: TRANSLATED_DICTS.EQUIPMENT_TYPES,
  //   component: SetType,
  //   bgColor: '#45B873',
  // },
  {
    translate: 'ventilationType',
    path: 'ventilation-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: VentilationType,
    bgColor: '#38C1C9',
    categoryDetailName: CATEGORY_TYPE.VENTILATION_TYPES,
  },
  {
    translate: 'airConditioningType',
    path: 'air-conditioning-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: AirConditioningType,
    bgColor: '#38C1C9',
    categoryDetailName: CATEGORY_TYPE.AIR_CONDITIONING_TYPES,
  },

];

export const DICT_CONFIGURATION_OTHER_LIST: IDictListItem[] = [
  {
    translate: 'materialType',
    path: 'material-type',
    editTranslationPathPrefix: 'material-types',
    dict: TRANSLATED_DICTS.MATERIAL_TYPES,
    component: MaterialType,
  },
  {
    translate: 'equipmentType',
    path: 'equipment-type',
    editTranslationPathPrefix: 'equipment-types',
    dict: TRANSLATED_DICTS.EQUIPMENT_TYPES,
    component: EquipmentType,
    bgColor: '#45B873',
    isGroup: true,
  },
  {
    translate: 'step2InteriorFloorType',
    path: 'step2-interior-floor-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: Step2InteriorFloorType,
    categoryDetailName: CATEGORY_TYPE.STEP2_INTERIOR_FLOOR_TYPES,
  },
  {
    translate: 'step2InteriorLightingType',
    path: 'step2-interior-lighting-type',
    editTranslationPathPrefix: 'sub-component-property-types',
    dict: TRANSLATED_DICTS.SUB_COMPONENT_PROPERTY_TYPES,
    component: Step2InteriorLightingType,
    categoryDetailName: CATEGORY_TYPE.STEP2_INTERIOR_LIGHTING_TYPES,
  },
  {
    translate: 'objectConstructionType',
    path: 'object-construction-type',
    editTranslationPathPrefix: 'object-construction-types',
    dict: TRANSLATED_DICTS.OBJECT_CONSTRUCTION_TYPES,
    component: ObjectConstructionType,
  },

  {
    translate: 'componentPropertyName',
    path: 'component-property-name',
    component: ComponentPropertyName,
    bgColor: '#0066FF',
  },
  {
    translate: 'componentPropertyDescription',
    path: 'component-property-description',
    component: ComponentPropertyDescription,
    bgColor: '#0066FF',
  },

];
