import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IDiscountCode, defaultValue } from 'app/shared/model/discount-code.model';

export interface IDiscountCodeEntity extends EntityState<IDiscountCode> {
  sendSuccess: boolean;
  code: string;
}

const initialState: IDiscountCodeEntity = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  sendSuccess: false,
  code: null,
};

const apiUrl = 'api/discount-codes';

// Actions

export const getEntities = createAsyncThunk('discountCode/fetch_entity_list', async (params: any) => {
  return axios.get<IDiscountCode[]>(apiUrl, { params });
});

export const getEntity = createAsyncThunk(
  'discountCode/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IDiscountCode>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'discountCode/create_entity',
  async (entity: IDiscountCode, thunkAPI) => {
    try {
      const result = await axios.post<IDiscountCode>(apiUrl, cleanEntity(entity));
      thunkAPI.dispatch(getEntities({}));
      return result;
    } catch (err) {
      if (err?.response?.data?.message && err?.response?.data?.message === 'error.discountCodeExpiryOverlapping') {
        thunkAPI.dispatch(updateErrorMessage({ errorMessage: err.response.data.message }));
        return thunkAPI.fulfillWithValue(err.response.data.message);
      }
      throw err;
    }
  },
  { serializeError: serializeAxiosError }
);

export const generateDiscountCode = createAsyncThunk(
  'discountCode/generate_discount_code',
  async () => {
    return await axios.get<string>(`${apiUrl}/generate`);
  },
  { serializeError: serializeAxiosError }
);

export const checkingDiscountCode = createAsyncThunk(
  'discountCode/checking_discount_code',
  async (code: any) => {
    return await axios.get<IDiscountCode>(`${apiUrl}/code/${code}`);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'discountCode/update_entity',
  async (entity: IDiscountCode, thunkAPI) => {
    const result = await axios.put<IDiscountCode>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'discountCode/partial_update_entity',
  async (entity: IDiscountCode, thunkAPI) => {
    const result = await axios.patch<IDiscountCode>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'discountCode/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IDiscountCode>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deactivateDiscountCode = createAsyncThunk(
  'discountCode/deactivate_entity',
  async (discountCode: IDiscountCode) => {
    return await axios.put(`${apiUrl}/${discountCode?.id}/deactivate`);
  },
  { serializeError: serializeAxiosError }
);

export const restoreDiscountCode = createAsyncThunk(
  'discountCode/restore_entity',
  async (discountCode: IDiscountCode) => {
    return await axios.put(`${apiUrl}/${discountCode?.id}/restore`);
  },
  { serializeError: serializeAxiosError }
);

export const sendDiscountCode = createAsyncThunk(
  'discountCode/send_discount_code',
  async (data: any) => {
    return await axios.post<any>(`${apiUrl}/share`, data);
  },
  { serializeError: serializeAxiosError }
);

export const DiscountCodeSlice = createSlice({
  name: 'discountCode',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    updateErrorMessage(state, action) {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(sendDiscountCode), state => {
        state.updating = false;
        state.loading = false;
        state.sendSuccess = true;
      })
      .addMatcher(isFulfilled(checkingDiscountCode), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })

      .addMatcher(isFulfilled(restoreDiscountCode, deactivateDiscountCode, sendDiscountCode), state => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(generateDiscountCode), (state, action) => {
        state.code = action.payload.data;
      })

      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(
        isRejected(getEntities, getEntity, createEntity, updateEntity, partialUpdateEntity, deleteEntity, sendDiscountCode),
        (state, action) => {
          state.loading = false;
          state.updating = false;
          state.updateSuccess = false;
          state.errorMessage = action.error.message;
        }
      );
  },
});

export const { reset, updateErrorMessage } = DiscountCodeSlice.actions;

// Reducer
export default DiscountCodeSlice.reducer;
