import React, { memo, useContext, useEffect, useMemo } from 'react';
import Translate from 'app/shared/layout/Translation/translate';
import {
  HomeObjectContext,
  LayerContext,
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import { debounce, uniqueId } from 'lodash';
import AddRowWrapper from 'app/modules/administration/home-object-v2/add-row-wrapper';
import { Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import {
  REQUIRED,
} from 'app/shared/util/validation';
import { updateHomeObject } from 'app/entities/project/project.reducer';
import { useAppDispatch } from 'app/config/store';
import { useOptions } from 'app/shared/hooks/useOptions';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';
import DeleteRowWrapper from 'app/modules/administration/home-object-v2/delete-row-wrapper';
import { useOptionDescriptions, useOptionNames } from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';

export const WallRoomColorItem = ({ showDelete, options, wallSize, wallRoomColor, wallRoomColorIndex, path, isDetail = false }) => {
  const { onRemoveRow, onChangeInputNumber, onChangeSelect, onAddRow, onChangeInput, selectPropertyOption, calcTotalPrice } =
    useContext(HomeObjectContext);
  const dispatch = useAppDispatch();
  const { layerInteriors } = useContext(LayerContext);
  const wallColorType = useMemo(
    () => options?.find(wallColorTypesOption => wallColorTypesOption.id === wallRoomColor?.wallColorTypeId),
    [wallRoomColor, options]
  );

  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const { priceTypesOptions } = useOptions(TRANSLATED_DICTS_KEYS.PRICE_TYPES, true);

  const updateTotalPrice = debounce(() => {
    const totalPrice = calcTotalPrice(wallSize, wallColorType?.propertyPrice?.netPurchasingPrice, wallRoomColor?.labour);
    dispatch(
      updateHomeObject({
        name: `${path}[${wallRoomColorIndex}].price`,
        value: totalPrice,
      })
    );
  }, 500);

  useEffect(() => {
    if (wallRoomColor?.labour !== 0 || wallColorType?.propertyPrice?.netPurchasingPrice > 0 || wallSize > 0) {
      updateTotalPrice();
    }
  }, [wallSize, wallColorType?.propertyPrice?.netPurchasingPrice, wallRoomColor?.labour]);

  const fields = [
    {
      readOnly: isDetail,
      name: `${path}[${wallRoomColorIndex}].cpNameId`,
      id: `${path}[${wallRoomColorIndex}].cpNameId`,
      type: 'select',
      onChange: onChangeSelect(''),
      value: nameOptions?.find(nameOption => nameOption.id === wallRoomColor?.cpNameId),
      options: nameOptions,
      isClearable: true,
      validate: {
        ...REQUIRED(),
      },
      label: <Translate contentKey="proEcoApp.project.name" />,
      col: { lg: '2', md: '2' },
    },
    {
      readOnly: isDetail,
      name: `${path}[${wallRoomColorIndex}].cpDescriptionId`,
      id: `${path}[${wallRoomColorIndex}].cpDescriptionId`,
      type: 'select',
      onChange: onChangeSelect(''),
      value: descriptionOptions?.find(descriptionOption => descriptionOption.id === wallRoomColor?.cpDescriptionId),
      options: descriptionOptions,
      isClearable: true,
      label: <Translate contentKey="proEcoApp.project.description" />,
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: `${path}[${wallRoomColorIndex}].wallColorTypeId`,
      id: `${path}[${wallRoomColorIndex}].wallColorTypeId`,
      type: 'select',
      onChange: onChangeSelect(''),
      value: wallColorType,
      options,
      label: <Translate contentKey="proEcoApp.project.wallColorTypeId" />,
      childrenLabel: (
        <AddRowWrapper
          handleAdd={() =>
            onAddRow(path, {
              key: uniqueId(),
            })
          }
          showAdd={wallRoomColorIndex === 0 && !isDetail}
        />
      ),
      validate: {
        ...REQUIRED(),
      },
      col: { lg: '2', md: '2' },
    },

    {
      readOnly: true,
      name: `${path}[${wallRoomColorIndex}].priceTypeId`,
      id: `${path}[${wallRoomColorIndex}].priceTypeId`,
      type: 'select',
      onChange: onChangeSelect(''),
      value: priceTypesOptions.find(priceTypesOption => priceTypesOption.id === wallColorType?.propertyPrice?.priceTypeId),
      options: priceTypesOptions,
      isClearable: true,
      label: <Translate contentKey="proEcoApp.project.priceTypeId" />,
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: true,
      name: `${path}[${wallRoomColorIndex}].pricePerUnit`,
      id: `${path}[${wallRoomColorIndex}].pricePerUnit`,
      onChange: onChangeInput,
      value: wallColorType?.propertyPrice?.netPurchasingPrice,
      label: <Translate contentKey="proEcoApp.project.pricePerUnit" />,
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN" />
        </span>
      ),
      col: { lg: '2', md: '2' },
    },

    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: `${path}[${wallRoomColorIndex}].labour`,
      id: `${path}[${wallRoomColorIndex}].labour`,
      onChange: onChangeInputNumber,
      type: 'decimalNumber',
      onWheel: event => event.currentTarget.blur(),
      min: 0,
      value: wallRoomColor?.labour,
      label: <Translate contentKey="proEcoApp.project.labourCost" />,
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN" />
        </span>
      ),
      validate: {
        ...REQUIRED(),
      },
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: true,
      name: `${path}[${wallRoomColorIndex}].price`,
      id: `${path}[${wallRoomColorIndex}].price`,
      value: wallRoomColor?.price,
      label: <Translate contentKey="proEcoApp.project.totalNetPrice" />,
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN" />
        </span>
      ),
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      name: `${path}[${wallRoomColorIndex}].imageLayerName`,
      id: `${path}[${wallRoomColorIndex}].imageLayerName`,
      type: 'select',
      onChange: selectPropertyOption(`${path}[${wallRoomColorIndex}]`),
      isClearable: true,
      value: layerInteriors?.find(layer => layer.value === wallRoomColor?.imageLayerName),
      options: layerInteriors || [],
      label: (
        <>
          <Translate contentKey="proEcoApp.project.imageLayerName" className={'mr-2'} />
          {wallRoomColor?.loading ? (
            <Spinner className={'uploaded'} />
          ) : wallRoomColor?.imagePath ? (
            <FontAwesomeIcon icon={faCircleCheck} className={'uploaded'} />
          ) : (
            ''
          )}
        </>
      ),
      col: { lg: '4', md: '4' },
    },
  ];
  return (
    <div>
      <DeleteRowWrapper showDelete={showDelete} handleDelete={() => onRemoveRow(path, wallRoomColorIndex)}>
        <Row>
          <PartForm fields={fields} />
        </Row>
      </DeleteRowWrapper>
    </div>
  );
};

export default memo(WallRoomColorItem);
