import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { Col, Row } from 'reactstrap';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import {
  HomeObjectContext,
  LayerContext,
  SHELL_AND_CORE_STEP,
  StepContext,
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import Translate from 'app/shared/layout/Translation/translate';
import Button from 'reactstrap/es/Button';
import ButtonLoading from 'app/shared/layout/ButtonLoading/ButtonLoading';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useParams } from 'react-router-dom';
import { createEntity, updateEntity, updateHomeObjectEntity, uploadPsd } from 'app/entities/project/project.reducer';
import { AvForm } from 'availity-reactstrap-validation';

import BasicInformation from 'app/modules/administration/home-object-v2/Components/home-object-update/basic-information';
import { MAX, MIN, onChangePositiveInput, REQUIRED, scrollToError } from 'app/shared/util/validation';
import { nanoid } from '@reduxjs/toolkit';
import { debounce, range, slice } from 'lodash';
import RoomProjects from 'app/modules/administration/home-object-v2/room-projects';
import { Others } from 'app/modules/administration/home-object-v2/Components/home-object-update/others';
import { useHistory } from 'react-router';
import ObligatoryInfo from 'app/shared/layout/ObligatoryInfo/ObligatoryInfo';
import { FileLabel } from 'app/shared/layout/CustomInput/Components/FileLabel';
import BadgeInPublicInfo from 'app/shared/layout/BadgeInPublic/BadgeInPublicInfo';
import { SUB_COMPONENT_PROPERTY_TYPES } from 'app/config/constants';
import CustomInput from 'app/shared/layout/CustomInput/CustomInput';
import InteriorComponent from 'app/modules/administration/home-object-v2/interior-component';

const FurnishingForm = ({ isDetail = false }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isNew = !id;
  const { setStep } = useContext(StepContext);
  const { onClickCancel, parseFilePsd } = useContext(HomeObjectContext);
  const { setLayerInteriors, layerInteriors } = useContext(LayerContext);
  const interiorPreviewLayerUrl = useAppSelector(state => state.project.entity.interiorPreviewLayerUrl);
  const updating = useAppSelector(state => state.project.updating);
  const roomProjects = useAppSelector(state => state.project.entity?.roomProjects);
  const components = useAppSelector(state => state.project.entity.components);
  const roomNumber = useAppSelector(state => state.project.entity?.roomNumber);
  const projectEntity = useAppSelector(state => state.project.entity);
  const [isLoading, setIsLoading] = useState(false);
  const floorInteriorIndex = useMemo(
    () => components?.findIndex(component => component.componentTypeId === SUB_COMPONENT_PROPERTY_TYPES.STEP2_INTERIOR_FLOOR),
    [components]
  );

  const roomProjectListCached = useMemo(() => {
    return projectEntity?.roomProjects?.map(room => {
      return {
        ...room,
        index: nanoid(),
      };
    });
  }, [projectEntity?.roomProjects]);

  const transformRoomProject = roomNumberValue => {
    if (Number(roomNumberValue) > roomProjectListCached?.length) {
      return [
        ...roomProjectListCached,
        ...range(roomProjectListCached?.length, Math.min(Number(roomNumberValue), 10))?.map(roomIndex => ({
          ...(roomProjectListCached?.[roomIndex] || {
            roomTypeId: null,
            isEmptyFurnishing: false,
            wallCount: null,
            index: nanoid(),
            equipments: [],
            equipmentSets: [],
            wallRooms: [],
          }),
        })),
      ];
    } else {
      return slice(roomProjectListCached, 0, Number(roomNumberValue));
    }
  };

  const handleChangeRoomNumberInput = ev => {
    dispatch(
      updateHomeObjectEntity({
        roomNumber: Number(ev.target.value),
      })
    );

    debounce(() => {
      dispatch(
        updateHomeObjectEntity({
          roomProjects: transformRoomProject(Number(ev.target.value)),
        })
      );
    }, 300)();
  };

  const handleSubmit = useCallback((event, error) => {
    if (error?.length) {
      scrollToError(error);
    } else if (isNew) {
      dispatch(createEntity());
    } else {
      dispatch(updateEntity());
    }
  }, []);
  const upload = event => {
    if (event.target.files && event.target.files[0]) {
      const resultFile = parseFilePsd(event.target.files[0], setIsLoading, setLayerInteriors, true);
      if (resultFile) {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        dispatch(
          uploadPsd({
            formData: data,
            isInterior: true,
          })
        );
      }
    }
  };

  const fields = [
    {
      avGroupClassName: 'file-wrapper flex-column',
      id: `baseLayerPath`,
      name: `baseLayerPath`,
      type: 'image',
      accept: '.psd',
      validate: {
        ...REQUIRED(),
      },
      readOnly: isDetail,
      disabled: isDetail,
      src: interiorPreviewLayerUrl,
      label: <FileLabel acceptFile=".psd" name={''} isLoading={isLoading} maxResolution={'1920 x 1080px'} />,
      value: !!layerInteriors?.length,
      style: {
        width: '100%',
        padding: '5px 0',
      },
      onChange: upload,
      col: { sm: 6 },
    },
    {
      type: 'space',
      col: { sm: 6 },
      name: 'imageUpload',
      id: 'imageUpload',
    },
  ];

  return (
    <AvForm onSubmit={handleSubmit}>
      <BasicInformation isDetail={isDetail} isNew={isNew} />
      <div className={'section-block'}>
        <div className={'step-header'}>
          <Translate contentKey="proEcoApp.project.furnishings" />
        </div>
        <div className={'step-description'}>
          <Translate contentKey="proEcoApp.project.furnishingDescription" />
        </div>

        <Row>
          <PartForm fields={fields} />
        </Row>
        <InteriorComponent
          componentIndex={floorInteriorIndex}
          componentProperties={components?.[floorInteriorIndex]?.componentProperties}
          componentTypeId={components?.[floorInteriorIndex]?.componentTypeId}
          isDetail={isDetail}
          path={`components[${floorInteriorIndex}].componentProperties`}
        />
        <Row>
          <Col md={2} className={'no-mr'}>
            <CustomInput
              {...{
                name: 'roomNumber',
                id: 'roomNumber',
                readOnly: isDetail,
                label: <Translate contentKey="proEcoApp.project.roomNumber" />,
                onChange: handleChangeRoomNumberInput,
                onKeyPress: onChangePositiveInput,
                validate: { ...REQUIRED(), ...MIN(0), ...MAX(10) },
                value: roomNumber,
                min: 1,
              }}
            />
          </Col>
        </Row>

        <RoomProjects roomProjects={roomProjects} path={`roomProjects`} isDetail={isDetail} />
        <Others isDetail={isDetail} />
        <ObligatoryInfo />
        <BadgeInPublicInfo />
      </div>
      <div className={'w-100 home-object-button'}>
        <Button type="button" color="only-border" onClick={onClickCancel} className={'btn-default-size'}>
          <Translate contentKey="entity.action.cancel" />
        </Button>
        <Button
          type="button"
          color="second-type"
          onClick={() => setStep(SHELL_AND_CORE_STEP)}
          className={'btn-default-size'}
          style={{ minHeight: '48px' }}
        >
          <Translate contentKey="entity.action.back" />
        </Button>
        {isDetail ? (
          <ButtonLoading
            updating={updating}
            disabled={updating}
            onClick={e => {
              e.preventDefault();
              history.push(`/admin/home-object/${id}/edit`);
            }}
            text="entity.action.edit"
            classNames={'btn-default-size'}
            id="home-object"
          />
        ) : (
          <ButtonLoading
            updating={updating}
            disabled={updating}
            text="entity.action.save"
            classNames={'btn-default-size'}
            id="home-object"
          />
        )}
      </div>
    </AvForm>
  );
};

export default FurnishingForm;
