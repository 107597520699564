import { Nav } from 'reactstrap';
import Translate from 'app/shared/layout/Translation/translate';
import React from 'react';
import './HomeMenu.scss';
import { Link } from 'react-router-dom';

const HomeMenu = ({ toggleClose = () => {} }) => {
  return (
    <div style={{ flex: 1, textAlign: 'center' }}>
      <Nav className=" d-none d-lg-block home-menu" navbar>
        <Link className="home-menu-item" to="/offer" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.products" />
        </Link>
        <Link to="/our-services" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.services" />
        </Link>
        <Link to="/how-it-works" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.howItWorks" />
        </Link>
        <Link to="/faq" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.faq" />
        </Link>
        <Link to="/blog" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.blog" />
        </Link>
        <Link to="/contact" onClick={toggleClose}>
          <Translate contentKey="global.menu.homePage.contact" />
        </Link>
      </Nav>
    </div>
  );
};
export default HomeMenu;
