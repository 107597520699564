import React, {useContext} from 'react';
import {
  HomeObjectContext
} from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import {SeasonItem} from 'app/modules/administration/home-object-v2/season-item';
import AddRowWrapper from 'app/modules/administration/home-object-v2/add-row-wrapper';
import Translate from "app/shared/layout/Translation/translate";

const SEASON_SECTION = 4;
export const
  Seasons = ({seasons, path, isDetail = false, isInterior = false}) => {
    const {onAddRow} = useContext(HomeObjectContext);
    return (
      <div className={!isInterior? "mt-48": ""}>
        <AddRowWrapper handleAdd={() => onAddRow(path)}
                       showAdd={!isInterior && seasons?.length < SEASON_SECTION && !isDetail}>

          <Translate contentKey="proEcoApp.project.projectSeason" className={'component-header'}/>
        </AddRowWrapper>
        {seasons?.map((seasonItem, seasonIndex) => {
          return (
            <SeasonItem
              isDetail={isDetail}
              isInterior={isInterior}
              seasonItem={seasonItem}
              seasonIndex={seasonIndex}
              path={path}
              projectSeasonIds={seasons?.map(season => season?.projectSeasonId)}
              key={seasonItem?.id ?? seasonItem?.key}
            />
          );
        })}
      </div>
    );
  };

export default Seasons;
