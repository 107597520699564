export interface IDeliveryCostRange {
  id?: number;
  distanceFrom?: number;
  distanceTo?: number;
  amountForMaxDistance?: number;
  isActive?: boolean;
}

export const defaultValue: Readonly<IDeliveryCostRange> = {
  isActive: false,
};
