import React from 'react';
import { Switch, useRouteMatch } from "react-router-dom";
import ErrorBoundaryRoute from "app/shared/error/error-boundary-route";
import ComponentPropertyNameUpdateWrapper
  from "app/entities/component-property-name/component-property-name-update-wrapper";
import ComponentPropertyNameList from "app/entities/component-property-name/component-property-name-list";

const Routes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <ErrorBoundaryRoute
        exact path={`${url}/new`} component={ComponentPropertyNameUpdateWrapper}
      />
      <ErrorBoundaryRoute
        exact path={`${url}/:id/edit`} component={ComponentPropertyNameUpdateWrapper}
      />
      <ErrorBoundaryRoute
        exact path={`${url}/:id`} component={ComponentPropertyNameUpdateWrapper}
        componentProps={{ isReadOnly: true }}
      />
      <ErrorBoundaryRoute
        path={url} component={ComponentPropertyNameList}
      />
    </Switch>
  );
}

export default Routes;
