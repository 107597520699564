import React from 'react';
import { getEntities, reset, deactivateEntity, restoreEntity } from 'app/entities/subsection-type/subsection-type.reducer';
import DictionaryRoutes from 'app/modules/dictionaries/Components/dictionaryRoutes';
import { createEntity, getEntity, updateEntity } from 'app/entities/subsection-type/subsection-type.reducer';

const Routes = () => {
  return (
    <DictionaryRoutes
      updateEntity={updateEntity}
      createEntity={createEntity}
      getEntities={getEntities}
      getEntity={getEntity}
      reset={reset}
      deleteEntity={deactivateEntity}
      restoreEntity={restoreEntity}
    />
  );
};

export default Routes;
