import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IImageOption } from 'app/shared/model/image-option.model';
import qs from 'query-string';

const initialState: EntityState<IImageOption> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/uploaded-images';

// Actions

export const getEntities = createAsyncThunk('image-option/fetch_entity_list', async (params: any) => {
  return axios.get<IImageOption[]>(apiUrl, {
    params,
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'none' }),
  });
});

export const getEntity = createAsyncThunk(
  'image-option/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IImageOption>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'image-option/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<IImageOption>(apiUrl, entity, { headers: { ['Content-Type']: 'multipart/form-data' } });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'image-option/update_entity',
  async (data: { entity: any; id: number | string }, thunkAPI) => {
    const result = await axios.put(`${apiUrl}/${data.id}`, data.entity, { headers: { ['Content-Type']: 'multipart/form-data' } });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'image-option/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IImageOption>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deactivateImageOption = createAsyncThunk(
  'image-option/deactivate_entity',
  async (offerId: number, thunkAPI) => {
    const result = await axios.put(`${apiUrl}/${offerId}/deactivate`);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const restoreImageOption = createAsyncThunk(
  'image-option/restore_entity',
  async (offerId: number, thunkAPI) => {
    const result = await axios.put(`${apiUrl}/${offerId}/restore`);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const ImageOptionSlice = createEntitySlice({
  name: 'imageOption',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deactivateImageOption), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, deleteEntity, deactivateImageOption), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ImageOptionSlice.actions;

// Reducer
export default ImageOptionSlice.reducer;
