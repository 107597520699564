import React, { memo } from 'react';
import RoomProjectItem from 'app/modules/administration/home-object-v2/room-project-item';

export const RoomProjects = ({ roomProjects = [], path, isDetail = false }) => {
  return (
    <>
      {roomProjects?.map((roomProject, roomProjectIndex) => {
        return (
          <RoomProjectItem
            key={roomProject?.id ?? roomProject?.index}
            roomProject={roomProject}
            roomProjectIndex={roomProjectIndex}
            path={path}
            isDetail={isDetail}
          />
        );
      })}
    </>
  );
};

export default memo(RoomProjects);
