import React, { useState, FC, useEffect } from 'react';
import { LocaleContentMenu } from 'app/shared/layout/LocaleMenu/LocaleMenu';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './tabcustom.scss';
interface ITabsCustom {
  tabs?: any[];
  activeTab?: number;
}

const TabsCustom: FC<ITabsCustom> = ({ tabs = [], activeTab }) => {
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    if (activeTab && activeTab !== -1) {
      setTabIndex(activeTab);
    }
  }, [activeTab]);

  return (
    <div>
      <Tabs
        className={'properties-options'}
        selectedIndex={tabIndex}
        onSelect={index => {
          setTabIndex(index);
        }}
      >
        <TabList>
          {tabs.map((e, i) => (
            <Tab key={i}>{e.label}</Tab>
          ))}
          <div className="float-right">
            <LocaleContentMenu />
          </div>
        </TabList>
        {tabs.map((e, i) => (
          <TabPanel key={i}>{e.component}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default TabsCustom;
