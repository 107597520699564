import { useMemo } from 'react';

export const useLayers = (arrangement, isInterior) => {
  return useMemo(() => {
    let options = [];
    if (arrangement?.shellAndCores && !isInterior) {
      arrangement?.shellAndCores?.map(shellAndCore => {
        shellAndCore?.components.map(component => {
          component?.materialGroups?.map(materialGroup => {
            options = [
              ...options,
              ...materialGroup?.componentProperties?.map(componentProperty => ({
                ...componentProperty,
                isInterior: false,
                optionName: 'componentProperties',
              })),
            ];
          });
        });
      });
    }
    if (arrangement?.interiorSection?.floorComponent && isInterior) {
      arrangement?.interiorSection?.floorComponent?.materialGroups?.map(materialGroup => {
        options = [
          ...options,
          ...materialGroup?.componentProperties?.map(componentProperty => ({
            ...componentProperty,
            isInterior: true,
            optionName: 'floorComponent',
          })),
        ];
      });
    }

    if (arrangement?.interiorSection?.roomLightings && isInterior) {
      arrangement?.interiorSection?.roomLightings?.map(roomLighting => {
        options = [
          ...options,
          ...roomLighting?.componentProperties?.map(componentProperty => ({
            ...componentProperty,
            isInterior: true,
            optionName: 'roomLightings',
          })),
        ];
      });
    }

    if (arrangement?.interiorSection?.furnishingSubsection?.roomEquipments && isInterior) {
      arrangement?.interiorSection?.furnishingSubsection?.roomEquipments?.map(roomEquipment => {

        roomEquipment?.equipments?.map(equipment => {
          options = [
            ...options,
            ...equipment?.materialGroups?.map(materialGroup => ({
              ...materialGroup,
              isInterior: true,
              optionName: 'roomEquipments',
            })),
          ];
        });

        roomEquipment?.equipmentSets?.map(equipmentSet => {
          options = [
            ...options,
            ...equipmentSet?.materialGroups?.map(materialGroup => ({
              ...materialGroup,
              isInterior: true,
              optionName: 'roomEquipments',
            })),
          ];
        });

      });
    }

    if (arrangement?.interiorSection?.wallRoomComponents && isInterior) {
      arrangement?.interiorSection?.wallRoomComponents?.map(wallRoomComponent => {
        wallRoomComponent?.wallRooms?.map(wallRoom => {
          options = [
            ...options,
            ...wallRoom.wallRoomColors?.map(wallRoomColor => ({
              ...wallRoomColor,
              isInterior: true,
              optionName: 'wallRoomComponents',
            })),
          ];
        });
      });
    }

    return options;
  }, [arrangement?.interiorSection, arrangement?.shellAndCores, arrangement?.roomProjects, isInterior]);
};
