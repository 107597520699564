import React, { FC, useMemo } from 'react';
import ComponentCollapse from 'app/modules/administration/arrangement/Components/ComponentCollapse';
import Translate from 'app/shared/layout/Translation/translate';

import PropertiesOptions from 'app/modules/administration/arrangement/Components/arrangement-section-summary/select-properties-options/properties-options';

import { FURNISHING_STEP } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import { flatMap } from 'lodash';
import { useOptionDescriptions, useOptionNames } from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';
import SelectPropertyName from 'app/modules/administration/arrangement/Components/select-properties-options/select-property-name';

interface IEquipmentPart {
  equipments: any;
  path: any;
  isSet: boolean;
  isDetail?: boolean;
  optionIdLabel: string;
}

export const EquipmentPart: FC<IEquipmentPart> = ({ path, equipments, isSet, optionIdLabel }) => {
  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();

  const equipmentOptions = useMemo(
    () =>
      flatMap(
        equipments?.map((equipment, equipmentIndex) => {
          return equipment?.materialGroups
            ?.map((materialGroup, materialGroupsIndex) => {
              return {
                ...materialGroup,
                label: nameOptions?.find(nameOption => nameOption.id === materialGroup?.cpNameId)?.label,
                children: (
                  <>
                    <div className="properties-header-description">
                      {descriptionOptions?.find(descriptionOption => descriptionOption?.id === materialGroup?.cpDescriptionId)?.label}
                    </div>
                  </>
                ),
                path: `${path}[${equipmentIndex}].materialGroups[${materialGroupsIndex}].isSelected`,
              };
            })
            ?.filter(item => item?.isSelected);
        })
      ),
    [equipments, nameOptions, path]
  );

  return (
    <div className={'ml-4'}>
      <ComponentCollapse
        header={isOpen =>
          equipmentOptions?.length >= 1 ? (
            <div className={`subsection-header`}>
              {isSet ? (
                <Translate contentKey="proEcoApp.arrangement.equipmentSet" />
              ) : (
                <Translate contentKey="proEcoApp.arrangement.equipment" />
              )}
            </div>
          ) : null
        }
        id={`equipment-${optionIdLabel}`}
        defaultOpen={true}
      >
        <div className={'component-properties'}>
          <PropertiesOptions
            currentStep={FURNISHING_STEP}
            options={equipmentOptions}
            disabled={true}
            optionIdLabel={optionIdLabel}
            isRequiredSection={false}
          />
        </div>
      </ComponentCollapse>
    </div>
  );
};
