import React, { FC, useContext } from 'react';

import SelectPropertiesOptions from 'app/modules/administration/arrangement/Components/select-properties-options/select-properties-options';
import { useEquipmentTypes } from 'app/modules/administration/home-object-v2/hooks/useEquipmentTypes';
import { updateSelectedOption, updateSelectSingleOption } from 'app/modules/administration/arrangement/arrangement.reducer';
import { useAppDispatch } from 'app/config/store';
import { ConfigurationLayoutContext } from 'app/app';
import { IsSubmitForShareContext } from 'app/modules/administration/arrangement/arrangement-update';
import { useOptionDescriptions, useOptionNames } from 'app/modules/administration/home-object-v2/hooks/useNameDescriptions';

interface IArrangementEquipments {
  materialGroups: any[];
  equipmentIndex: number;
  isEdit?: boolean;
  path?: string;
  isConfiguration?: boolean;
  isSet?: boolean;
  isNew?: boolean;
}

export const ArrangementEquipments: FC<IArrangementEquipments> = ({
  materialGroups,
  equipmentIndex,
  isEdit,
  isNew,
  path,
  isConfiguration,
  isSet,
}: IArrangementEquipments) => {
  const dispatch = useAppDispatch();
  const { setErrorMessage } = useContext(ConfigurationLayoutContext);
  const { setIsSubmitForShare } = useContext(IsSubmitForShareContext);
  const nameOptions = useOptionNames();
  const descriptionOptions = useOptionDescriptions();
  const equipmentTypeOptions = useEquipmentTypes(isSet);
  const equipmentOptions = materialGroups?.map((materialGroup, materialGroupIndex) => {
    return {
      ...materialGroup,
      path: `${path}[${equipmentIndex}].materialGroups[${materialGroupIndex}]`,
      label:
        nameOptions?.find(nameOption => nameOption?.id === materialGroup?.cpNameId)?.label ??
        equipmentTypeOptions?.find(option => option?.id === materialGroup?.equipmentTypeId)?.translateLabel,
      children: (
        <>
          <div className="properties-header-description">
            {descriptionOptions?.find(descriptionOption => descriptionOption?.id === materialGroup?.cpDescriptionId)?.label}
          </div>
        </>
      ),
    };
  });
  const handleSelected = (pathName) => (name, value) => {
    if (isSet) {
      dispatch(updateSelectSingleOption({ name, value, path: pathName}));
    } else {
      dispatch(updateSelectedOption({ name, value }));
    }
    setErrorMessage('');
    setIsSubmitForShare(false);
  };

  return (
    <div className={'component-properties'}>
      <SelectPropertiesOptions
        options={equipmentOptions}
        disabled={!isNew && !isEdit && !isConfiguration}
        name={'Equipments'}
        hasImage={true}
        onSelected={handleSelected(`${path}[${equipmentIndex}].materialGroups`)}
        isRequiredSection={false}
      />
    </div>
  );
};

export default ArrangementEquipments;
