export interface IBlogContent {
  id?: number;
  number?: number | null;
  title?: string | null;
  text?: string | null;
  imageDescription?: string | null;
  imagePath?: string | null;
}

export const defaultValue: Readonly<IBlogContent> = {};
