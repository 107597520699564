import React, {memo, useCallback, useContext, useMemo} from 'react';
import PartForm from 'app/shared/layout/FormGenerator/Components/PartForm';
import Translate from 'app/shared/layout/Translation/translate';
import { HomeObjectContext } from 'app/modules/administration/home-object-v2/Components/home-object-update/home-object-update';
import DeleteRowWrapper from 'app/modules/administration/home-object-v2/delete-row-wrapper';
import { useAppDispatch } from 'app/config/store';
import {removeOptionHomeObject, updateHomeObject} from 'app/entities/project/project.reducer';
import { useOptions } from 'app/shared/hooks/useOptions';
import { TRANSLATED_DICTS_KEYS } from 'app/entities/translate-dict-item/constants';

import { Row } from 'reactstrap';
import { onChangePositiveInput, REQUIRED } from 'app/shared/util/validation';
import { debounce } from 'lodash';
import { getNumber } from 'app/shared/util/entity-utils';
import { useMaterialOptions } from 'app/modules/administration/home-object-v2/hooks/useMaterialOptions';
import { useEquipmentTypes } from 'app/modules/administration/home-object-v2/hooks/useEquipmentTypes';

export const EquipmentMemberItem = ({
  equipmentMemberTypes,
  equipmentMember,
  equipmentMemberIndex,
  path,
  isDetail = false,
  showDelete,
}) => {
  const dispatch = useAppDispatch();
  const { onChangeInputNumber, onChangeSelect, onChangeInput, onRemoveRow, calcTotalPrice } = useContext(HomeObjectContext);
  const { priceTypesOptions } = useOptions(TRANSLATED_DICTS_KEYS.PRICE_TYPES, true);
  const materialTypesOptions = useMaterialOptions({ isDetail, hasMaterial: true });


  const equipmentTypeOptions = useEquipmentTypes(isDetail);

  const options = useMemo(
    () =>
      equipmentTypeOptions?.filter(
        equipmentTypeOption =>
          !equipmentMemberTypes?.includes(equipmentTypeOption?.id) || equipmentMember?.equipmentTypeId === equipmentTypeOption?.id
      ),
    [equipmentTypeOptions, equipmentMemberTypes, equipmentMember?.equipmentTypeId]
  );

  const equipmentType = useMemo(
    () => options.find(equipmentTypeOption => equipmentTypeOption.id === equipmentMember?.equipmentTypeId),
    [options, equipmentMember?.equipmentTypeId]
  );

  const updateTotalPrice = debounce((quantity, netPurchasingPrice) => {
    const totalPrice = calcTotalPrice(quantity, netPurchasingPrice);
    dispatch(updateHomeObject({ name: `${path}[${equipmentMemberIndex}].price`, value: totalPrice }));
  }, 300);

  const handleChangeType = useCallback(
    (objSelected, meta) => {
      if (meta.action === 'clear' && objSelected === null) {
        dispatch(
          removeOptionHomeObject({
            keys: [meta?.name],
          })
        );
      } else {
        onChangeSelect()(objSelected, meta);
      }
      updateTotalPrice(equipmentMember?.quantity, objSelected?.propertyPrice?.netPurchasingPrice);
    },
    [removeOptionHomeObject, updateTotalPrice, onChangeSelect, equipmentMember?.quantity]
  );

  const handleChangeInput = ev => {
    onChangeInputNumber(ev);
    updateTotalPrice(getNumber(ev.target.value, 0), equipmentType?.propertyPrice?.netPurchasingPrice);
  };

  const fieldComponent = [
    {
      readOnly: isDetail,
      name: `${path}[${equipmentMemberIndex}].equipmentTypeId`,
      id: `${path}[${equipmentMemberIndex}].equipmentTypeId`,
      type: 'select',
      onChange: handleChangeType,
      value: equipmentType,
      options,
      isClearable: true,
      validate: {
        ...REQUIRED(),
      },
      label: (
        <>
          <Translate contentKey="proEcoApp.project.equipmentTypeId" />
        </>
      ),
      col: { lg: '2', md: '2' },
    },
    {
      readOnly: true,
      name: `${path}[${equipmentMemberIndex}].materialTypeId`,
      id: `${path}[${equipmentMemberIndex}].materialTypeId`,
      type: 'select',
      value: materialTypesOptions?.find(materialTypesOption => materialTypesOption.id === equipmentType?.materialTypeId),
      options: materialTypesOptions,
      isClearable: true,
      label: <Translate contentKey="proEcoApp.project.materialTypeId" />,
      col: { lg: '2', md: '2' },
    },
    {
      readOnly: true,
      name: `${path}[${equipmentMemberIndex}].priceTypeId`,
      id: `${path}[${equipmentMemberIndex}].priceTypeId`,
      type: 'select',
      onChange: onChangeSelect(''),
      value: priceTypesOptions?.find(priceTypesOption => priceTypesOption.id === equipmentType?.propertyPrice?.priceTypeId),
      options: priceTypesOptions,
      isClearable: true,
      label: <Translate contentKey="proEcoApp.project.priceTypeId" />,
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: isDetail,
      type: 'number',
      onKeyPress: onChangePositiveInput,
      name: `${path}[${equipmentMemberIndex}].quantity`,
      id: `${path}[${equipmentMemberIndex}].quantity`,
      onChange: handleChangeInput,
      min: 0,
      validate: {
        ...REQUIRED(),
      },
      onWheel: event => event.currentTarget.blur(),
      value: equipmentMember.quantity,
      label: <Translate contentKey="proEcoApp.project.quantity" />,
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: true,
      name: `${path}[${equipmentMemberIndex}].pricePerUnit`,
      id: `${path}[${equipmentMemberIndex}].pricePerUnit`,
      value: equipmentType?.propertyPrice?.netPurchasingPrice,
      label: <Translate contentKey="proEcoApp.project.pricePerUnit" />,
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN" />
        </span>
      ),
      col: { lg: '2', md: '2' },
    },
    {
      autoComplete: 'off',
      readOnly: true,
      name: `${path}[${equipmentMemberIndex}].price`,
      id: `${path}[${equipmentMemberIndex}].price`,
      onChange: onChangeInput,
      value: equipmentMember?.price,
      label: <Translate contentKey="proEcoApp.project.totalNetPrice" />,
      appendText: (
        <span className={'unit-append'}>
          <Translate contentKey="currency.PLN" />
        </span>
      ),
      col: { lg: '2', md: '2' },
    },
  ];

  return (
    <div>
      <DeleteRowWrapper showDelete={showDelete} handleDelete={() => onRemoveRow(path, equipmentMemberIndex)}>
        <Row>
          <PartForm fields={fieldComponent} />
        </Row>
      </DeleteRowWrapper>
    </div>
  );
};

export default memo(EquipmentMemberItem);
