import './app.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'app/config/dayjs.ts';
import 'react-tabs/style/react-tabs.css';
import 'app/shared/layout/TopNavMenu/navbar.scss';
import 'app/shared/layout/TopNavMenu/account-menu.scss';
import 'app/shared/layout/LocaleMenu/LocaleMenu.scss';
import React, {createContext, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {BrowserRouter as Router, useLocation} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { useAppDispatch } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { Storage } from 'react-jhipster';
import Loader from 'app/shared/layout/Loader/Loader';
import Loadable from 'react-loadable';
import ScrollToTop from './shared/layout/ScrollToTop/ScrollToTop';
import CookieConsent from "app/shared/layout/CookieConsent/CookieConsent";

const AppRoutes = Loadable({
  loader: () => import(/* webpackChunkName: "appContainer" */ 'app/routes'),
  loading: () => <Loader />,
});
const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IConfigurationLayout {
  tabs?: any[];
  setTabs?: any;
  currentTab?: number;
  setCurrentTab?: any;
  errorMessage?: any;
  setErrorMessage?: any;
  changeCurrentTab?: any;
  setChangeCurrentTab?: any;
  setScrollTo?: any;
}

export const ConfigurationLayoutContext = createContext<IConfigurationLayout>({});

export const App = () => {
  const dispatch = useAppDispatch();
  const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
  const [tabs, setTabs] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [changeCurrentTab, setChangeCurrentTab] = useState();
  const [scrollTo, setScrollTo] = useState('');
  const element = document.getElementById(scrollTo);

  useEffect(() => {
    if (token) {
      dispatch(getSession(true));
    }
    dispatch(getProfile());
  }, []);

  useLayoutEffect(() => {
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollTo, element]);

  return (
    <Router basename={baseHref}>
        <CookieConsent/>
        <ScrollToTop>
        <ConfigurationLayoutContext.Provider
          value={{
            tabs,
            setTabs,
            currentTab,
            setCurrentTab,
            errorMessage,
            setErrorMessage,
            changeCurrentTab,
            setChangeCurrentTab,
            setScrollTo,
          }}
        >
          <ToastContainer position={toast.POSITION.TOP_RIGHT} className="toastify-container" toastClassName="toastify-toast" />
          <AppRoutes />
        </ConfigurationLayoutContext.Provider>
      </ScrollToTop>
    </Router>
  );
};

export default App;
